import { DateInput, DatePicker } from "@progress/kendo-react-dateinputs";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ButtonPrimary, ButtonTertiary } from "../Sections/Components/Buttons";
import { InputDatePicker, InputText } from "../Sections/Components/Inputs";
import { DialogBox } from "../Sections/Dialogs";
import useLoader from "../../hooks/useLoader";
import moment from "moment";
import { useUsuario } from "../../Context/UserContext";
import NonWorkingService from '../../Services/NonWorkingService';

export default function Created() {
  let history = useHistory();
  const { usuario, signOutCognito } = useUsuario();
  const Loading = useLoader();

  const [nonWork, setNonWork] = useState({ reason: "", date: null });
  const [editing, setEditing] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [dataConfirm, setDataConfirm] = useState({});
  const [visible, setVisible] = useState(false);
  const [dataResponse, setDataResponse] = useState({
    title: "",
    message: "",
    success: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNonWork({ ...nonWork, [name]: value });
  };

  const openDialog = () => {
    setVisibleConfirm(true);

    if (editing) {
      setDataConfirm({
        title: "Edición de día inhabil",
        message: "¿Desea guardar los cambios?",
      });
    } else {
      setDataConfirm({
        title: "Creación de día inhabil",
        message: "¿Estás seguro de registrar este día inhabil?",
      });
    }
  };
  const handleDialogResponse = () => {
    if (dataResponse.success) {
      history.push("/dias-inhabiles");
    } else {
      setVisible(!visible);
    }
  };

  const handleClickCancel = () => {
    history.push("/dias-inhabiles");
  };
  const handleClickSave = async () => {
    setVisibleConfirm(false);
    let success = true;
    let message = "";
    let data = {};

    if (editing) {
      try {
        Loading();
        if (!(nonWork.reason != "" && nonWork.reason != null))
          throw Error("El motivo es obligatorio");

        if (nonWork.date != null) throw Error("La fecha es obligatorio");

        data.reason = nonWork.reason;
        data.date = moment(nonWork.date).format("YYYY-MM-DD");
        // const res = await AuditService.update(audit.id, data);
        message = "La información de la auditoria se ha actualizado";
        Loading();
      } catch (error) {
        Loading();
        if (error?.response?.status == 401) signOutCognito();
        success = false;
        message = error.message;
      }
      setDataResponse({
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: success,
      });
      setVisible(!visible);
    } else {
      try {
        Loading();
        if (!(nonWork.reason != "" && nonWork.reason != null))
          throw Error("El motivo es obligatorio");

        if (!(nonWork.date != null)) throw Error("La fecha es obligatorio");

        data.reason = nonWork.reason;
        data.date = moment(nonWork.date).format("YYYY-MM-DD");
        const res = await NonWorkingService.create(data);
        message = "El día inhabil se ha guardado";
        Loading();
      } catch (error) {
        Loading();
        if (error?.response?.status == 401) signOutCognito();

        success = false;
        message = error.message;
      }
      setDataResponse({
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: success,
      });
      setVisible(!visible);
    }
  };
  return (
    <div className="finbe-app-container">
      {/* <h3>Datos d</h3> */}
      <Form
        render={(formRenderProps) => (
          <FormElement>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputDatePicker}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="date"
                  label="Fecha"
                  nombre="date"
                  onChange={handleChange}
                  placeholder="Ingresar fecha"
                  value={nonWork.date}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="reason"
                  label="Motivo"
                  nombre="reason"
                  onChange={handleChange}
                  placeholder="Ingresar motivo"
                  value={nonWork.reason}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
          </FormElement>
        )}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}></div>
        <div style={{ display: "flex", textAling: "left" }}>
          <ButtonTertiary
            className=""
            icon="close"
            onClick={handleClickCancel}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Cancelar"
          />
          <ButtonPrimary
            className=""
            icon="check"
            onClick={openDialog}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Guardar"
          />
        </div>
      </div>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={dataResponse.title}
        visible={visible}
      >
        {dataResponse.message}
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => {
          setVisibleConfirm(false);
        }}
        handelCancel={() => setVisibleConfirm(false)}
        handelAccept={handleClickSave}
        title={dataConfirm.title}
        visible={visibleConfirm}
        visibleCancelButton={true}
      >
        {dataConfirm.message}
      </DialogBox>
    </div>
  );
}
