import React, { useEffect } from "react";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import { InputComboBox, InputText } from "../Sections/Components/Inputs";
import UserButtons from "../users/userButtons";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { ButtonDanger, ButtonSuccess } from "../Sections/Components/Buttons";
import { DialogBox } from "../Sections/Dialogs";
import EmailServiceDeskService from "../../Services/EmailServiceDeskService";
import { useUsuario } from "../../Context/UserContext";
import useLoader from "../../hooks/useLoader";
export default function CreateEdit(props) {
  const { usuario, signOutCognito } = useUsuario();

  const initialState = { email: "" };
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const [email, setEmail] = useState(initialState);

  const [visible, setVisible] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [response, setResponse] = useState({
    title: "",
    message: "",
    success: "",
  });
  const [confirm, setConfirm] = useState({});
  const Loading = useLoader();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEmail({ ...email, [name]: value });
  };
  const getEmailInformation = async (id) => {
    try {
      Loading();
      const { data } = await EmailServiceDeskService.getById(id);
      setEmail({ email: data.email });
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status === 401) signOutCognito();
    }
  };
  const handleSave = async () => {
    setVisibleConfirm(false);
    let success = true;
    let message = "";
    try {
      Loading();
      if (
        !RegExp("([a-z0-9_.-]+)@([da-z.-]+).([a-z.]{2,6})$").test(email.email)
      ) {
        throw new Error("El correo ingresado no tiene el formato correcto");
      }
      if (isEditing) {
        const { data } = await EmailServiceDeskService.update(
          props.match.params.id,
          { email: email.email }
        );
        message = "El correo [" + data.email + "] se ha guardado";
      } else {
        const { data } = await EmailServiceDeskService.create({
          email: email.email,
        });
        message = "El correo [" + data.email + "] se ha guardado";
      }
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status === 401) signOutCognito();
      success = false;
      message = error.message;
    }
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
    });
    setVisible(!visible);
  };
  const handleDialogResponse = () => {
    if (response.success) {
      history.push("/emails-mesa-servicio");
    } else {
      setVisible(!visible);
    }
  };
  const handleConfirm = () => {
    if (isEditing) {
      setConfirm({
        title: "Edición de correo",
        message: "¿Desea guardar los cambios?",
      });
    } else {
      setConfirm({
        title: "Creación de correo",
        message: "¿Estás seguro de guardar este email?",
      });
    }
    setVisibleConfirm(true);
  };

  useEffect(() => {
    if (props.match.params.id) {
      setIsEditing(true);
      getEmailInformation(props.match.params.id);
    }
  }, []);
  return (
    <div className="finbe-app-container">
      <Form
        render={(formRenderProps) => (
          <FormElement>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="email"
                  label="* Correo electrónico"
                  nombre="email"
                  onChange={handleChange}
                  placeholder="Ingresar correo electrónico"
                  value={email.email}
                  required={true}
                  rules={"([a-z0-9_.-]+)@([da-z.-]+).([a-z.]{2,6})$"}
                  validityStyles={true}
                  minLength={1}
                  maxLength={150}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
          </FormElement>
        )}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "5em",
        }}
      >
        <div style={{ display: "flex" }}></div>
        <div style={{ display: "flex", textAling: "left" }}>
          <ButtonDanger
            className=""
            icon="close"
            onClick={() => {
              history.push("/emails-mesa-servicio");
            }}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Cancelar"
          />
          <ButtonSuccess
            className=""
            icon="check"
            onClick={handleConfirm}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Guardar"
          />
        </div>
      </div>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={response.title}
        visible={visible}
      >
        {response.message}
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => {
          setVisibleConfirm(false);
        }}
        handelCancel={() => setVisibleConfirm(false)}
        handelAccept={handleSave}
        title={confirm.title}
        visible={visibleConfirm}
        visibleCancelButton={true}
      >
        {confirm.message}
      </DialogBox>
    </div>
  );
}
