import React, { useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { StepBox } from "../../Sections/Steps";
import { InputComboBox, InputText } from "../../Sections/Components/Inputs";
import {
  ButtonAction,
  ButtonPrimary,
  ButtonTertiary,
} from "../../Sections/Components/Buttons";

export const RepresentanteLegal = (props) => {
  const [dataState, setDataState] = useState({
    sort: [{ field: "name", dir: "asc" }],
    take: 10,
    skip: 0,
  });

  const {
    addCompanie,
    companies,
    cancel,
    buttons,
    handleChange,
    legalPerson,
    legalPersons,
    legalPersonsFinbe,
    roles,
    step,
    saveForm,
    typeAccount,
  } = props;

  const showForm =
    step == 2 ? true : step == 3 ? (typeAccount == 2 ? true : false) : false;
  const showButtons = step == 2 ? (typeAccount == 1 ? true : false) : false;
  const showTable =
    step == 2
      ? typeAccount == 1
        ? true
        : false
      : step == 3
      ? typeAccount == 1
        ? true
        : false
      : false;
  const showBTable = step == 2 && typeAccount == 1 ? true : false;

  let _listCompanies = companies.map((x) => {
    return { id: x.rowId, text: x.name };
  });
  let _legalPersons = legalPersonsFinbe.map((x) => {
    return { id: x.numeroCliente, text: x.nombres + " " + x.apellidos };
  });

  return (
    <>
      <StepBox title="Datos del representante legal">
        <Form
          render={(formRenderProps) => (
            <FormElement>
              {showForm && (
                <>
                  <div style={{ display: "flex" }}>
                    {typeAccount == 1 && (
                      <div style={{ width: "50%" }}>
                        <Field
                          component={InputComboBox}
                          data={_listCompanies}
                          dClassName="k-form-field-wrap finbe-form-combo"
                          id="companyId"
                          label="Empresa"
                          name="companyId"
                          onChange={handleChange}
                          placeholder="Seleccionar empresa"
                          required={true}
                          validityStyles={true}
                          value={_listCompanies.find(
                            (x) => x.id == legalPerson.companyId
                          )}
                          wrapperStyle={{ width: "90%" }}
                        />
                      </div>
                    )}
                    <div style={{ width: "50%" }}>
                      <Field
                        component={InputComboBox}
                        data={_legalPersons}
                        dClassName="k-form-field-wrap finbe-form-combo"
                        id="legalId"
                        label="* Representante Legal"
                        name="legalId"
                        onChange={handleChange}
                        placeholder="Seleccionar representante legal"
                        required={true}
                        validityStyles={true}
                        value={_legalPersons.find(
                          (x) => x.id === legalPerson.legalId
                        )}
                        wrapperStyle={{ width: "90%" }}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                      <Field
                        component={InputText}
                        dClassName="k-form-field-wrap finbe-form-field-filter"
                        id="firstname"
                        label="Nombres"
                        nombre="firstname"
                        onChange={handleChange}
                        placeholder="Ingresar nombre"
                        value={legalPerson.firstname}
                        wrapperStyle={{ width: "90%" }}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <Field
                        component={InputText}
                        dClassName="k-form-field-wrap finbe-form-field-filter"
                        id="lastname"
                        label="Apellidos"
                        nombre="lastname"
                        onChange={handleChange}
                        placeholder="Ingresar apellidos"
                        value={legalPerson.lastname}
                        wrapperStyle={{ width: "90%" }}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                      <Field
                        component={InputText}
                        dClassName="k-form-field-wrap finbe-form-field-filter"
                        id="email"
                        label="Correo electronico"
                        maxLength={150}
                        minLength={1}
                        nombre="email"
                        onChange={handleChange}
                        placeholder="Ingresar correo"
                        required={true}
                        rules={"([a-z0-9_.-]+)@([da-z.-]+).([a-z.]{2,6})$"}
                        validityStyles={true}
                        value={legalPerson.email}
                        wrapperStyle={{ width: "90%" }}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <Field
                        component={InputText}
                        dClassName="k-form-field-wrap finbe-form-field-filter"
                        id="phone"
                        label="Teléfono"
                        nombre="phone"
                        onChange={handleChange}
                        rules={"^[0-9]{8,13}$"}
                        validityStyles={true}
                        minLength={0}
                        maxLength={13}
                        placeholder="Ingresar teléfono"
                        value={legalPerson.phone}
                        wrapperStyle={{ width: "90%" }}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                      <Field
                        component={InputComboBox}
                        data={roles}
                        dClassName="k-form-field-wrap finbe-form-combo"
                        id="roleId"
                        label="* Rol"
                        name="roleId"
                        onChange={handleChange}
                        placeholder="Seleccionar rol"
                        value={roles.find((x) => x.id === legalPerson.roleId)}
                        wrapperStyle={{ width: "90%" }}
                      />
                    </div>
                  </div>
                </>
              )}
            </FormElement>
          )}
        />
        {showButtons && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "3.5em",
              marginTop: "1.1em",
            }}
          >
            <ButtonPrimary
              disabled={buttons.add}
              onClick={addCompanie}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Agregar"
            />
            <ButtonPrimary
              disabled={buttons.save}
              onClick={saveForm}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Guardar"
            />
            <ButtonTertiary
              disabled={buttons.cancel}
              onClick={cancel}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Cancelar"
            />
          </div>
        )}
      </StepBox>
      {showTable && (
        <Grid
          className="finbe-table"
          pageable={{
            info: false,
          }}
          sortable
          data={process(legalPersons, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.data);
          }}
        >
          <Column
            title="Nombre del representante legal"
            cell={(data) => (
              <th>{data.dataItem.firstname + " " + data.dataItem.lastname}</th>
            )}
          />
          <Column field="email" title="Correo" />
          <Column field="phone" title="Teléfono" />
          <Column
            title="Empresa"
            cell={(data) => (
              <th>
                {data.dataItem.companyId != null
                  ? props.companies.find(
                      (x) => x.rowId == data.dataItem.companyId
                    ).name
                  : "-"}
              </th>
            )}
          />
          {showBTable && (
            <Column
              title="Acciones"
              cell={(data) => (
                <td style={{ display: "flex" }}>
                  <ButtonAction
                    title={"Editar"}
                    data={data.dataItem.rowId}
                    icon=" icon editar"
                    isRedirect={false}
                    look="bare"
                    onClick={props.editCompanie}
                    primary={false}
                  />
                  <ButtonAction
                    title={"Eliminar"}
                    data={data.dataItem.rowId}
                    icon=" icon eliminar"
                    isRedirect={false}
                    look="bare"
                    onClick={props.removeCompanie}
                    primary={false}
                  />
                </td>
              )}
            />
          )}
        </Grid>
      )}
    </>
  );
};
