
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Drawer, DrawerContent, DrawerItem } from '@progress/kendo-react-layout';
import { menuSU, menuAF, menuOF, menuRL, menuOA } from '../../Utils/Layout/ItemsRoutes'
import { useUsuario } from '../../Context/UserContext'

import UserServices from "../../Services/UserServices";

import dotenv from 'dotenv'

dotenv.config();
const CustomItem = (props) => {
    const { visible, showNotification ,...others } = props;
    const arrowDir = props['data-expanded'] ? 'k-i-arrow-chevron-down' : 'k-i-arrow-chevron-right';
    return (
        <React.Fragment>
            {props.visible === false ? null :
                <DrawerItem {...others}>
                    <div className={"finbe-menu-item-content"}>
                        <span className={`finbe-menu-icon k-icon${props.icon?" "+props.icon:" icon none"}`} />
                        <span className={'k-item-text'}>{props.text}</span>
                        {showNotification && <span className="icon circulo-rojo-notificacion"></span>}
                        {props['data-expanded'] !== undefined && <span className={"finbe-menu-icon finbe-arrow k-icon " + arrowDir}/>}
                    </div>
                </DrawerItem>}
        </React.Fragment>
    );
};

const DrawerContainer = (props) => {
    const [items, setItems] = useState([])
    const [notificacion, setNotifications] = useState(false)
    const { usuario, isAuthenticated } = useUsuario();

    useEffect(() => {
        if (usuario){
            checkNotification();
            getMenu();
        }
    }, [usuario, isAuthenticated])

    const checkNotification = async () => {
        const res = await UserServices.getPendingsNotifications(usuario.id);
        setNotifications(res.data.total > 0 );
    }

    const getMenu = () => {
        switch (usuario.type) {
            case 1:
                setItems(menuSU)
                break;
            case 2:
                setItems(menuAF)
                break;
            case 3:
                setItems(menuOF)
                break;
            case 4:
                setItems(menuRL)
                break;
            case 5:
                setItems(menuOA)
                break;
            default:
                setItems([])

        }
    }


    const onSelect = (ev) => {
        const currentItem = ev.itemTarget.props;
        const isParent = currentItem['data-expanded'] !== undefined;
        const nextExpanded = !currentItem['data-expanded'];

        const newData = items.map((item) => {
            const { selected, ['data-expanded']: currentExpanded, id, ...others } = item;
            const isCurrentItem = currentItem.id === id;
            return {
                selected: isCurrentItem,
                ['data-expanded']: isCurrentItem && isParent ? nextExpanded : currentExpanded,
                id,
                ...others
            };
        });

        setItems(newData)
        // this.setState({
        //     items: newData,
        //     location: ev.itemTarget.props.text
        // })

        props.history.push(ev.itemTarget.props.route);
    }

    const data = items.map((item) => {
        const { parentId, ...others } = item;
        if (parentId !== undefined) {
            const parent = items.find(parent => parent.id === parentId)
            return {
                ...others,
                visible: parent['data-expanded'],
                showNotification: item.text === 'Notificaciones' && notificacion,
            };
        }
        const filtered = items
            .filter(t => t.selected)
            .filter(t => t.parentId === item.id);
        return {
            ...others,
            selected: filtered.length > 0,
        }
    });

    return (
        <aside className="finbe-aside">
            <Drawer  
                className={"finbe-menu"}                              
                expanded={true}
                mode='push'
                width={240}
                items={data}
                mini="true"
                item={CustomItem}
                onSelect={onSelect}
            >
                <DrawerContent>
                    {props.children}
                </DrawerContent>
            </Drawer>
        </aside>
    );
}

export default withRouter(DrawerContainer);
