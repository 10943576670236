import React from "react";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import { InputComboBox, InputText } from "../../Sections/Components/Inputs";
import { EMAIL_REGEX, PHONE_REGEX } from "../../../Utils/Validators/regex";

export const FormRL = (props) => {
  const { user, agencies, handleChange, nameAccount, roles, editing } = props;
  return (
    <div>
      <h3>Datos de Usuario</h3>
      <Form
        render={(formRenderProps) => (
          <FormElement>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="firstname"
                  label="* Nombres"
                  nombre="firstname"
                  onChange={handleChange}
                  placeholder="Ingresar nombres"
                  required={true}
                  validityStyles={true}
                  minLength={1}
                  maxLength={150}
                  value={user.firstname}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="lastname"
                  label="* Apellidos"
                  nombre="lastname"
                  onChange={handleChange}
                  placeholder="Ingresar apellidos"
                  required={true}
                  validityStyles={true}
                  minLength={1}
                  maxLength={150}
                  value={user.lastname}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  // disabled={editing}
                  id="email"
                  label="* Correo electrónico"
                  nombre="email"
                  onChange={handleChange}
                  placeholder="Ingresar correo electrónico"
                  required={true}
                  rules={EMAIL_REGEX}
                  validityStyles={true}
                  minLength={1}
                  maxLength={150}
                  value={user.email}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="phone"
                  label="Teléfono"
                  nombre="phone"
                  onChange={handleChange}
                  placeholder="Ingresar teléfono"
                  rules={PHONE_REGEX}
                  validityStyles={true}
                  minLength={0}
                  maxLength={13}
                  value={user.phone}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  // disabled={editing}
                  id="email"
                  label="* Correos adicionales"
                  nombre="additionalEmails"
                  onChange={handleChange}
                  placeholder="Ingresar correos adicionales"
                  value={user.additionalEmails}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputComboBox}
                  data={roles}
                  dClassName="k-form-field-wrap finbe-form-combo"
                  id="roleId"
                  label="* Rol"
                  name="roleId"
                  onChange={handleChange}
                  placeholder="Seleccionar rol"
                  required={true}
                  validityStyles={true}
                  value={roles.find((x) => x.id === user.roleId)}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            {/* <h4>{nameAccount}</h4>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputComboBox}
                  data={agencies}
                  dClassName="k-form-field-wrap finbe-form-combo"
                  id="agencyId"
                  label="* Sucursal"
                  name="agencyId"
                  onChange={handleChange}
                  placeholder="Seleccionar sucursal"
                  required={true}
                  validityStyles={true}
                  value={agencies.find((x) => x.id === user.agencyId)}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div> */}
          </FormElement>
        )}
      />
    </div>
  );
};
