import React from 'react'
import { Input, RadioGroup, Switch, Checkbox, NumericTextBox } from '@progress/kendo-react-inputs'
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { DateRangePicker, DateInput } from '@progress/kendo-react-dateinputs';
import { FieldWrapper } from '@progress/kendo-react-form';

export const InputText = (props) => {

    const { id, label, nombre, value, required, wrapperStyle, onChange } = props;

    return (
        <FieldWrapper style={wrapperStyle}>
            <div className={'k-form-field-wrap'}>
                <Input
                    id={id}
                    name={nombre}
                    label={label}
                    value={value}
                    onChange={onChange}
                    required={required}

                />

            </div>
        </FieldWrapper>

    )
}

export const Numeric = (props) => {
    const { id, nombre, value, min, max, wrapperStyle, label, onChange } = props
    return (
        <FieldWrapper style={wrapperStyle}>
            <div className='k-form-field-wrap'>
                <NumericTextBox
                label={label}
                name={nombre}
                id={id}
                value={value}
                min={min}
                max={max}
                onChange={onChange}
                />
            </div>
        </FieldWrapper>    
    )
}

export const ComboBoxInput = (props) => {
    const { id, nombre, data, value, wrapperStyle, label, onChange, allowCustom } = props;
    return (
        <FieldWrapper style={wrapperStyle}>
            <div className={'k-form-field-wrap'}>
                <ComboBox
                    id={id}
                    name={nombre}
                    data={data}
                    label={label}
                    suggest={true}
                    textField="text"
                    dataItemKey="id"
                    value={value}
                    onChange={onChange}
                    allowCustom={allowCustom}
                />

            </div>
        </FieldWrapper>

    )
}

export const RadioGroupInput = (props) => {
    const { data, layout, id, nombre, textField, value, onChange, disabled } = props
    return (
        <FieldWrapper>
            <div className={'k-form-field-wrap'}>
                <RadioGroup data={data}
                    layout={layout}
                    id={id}
                    name={nombre}
                    textField={textField}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}/>
                    

            </div>
        </FieldWrapper>
    )
}


export const SwitchInput = (props) => {
    const { data, defaultValue, onChange } = props;
    return <Switch
        data={data}
        defaultChecked={defaultValue}
        onChange={onChange}
    />
}

export const CheckboxInput = (props) => {
    const { id, name, label, defaultChecked, onChange, } = props
    return <Checkbox
        id={id}
        name={name}
        label={label}
        defaultChecked={defaultChecked}
        onChange={onChange}
    />
}

export const MultiSelectInput = (props) => {
    const { id, name, label, data, onChange, value, allowCustom, textField } = props

    return <MultiSelect
        id={id}
        name={name}
        label={label}
        data={data}
        onChange={onChange}
        value={value}
        allowCustom={allowCustom}
        textField={textField}

    />
}
export const DateInputItem = (props) => {
    return <DateInput
        id={props.id}
        name={props.name}
        label={props.label}
        value={props.value}

    />
}
export const RangeDatePicker = (props) => {
    // customFormatPlaceholder = {
    //     year: 'y',
    //     month: 'M',
    //     day: 'd',
    //     hour: 'h',
    //     minute: 'm',
    //     second: 's'
    // }

    const startDateInputSettings = {
        label: 'Inicia',
        format: 'dd/M/yyyy',
    }

    const endDateInputSettings = {
        label: 'Finaliza',
        format: 'dd/M/yyyy',

    }
    return (
        <FieldWrapper style={props.wrapperStyle}>

            <DateRangePicker
                id={props.id}
                name={props.nombre}
                onChange={props.onChange}
                startDateInputSettings={startDateInputSettings}
                endDateInputSettings={endDateInputSettings}
                defaultValue={props.value}
            />
        </FieldWrapper>
    )

}
