import httpCommon from "../Utils/http-common";

const getAll = (params) => {
  const client = httpCommon();
  return client.get("/non-workings", params);
};

const getById = (id) => {
  const client = httpCommon();
  return client.get(`/non-workings/${id}`);
};

const create = (data) => {
  const client = httpCommon();
  return client.post("/non-workings", data);
};

// const update = (id, data) => {
//   const client = httpCommon();
//   return client.put(`/non-workings/${id}`, data);
// };

const remove = (id) => {
  const client = httpCommon();
  return client.delete(`/non-workings/${id}`);
};

export default {
  getAll,
  getById,
  create,
//   update,
  remove,
};
