import React from 'react'

export default function ErrorsUnits({validations=[]}) {

    return (
        <div style={{overflow:"auto",height:"200px",width:"450px"}}>
            {validations.map(item=>{
                if(item.items.length>1){
                    return <ul>{item.title}{item.items.map(elem=>(<li>{elem}</li>))}</ul>
                }else{
                    return <p>{item.title} {item.items[0]}</p>
                }
            })}
        </div>
    )
}
