/**
 * @author: Alexander Chi
 * @description: Component create step CSV
 * @date: 24/sept/2020
 **/
import React, { Component } from 'react';
import { StepBox } from '../../Sections/Steps';
import { InputFile,InputFileH } from '../../Sections/Components/Inputs';
import { Form, FormElement } from '@progress/kendo-react-form';
import { CSVReader } from 'react-papaparse'

/** Main component file csv **/
class File extends Component {

  constructor (props) {
    super(props);
    this.state = { };
  };

  render () {
    const {
      attachedDocumentCsv,
      fileTitle,
      handleChangeFile,
      handleDeleteFile,
      styleContainer,
      refFile,
    } = this.props;
    return (
    <div className="finbe-app-container" style = {styleContainer}>
      <StepBox
        title = {fileTitle ?? 'Subir documento CSV'}
      >
        <Form
          render={(formRenderProps) => (
            <FormElement>
              <div style = {{display: 'flex'}}>
                <div style = {{width: '50%'}}>
                <InputFileH 
                  handleChange={handleChangeFile}
                  handleDelete={handleDeleteFile}
                  name='attachedDocumentCsv'
                  id='attachedDocumentCsv'
                  files={[...attachedDocumentCsv]}
                  accept={".csv"}
                  uploadLabel={"Documento CSV"}
                  refFile={refFile}
                  />
                  {/* <InputFile
                    fieldWrapperStyle = {{ width: '90%' }}
                    uploadDefaultFiles = {attachedDocumentCsv}
                    uploadId = 'attachedDocumentCsv'
                    uploadLabel = 'Documento CSV'
                    uploadOnAdd = {handleChangeFile}
                    uploadAllowedExtensions = {['csv']}
                    uploadRemoveUrl = 'https://demos.telerik.com/kendo-ui/service-v4/upload/remove'
                    uploadSaveUrl = 'https://demos.telerik.com/kendo-ui/service-v4/upload/save'
                  /> */}
                </div>
              </div>
            </FormElement>
          )}
        />
      </StepBox>
    </div>
    );
  }
};

export default File;

/** default props file **/
File.defaultProps = {
  attachedDocumentCsv: []
};
