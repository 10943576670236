import httpCommon from "../Utils/http-common";

const getAll = (params) => {
  const client = httpCommon();
  return client.get("/users", params);
};

const getCompanies = (userId) => {
  const client = httpCommon();
  return client.get(`/users/${userId}/companies`);
};
const getAgencies = (userId) => {
  const client = httpCommon();
  return client.get(`/users/${userId}/agencies`);
};

const getById = (id) => {
  const client = httpCommon();
  return client.get(`/users/${id}`);
};

const getNotifications = (id, params) => {
  const client = httpCommon();
  return client.get(`/users/${id}/notifications`, params);
};

const getPendingsNotifications = (id) => {
  const client = httpCommon();
  return client.get(`/users/${id}/pending-notifications`);
};

const create = (data) => {
  const client = httpCommon();
  return client.post(`/users`, data);
};

const update = (id, data) => {
  const client = httpCommon();
  return client.put(`/users/${id}`, data);
};

const setActive = (id, data) => {
  const client = httpCommon();
  return client.patch(`/users/${id}/active`, data);
};

const setArchive = (id, data) => {
  const client = httpCommon();
  return client.patch(`/users/${id}/archive`, data);
};

const getArchived = () => {
  const client = httpCommon();
  return client.get("/users/archived");
};

const resendPassword = (id) => {
  const client = httpCommon();
  return client.patch(`/users/${id}/resend`);
};

//Aun faltan los metodos de filtrado

export default {
  getAll,
  getCompanies,
  getAgencies,
  getById,
  getNotifications,
  getPendingsNotifications,
  create,
  update,
  setActive,
  setArchive,
  getArchived,
  resendPassword,
};
