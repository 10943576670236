import httpCommon from "../Utils/http-common";

const getAll = (params) => {
  const client = httpCommon();
  return client.get("/credits-failures", params);
};
const setArchived = (id, data) => {
  const client = httpCommon();
  return client.patch(`/credits-failures/${id}/archive`, data);
};
const retryInsertion = (id, data) => {
  const client = httpCommon();
  return client.patch(`/credits-failures/${id}/retryInsertion`, data);
};

const insertCredit = (id) => {
  const client = httpCommon();
  return client.post(`/credits-failures/${id}/insertUnit`);
};

export default { getAll, setArchived, retryInsertion,insertCredit };
