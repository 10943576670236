/**
 * @author: Alexander Chi
 * @description: Component create step CSV
 * @date: 24/sept/2020
 **/
import React, { Component } from 'react';
import { StepBox } from '../../Sections/Steps';
import Company from './Company';
import File from './File';
import Units from './Units';

/** Main component requisition completed **/
class RequisitionCompleted extends Component {
  constructor (props) {
    super(props);
    this.state = { };
  };

  render () {
    const {
      agencies,
      agency,
      attachedDocument,
      attachedDocumentCsv,
      availableBalance,
      companies,
      company,
      creditLine,
      creditLines,
      deadline,
      deadlines,
      description,
      document,
      handleCalculate,
      handleChange,
      handleChangeFile,
      handleDeleteFile,
      includeAll,
      fileName,
      legalRepresentative,
      legalRepresentatives,
      remainingBalance,
      reviewUnits,
      stepBoxTitle,
      totalAmount,
      units,
      uploadDocument,
      showAddUnit,
      handleShowAddUnit,
      refFile,
      refFileCsv,
      isPreowned,
    } = this.props;
    return (
    <div className="finbe-app-container">
      <StepBox
        title = {stepBoxTitle ?? 'Confirmación de datos'}
      >
        <Company
          agencies={agencies}
          agency = {agency}
          attachedDocument = {attachedDocument}
          companies= {companies}
          company = {company}
          companyTitle = 'Datos de la empresa'
          description = {description}
          handleChange = {handleChange}
          handleChangeFile = {handleChangeFile}
          legalRepresentative = {legalRepresentative}
          legalRepresentatives = {legalRepresentatives}
          reviewUnits = {reviewUnits}
          styleContainer = {{paddingTop: '0em'}}
          includeAll = {includeAll}
          fileName={fileName}
          handleDeleteFile={handleDeleteFile}
          refFile={refFile}

        />
        {
          !reviewUnits &&
          (
            <>
              <div style = {{display: 'flex', paddingTop: '2.5em'}}>
                <div className="finbe-app-line" style = {{marginBottom: '0.5em'}}> </div>
              </div>
              <File
                fileTitle = 'Documento CSV cargado'
                styleContainer = {{paddingTop: '0em'}}
                attachedDocumentCsv = {attachedDocumentCsv}
                handleChangeFile = {handleChangeFile}
                handleDeleteFile={handleDeleteFile}
                refFile={refFileCsv}
              />
              <div style = {{display: 'flex', paddingTop: '2.5em'}}>
                <div className="finbe-app-line" style = {{marginBottom: '0.5em'}}> </div>
              </div>
            </>
          )
        }
        <Units
          availableBalance = {availableBalance}
          creditLine = {creditLine}
          creditLines = {creditLines}
          deadline = {deadline}
          deadlines = {deadlines}
          document = {document}
          handleCalculate = {handleCalculate}
          handleChange = {handleChange}
          handleChangeFile = {handleChangeFile}
          remainingBalance = {remainingBalance}
          reviewUnits = {reviewUnits}
          styleContainer = {{paddingTop: '0em'}}
          totalAmount = {totalAmount}
          units = {units}
          unitsTitle = 'Unidades seleccionadas del CSV'
          uploadDocument = {uploadDocument}
          attachedDocument= {attachedDocument}
          includeAll = {includeAll}
          showAddUnit={showAddUnit}
          handleShowAddUnit={handleShowAddUnit}
          isPreowned={isPreowned}
        />
      </StepBox>
    </div>
    );
  }
};

export default RequisitionCompleted;

RequisitionCompleted.defaultProps = {
 reviewUnits: false
};
