import React, { useState, useEffect } from "react";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useUsuario } from "../../Context/UserContext";
import AccountService from "../../Services/AccountService";
import { FilterBox } from "../Sections/Filters";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { InputComboBox } from "../Sections/Components/Inputs";
import AuditService from "../../Services/AuditService";
import { ButtonAction } from "../Sections/Components/Buttons";
import moment from "moment";
import useLoader from "../../hooks/useLoader";
const Audits = () => {
  const initialFilters = {
    accountId: "",
  };
  const { usuario, signOutCognito } = useUsuario();

  //   const [auditId, setAuditId] = useState("");
  const [dataFilters, setDataFilters] = useState(initialFilters);
  const [audits, setAudits] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [dataState, setDataState] = useState({
    sort: [{ field: "company.name", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });
  const Loading = useLoader();

  useEffect(() => {
    if (usuario) {
      getAccounts();
      getAudits({ registerBy: usuario.id });
    }
  }, [usuario]);

  const getAccounts = async () => {
    try {
      Loading();
      const { data } = await AccountService.getAll();
      const _accounts = data.map((item) => {
        return {
          id: item.id,
          text: item.name,
        };
      });
      setAccounts(_accounts);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };
  const getAudits = async (data) => {
    try {
      Loading();
      const res = await AuditService.getAll({ params: data });
      const _data = res.data.map((value) => {
        const { initialDate, endDate, ...res } = value;
        const iDate = moment(initialDate, "DD/MM/YYYY HH:mm").toDate();
        const eDate = moment(endDate, "DD/MM/YYYY HH:mm").toDate();

        return {
          ...res,
          initialDate: iDate,
          endDate: eDate,
        };
      });
      setAudits(_data);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (typeof value !== "object") {
      setDataFilters({ ...dataFilters, [name]: value });
    } else if (value != null) {
      if (value.id) {
        setDataFilters({ ...dataFilters, [name]: value.id });
      }
    } else {
      setDataFilters({ ...dataFilters, [name]: "" });
    }
  };
  const handleShowRegisterChange = (e) => {
    const { value } = e.target;
    setShowRegister(value);
    setDataState({ ...dataState, ["take"]: value.text, skip: 0 });
  };
  const handleClickSearch = () => {
    let data = {};
    if (dataFilters.accountId != "") {
      data.account = dataFilters.accountId;
    }
    data.registerBy = usuario.id;
    setDataState({ ...dataState, skip: 0 });
    getAudits(data);
  };
  return (
    <div className="finbe-app-CredentialsContainer">
      <div className="finbe-app-section-top">
        <FilterBox
          buttonSearchOnClick={handleClickSearch}
          isMoreFilter={false}
          inputDropDownValue={showRegister}
          inputFilterOnChange={handleShowRegisterChange}
        >
          <Form
            render={(formRenderProps) => (
              <FormElement style={{ display: "flex" }}>
                <Field
                  dClassName="k-form-field-wrap finbe-form-combo"
                  id="accountId"
                  name="accountId"
                  data={accounts}
                  onChange={handleChange}
                  placeholder="Selec. Cuenta"
                  value={accounts.find((x) => x.id == dataFilters.accountId)}
                  wrapperStyle={{ width: "100%", margin: "1em" }}
                  component={InputComboBox}
                />
              </FormElement>
            )}
          />
        </FilterBox>
      </div>
      <div>
        <Grid
          className="finbe-table"
          data={process(audits, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.data);
          }}
          pageable={{
            info: false,
          }}
          sortable
          skip={dataState.skip}
          take={dataState.take}
          resizable
        >
          <Column field="folio" title="Folio de auditoria" />
          <Column field="company.name" title="Empresa" />
          {/* <Column field='status' title='Estado de auditoria' /> */}
          <Column
            field="isSuspended"
            title="Estado de linea"
            cell={(props) => (
              <td>{props.dataItem.isSuspended ? "Desactivado" : "Activado"}</td>
            )}
          />
          <Column
            field="initialDate"
            title="Inicio"
            filter="date"
            format="{0:dd/MM/yyyy}"
          />
          <Column
            field="endDate"
            title="Fin"
            filter="date"
            format="{0:dd/MM/yyyy}"
          />
          <Column
            title="Acciones"
            cell={(props) => (
              <td style={{ display: "table-cell" }}>
                <ButtonAction
                  title={"Editar"}
                  icon=" icon editar"
                  isRedirect={true}
                  look="bare"
                  primary={false}
                  url={"/editar-auditoria/" + props.dataItem.id}
                />
                {/* <ButtonAction
                  data={props.dataItem.id}
                  icon=" icon visualizar"
                  isRedirect={true}
                  look="bare"
                  primary={false}
                  url={"/visualizar-auditoria/" + props.dataItem.id}

                /> */}
              </td>
            )}
          />
        </Grid>
      </div>
    </div>
  );
};

export default Audits;
