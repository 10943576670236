import React, { useEffect, useState } from "react";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { FilterBox } from "../Sections/Filters";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { InputComboBox, InputFilterText } from "../Sections/Components/Inputs";
import AccountService from "../../Services/AccountService";
import RefinancingService from "../../Services/RefinancingService";
import useLoader from "../../hooks/useLoader";
import { useUsuario } from "../../Context/UserContext";
import { ButtonAction } from "../Sections/Components/Buttons";
import qs from "qs";
import CompanyService from "../../Services/CompanyService";
import { DialogBox } from "../Sections/Dialogs";
import moment from "moment";
import AuditService from "../../Services/AuditService";

/** all status provisions **/
let statusRef = [
  { id: 1, text: "Creada", value: "CREADA" },
  { id: 2, text: "Solicitud Evidencias", value: "ENVIADA_VALIDAR" },
  { id: 3, text: "Validación Administrador", value: "VALIDACION_FINBE" },
  { id: 4, text: "Aceptada", value: "ACEPTADA" },
  { id: 5, text: "Enviada a firmar", value: "ENVIADA_FIRMA" },
  { id: 6, text: "Proceso firma", value: "PROCESO_FIRMA" },
  { id: 7, text: "Firmado", value: "FIRMADO" },
  { id: 8, text: "Autorizado", value: "AUTORIZADO" },
  { id: 9, text: "Rechazada", value: "RECHAZADO" },
  { id: 10, text: "Autorizado", value: "LIBERADO" },
];
function List() {
  const initialFilters = {
    company: null,
    folio: null,
  };
  const Loading = useLoader();
  const { usuario, signOutCognito } = useUsuario();
  const [refinancingId, setRefinancingId] = useState(null);
  const [filters, setFilters] = useState(initialFilters);
  const [clients, setClients] = useState([]);
  const [refinancings, setRefinancings] = useState([]);
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [dataState, setDataState] = useState({
    sort: [{ field: "folio", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });

  const [visibleDocumentsAndSend, setVisibleDocumentsAndSend] = useState(false);
  const [visibleSendAdmin, setVisibleSendAdmin] = useState(false);
  const [visibleResponse, setVisibleResponse] = useState(false);
  const [response, setResponse] = useState({
    title: "",
    message: "",
    success: false,
  });

  const [canCreate,setCanCreate]=useState(true);

  const getClients = async (id) => {
    try {
      Loading();
      let data = [];
      if (usuario.type == 3) {
        const res = await AccountService.getCompaniesByAssingned(id);
        data = res.data;
      }
      setClients(
        data.map((item) => {
          return {
            id: item.id,
            text: `${item.legalName} (${item.clientId})`,
            accountId: item.accountId,
            clientId: item.clientId,
          };
        })
      );
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status === 401) signOutCognito();

    }
  };

  const getRefinancings = async (params) => {
    try {
      Loading();
      const { data } = await RefinancingService.getRefinancings({
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      const refs = data.map(item=>{item.date = moment(item.createdAt, 'DD/MM/YYYY HH:mm').toDate();return item})
      setRefinancings(refs);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };

  const getRefinancingsAF = async (params) => {
    try {
      Loading();
      const { data } = await RefinancingService.getRefinancingsAF({
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      const refs = data.map(item=>{item.date = moment(item.createdAt, 'DD/MM/YYYY HH:mm').toDate();return item})

      setRefinancings(refs);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };
  const validations = async (account,company) => {
    let canProvision = true;
    let message = "";

    let _auditValid = false;
    let _exceptionValid = false;
    let audits = []
    let showResponse = true;
    try {
      Loading();
      // Valida Auditoria
      let params = {
        account: account,
        company: company,
        date: moment().format("YYYY/MM/DD"),
      };
      if (params.account === undefined) { 
        message = "El usuario no tiene relacionada una cuenta.";
      }
      if (params.company === undefined) { 
        message = (message.length > 0 ? "" : message) + "El usuario no tiene relacionada una compañía.";
      }
      let _auresponse = await AuditService.validateProcess({ params });
      audits = _auresponse.data.filter((audit) => audit.isSuspended);
      if(audits.length > 0){
        _auditValid = true
        message = (message.length > 0 ? message + "" : message) + "Tienes [" + audits.length + "] pendientes de auditorías.";
      }

      // Valida una excepción aprobada
      if(_auditValid) {
        let _exresponse = await CompanyService.lastException(company, {params: {date: moment().format("YYYY/MM/DD")}});
        if(_exresponse.data.length > 0){
          let _valid = _exresponse.data.filter(exception => exception.status === 2);
          if(_valid.length > 0){
            _exceptionValid = true;
            message = (message.length > 0 ? message + "" : message) + " La última excepción está aprobada. Ya puedes disponer.";
          }else{
            message = (message.length > 0 ? message + "" : message) + " La última excepción no está aprobada. No puedes disponer";
          }
        }else{
          message = (message.length > 0 ? message + "" : message) + " No puedes disponer"
        }
      }else{
        _exceptionValid = false;
        showResponse = false;
        message = (message.length > 0 ? message + "" : message) + " Puedes disponer.";
      }
      canProvision = _exceptionValid ? false : (_auditValid) ? true : false;

      Loading();
    } catch (err) {
      Loading();
      if (err.response && err.response.status == 401) signOutCognito();
      message = err.response.data.message || err.message;
    }
    setResponse({
      title: true ? "Resultado de validación" : "Ocurrió un error",
        message: message,
        success: true,
    })
    setVisibleResponse(showResponse)
    setCanCreate(canProvision);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value !== null && name == undefined) {
      setFilters({
        ...filters,
        ["datePickerFilter"]: value,
      });
    } else if (typeof value === "object") {
      setFilters({ ...filters, [name]: value });
      if(name==="company"){
        validations(value.accountId,value.id)
      }
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };
  const handleDialogResponse = (e) => {
    setVisibleResponse(!visibleResponse);
  };

  const handleDialogSendFinbe=(e)=>{
    const target = e.currentTarget;
    if (target !== undefined) {
      let index = target.getAttribute("data");
      setRefinancingId(index);
      setVisibleSendAdmin(!visibleSendAdmin);
    }
  }
  const handleSendAdmin=async()=>{
    let message = "";
    let success = true;
    try {
      Loading();
      setVisibleSendAdmin(false);
      const { data } = await RefinancingService.setStatus(refinancingId, {
        status: 3,
      });
      message =
        "N° de Refinanciamiento [" +
        data.folio +
        "]: " +
        statusRef.find((item) => item.id == 5).text;
      let _refs = [...refinancings].map((item, index) => {
        if (data.id === item.id) {
          item.status = data.status;
        }
        return item;
      });
      setRefinancings(_refs);
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOutCognito();
      success = false;
      message = error.response.data.message || error.message;
    }
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message,
      success,
    });
    setVisibleResponse(true);
  }
  const handleDialogDocumentsAndSend = (e) => {
    const target = e.currentTarget;
    if (target !== undefined) {
      let index = target.getAttribute("data");
      setRefinancingId(index);
      setVisibleDocumentsAndSend(!visibleDocumentsAndSend);
    }
  };

  const handleSendSignature = async () => {
    //enviar a firma el refinanciamiento Estatus 5
    let message = "";
    let success = true;
    try {
      Loading();
      setVisibleDocumentsAndSend(false);
      const { data } = await RefinancingService.setStatus(refinancingId, {
        status: 5,
      });
      message =
        "N° de Refinanciamiento [" +
        data.folio +
        "]: " +
        statusRef.find((item) => item.id == 5).text;
      let _refs = [...refinancings].map((item, index) => {
        if (data.id === item.id) {
          item.status = data.status;
        }
        return item;
      });
      setRefinancings(_refs);
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOutCognito();
      success = false;
      message = error.response.data.message || error.message;
    }
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message,
      success,
    });
    setVisibleResponse(true);
  };
  const handleClickSearch = () => {
    let data = {};
    if (filters.company !== null && filters.company !== undefined) {
      data.company = filters.company.id;
    }
    if (filters.folio !== null && filters.folio !== undefined && filters.folio !== "") {
      data.folio = filters.folio;
    }
    if(usuario.type == 2){
      data.status=[3,4,5,6,7,8,9,10]
      getRefinancingsAF(data)
    }else if (usuario.type == 3) {
      data.status=[1,2,3,4,5,6,7,8,9,10]
      getRefinancings(data);
    } else if (usuario.type == 5) {
      data.company = usuario.company.id
      data.status=[1,2,3,4,5,6,7,8,9,10]
      getRefinancings(data);
    } else if (usuario.type == 4) {
      data.company = usuario.company.id
      data.status=[5,6,7,8,9,10]
      getRefinancings(data);
    }
  };
  const handleShowRegisterChange = (e) => {
    const { value } = e.target;
    setShowRegister(value);
    setDataState({ ...dataState, ["take"]: value.text, skip: 0 });
  };

  useEffect(() => {
    if (usuario) {
      if (usuario.type == 2) {
        getRefinancingsAF({ status: [3,4,5,6,7,8,9,10] });
      } else if (usuario.type == 3) {
        getClients(usuario.id);
      } else if (usuario.type == 5) {
        getRefinancings({
          company: usuario.company.id,
          status: [1,2,3,4,5,6,7,8,9,10],
        });
        validations(usuario.account.id,usuario.company.id)
      } else if (usuario.type == 4) {
        getRefinancings({
          company: usuario.company.id,
          status: [5,6,7,8,9,10],
        });
      }
    }
  }, []);
  return (
    <div lassName="finbe-app-container">
      <div className="finbe-app-section-top">
        <FilterBox
          buttonSearchOnClick={handleClickSearch}
          inputDropDownValue={showRegister}
          inputFilterOnChange={handleShowRegisterChange}
          isMoreFilter={false}
        >
          <div style={{ width: "100%" }}>
            <Form
              render={(formRenderProps) => (
                <FormElement style={{ display: "flex" }}>
                  {usuario.type == 3 && (
                    <Field
                      component={InputComboBox}
                      dClassName="k-form-field-wrap finbe-form-combo"
                      id="company"
                      name="company"
                      onChange={handleChange}
                      placeholder="Seleccionar compañía"
                      data={clients}
                      value={filters.company}
                      wrapperStyle={{ width: "33%", paddingTop: "1.8em" }}
                    />
                  )}
                  <Field
                    component={InputFilterText}
                    dClassName="k-form-field-wrap finbe-form-field-filter"
                    id="folio"
                    nombre="folio"
                    onChange={handleChange}
                    placeholder="Ingrese N° de refinanciamiento"
                    value={filters.folio}
                    wrapperStyle={{ width: "33%", paddingTop: "1.8em" }}
                  />
                  
                  {/* <Field
                      component={InputFilterText}
                      dClassName="k-form-field-wrap finbe-form-field-filter"
                      id="model"
                      nombre="model"
                      //   onChange={this.handleChange}
                      placeholder="Ingrese modelo"
                      value={filters.model}
                      wrapperStyle={{ width: "33%", paddingTop: "1.8em" }}
                    /> */}
                  {/* <Field
                      component={InputFilterText}
                      dClassName="k-form-field-wrap finbe-form-field-filter"
                      id="vinNumber"
                      nombre="vinNumber"
                      //   onChange={this.handleChange}
                      placeholder="Ingrese N° de VIN"
                      value={filters.vinNumber}
                      wrapperStyle={{ width: "33%", paddingTop: "1.8em" }}
                    /> */}
                </FormElement>
              )}
            />
          </div>
        </FilterBox>
      </div>
      <div>
        <Grid
          className="finbe-table"
          data={process(refinancings, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.data);
          }}
          sortable={{
            mode: "single",
          }}
          pageable={{
            info: false,
          }}
          resizable
        >
          <Column field="folio" title="N° de Refinanciamiento" cell={(props) => <td>{props.dataItem.useManualFolio?props.dataItem.manualFolio:props.dataItem.folio}</td>} />
          <Column field="total" title="Monto dispuesto" />
          
          {usuario.type==2&&<Column field="company.name" title="Cliente" />}
          <Column
            field="status"
            title="Estatus"
            cell={(props) => (
              <td>
                {statusRef.find((it) => it.id == props.dataItem.status).text}
              </td>
            )}
          />
          <Column field="units" title="N. Unidades" />
          <Column
            field="date"
            title="Fecha"
            filter="date"
            format="{0:dd/MM/yyyy}"
          />
          <Column
            title="Acciones"
            cell={(props) => (
              <td>
                <div style={{ display: "flex" }}>
                  {(usuario.type === 3 && !props.dataItem.requireEvidences) && (
                    <ButtonAction
                      title={"Enviar a FinBe"}
                      data={props.dataItem.id}
                      disabled={
                        props.dataItem.status != 1 || canCreate
                      }
                      icon={
                        " icon " +
                        (props.dataItem.status == 5
                          ? "enviar_a_finbe_enviado"
                          : "enviar_a_finbe")
                      }
                      look="bare"
                      onClick={handleDialogSendFinbe}
                      primary={false}
                    />
                  )}
                  <ButtonAction
                    title={"Visualizar"}
                    icon={" icon visualizar"}
                    isRedirect={true}
                    look="bare"
                    primary={false}
                    url={`/refinanciamientos/${props.dataItem.id}`}
                  />
                  {usuario.type == 3 && (
                    <ButtonAction
                      title={"Enviar"}
                      data={props.dataItem.id}
                      disabled={props.dataItem.status != 4}
                      icon=" icon enviar"
                      look="bare"
                      onClick={handleDialogDocumentsAndSend}
                      primary={false}
                    />
                  )}
                </div>
              </td>
            )}
          />
        </Grid>
      </div>
      <DialogBox
        buttonSuccessText="Aceptar"
        centerButtons={true}
        // enableSuccess={this.state.enableSuccess}
        handelAccept={handleSendSignature}
        handelClose={handleDialogDocumentsAndSend}
        title={"Generar documentos y enviar a firma"}
        visible={visibleDocumentsAndSend}
        visibleCancelButton={true}
      >
        <p>¿Seguro que desea generar documentos y enviar a firma?</p>
        <p>
          <span style={{ fontWeight: "bold" }}> NOTA:</span> Se generarán los
          documentos y el Representante podrá descargar los documentos para su
          firma.{" "}
        </p>
      </DialogBox>
      <DialogBox
        buttonSuccessText="Aceptar"
        centerButtons={true}
        // enableSuccess={this.state.enableSuccess}
        handelAccept={handleSendAdmin}
        handelClose={handleDialogSendFinbe}
        title={"Enviar a validación"}
        visible={visibleSendAdmin}
        visibleCancelButton={true}
      >
        <p>¿Seguro que desea enviar a validación este refinanciamiento?</p>
        <p>
          <span style={{ fontWeight: "bold" }}> NOTA:</span> Las evidencias deben estar completas.
        </p>
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={response.title}
        visible={visibleResponse}
      >
        {response.message}
      </DialogBox>
    </div>
  );
}

export default List;
