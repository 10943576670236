import React, { Component } from "react";
import { DialogBox } from "../Sections/Dialogs";
import { useUsuario } from "../../Context/UserContext";
import { useHistory } from "react-router-dom";

/** Get user cotext hook **/
function UserData({ children }) {
  const { usuario } = useUsuario();
  const history = useHistory();
  return children({ user: usuario, history });
}

/** Main component RequestExceptions **/
export class ConfirmRefinancing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      units: [],
      visible: this.props.visible,
    };
  }

  /** Get new props **/
  componentWillReceiveProps(nextProps) {
    const { visible } = nextProps;
    let data = localStorage.getItem("dataR");
    const { units } = JSON.parse(data);
    this.setState({ visible, units:units?units:[] });
  }

  render() {
    const { handelClose, identificador, visible } = this.props;
    return (
      <UserData>
        {({ user, history }) => {
          return (
            <DialogBox
              identificador={identificador}
              buttonSuccessText={"Aceptar"}
              handelAccept={(e) => {
                let dataR = localStorage.getItem("dataR");
                history.push({
                  pathname: "/crear-refinanciamiento",
                  // state: { dataR: JSON.parse(dataR) },
                });
              }}
              handelClose={handelClose}
              title={"Creación de refinanciamiento"}
              visible={visible}
              visibleCancelButton={true}
              visibleButtons={this.state.units.length > 0}
            >
              {this.state.units.length > 0 ? (
                <p>
                  ¿Está seguro de incluir la(s) unidad(es) seleccionada(s) en un
                  nuevo refinanciamiento?
                </p>
              ) : (
                <p>Seleccione al menos una unidad</p>
              )}
            </DialogBox>
          );
        }}
      </UserData>
    );
  }
}

export default ConfirmRefinancing;

/** default props file **/
File.ConfirmRefinancing = {};
