import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import { Datos } from "./Sections/Datos";
import { RepresentanteLegal } from "./Sections/RepresentanteLegal";
import { Empresas } from "./Sections/Empresas";
import { Resumen } from "./Sections/Resumen";
import { useUsuario } from "../../Context/UserContext";
import FinbeService from "../../Services/Finbe/FinbeService";
import AccountService from "../../Services/AccountService";
import RoleService from "../../Services/RoleService";
import { StepFooter } from "../Sections/Steps";
import qs from "qs";
import { DialogBox } from "../Sections/Dialogs";
import useLoader from "../../hooks/useLoader";

const FormStepper = () => {
  let history = useHistory();
  const Loading=useLoader();

  const { usuario, signOutCognito } = useUsuario();
  const stepperItems = [
    { icon: "", label: "Datos", optional: false, className: "finbe-step-1" },
    { icon: "", label: "Empresas", optional: false, className: "finbe-step-2" },
    {
      icon: "",
      label: "Repr. Legal",
      optional: false,
      className: "finbe-step-3",
    },
    {
      icon: "",
      label: "Registro finalizados",
      optional: false,
      className: "finbe-step-4",
    },
  ];
  const initialAccount = {
    name: "",
    email: "",
    type: 2,
    assignedTo: null,
    registerBy: null,
  };
  const initialLegal = {
    id: null,
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    legalId: null,
    companyId: null,
    roleId: null,
    rowId: "",
  };
  const initialCompany = {
    id: null,
    name: "",
    legalName: "",
    clientId: null,
    rowId: "",
  };
  const initialButtons = { save: true, cancel: false, add: false };

  const [account, setAccount] = useState(initialAccount);
  const [legalPerson, setLegalPerson] = useState(initialLegal);
  const [legalPersons, setLegalPersons] = useState([]);
  const [company, setCompany] = useState(initialCompany);
  const [companies, setCompanies] = useState([]);
  const [buttons, setButtons] = useState(initialButtons);
  const [moralPersons, setMoralPersons] = useState([]);
  const [legalPersonsFinbe, setLegalPersonsFinbe] = useState([]);
  const [roles, setRoles] = useState([]);

  const changeCompanie = (event) => {
    //Cmabiar datos de form compania
    const { name, value } = event.target;
    if (value != null) {
      let _moralPerson = moralPersons.find((x) => x.numeroCliente === value.id);
      if (account.type === 2) {
        getLegalPersons(value.id);
      }
      setCompany({
        ...company,
        clientId: value.id,
        name: _moralPerson.nombreEmpresa,
        legalName: _moralPerson.razonSocial,
      });
    }
  };

  const changeData = (event) => {
    //Cambiar datos form cuenta
    const { name, value } = event.target;
    if (name !== undefined) setAccount({ ...account, [name]: value });
    else setAccount({ ...account, ["type"]: event.value });
  };

  const changeLegal = (event) => {
    //Cambiar datos form RL
    const { name, value } = event.target;
    if (typeof value !== "object")
      setLegalPerson({ ...legalPerson, [name]: value });
    else if (value != null)
      setLegalPerson({ ...legalPerson, [name]: value.id });
    if (name === "companyId") {
      let _company = companies.find((x) => x.rowId === value.id);
      getLegalPersons(_company.clientId);
    }
    if (name === "legalId") {
      let _legalPerson = legalPersonsFinbe.find(
        (x) => x.numeroCliente === value.id
      );
      setLegalPerson({
        ...legalPerson,
        firstname: _legalPerson?_legalPerson.nombres:"",
        lastname: _legalPerson?_legalPerson.apellidos:"",
        email: _legalPerson?_legalPerson.correo:"",
        phone: _legalPerson? _legalPerson.telefono!==null?_legalPerson.telefono:"" : "",
        legalId: _legalPerson?_legalPerson.numeroCliente:"",
      });
    }
  };

  const addData = () => {
    //añadir compania o representante legal a la list
    if (step === 1) {
      let _company = company;
      _company.rowId = Math.random().toString(36).substr(2, 9);

      let _companies = companies;
      _companies.push(_company);
      setCompanies(_companies);
      setCompany(initialCompany);
    } else if (step === 2) {
      let _legalPerson = legalPerson;
      _legalPerson.rowId = Math.random().toString(36).substr(2, 9);

      if(RegExp('^[0-9]{8,13}$').test(_legalPerson.phone)){
        let _legalPersons = legalPersons;
        _legalPersons.push(_legalPerson);
        setLegalPersons(_legalPersons);
        setLegalPerson(initialLegal);
      }else{
        setDataResponse({
          title: "Ocurrió un error",
          message: "Verifique la información ingresada",
          success: false,
        });
        setVisible(!visible);
      }
    }
  };

  const cleanForm = () => {
    if (step === 1) {
      setCompany(initialCompany);
    } else if (step === 2) {
      setLegalPerson(initialLegal);
    }
    setButtons(initialButtons);
  };

  const saveData = () => {
    if (step === 1) {
      //Buscar compania y remplazarla
      let _companies = companies.filter((x) => x.rowId !== company.rowId);
      _companies.push(company);
      setCompanies(_companies);
      setCompany(initialCompany);
      setButtons(initialButtons);
    } else if (step === 2) {
      let _legalPersons = legalPersons.filter(
        (x) => x.rowId !== legalPerson.rowId
      );
      if(RegExp('^[0-9]{8,13}$').test(legalPerson.phone)){
      _legalPersons.push(legalPerson);
      setLegalPersons(_legalPersons);
      setLegalPerson(initialLegal);
      setButtons(initialButtons);
      }else{
        setDataResponse({
          title: "Ocurrió un error",
          message: "Verifique la información ingresada",
          success: false,
        });
        setVisible(!visible);
      }
    }
  };

  const editData = (event) => {
    let id = event.currentTarget.getAttribute("data");
    if (step === 1) {
      setCompany(companies.find((x) => x.rowId === id));
      setButtons({ save: false, cancel: false, add: true });
      //Falta logica de botones
    } else if (step === 2) {
      setLegalPerson(legalPersons.find((x) => x.rowId === id));
      setButtons({ save: false, cancel: false, add: true });
    }
  };
  const deleteData = (event) => {
    //Quitar compania de lista
    let id = event.currentTarget.getAttribute("data");
    if (step === 1) {
      let _companies = companies.filter((x) => x.rowId !== id);
      setCompanies(_companies);
    } else if (step === 2) {
      let _legalPersons = legalPersons.filter((x) => x.rowId !== id);
      setLegalPersons(_legalPersons);
    }
  };
  const [dataResponse, setDataResponse] = useState({
    title: "",
    message: "",
    success: "",
  });
  const [visible, setVisible] = useState(false);
  const handleDialogResponse = () => {
    if (dataResponse.success) {
      history.push("/cuentas");
    } else {
      setVisible(!visible);
    }
  };

  const onSave = async () => {

    // setCargando(true);
    setVisibleConfirm(false);
    let success = true;
    let message = "";
    try {
      Loading();

    let data = account;
    data.assignedTo = usuario.id;
    data.registerBy = usuario.id;
    data.companies = [];
    let valid = true;
    if (account.type == 1) {
      legalPersons.map((user) => {
        const _company = companies.find((c) => c.rowId == user.companyId);
        let dataCompany = {
          id: _company.id,
          name: _company.name,
          legalName: _company.legalName,
          clientId: _company.clientId,
        };
        let dataLP = {
          id: user.id,
          firstname: user.firstname,
          lastname: user.lastname,
          email: user.email,
          phone: user.phone,
          legalId: user.legalId,
          roleId: user.roleId,
        };
        if((dataCompany.clientId == null && dataCompany.clientId == undefined)|| (dataLP.legalId == null && dataLP.legalId == undefined) || !RegExp('^[0-9]{8,13}$').test(dataLP.phone))valid= false;
        dataCompany.legalPerson = dataLP;
        data.companies.push(dataCompany);
      });
    } else if (account.type == 2) {
      let _company = company;
      data.name = _company.name;
      let dataCompany = {
        id: _company.id,
        name: _company.name,
        legalName: _company.legalName,
        clientId: _company.clientId,
      };

      let _legalPerson = legalPerson;
      let dataLP = {
        id: _legalPerson.id,
        firstname: _legalPerson.firstname,
        lastname: _legalPerson.lastname,
        email: _legalPerson.email,
        phone: _legalPerson.phone,
        legalId: _legalPerson.legalId,
        roleId: _legalPerson.roleId,
      };
      if((dataCompany.clientId == null && dataCompany.clientId == undefined)|| (dataLP.legalId == null && dataLP.legalId == undefined) || !RegExp('^[0-9]{8,13}$').test(dataLP.phone))valid= false;

      dataCompany.legalPerson = dataLP;

      data.companies.push(dataCompany);
    }
      if(valid){
        const res = await AccountService.create(data);
        message = "La cuenta se guardo correctamente";

      }else{
        success = false;
        message = "Verifique que la información ingresada sea correcta"
      }
      // setCargando(false)
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
      success = false;
      message = error.response?error.response.data.message:error.message
    }
    // setCargando(false)
    setDataResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
    });
    setVisible(!visible);

  };

  const [step, setStep] = React.useState(0);

  const getClients = async () => {
    try {
      // setCargando(true)
      Loading();
      const res = await FinbeService.getClients();
      setMoralPersons(res.data);
      if(res.data.length===1){
        setCompany(prev=>({...prev,clientId:res.data[0].numeroCliente}))
        await getLegalPersons(res.data[0].numeroCliente)
      }
      Loading();
    } catch (error) {
      Loading();
    }
  };
  const getLegalPersons = async (clientId) => {
    try {
      // setCargando(true)
      Loading();
      const res = await FinbeService.getLegalPersons({
        params: { numeroCliente: clientId },
      });
      setLegalPersonsFinbe(res.data);
      if(res.data.length===1){
        setLegalPerson(prev=>({
          ...prev,
          firstname: res.data[0].nombres,
          lastname: res.data[0].apellidos,
          email: res.data[0].correo,
          phone: res.data[0].telefono!==null?res.data[0].telefono:"",
          legalId: res.data[0].numeroCliente,
        }))
      }
      // setCargando(false)
      Loading();
    } catch (error) {
      // setCargando(false)
      Loading();
    
    }
  };
  const getRoles = async () => {
    try {
      // setCargando(true)
      Loading();
      const res = await RoleService.getAll({
        params: { type: [4] },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      let _roles = await res.data.map((x) => {
        return { id: x.id, text: x.name };
      });
      setRoles(_roles);
      if(_roles.length===1){
        setLegalPerson(prev=>({...prev,roleId:_roles[0].id}))
      }
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };
  const getComponent = () => {
    switch (step) {
      case 0:
        return (
          <Datos disabled={false} handleChange={changeData} account={account} />
        );
      case 1:
        return (
          <Empresas
            moralPersons={moralPersons}
            handleChange={changeCompanie}
            company={company}
            companies={companies}
            addCompanie={addData}
            removeCompanie={deleteData}
            editCompanie={editData}
            cancel={cleanForm}
            saveForm={saveData}
            buttons={buttons}
            step={step}
            typeAccount={account.type}
          />
        );
      case 2:
        return (
          <RepresentanteLegal
            handleChange={changeLegal}
            legalPerson={legalPerson}
            legalPersons={legalPersons}
            legalPersonsFinbe={legalPersonsFinbe}
            companies={companies}
            addCompanie={addData}
            removeCompanie={deleteData}
            editCompanie={editData}
            cancel={cleanForm}
            saveForm={saveData}
            buttons={buttons}
            step={step}
            roles={roles}
            typeAccount={account.type}
          />
        );
      case 3:
        return (
          <Resumen>
            <Datos
              disabled={true}
              handleChange={changeData}
              account={account}
            />
            <Empresas
              moralPersons={moralPersons}
              handleChange={changeCompanie}
              company={company}
              companies={companies}
              addCompanie={addData}
              removeCompanie={deleteData}
              editCompanie={editData}
              cancel={cleanForm}
              saveForm={saveData}
              buttons={buttons}
              step={step}
              typeAccount={account.type}
            />
            <RepresentanteLegal
              handleChange={changeLegal}
              legalPerson={legalPerson}
              legalPersons={legalPersons}
              legalPersonsFinbe={legalPersonsFinbe}
              companies={companies}
              addCompanie={addData}
              removeCompanie={deleteData}
              editCompanie={editData}
              cancel={cleanForm}
              saveForm={saveData}
              buttons={buttons}
              step={step}
              roles={roles}
              typeAccount={account.type}
            />
          </Resumen>
        );
    }
  };

  useEffect(() => {
    if (usuario) {
      getClients();
      getRoles();
    }
  }, []);

  const handleChangeStep = (e) => {
    setStep(e.value);
  };
  /** Click back **/
  const handleClickBack = (e) => {
    let _step = step - (step > 0 ? 1 : 0);
    setStep(step - (step > 0 ? 1 : 0));
  };

  /** Click cancel **/
  const handleClickCancel = (e) => {
    history.push("/cuentas");
  };

  /** Click next or save **/
  const handleClickNextOrSave = (e) => {
    if (step < stepperItems.length - 1) {
      setStep(step + 1);
    } else {
      openDialog();
    }
  };
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [dataConfirm, setDataConfirm] = useState({});
  const openDialog = () => {
    setVisibleConfirm(true);
    setDataConfirm({
      title: "Creación de cuenta",
      message: "¿Estás seguro de crear esta cuenta?",
    });
  };
  return (
    <div className="finbe-app-container">
      <Stepper
        className="finbe-stepper-horizontal"
        value={step}
        onChange={handleChangeStep}
        items={stepperItems}
      />
      <div>{getComponent()}</div>
      <StepFooter
        step={step + 1}
        stepLength={stepperItems.length}
        backOnClick={handleClickBack}
        cancelOnclick={handleClickCancel}
        saveOrNextOnclick={handleClickNextOrSave}
        saveOrNextIcon={
          step == stepperItems.length - 1 ? "check" : "arrow-chevron-right"
        }
        saveOrNextText={
          step == stepperItems.length - 1 ? "Guardar" : "Siguiente"
        }
      />
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={dataResponse.title}
        visible={visible}
      >
        {dataResponse.message}
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => {
          setVisibleConfirm(false);
        }}
        handelCancel={() => setVisibleConfirm(false)}
        handelAccept={onSave}
        title={dataConfirm.title}
        visible={visibleConfirm}
        visibleCancelButton={true}
      >
        {dataConfirm.message}
      </DialogBox>
    </div>
  );
};

export default FormStepper;
