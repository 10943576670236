/**
 * @author: Alexander Chi
 * @description: Component for visualize views
 * @date: 30/sept/2020
 **/
import React from 'react'

/** Label with text inline text or no  **/
export const LabelWithText = (props) => {
    const {
      bText,
      divClassName,
      divStyle,
      inline,
      labelClassName,
      labelStyle,
      labelText,
    } = props;
    return (
      <div
        style = {divStyle}
        className = {divClassName}>
        <div style = {{width: '90%'}}>
          <label
            className = {labelClassName}
            style = {labelStyle}>
            {labelText}:
          </label>
          {inline ?
          <b style = {{marginLeft: '1em'}}>{bText}</b> :
          <p
            style = {{marginBottom: '1.2em'}}>
            <b>{bText}</b>
          </p>}
        </div>
      </div>
    );
};

/** Values default of props **/
LabelWithText.defaultProps = {
  bText: 'Value',
  divClassName: '',
  divStyle: null,
  inline: true,
  labelClassName: '',
  labelStyle: null,
  labelText: 'Label text',
};

/** Label with link inline text or no  **/
export const LabelWithLink = (props) => {
    const {
      bText,
      divClassName,
      divStyle,
      href,
      inline,
      labelClassName,
      labelStyle,
      labelText,
      onClick
    } = props;
    return (
      <div
        style = {divStyle}
        className = {divClassName}>
        <div style = {{width: '90%'}}>
          <label
            className = {labelClassName}
            style = {labelStyle}>
            {labelText}
          </label>
          {inline ?
          <a
            href = {href}
            onClick = {onClick}
            style = {{marginLeft: '1em', color: '#3c91da'}}>
            {bText}
          </a> :
          <p style = {{marginBottom: '1.2em'}}>
            <a
              href = {href}
              onClick = {onClick}
              style = {{color: '#3c91da'}}>
              {bText}
            </a>
          </p>}
        </div>
      </div>
    );
};

/** Values default of props **/
LabelWithLink.defaultProps = {
  bText: 'Value',
  divClassName: '',
  divStyle: null,
  href: '#',
  inline: true,
  labelClassName: '',
  labelStyle: null,
  labelText: 'Label text',
  onClick: null
};

/** Label with link inline text or no  **/
export const LabelWithTextBorder = (props) => {
    const {
      bText,
      divClassName,
      divStyle,
      labelClassName,
      labelStyle,
      labelText,
      pBorderRadius,
      textBold,
      textStyle,
    } = props;
    return (
      <div
        style = {divStyle}
        className = {divClassName}>
        <div style = {{width: '100%'}}>
          <label
            className = {labelClassName}
            style = {labelStyle}>
            {labelText}
          </label>
          <p style = {{border: '1px solid #c6c6c4', borderRadius: (pBorderRadius ?? '1.5em'), padding: '1em', marginBottom: '1.2em'}}>
            <span style = {textStyle}>
              {
                textBold ?
                <b>{bText}</b> :
                bText
              }
            </span>
          </p>
        </div>
      </div>
    );
};

/** Values default of props **/
LabelWithTextBorder.defaultProps = {
  bText: 'Value',
  divClassName: '',
  divStyle: null,
  labelClassName: '',
  labelStyle: null,
  labelText: 'Label text',
  onClick: null,
  textBold: false,
  textStyle: null,
};

/** Row Link text size inline **/
export const RowLinkSize = (props) => {
    const {
      aHref,
      aOnclick,
      aText,
      divClassName,
      spanStyle,
      spanText,
    } = props;
    return (
      <div
        className = {divClassName}
        style = {{ display: 'flex', textAling: 'left'}}>
        <a
          href = {aHref}
          onClick = {aOnclick}
          style = {{color: '#0071d4'}}>
          {aText}
        </a>
        <span
          style = {spanStyle}>
          {spanText}
        </span>
      </div>
    );
};

/** Values default of props **/
RowLinkSize.defaultProps = {
  aHref: '#',
  aText: 'Link',
  divClassName: '',
  spanStyle: null,
  spanText: 'Size',
};

/** Row text link size inline **/
export const RowTextLinkSize = (props) => {
    const {
      aHref,
      aOnclick,
      aText,
      bText,
      spanText,
    } = props;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1em' }}>
        <b>{bText}</b>
        <RowLinkSize
          aHref = {aHref}
          aOnclick = {aOnclick}
          aText = {aText}
          spanText = {spanText}
          spanStyle = {{marginLeft: '1.5em'}}
        />
      </div>
    );
};

/** Values default of props **/
RowTextLinkSize.defaultProps = {
  aHref: '#',
  aText: 'Texto link',
  bText: 'Usuario',
  spanText: 'Size',
};

/** Row icon text inline **/
export const RowIconText = (props) => {
    const {
      bText,
      spanIcon
    } = props;
    return (
      <div
        style = {{ display: 'flex', textAling: 'left'}}>
        <span className = {' icon ' + spanIcon} />
        <b style = {{color: '#0071d4'}}> {bText} </b>
      </div>
    );
};

/** Values default of props **/
RowIconText.defaultProps = {
  bText: 'Texto',
  spanIcon: 'visto_bueno'
};
