/**
 * @author: Alexander Chi
 * @description: Component of disposiciones list, create, edit, information CSV,
 *               upload XML o CSV, units evidences, FinBe validation
 * @date: 29/sept/2020
 **/
import React, { Component } from "react";
import { Stepper } from "@progress/kendo-react-layout";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Field, Form } from "@progress/kendo-react-form";
import { InputText } from "../Sections/Components/Inputs";
import { RadioButton } from "@progress/kendo-react-inputs";
import {
  ButtonSuccess,
  ButtonDanger,
  ButtonAction,
  ButtonPrimary,
  ButtonTertiary,
} from "../Sections/Components/Buttons";
import {
  LabelWithText,
  LabelWithLink,
  LabelWithTextBorder,
} from "../Sections/Components/Tags";
import { StepBox } from "../Sections/Steps";
import { DialogBox } from "../Sections/Dialogs";
import { GridUnits, MoneyUnits } from "./Sections/Units";
import { useUsuario } from "../../Context/UserContext";
import ProvisionService from "../../Services/ProvisionService";
import FinbeService from "../../Services/Finbe/FinbeService";
import DocumentService from "../../Services/DocumentService";
import qs from "qs";
import useLoader from "../../hooks/useLoader";
import { DateInputItem } from "../Inputs/Inputs";
import moment from "moment";
import { DateInput } from "@progress/kendo-react-dateinputs";
import dotenv from "dotenv";
dotenv.config();
// import { ExcelExport } from "@progress/kendo-react-excel-export";

/** Varial global **/
let user = null;
let signOut = null;
let Loading = useLoader();

/** Get user cotext hook **/
function UserData({ children }) {
  const { usuario, signOutCognito } = useUsuario();
  user = usuario;
  signOut = signOutCognito;
  return children({ user: usuario });
}

/** Information data show registers **/
const dataDropDown = [
  { id: 1, text: 10 },
  { id: 2, text: 25 },
  { id: 3, text: 50 },
  { id: 4, text: 100 },
];

/** Step headers **/
const stepperItems = [
  {
    icon: " icon stepper-uno-n",
    label: "Creada",
    optional: false,
    className: "finbe-step-1",
    disabled: true,
  },
  {
    icon: " icon stepper-dos-n",
    label: "Validación FinBe",
    optional: false,
    className: "finbe-step-2",
    disabled: true,
  },
  {
    icon: "icon stepper-aprobado-n",
    label: "Aceptada",
    optional: false,
    className: "finbe-step-3",
    disabled: true,
  },
  {
    icon: "icon stepper-tres-n",
    label: "Enviada a firma",
    optional: false,
    className: "finbe-step-4",
    disabled: true,
  },
  {
    icon: "icon stepper-aprobado-n stepper-aceptado",
    label: "Autorizado",
    optional: false,
    className: "finbe-step-5",
    disabled: true,
  },
];

/** Format money **/
const numberFormat = (value) => new Intl.NumberFormat("en-US").format(value);

/** Create, Edit main **/
class VisualizeProvisions extends Component {
  _export;

  constructor(props) {
    super(props);
    this.state = {
      agency: {},
      attachedDocument: [],
      attachedDocumentCsv: [],
      availableBalance: 0,
      changes: [],
      company: {},
      description: "",
      documents: [],
      handleChange: null,
      legalRepresentative: {},
      lineCredit: 0,
      loading: false,
      deadlines: 0,
      refresh: false,
      remainingBalance: 0,
      response: { title: "", message: "", success: false },
      skip: 0,
      step: 0,
      stepMax: 0,
      take: 10,
      takeShow: { id: 1, text: 10 },
      totalAmount: 0,
      units: [],
      unitsNews: [],
      visibleApprove: false,
      visibleResponse: false,
      redoDocument: {
        //Se agrega objeto para modal de firma
        title: "",
        text: "",
      },
      visibleConfirm: false,
      visibleSendCRM: false,
      visibleLiberate: false,
      visibleUpdateFolio: false,
      useManualFolio: false,
      manualFolio: "",
      updateFolio: "",
      sendDate: new Date(),
    };
  }

  componentWillMount() {
    // this.setState({loading:true})
    this._getProvision(this.props.match.params.id);
    // this.setState({loading:false})
  }

  /** Get information provision **/
  _getProvision = async (provisionId) => {
    try {
      Loading();
      // this.setState({loading:true})
      const { data } = await ProvisionService.getById(provisionId);
      let step = 0;
      let totalAmount =
        data.status === 1 || data.status >= 4 ? Number(data.total) : 0;

      /** Valid check included **/
      data.units = data.units.map((unit, index) => {
        //unit.included = unit.status == 1 && this._renderCheck(unit);
        unit.check = unit.included;
        unit.index = index;

        // if (unit.check || user.type == 5 || data.status == 4) { totalAmount =+ Number(unit.amount); }
        return unit;
      });
      if (data.status <= 2) {
        step = 0;
      } else if (data.status === 3) {
        step = 1;
      } else if (data.status === 4) {
        step = 2;
      } else if (data.status === 5 || data.status < 8) {
        step = 3;
      } else if (data.status === 9) {
        step = 2;
        stepperItems[2].label = "Rechazado";
        stepperItems[2].icon = " icon rechazado";
      } else if (data.status === 11) {
        step = 0;
        stepperItems[0].label = "Cancelado";
        stepperItems[0].icon = " icon rechazado";
      } else {
        step = 4;
      }
      this.setState({
        agency: data.agency,
        company: data.company,
        description: data.description,
        evidences: data.evidences,
        folio:  data.useManualFolio?data.manualFolio: data.folio,
        legalRepresentative: data.legalPerson,
        lineCredit: data.creditLine,
        typeLine: data.typeLine,
        deadlines: data.deadlines,
        status: data.status,
        units: data.units,
        totalAmount,
        step,
        // loading:false,
      });
      await this._getCreditLines(data.company.clientId);
      // this.setState({loading:false})
      Loading();
    } catch (error) {
      Loading();
      // this.setState({loading:false})
      if (error.response && error.response.status === 401) signOut();
      console.info(error);
    }
  };

  /** Macth of units review AF **/
  _getReviewUnits = async (provisionId, refresh = true) => {
    try {
      Loading();
      let changes = [];
      const { data } = await ProvisionService.getById(provisionId);
      const { units, availableBalance } = this.state;
      let totalAmount = 0;
      let remainingBalance = 0;
      let result = data.units.map((unit, index) => {
        let unitNew = units.find((it) => it.id === unit.id);
        unit.reviewAF = unitNew.reviewAF;
        //unit.included = unit.status == 1 && this._renderCheck(unit);
        unit.check = unit.included;
        if (unit.check) {
          totalAmount += unit.amount;
        }
        remainingBalance = availableBalance - totalAmount;
        if (unit.status != unitNew.status && unit.reviewAF) {
          changes.push(unit);
        }
        return unit;
      });
      if (refresh) {
        this.setState({ units: result, totalAmount, remainingBalance });
      } else if (changes.length > 0) {
        this.setState({
          unitsNews: result,
          visibleApprove: !this.state.visibleApprove,
          refresh: false,
          changes,
        });
      } else {
        this._approveReject(this.props.match.params.id, 4);
      }
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOut();
      console.info(error);
    }
  };

  //Recuperar monto reservado
  _getReservedAmount = async (params) => {
    try {
      Loading();
      const res = await ProvisionService.getReservedAmount(params);
      Loading();
      return res.data.total || 0;
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOut();
    }
  };

  /** Get all credit lines of customer **/
  _getCreditLines = async (customerId, type) => {
    let creditLines = [];
    // let success = true;
    // let message = "";
    try {
      // this.setState({loading:true})
      Loading();
      const res = await FinbeService.getConfigurationCreditLine({
        params: { numeroCliente: customerId },
      });
      creditLines = res.data.map((line, index) => {
        return {
          id: line.lineaCredito,
          text: line.lineaCredito,
          value: line.lineaCredito,
          availableBalance: line.montoRestante,
        };
      });
      const availableBalance = res.data.find(
        (x) => x.lineaCredito == this.state.lineCredit
      ).montoRestante;
      let montoReservado = await this._getReservedAmount({
        params: { creditLine: this.state.lineCredit },
      });
      let _availableBalance = +(availableBalance - +montoReservado).toFixed(2);
      this.setState({
        creditLines,
        availableBalance: _availableBalance > 0 ? _availableBalance : 0,
      });
      // this.setState({loading:false})
      Loading();
    } catch (error) {
      Loading();
    }
  };

  /** Set approve provision **/
  _approveReject = async (provisionId, status) => {
    let success = true;
    let message = "";
    try {
      Loading();
      const { units, folio } = this.state;
      let unitsSend = [];
      let params = {
        units: [],
        status: status,
      };
      unitsSend = status === 9 ? units : units.filter((unit) => unit.check);
      if (unitsSend.length > 0 || status === 9) {
        /** Set units of true checkbox **/
        // unitsSend = units;

        if (this.state.useManualFolio) {
          if (this.state.manualFolio === "") {
            success = false;
            message =
              "Disposición con folio [" + folio + "]: Ingrese el folio manual";
            throw { message: message, response: { status: 200 } };
          }
        }

        unitsSend.map((unit, index) => {
          unit.included = unit.check;
          delete unit.validation;
          delete unit.createdAt;
          delete unit.check;
          delete unit.index;
          unit.evidences.map((evidence) => {
            delete evidence.createdAt;
            delete evidence.typeSignDoc;

            return evidence;
          });
          return unit;
        });
        params.total = this.state.totalAmount;
        params.units = unitsSend;
        params.useManualFolio = this.state.useManualFolio;
        params.manualFolio = this.state.useManualFolio
          ? this.state.manualFolio
          : null;
        let response = await ProvisionService.approveReject(
          provisionId,
          params
        );
        message =
          "Disposición con folio [" +
          response.data.folio +
          "]: " +
          (status === 4 ? "Confirmada" : "Rechazada");
      } else {
        success = false;
        message =
          "Disposición con folio [" +
          folio +
          "]: Seleccione al menos una unidad";
      }
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status === 401) signOut();
      success = false;
      message = error.message;
    }
    /** Show response dialog **/
    this.setState({
      response: {
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: success,
      },
      visibleResponse: !this.state.visibleResponse,
    });
  };

  handleRedoDocument = async () => {
    let success = true;
    let message = "";
    this.setState({ visibleConfirm: false });
    try {
      Loading();
      // alert("rehacer documento")
      const res = await DocumentService.redoDocument({
        provisionId: this.props.match.params.id,
      });
      message = "El documento se generó nuevamente";
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status === 401) signOut();
      success = false;
      message = error.message;
    }
    /** Show response dialog **/
    this.setState({
      response: {
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: false,
      },
      visibleResponse: !this.state.visibleResponse,
      // loading: false,
    });
  };

  /** Get information document **/
  _getDocument = async (url, download = false) => {
    try {
      Loading();
      const response = await DocumentService.showDocument({
        path: url,
        download: download,
      });
      window.open(response.data.url, "_blank");
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOut();
      console.info(error);
    }
  };

  /** Click cancel **/
  handleClickCancel = (e) => {
    this.props.history.push("/disposiciones");
  };

  /** Click approve provision **/
  handelApprove = (e) => {
    if (this.state.refresh) {
      this.setState({
        units: this.state.unitsNews,
        visibleApprove: !this.state.visibleApprove,
      });
    } else {
      this._approveReject(this.props.match.params.id, 4);
    }
  };

  /** Click close response dialog **/
  handleDialogResponse = (e) => {
    if (this.state.response.success) {
      this.props.history.push("/disposiciones");
    } else {
      this.setState({ visibleResponse: !this.state.visibleResponse });
    }
  };

  /** Show dialog refresh approve **/
  handleDialogRefresh = (e) => {
    this._getReviewUnits(this.props.match.params.id, false);
  };

  /** Click rejection provision **/
  handelRejection = (e) => {
    this._approveReject(this.props.match.params.id, 9);
  };

  /** Click refresh provision **/
  handelRefresh = (e) => {
    this._getReviewUnits(this.props.match.params.id);
  };

  /** Change for step index **/
  handleChangeStep = (e) => {
    this.setState({ step: e.value });
  };

  /** Refresh information units **/
  handleChange = (e) => {
    const { name, value } = e.target;
    console.info(name);
    this.setState({ [name]: value });
  };

  /** Change page of grid **/
  handlePageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  /** Select radio button option **/
  handelRadioButton = (e) => {
    this.setState({ refresh: e.value });
  };

  handleManualFolioCheck = (e) => {
    const { value, props } = e.target;
    if (props === undefined) {
      const { element } = e.target;
      this.setState({ [element.current.id]: e.value });
    } else {
      this.setState({ [props.id]: value });
    }
  };

  /** Calculate amounts**/
  handleCalculate = (amount, check, ignore = false) => {
    let result = ignore ? amount : 0;
    if (!ignore) {
      if (check) {
        result = this.state.totalAmount + Number(amount.toFixed(2));
      } else {
        result = this.state.totalAmount - Number(amount.toFixed(2));
      }
    }
    let saldoRestante = this.state.availableBalance - result;
    this.setState({
      totalAmount: result < 0 ? 0 : result,
      remainingBalance: saldoRestante,
    });
  };

  /** Convert bytes to MegaBytes or Kilobytes **/
  _renderFileSize(size) {
    let sizeInt = Number(size);
    return sizeInt > 1000000
      ? sizeInt / 1000000 + " MB"
      : sizeInt / 1000 + " KB";
  }

  /** Show or hide checkbox for include **/
  _renderCheck(unit) {
    if (unit.validation !== undefined) {
      return (
        (unit.status == 1 && unit.reviewAF) ||
        (unit.validation.validInvoice &&
          unit.validation.validRepuve &&
          unit.validation.validTransunion &&
          unit.validation.validAx)
      );
    }
    return false;
  }

  /**Envio a CRM de unidades */
  _sendCRM = async () => {
    let success = true;
    let message = "";
    let _status = this.state.status;
    this.setState({ visibleSendCRM: false });
    try {
      Loading();
      let provisionId = this.props.match.params.id;
      const res = await ProvisionService.sendCRM({
        provisionId,
        approvedDate: moment(this.state.sendDate).format("YYYY-MM-DD"),
      });
      message = "Las unidades fueron enviadas a CRM";
      _status = res.data.status;
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status === 401) signOut();
      success = false;
      message = error.message;
    }
    /** Show response dialog **/
    this.setState({
      response: {
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: false,
      },
      visibleResponse: !this.state.visibleResponse,
      // loading: false,
      status: _status,
    });
  };
  handleSendCRM = () => {
    this.setState({ visibleSendCRM: true });
  };
  _liberateProvision = async () => {
    let success = true;
    let message = "";
    let _status = this.state.status;
    try {
      Loading();
      this.setState({ visibleLiberate: false });
      let provisionId = this.props.match.params.id;
      const res = await ProvisionService.setStatus(provisionId, {
        status: 10,
      });
      success = true;
      message = "Las unidades fueron liberadas sin enviar a CRM";
      _status = res.data.status;
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOut();
      success = false;
      message = error.message;
    }
    this.setState({
      response: {
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: false,
      },
      visibleResponse: !this.state.visibleResponse,
      status: _status
      // loading: false,
    });
  };
  handleLiberate = () => {
    this.setState({ visibleLiberate: true });
  };
  handleDialogUpdateFolio=()=>{
    this.setState({visibleUpdateFolio:true});
  }
  handleUpdateFolioManual = async()=>{
    let success = true;
    let message = "";
    this.setState({ visibleUpdateFolio: false });

    try {
      Loading();
      let provisionId = this.props.match.params.id;
      if(this.state.updateFolio === "" || +this.state.updateFolio<=0)
      throw Error("El folio es requerido y debe de ser un numero mayor a 0");

      const {data} = await ProvisionService.updateManualFolio(provisionId,{manualFolio:+this.state.updateFolio});
      message = "El folio manual se actualizo correctamente"
      Loading();
    } catch (error) {
      Loading();

      if (error.response && error.response.status === 401) signOut();
      success = false;
      message = error.response ? error.response.data.message : error.message;
    }
    this.setState({
      response: {
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: false,
      },
      updateFolio: "",
      visibleResponse: !this.state.visibleResponse,
      // loading: false,
    });
  }
  handleDownload = () => {
    this._export.save();
    this.setState({
      // visibleConfirm: !this.state.visibleConfirm,
      response: {
        title: "Descarga completa",
        message: "El archivo se descargo correctamente",
        success: false,
      },
      visibleResponse: !this.state.visibleResponse,
    });
  };

  handleVisibleRedoDocument = (e) => {
    const target = e.currentTarget;
    if (target !== undefined) {
      let docId = target.getAttribute("data");
      const doc = this.state.evidences.find((item) => item.id === docId);
      if (doc.typeSignDoc === 0) {
        this.setState({
          redoDocument: {
            title: "Rehacer documento",
            text: "¿Seguro que desea rehacer el documento de firma?",
          },
          visibleConfirm: true,
        });
      } else {
        this.setState({
          redoDocument: {
            title: "Rehacer documentos",
            text: (
              <>
                <p>¿Seguro que desea rehacer el documento de firma?</p>
                <span>
                  <strong>Nota:</strong> Todos los documentos se generaran
                  nuevamente.
                </span>
              </>
            ),
          },
          visibleConfirm: true,
        });
      }
    }
  };

  render() {
    return (
      <UserData>
        {({ user }) => {
          return (
            <div className="finbe-app-container">
              {user.type != 3 && user.type !== 2 && user.type !== 1 && (
                <h3>Seguimiento de disposición</h3>
              )}
              <h4>N° de disposición {this.state.folio}</h4>
              {user.type != 3 && user.type !== 2 && user.type !== 1 && (
                <div>
                  <Stepper
                    className="finbe-stepper-horizontal"
                    value={this.state.step}
                    onChange={this.handleChangeStep}
                    items={stepperItems}
                  />
                </div>
              )}
              <div>
                <StepBox title="Datos de la empresa">
                  <div style={{ display: "flex", marginBottom: "1.5em" }}>
                    <LabelWithText
                      bText={
                        this.state.company !== "" ? this.state.company.name : ""
                      }
                      divStyle={{ width: "50%" }}
                      labelText={"Empresa"}
                    />
                    <LabelWithText
                      bText={
                        this.state.agency !== "" ? this.state.agency.name : ""
                      }
                      divStyle={{ width: "50%" }}
                      labelText={"Sucursal"}
                    />
                  </div>
                  <div style={{ display: "flex", marginBottom: "1.5em" }}>
                    <LabelWithText
                      divStyle={{ width: "50%" }}
                      labelText={"Representante legal"}
                      bText={
                        this.state.legalRepresentative !== ""
                          ? this.state.legalRepresentative.name
                          : ""
                      }
                    />
                  </div>
                  <div
                    className="finbe-app-line"
                    style={{ marginTop: "1em", marginBottom: "1em" }}
                  >
                    {" "}
                  </div>
                  <div style={{ display: "flex", marginBottom: "1.5em" }}>
                    {this.state.evidences != null &&
                      this.state.evidences.length > 0 && (
                        <LabelWithLink
                          onClick={() => {
                            this._getDocument(this.state.evidences[0].url);
                          }}
                          bText={this.state.evidences[0].fileName}
                          divStyle={{ width: "50%" }}
                          inline={false}
                          labelText={"Documento anexo"}
                          href={"#"}
                        />
                      )}
                    <LabelWithTextBorder
                      divStyle={{ width: "47%" }}
                      labelText={"Descripción"}
                      bText={this.state.description}
                      inline={true}
                    />
                  </div>
                </StepBox>
                <div
                  className="finbe-app-line"
                  style={{ marginTop: "1em", marginBottom: "1em" }}
                >
                  {" "}
                </div>
                {(user.type == 3 || user.type === 1) &&
                this.state.status > 3 ? (
                  <>
                    <StepBox title="Descargar documentos firmados">
                      <Grid className="finbe-table" data={this.state.evidences}>
                        <Column field="fileName" title="Documento" />
                        <Column
                          title="Tamaño"
                          cell={(props) => (
                            <td>
                              {this._renderFileSize(props.dataItem.fileSize)}
                            </td>
                          )}
                        />
                        <Column
                          title="Acciones"
                          cell={(props) => (
                            <td>
                              <div style={{ display: "flex" }}>
                                <ButtonAction
                                  title="Descargar"
                                  icon={" icon descargar"}
                                  look="bare"
                                  primary={false}
                                  onClick={() => {
                                    this._getDocument(props.dataItem.url, true);
                                  }}
                                />
                                {props.dataItem.type == 1 &&
                                  (props.dataItem.typeSignDoc == 0 ||
                                    props.dataItem.typeSignDoc == 2) && (
                                    <ButtonAction
                                      title={"Rehacer"}
                                      data={props.dataItem.id}
                                      disabled={false}
                                      icon=" icon enviar"
                                      look="bare"
                                      onClick={this.handleVisibleRedoDocument}
                                      primary={false}
                                    />
                                  )}
                              </div>
                            </td>
                          )}
                        />
                      </Grid>
                    </StepBox>
                    <div
                      className="finbe-app-line"
                      style={{ marginTop: "1em", marginBottom: "1em" }}
                    >
                      {" "}
                    </div>
                  </>
                ) : null}
                <StepBox title="Unidades Seleccionadas del CSV">
                  <div
                    style={{
                      display: "flex",
                      marginTop: "1em",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <LabelWithText
                        divStyle={{ width: "100%" }}
                        labelText={"Línea de crédito"}
                        bText={
                          this.state.lineCredit !== 0
                            ? this.state.lineCredit
                            : ""
                        }
                      />
                      <LabelWithText
                        divStyle={{ width: "100%" }}
                        labelText={"Tipo de línea"}
                        bText={
                          this.state.typeLine !== 0
                            ? this.state.typeLine ==
                              process.env.REACT_APP_TYPE_PREOWNED
                              ? "Seminuevos"
                              : "Nuevos"
                            : ""
                        }
                      />
                      <LabelWithText
                        divStyle={{ width: "100%" }}
                        labelText={"Plazo"}
                        bText={
                          this.state.deadlines !== 0 ? this.state.deadlines : ""
                        }
                      />
                      {(user.type == 3 || user.type === 1) &&
                      this.state.status === 3 ? (
                        <LabelWithText
                          divStyle={{ width: "100%" }}
                          labelText={"Unidades en revisión"}
                          bText={`${
                            this.state.units.filter(
                              (item) => item.status === 1 || item.status === 2
                            ).length
                          } de ${this.state.units.length} unidades validadas`}
                        />
                      ) : null}
                    </div>
                  </div>
                  <MoneyUnits
                    availableBalance={numberFormat(this.state.availableBalance)}
                    remainingBalance={numberFormat(
                      this.state.availableBalance - this.state.totalAmount
                    )}
                    totalAmount={numberFormat(this.state.totalAmount)}
                    showRemainingBalance={this.state.status >= 4 ? false : true}
                  />
                  {(user.type === 3 || user.type === 1) &&
                  this.state.status === 3 ? (
                    <div style={{ display: "flex", marginTop: "1em" }}>
                      <div style={{ width: "50%" }}>
                        <div>
                          ¿Desea agregar un folio manual a la disposición?
                        </div>
                        <div style={{ paddingLeft: "1em" }}>
                          <RadioButton
                            name="useManualFolio"
                            id="useManualFolio"
                            value={true}
                            checked={this.state.useManualFolio === true}
                            label="Si"
                            onChange={this.handleManualFolioCheck}
                          />
                          <RadioButton
                            name="useManualFolio"
                            id="useManualFolio"
                            value={false}
                            checked={this.state.useManualFolio === false}
                            label="No"
                            onChange={this.handleManualFolioCheck}
                          />
                        </div>
                      </div>
                      <></>
                    </div>
                  ) : null}
                  {(user.type === 3 || user.type === 1) &&
                  this.state.status === 3 &&
                  this.state.useManualFolio ? (
                    <div style={{ display: "flex" }}>
                      <Form
                        render={(formRenderProps) => (
                          <div style={{ width: "50%" }}>
                            <Field
                              component={InputText}
                              dClassName="k-form-field-wrap finbe-form-field-filter"
                              id="manualFolio"
                              label="Folio manual"
                              name="manualFolio"
                              onChange={this.handleManualFolioCheck}
                              editable={true}
                              placeholder="Agregue el folio manual"
                              value={this.state.manualFolio}
                              validityStyles={true}
                              minLength={0}
                              maxLength={250}
                              type={"number"}
                              // wrapperStyle = {{ width: '90%' }}
                            />
                          </div>
                        )}
                      />
                    </div>
                  ) : null}
                  {/* {(user.type===3 && this.state.status >= 6 )&&
                  <div style={{ marginTop: "1em" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                      <ButtonSuccess
                        className=""
                        icon=""
                        onClick={() => {
                          this.setState({visibleSendCRM:!this.state.visibleSendCRM})
                        }}
                        pClassName="finbe-btn-margin"
                        primary={true}
                        text="Enviar a CRM"
                        disabled={!this.state.units.length>0}
                      />
                    </div>
                  </div>
                  } */}
                  <GridUnits
                    units={this.state.units}
                    dataDropDown={dataDropDown}
                    showActions={false}
                    status={this.state.status}
                    getDocument={this._getDocument}
                    handleCalculate={this.handleCalculate}
                    totalAmount={this.state.totalAmount}
                    remainingBalance={
                      this.state.availableBalance - this.state.totalAmount
                    }
                    loading={this.state.loading}
                    handleSendCRM={this.handleSendCRM}
                    handleLiberate={this.handleLiberate}
                    handleDialogUpdateFolio={this.handleDialogUpdateFolio}
                  />
                </StepBox>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}></div>
                <div style={{ display: "flex", textAling: "left" }}>
                  {/* <ButtonTertiary
                    className=""
                    icon={"arrow-left"}
                    onClick={this.handleClickCancel}
                    pClassName="finbe-btn-margin"
                    primary={true}
                    text={"Regresar"}
                  /> */}
                  {(user.type == 3 || user.type === 1) &&
                    this.state.status == 3 && (
                      <>
                        <ButtonPrimary
                          className=""
                          icon="refresh"
                          onClick={this.handelRefresh}
                          pClassName="finbe-btn-margin"
                          primary={true}
                          text="Refrescar"
                        />
                        <ButtonDanger
                          className=""
                          icon="close"
                          onClick={this.handelRejection}
                          pClassName="finbe-btn-margin"
                          primary={true}
                          text="Rechazar"
                        />
                        <ButtonSuccess
                          className=""
                          icon="check"
                          onClick={this.handleDialogRefresh}
                          pClassName="finbe-btn-margin"
                          primary={true}
                          text="Confirmar"
                        />
                      </>
                    )}
                </div>
              </div>
              <DialogBox
                buttonSuccessText={"Aceptar"}
                handelClose={this.handleDialogRefresh}
                handelAccept={this.handelApprove}
                title={"Confirmación"}
                visible={this.state.visibleApprove}
                visibleCancelButton={true}
              >
                <p>
                  Se han encontrado [{this.state.changes.length}] cambio(s) en
                  la información de las unidades
                </p>
                <p>¿Desea revisar la información?</p>
                <div style={{ paddingLeft: "1em" }}>
                  <p>
                    <RadioButton
                      name="confirmar"
                      value={true}
                      checked={this.state.refresh === true}
                      label="Si, la tabla de las unidades se actualizará"
                      onChange={this.handelRadioButton}
                    />
                  </p>
                  <p>
                    <RadioButton
                      name="confirmar"
                      value={false}
                      checked={this.state.refresh === false}
                      label="No, se enviará la información tal cual la indicó"
                      onChange={this.handelRadioButton}
                    />
                  </p>
                </div>
              </DialogBox>
              <DialogBox
                buttonSuccessText={"Aceptar"}
                handelClose={this.handleDialogResponse}
                handelAccept={this.handleDialogResponse}
                title={this.state.response.title}
                visible={this.state.visibleResponse}
              >
                {this.state.response.message}
              </DialogBox>
              <DialogBox
                buttonSuccessText={"Aceptar"}
                handelClose={() => {
                  this.setState({ visibleConfirm: !this.state.visibleConfirm });
                }}
                handelCancel={() => {
                  this.setState({ visibleConfirm: !this.state.visibleConfirm });
                }}
                handelAccept={this.handleRedoDocument}
                title={this.state.redoDocument.title}
                visible={this.state.visibleConfirm}
                visibleCancelButton={true}
              >
                {this.state.redoDocument.text}
              </DialogBox>
              {/* Envio de unidades a CRM */}
              <DialogBox
                buttonSuccessText={"Aceptar"}
                handelClose={() => {
                  this.setState({
                    visibleSendCRM: !this.state.visibleSendCRM,
                    sendDate: new Date(),
                  });
                }}
                handelCancel={() => {
                  this.setState({
                    visibleSendCRM: !this.state.visibleSendCRM,
                    sendDate: new Date(),
                  });
                }}
                handelAccept={this._sendCRM}
                title="Envió de unidades"
                visible={this.state.visibleSendCRM}
                visibleCancelButton={true}
              >
                ¿Desea realizar el envío de las unidades a CRM?
                <div>
                  <p>
                    <strong>Inicio de la disposición:</strong>
                  </p>
                  <DateInput
                    id="sendDate"
                    name="sendDate"
                    value={this.state.sendDate}
                    onChange={this.handleChange}
                  />
                </div>
              </DialogBox>
              {/* Actualización de folio manual */}
              <DialogBox
                buttonSuccessText={"Aceptar"}
                handelClose={() => {
                  this.setState({
                    visibleUpdateFolio: !this.state.visibleUpdateFolio,
                    updateFolio: "",
                  });
                }}
                handelCancel={() => {
                  this.setState({
                    visibleUpdateFolio: !this.state.visibleUpdateFolio,
                    updateFolio: "",
                  });
                }}
                handelAccept={this.handleUpdateFolioManual}
                title="Actualización de folio manual"
                visible={this.state.visibleUpdateFolio}
                visibleCancelButton={true}
              >
                ¿Desea realizar la actualización del folio manual?
                <div>
                  <InputText
                    id="updateFolio"
                    label="Folio manual"
                    name="updateFolio"
                    onChange={this.handleManualFolioCheck}
                    editable={true}
                    placeholder="Agregue el folio manual"
                    value={this.state.updateFolio}
                    validityStyles={true}
                    required={true}
                    // minLength={0}
                    // maxLength={250}
                    type={"number"}
                  />
                  <p>
                    <span style={{ fontWeight: "bold", color:"red" }}> NOTA:</span>Este
                    proceso no actualiza el folio en los documentos generados.
                  </p>
                </div>
              </DialogBox>
              {/* Fin de actualización de folio manual */}
              <DialogBox
                buttonSuccessText={"Aceptar"}
                handelClose={() => {
                  this.setState({
                    visibleLiberate: !this.state.visibleLiberate,
                  });
                }}
                handelCancel={() => {
                  this.setState({
                    visibleLiberate: !this.state.visibleLiberate,
                  });
                }}
                handelAccept={this._liberateProvision}
                title="Liberación de unidades"
                visible={this.state.visibleLiberate}
                visibleCancelButton={true}
              >
                ¿Desea realizar la liberación de las unidades sin enviar a CRM?
              </DialogBox>
            </div>
          );
        }}
      </UserData>
    );
  }
}

export default VisualizeProvisions;
