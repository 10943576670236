/**
 * @author: Alexander Chi
 * @description: Component of disposiciones list, create, edit, information CSV,
 *               upload XML o CSV, units evidences, FinBe validation
 * @date: 22/sept/2020
 **/
import React, { Component } from "react";
import { Stepper } from "@progress/kendo-react-layout";
import { StepFooter } from "../Sections/Steps";
import Company from "./Sections/Company";
import File from "./Sections/File";
import Units from "./Sections/Units";
import RequisitionCompleted from "./Sections/RequisitionCompleted";
import { useUsuario } from "../../Context/UserContext";
import ProvisionService from "../../Services/ProvisionService";
import AccountService from "../../Services/AccountService";
import CompanyService from "../../Services/CompanyService";
import UserService from "../../Services/UserServices";
import DocumentService from "../../Services/DocumentService";
import FinbeService from "../../Services/Finbe/FinbeService";
import { DialogBox } from "../Sections/Dialogs";
import * as XLSX from "xlsx";
import qs from "qs";
import UnitsService from "../../Services/UnitsService";
import ErrorsUnits from './Sections/ErrorsUnits'
import UserServices from "../../Services/UserServices";
import useLoader from '../../hooks/useLoader'
import useOperation from "../../hooks/useOperation";
import dotenv from "dotenv";
dotenv.config();

/** Global variables **/
let user = null;
let signOut = null;
let Loading = useLoader();
let operation = null;


/** Get user cotext hook **/
function UserData({ children }) {
  const { usuario,signOutCognito } = useUsuario();
  user = usuario;
  operation = useOperation()
  signOut = signOutCognito;

  return children({ user: usuario });
}

/** Step head **/
const stepperItems = [
  {
    className: "finbe-step-1",
    icon: "icon stepper-empresa",
    label: "Empresa",
    optional: false,
  },
  {
    className: "finbe-step-2",
    icon: "icon stepper-csv-n",
    label: "CSV",
    optional: false,
  },
  {
    className: "finbe-step-3",
    icon: "icon stepper-unidades-n",
    label: "Unidades",
    optional: false,
  },
  {
    className: "finbe-step-4",
    icon: "icon stepper-resumen-n",
    label: "Requisición finalizada",
    optional: false,
  },
];

const COLUMNS_CSV={
  AGENCY: "SUCURSAL",
  BRANDNAME: "MARCA",
  TYPE: "TIPO UNIDAD",
  MODEL:"MODELO/YEAR",
  DESCRIPTION: "DESCRIPCION",
  COLOR: "COLOR EXTERNO",
  INSIDE_COLOR: "COLOR INTERNO",
  VIN: "VIN",
  MOTOR:"MOTOR",
  AMOUNT:"VALOR TOTAL"

}

/** Create main **/
class CreateProvisions extends Component {
  constructor(props) {
    super(props);
    this.attachedDocumentRef= React.createRef();
    this.attachedDocumentCsvRef= React.createRef();
    this.state = {
      agency: null,
      attachedDocument: [],
      attachedDocumentCsv: [],
      attachedDocumentEvidence: [],
      availableBalance: 0,
      companies: [],
      company: null,
      creditLine: null,
      deadline: null,
      deadlines: [],
      description: "",
      handleChange: "",
      includeAll: false,
      legalRepresentative: null,
      remainingBalance: 0,
      response: {title: "", message: "", success: false},
      reviewUnits: false,
      step: 0,
      totalAmount: 0,
      showAddUnit:false,
      units: [],
      visibleResponse: false,
      visibleConfirm: false,
      visibleSaveAndSend:false,
      loading:false,
      isPreowned:false,
    };
  }


  /** After constructor **/
  componentWillMount(){
    if(this.props.location.state != undefined){
      this.setState({loading:true})
      localStorage.setItem('units', JSON.stringify([]));
      let _total = this.props.location.state.units.map(item => item.amount).reduce((prev, next) => prev + next);
      let _units = this.props.location.state.units.map((u,index)=>{u.index=index; return u});
      _units.map(async item=>{
        item.evidences= await this._getEvidencesByIdUnit(item.id);
      })
      this.setState({
        step: 3,
        reviewUnits: true,
        units: _units,
        totalAmount: _total,
        loading:false
      })
    }
  }

  /** After render **/
  componentDidMount() {
    this._getCompaniesByUser(user.id);
    this._getAgenciesByCompany(user.company.id);
    this._getLegalRepresentantive(user.company.id);
    this._getCreditLinesByCustomer(user.company.clientId, true);
  }

  _getEvidencesByIdUnit = async (id) => {
    try{
    Loading();
      const response = await UnitsService.getEvidenes(id);
      Loading();
      return response.data.map((item,index)=>{
        item.index=index;
        delete item.createdAt;
        delete item.legalarioId;
        delete item.signedDocumentId;
        delete item.signedStatus;
        return item;
      });
      // this.setState({ evidences: response.data})
    }catch(error){
    Loading();
      if (error?.response?.status == 401) signOut();
      console.info(error);
    }
  };

  /**Get companies assigned to user   */
  _getCompaniesByUser=async(userId)=>{
    let companies = [];
    try {
    Loading();
      const { data } = await UserServices.getCompanies(user.id);
      companies = data.map((company, index) => {
        return {
          accountId: company.accountId,
          id: company.id,
          legal: company.legal,
          text: company.name,
          clientId: company.clientId
        };
      });
      let company = companies.find((it) => it.id === user.company.id);
      this.setState({ companies, company });
    Loading();
    } catch (error) {
    Loading();
      if (error?.response?.status == 401) signOut();

    }
  }

  /** Get all companies by account of user session  **/
  _getCompaniesByAccount = async (accountId) => {
    let companies = [];
    let company = {};
    try {
    Loading();
      const res = await AccountService.getCompanies(accountId);
      companies = res.data.map((company, index) => {
        return {
          accountId: company.accountId,
          id: company.id,
          legal: company.legal,
          text: company.name,
          clientId: company.clientId
        };
      });
      company = companies.find((it) => it.id === user.company.id);
      this.setState({ companies, company });
    Loading();
    } catch (error) {
    Loading();
      if (error?.response?.status == 401) signOut();

    }
  };

  /** Get all agencies by company of user session  **/
  _getAgenciesByCompany = async (companyId) => {
    let agencies = [];
    let agency = {};
    try {
    Loading();
      const {data} = await CompanyService.getAgencies(companyId);
      const _agencies = data.filter(ag=>user.agency.find(i=>i.id==ag.id));
      agencies = _agencies.map((agency, index) => {
        return {
          id: agency.id,
          text: agency.name,
          value: agency.id,
        };
      });
      agency = agencies.length===1?agencies[0]:null;//agencies.find((it) => it.id === user.agency.id);
      this.setState({ agencies, agency, });
    Loading();
    } catch (error) {
    Loading();
      if (error?.response?.status == 401) signOut();

    }
  };

  /** Get all legal representative by company of user session **/
  _getLegalRepresentantive = async (companyId) => {
    let legalRepresentatives = [];
    let legalRepresentative = {};
    try {
    Loading();
      const { data } = await UserService.getAll({
        params: { company: companyId, type: [4] },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      legalRepresentatives = data.map((legal, index) => {
        return {
          id: legal.id,
          text: legal.firstname + " " + legal.lastname,
          value: legal.id,
        };
      });
      legalRepresentative = legalRepresentatives[0];
      this.setState({ legalRepresentatives, legalRepresentative });
    Loading();
    } catch (error) {
    Loading();
      if (error?.response?.status == 401) signOut();

    }
  };

  /** Get all credit lines of customer **/
  _getCreditLinesByCustomer = async (customerId, type) => {
    let creditLines = [];
    try {
    Loading();
      const res = await FinbeService.getConfigurationCreditLine({
        params: { numeroCliente: customerId },
      });
      creditLines = res.data.map((line, index) => {
        let deadlines = line.plazos.split("|")
        deadlines.sort(function(a, b){return a-b})
        deadlines = deadlines.filter(it=>it!=="").map((deadline, index) => {
          return {
            id: index,
            text: "Plazo - " + deadline,
            value: deadline
          };
        });
        return {
          id: line.lineaCredito,
          text: `${line.lineaCredito} ${line.tipoDispLinea== process.env.REACT_APP_TYPE_PREOWNED?"(Seminuevos)":"(Nuevos)"}`,
          value: line.lineaCredito,
          availableBalance: line.montoRestante,
          deadlines: deadlines,
          type: line.tipoDispLinea
        };
      });
      let creditLine =null
       if(creditLines.length===1){
         creditLine = creditLines[0];
        let montoReservado = await this._getReservedAmount({params:{creditLine:creditLine.value}});
        let _availableBalance = (+(creditLine.availableBalance-(+montoReservado)).toFixed(2))
        const {totalAmount} = this.state;
        let remainingBalance = (_availableBalance>0?_availableBalance:0) - totalAmount;
        let deadline = creditLine.deadlines[creditLine.deadlines.length - 1];
        if(remainingBalance < 0){
          this.setState({
            isPreowned: process.env.REACT_APP_TYPE_PREOWNED==creditLine.type,
            availableBalance: _availableBalance>0?_availableBalance:0,
            remainingBalance,
            deadline,
            deadlines: creditLine.deadlines,
            creditLine,
            response: {title: 'Ocurrió un error', message: 'El monto total supera el saldo restante', success: false},
            visibleResponse: !this.state.visibleResponse
          });
        }else{
          this.setState({
            isPreowned: process.env.REACT_APP_TYPE_PREOWNED==creditLine.type,
            availableBalance: creditLine.availableBalance,
            remainingBalance,
            deadline,
            creditLine,
            deadlines: creditLine.deadlines,
          });
        }
       }
      this.setState({ creditLines });
    Loading();
    } catch (error) {
    Loading();
      this.setState({
        response: {
          title:  "Ocurrió un error API externa",
          message: "<<ConfiguracionClientesDispLinea>> número cliente [" + customerId + "]: " + error.response.data,
          success: false,
        },
        visibleResponse: true,
        // loading: false
      });
      //this._getCreditLinesByCustomer(customerId, true);
    }
  };

  _getReservedAmount=async(params)=>{
    try {
    Loading();
      const res = await ProvisionService.getReservedAmount(params);
      Loading();
      return res.data.total||0;
    } catch (error) {
    Loading();
      if (error?.response?.status == 401) signOut();
    }
  }

  /** Save document **/
  _uploadDocument = async (file, nameState) => {
    try{
    Loading();
      const response = await DocumentService.uploadDocument(file);
      let doc = this.state[nameState];
      if(response.data.url){
        doc[0].extension = `.${response.data.filename.split('.')[1]}`;
        doc[0].url = response.data.url;
        this.setState({ [nameState]: doc});
        if(nameState === 'attachedDocument'){
          if(this.state.includeAll){
            let totalAmount = 0;
            const {units} = this.state;
            if(units.length > 0){
              units.map((unit, index) => {
                let evidences = [];
                evidences.push(this._setEvidenceUnit(0, doc[0], 7));
                unit.check = true;
                unit.evidences = evidences;
                totalAmount += Number(unit.amount);
                return unit;
              });
              this.setState({units, totalAmount});
            }
          }
        }
      }else{
        this.setState({[nameState]:[]})
      }
    Loading();
    }catch(error){
    Loading();
      if (error?.response?.status == 401) signOut();
      console.info(error);
    }
  };

  /** Delete document */
  _deleteDocument=async(file,nameState)=>{
    try {
    Loading();
      if(file.url){
        const res = await DocumentService.deleteDocument({  path: file.url })
      }
      if(nameState=="attachedDocument"){
        this.attachedDocumentRef.current.value=""
        if(this.state.includeAll){
          const {units}=this.state;
          units.map(item=>{
            item.check = false;
            item.evidences = [];
            return item;
          })
          this.setState({units,includeAll:false})
        }
      }else if(nameState == "attachedDocumentCsv"){
        this.attachedDocumentCsvRef.current.value=""
      }
      // }else{
      // }
      this.setState({[nameState]:[]})
    Loading();
    } catch (error) {
    Loading();
      if (error?.response?.status == 401) signOut();
      console.info(error);
    }
  }

  /** Click back **/
  handleClickBack = (e) => {
    this.setState({ step: this.state.step - (this.state.step > 0 ? 1 : 0) });
  };

  /** Click cancel **/
  handleClickCancel = (e) => {
    this.props.history.push("/disposiciones");
  };
  /** save provision */

  handleSave=async()=>{
    let units = this.state.units;
    let success = true;
    let message = "";
    Loading();
      //Create data provision
      /** is greater than amount total **/
      const {remainingBalance} = this.state;
      if(remainingBalance < 0){
        this.setState({
          response: {title: 'Ocurrió un error', message: 'El monto total supera el saldo restante', success: false},
          visibleResponse: !this.state.visibleResponse,
          loading: false,
        })
      }else{
        try {          
          if(this.state.agency !== null && this.state.company !== null && this.state.creditLine !== null && this.state.deadline !== null && this.state.legalRepresentative !=null){
            let data = {
              accountId: user.account.id,
              agencyId: this.state.agency.id,
              companyId: this.state.company.id,
              creditLine: this.state.creditLine.id,
              deadlines: this.state.deadline.value,
              description: this.state.description,
              evidences: [],
              legalId: this.state.legalRepresentative.id,
              registerBy: user.id,
              total: Number(this.state.totalAmount.toFixed(2)),
              typeLine: this.state.creditLine.type,
              includeAll: this.state.includeAll,
              units: [],
            };
  
            /** Set evidences in header **/
            [...this.state.attachedDocument].map((file, index) => {
              data.evidences.push(this._setEvidence(0, file, 2));
            });
  
            /** Set units of true checkbox **/
            units.map((unit) => {
              const { index, check,createdAt, ...rest } = unit;
              if (check) {
                /** Delete attribute file **/
                if(rest.evidences){
                  rest.evidences.map((evidence) => {
                    delete evidence.file;
                    delete evidence.index;
                    delete evidence.createdAt;
                    return evidence;
                  });
                }else{
                  rest.evidences=[];
                }
                rest.model = String(rest.model);
                data.units.push(rest);
              }
            });
            /** Create API => ProvisionService **/
            let res = null;
            if(this.state.reviewUnits){
              res = await ProvisionService.createWithUnitsExistits(data)
            }else{
              res = await ProvisionService.create(data);
            }
            message = "Disposición con folio " + res.data.folio + " generada exitosamente.";
          }else{
            throw ({response:{status:500,data:{message:'Verifique los campos obligatorios'}}})

          }
    Loading();
        } catch (error) {
    Loading();
      if (error?.response?.status == 401) signOut();
          success = false;
          message = error.response?error.response.data.message:error.message
        }
        /** Show response dialog **/
        this.setState({
          response: {
            title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
            message: message,
            success: success
          },
          visibleResponse: !this.state.visibleResponse,
          // loading: false
        });
      }
  }

  handleSaveAndSend=async()=>{
    let units = this.state.units;
    let success = true;
    let message = "";
    Loading();
    operation();

      //Create data provision
      /** is greater than amount total **/
      const {remainingBalance} = this.state;
      if(remainingBalance < 0){
        this.setState({
          response: {title: 'Ocurrió un error', message: 'El monto total supera el saldo restante', success: false},
          visibleResponse: !this.state.visibleResponse,
          loading: false,
        })
      }else{
        try {          
          if(this.state.agency !== null && this.state.company !== null && this.state.creditLine !== null && this.state.deadline !== null && this.state.legalRepresentative !=null){
            let data = {
              accountId: user.account.id,
              agencyId: this.state.agency.id,
              companyId: this.state.company.id,
              creditLine: this.state.creditLine.id,
              deadlines: this.state.deadline.value,
              description: this.state.description,
              evidences: [],
              legalId: this.state.legalRepresentative.id,
              registerBy: user.id,
              total: Number(this.state.totalAmount.toFixed(2)),
              typeLine: this.state.creditLine.type,
              includeAll: this.state.includeAll,
              units: [],
              reviewUnits: this.state.reviewUnits,
            };
  
            /** Set evidences in header **/
            [...this.state.attachedDocument].map((file, index) => {
              data.evidences.push(this._setEvidence(0, file, 2));
            });
  
            /** Set units of true checkbox **/
            units.map((unit) => {
              const { index, check,createdAt, ...rest } = unit;
              if (check) {
                /** Delete attribute file **/
                if(rest.evidences){
                  rest.evidences.map((evidence) => {
                    delete evidence.file;
                    delete evidence.index;
                    delete evidence.createdAt;
                    return evidence;
                  });
                }else{
                  rest.evidences=[];
                }
                rest.model = String(rest.model);
                data.units.push(rest);
              }
            });

            /** Create API => ProvisionService **/
            const res = await ProvisionService.createAndSend(data);
            message = "Disposición con folio " + res.data.folio + " generada exitosamente.";
          }else{
            this.setState({
              response: {title: 'Ocurrió un error', message: 'Verifique los campos obligatorios', success: false},
              visibleResponse: !this.state.visibleResponse,
              loading: false,
            })
            // throw ({response:{status:500,data:{message:'Verifique los campos obligatorios'}}})

          }

          Loading();
        } catch (error) {
          Loading();
          if (error?.response?.status == 401) signOut();
          success = false;
          message = error.response?error.response.data.message:error.message
        }
        /** Show response dialog **/
        this.setState({
          response: {
            title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
            message: message,
            success: success
          },
          visibleResponse: !this.state.visibleResponse,
          // loading: false
        });
      }
  }

  /**open dialog Confirm */

  handleDialogConfirm=()=>{
    this.setState({visibleConfirm:!this.state.visibleConfirm});
  }

  handleDialogSaveAndSend=()=>{
    this.setState({visibleSaveAndSend:!this.state.visibleSaveAndSend});
  }

  /** Click next or save **/
  handleClickNextOrSave = async (e) => {
    if (this.state.step < stepperItems.length - 1) {
      this.setState({ step: this.state.step + 1 });
    } else {
      this.handleDialogConfirm();
    }
  };

  /** Handel close or show dialog response **/
  handleDialogResponse = (e) => {
    if(this.state.response.success){
      this.props.history.push("/disposiciones");
    }else{
      this.setState({ visibleResponse: !this.state.visibleResponse });
    }
  };

  handleShowAddUnit=()=>{
    this.setState({showAddUnit:!this.state.showAddUnit})
  }

  /** Change for step index **/
  handleChangeStep = (e) => {
    this.setState({ step: e.value });
  };

  /** Change for get value inputs **/
  handleChange = async (e) => {
    const { value, props } = e.target;
    if(props === undefined){
      /** when is RadioButton **/
      const { element } = e.target;
      const {units} = this.state;
      let totalAmount = 0;
      if(units.length > 0){
        const {attachedDocument} = this.state;
        if(attachedDocument.length > 0){
          units.map((unit, index) => {
            let evidences = [];
            if(e.value) { evidences.push(this._setEvidenceUnit(0, attachedDocument[0], 7)); }
            unit.check = e.value;
            unit.evidences = evidences;
            totalAmount += Number(unit.amount);
            return unit;
          });
        }
      }
      if(units.length > 0 && totalAmount > 0 && e.value === false){
        totalAmount = 0;
      }
      this.setState({
        [element.current.id]: e.value,
        units,
        totalAmount
      });
    }else{
        this.setState({ [props.id]: value });
         if (props.id === "creditLine") {
          if (value !== null) {
            let montoReservado = await this._getReservedAmount({params:{creditLine:value.value}});
            let _availableBalance = (+(value.availableBalance-(+montoReservado)).toFixed(2))
            const {totalAmount} = this.state;

            let _totalAmount = totalAmount;
            const _units = [...this.state.units];
            let remainingBalance = 0;
            //Validación de si debe de actualizar las unidades o no
            if(this.state.includeAll){
              //Validación del tipo de linea de credito
              if(process.env.REACT_APP_TYPE_PREOWNED==value.type){
                _totalAmount = 0;
                if(_units.length > 0){
                    _units.map((unit, index) => {
                      let evidences = [];
                      unit.check = false;
                      unit.evidences = evidences;
                      return unit;
                    });
                    // this.setState({units:units,totalAmount,remainingBalance:0 });
                }
              }else{
                // const {units} = this.state;
                _totalAmount = 0;
                if(_units.length > 0){
                  const {attachedDocument} = this.state;
                  if(attachedDocument.length > 0){
                    _units.map((unit, index) => {
                      let evidences = [];
                      evidences.push(this._setEvidenceUnit(0, attachedDocument[0], 7));
                      unit.check = true;
                      unit.evidences = evidences;
                      _totalAmount += Number(unit.amount);
                      return unit;
                    });
                  }
                }
                // this.setState({units,totalAmount,remainingBalance:this.state.availableBalance-totalAmount});
              }
            }
            

            
            remainingBalance = (_availableBalance>0?_availableBalance:0) - _totalAmount;
            let deadline = value.deadlines[value.deadlines.length - 1];
            if(remainingBalance < 0){
              this.setState({
                units:_units,
                totalAmount:_totalAmount,
                availableBalance: _availableBalance>0?_availableBalance:0,
                remainingBalance,
                deadline,
                isPreowned: process.env.REACT_APP_TYPE_PREOWNED==value.type,
                deadlines: value.deadlines,
                response: {title: 'Ocurrió un error', message: 'El monto total supera el saldo restante', success: false},
                visibleResponse: !this.state.visibleResponse
              });
            }else{
              this.setState({
                units:_units,
                totalAmount:_totalAmount,
                availableBalance: value.availableBalance,
                remainingBalance,
                deadline,
                isPreowned: process.env.REACT_APP_TYPE_PREOWNED==value.type,
                deadlines: value.deadlines,
              });
            }
          } else {
            this.setState({ availableBalance: 0.0, deadlines: [], deadline: null });
          }
        }
    }
  };

  /** Calculate amounts**/
  handleCalculate = (amount, check) => {
    let result = 0;
    if (check) {
      result = this.state.totalAmount + Number(amount.toFixed(2));
    } else {
      result = this.state.totalAmount - Number(amount.toFixed(2));
    }
    let saldoRestante = this.state.availableBalance - result;
    //console.info('remainingBalance: ', saldoRestante)
    this.setState({
      totalAmount: result,
      remainingBalance: saldoRestante,
    });
  };

  /**Validation unit CSV */
  handleValidateUnit=(unit)=>{
    let validations = []
    //Valid Model
    if(unit[COLUMNS_CSV.MODEL].toString() === ""){
      validations.push(`La columna ${COLUMNS_CSV.MODEL} está vacía`)
    }else if(unit[COLUMNS_CSV.MODEL].toString().length > 4){
      validations.push(`La columna ${COLUMNS_CSV.MODEL} excede el número de caracteres permitidos {4}`)
    }
    //Valid Motor number
    if(unit[COLUMNS_CSV.MOTOR].toString() === ""){
      validations.push(`La columna ${COLUMNS_CSV.MOTOR} está vacía`)
    }else if(unit[COLUMNS_CSV.MOTOR].toString().length > 25){
      validations.push(`La columna ${COLUMNS_CSV.MOTOR} excede el número de caracteres permitidos {25}`)
    }
    //Valid VIN
    if(unit[COLUMNS_CSV.VIN].toString() === ""){
      validations.push(`La columna ${COLUMNS_CSV.VIN} está vacía`)
    }else if(unit[COLUMNS_CSV.VIN].toString().length < 17 || unit[COLUMNS_CSV.VIN].toString().length > 23){
      validations.push(`La columna ${COLUMNS_CSV.VIN} no se encuentra en el rango de caracteres permitidos {17-23}`)
    }
    //Sucursal asignada
    if(unit[COLUMNS_CSV.AGENCY].toString() === ""){
      validations.push(`La columna ${COLUMNS_CSV.AGENCY} está vacía`)
    }else if(unit[COLUMNS_CSV.AGENCY].toString().length > 20){
      validations.push(`La columna ${COLUMNS_CSV.AGENCY} excede el número de caracteres permitidos {20}`)
    }
    //Valor total
    if(typeof(unit[COLUMNS_CSV.AMOUNT])!=="number"){
      validations.push(`La columna ${COLUMNS_CSV.AMOUNT} no es el tipo de dato especificado`)
    }else if(unit[COLUMNS_CSV.AMOUNT] ===0){
      validations.push(`La columna ${COLUMNS_CSV.AMOUNT} no contiene el valor especifico`)
    }else if((unit[COLUMNS_CSV.AMOUNT]+'').replace('.','').length>10){
      validations.push(`La columna ${COLUMNS_CSV.AMOUNT} excede el número de dígitos permitidos {10}`)
    }
    //Marca
    if(unit[COLUMNS_CSV.BRANDNAME].toString() === ""){
      validations.push(`La columna ${COLUMNS_CSV.BRANDNAME} está vacía`)
    }else if(unit[COLUMNS_CSV.BRANDNAME].toString().length > 15){
      validations.push(`La columna ${COLUMNS_CSV.BRANDNAME} excede el número de caracteres permitidos {15}`)
    }
    //Color exterior
    if(unit[COLUMNS_CSV.COLOR].toString() === ""){
      validations.push(`La columna ${COLUMNS_CSV.COLOR} está vacía`)
    }else if(unit[COLUMNS_CSV.COLOR].toString().length > 35){
      validations.push(`La columna ${COLUMNS_CSV.COLOR} excede el número de caracteres permitidos {35}`)
    }
    //Descripcion
    if(unit[COLUMNS_CSV.DESCRIPTION].toString() === ""){
      validations.push(`La columna ${COLUMNS_CSV.DESCRIPTION} está vacía`)
    }else if(unit[COLUMNS_CSV.DESCRIPTION].toString().length > 50){
      validations.push(`La columna ${COLUMNS_CSV.DESCRIPTION} excede el número de caracteres permitidos {50}`)
    }
    //Color interior
    if(unit[COLUMNS_CSV.INSIDE_COLOR].toString() === ""){
      validations.push(`La columna ${COLUMNS_CSV.INSIDE_COLOR} está vacía`)
    }else if(unit[COLUMNS_CSV.INSIDE_COLOR].toString().length > 70){
      validations.push(`La columna ${COLUMNS_CSV.INSIDE_COLOR} excede el número de caracteres permitidos {70}`)
    }
    //Tipo de unidad
    if(unit[COLUMNS_CSV.TYPE].toString() === ""){
      validations.push(`La columna ${COLUMNS_CSV.TYPE} está vacía`)
    }else if(unit[COLUMNS_CSV.TYPE].toString().length > 8){
      validations.push(`La columna ${COLUMNS_CSV.TYPE} excede el número de caracteres permitidos {8}`)
    }  
    return validations;
  }
  /** Change for get value inputs arrays file **/
  handleChangeFile = (e) => {
    try {

      const { name, props, files } = e.target;

      // this.setState({ [name]: files });
      if (name === "attachedDocumentCsv") {
        let types = ['csv']
        let totalAmount = 0;
        const file = files[0];
        if(!types.includes(file.name.split('.')[1])) throw new Error('La extensión del documento no es permitido')
        const promise = new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsText(file,'ISO-8859-1');
          fileReader.onload = (e) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: "buffer" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
        promise.then((unitsFormatCsv) => {
          let validations = [];
          let units = [];
          unitsFormatCsv.forEach((unit,index) => {
            let isValid = true;
            let evidences = [];
            if(this.state.includeAll){
              const {attachedDocument} = this.state;
              evidences.push(this._setEvidenceUnit(0, attachedDocument[0], 7));
              totalAmount += Number(unit[COLUMNS_CSV.AMOUNT]);
            }
            /**Validacion de unidades */
            if(Object.keys(unit).length !== 10){
              validations.push({title:`La fila ${index+1}:`,items:[`La fila no cuenta el número de columnas especificado en el formato CSV`]})
              isValid=false;
            }else{
              let validUnit = this.handleValidateUnit(unit);
              if(validUnit.length>0){
                isValid=false;
                validations.push({title:`La fila ${index+1}:`,items:this.handleValidateUnit(unit)})
              }
            }
  
            if(validations.length===0)
            units.push({
              index: index,
              model: unit[COLUMNS_CSV.MODEL].toString(),
              motorNumber: unit[COLUMNS_CSV.MOTOR].toString(),
              vinNumber: unit[COLUMNS_CSV.VIN].toString().trim(),
              color: unit[COLUMNS_CSV.COLOR].toString(),
              amount: unit[COLUMNS_CSV.AMOUNT],
              agency: unit[COLUMNS_CSV.AGENCY].toString(),
              brandName: unit[COLUMNS_CSV.BRANDNAME].toString(),
              description: unit[COLUMNS_CSV.DESCRIPTION].toString(),
              insideColor: unit[COLUMNS_CSV.INSIDE_COLOR].toString(),
              type: unit[COLUMNS_CSV.TYPE].toString(),
              evidences,
              check: this.state.includeAll,
            });
          });
          /** information csv **/
          // let units = unitsFormatCsv.map((unit, index) => {
          //   let evidences = [];
          //   if(this.state.includeAll){
          //     const {attachedDocument} = this.state;
          //     evidences.push(this._setEvidenceUnit(0, attachedDocument[0], 7));
          //     totalAmount += Number(unit[COLUMNS_CSV.AMOUNT]);
          //   }
          //   /**Validacion de unidades */
          //   if(Object.keys(unit).length !== 10){
          //     validations.push({title:`La fila ${index+1}:`,items:[`La fila no cuenta el número de columnas especificado en el formato CSV`]})
          //   }else{
          //     validations.push({title:`La fila ${index+1}:`,items:this.handleValidateUnit(unit)})
          //   }
  
  
          //   return {
          //     index: index,
          //     model: unit[COLUMNS_CSV.MODEL],
          //     // motorNumber: unit[COLUMNS_CSV.MOTOR].toString(),
          //     vinNumber: unit[COLUMNS_CSV.VIN].trim(),
          //     color: unit[COLUMNS_CSV.COLOR],
          //     amount: unit[COLUMNS_CSV.AMOUNT],
          //     agency: unit[COLUMNS_CSV.AGENCY],
          //     brandName: unit[COLUMNS_CSV.BRANDNAME],
          //     description: unit[COLUMNS_CSV.DESCRIPTION],
          //     insideColor: unit[COLUMNS_CSV.INSIDE_COLOR],
          //     type: unit[COLUMNS_CSV.TYPE],
          //     evidences,
          //     check: this.state.includeAll,
          //   };
          // });
          // this.state.visibleResponse;
          this.setState({ attachedDocumentCsv:validations.length>0?[]:files,visibleResponse:validations.length>0?true:false, units, totalAmount,response:validations.length>0?{title:"Ocurio un error",message:ErrorsUnits({validations}),success:false}:this.state.response });
        });
      }else{
        let types = ['pdf']
        const file = files[0];
        if(!types.includes(file.name.split('.')[1])) throw new Error('La extensión del documento no es permitido')

        if(file.validationErrors == undefined){
          this.setState({ [name]: files });
          let formData = new FormData();
          formData.append('file', file);
          this._uploadDocument(formData,name);
        }
      } 
    } catch (error) {
      this.setState({
        response: {
          title: "Ocurrió un error",
          message: error.message,
          success: false
        },
        visibleResponse: !this.state.visibleResponse,
      });
    }
  };

  /** Components of creatre provision **/
  _renderStep(step) {
    switch (step) {
      case 0:
        return (
          <Company
            agencies={this.state.agencies}
            agency={this.state.agency}
            attachedDocument={this.state.attachedDocument}
            companies={this.state.companies}
            company={this.state.company}
            description={this.state.description}
            handleChange={this.handleChange}
            handleChangeFile={this.handleChangeFile}
            handleDeleteFile={this._deleteDocument}
            legalRepresentative={this.state.legalRepresentative}
            legalRepresentatives={this.state.legalRepresentatives}
            includeAll = {this.state.includeAll}
            refFile={this.attachedDocumentRef}
          />
        );
      case 1:
        return (
          <File
            attachedDocumentCsv={this.state.attachedDocumentCsv}
            handleChangeFile={this.handleChangeFile}
            handleDeleteFile={this._deleteDocument}
            refFile={this.attachedDocumentCsvRef}
          />
        );
      case 2:
        return (
          <Units
            availableBalance = {this.state.availableBalance}
            creditLine = {this.state.creditLine}
            creditLines = {this.state.creditLines}
            deadline = {this.state.deadline}
            deadlines = {this.state.deadlines}
            document = {this.state.document}
            handleCalculate = {this.handleCalculate}
            handleChange = {this.handleChange}
            handleChangeFile = {this.handleChangeFile}
            remainingBalance = {this.state.remainingBalance}
            totalAmount = {this.state.totalAmount}
            units = {this.state.units}
            uploadDocument = {this._uploadDocument}
            attachedDocument= {this.state.attachedDocument}
            includeAll = {this.state.includeAll}
            showAddUnit={this.state.showAddUnit}
            handleShowAddUnit={this.handleShowAddUnit}
            reviewUnits = {this.state.reviewUnits}
            isPreowned={this.state.isPreowned}
          />
        );
      case 3:
        return (
          <RequisitionCompleted
            agencies={this.state.agencies}
            agency = {this.state.agency}
            attachedDocument = {this.state.attachedDocument}
            attachedDocumentCsv = {this.state.attachedDocumentCsv}
            availableBalance = {this.state.availableBalance}
            companies={this.state.companies}
            company = {this.state.company}
            creditLine = {this.state.creditLine}
            creditLines = {this.state.creditLines}
            deadline = {this.state.deadline}
            deadlines = {this.state.deadlines}
            description = {this.state.description}
            document = {this.state.document}
            handleCalculate = {this.handleCalculate}
            handleChange = {this.handleChange}
            handleChangeFile = {this.handleChangeFile}
            includeAll = {this.state.includeAll}
            legalRepresentative={this.state.legalRepresentative}
            legalRepresentatives={this.state.legalRepresentatives}
            remainingBalance = {this.state.remainingBalance}
            reviewUnits = {this.state.reviewUnits}
            totalAmount = {this.state.totalAmount}
            units = {this.state.units}
            uploadDocument = {this._uploadDocument}
            showAddUnit={this.state.showAddUnit}
            handleShowAddUnit={this.handleShowAddUnit}
            handleDeleteFile={this._deleteDocument}
            refFile={this.attachedDocumentRef}
            refFileCsv={this.attachedDocumentCsvRef}
            isPreowned={this.state.isPreowned}
          />
        );
      default:
        return "";
    }
  }

  /** Set object evidence for list evidences **/
  _setEvidence(index, evidence, type){
    return {
      extension: String(evidence.extension),
      /*file: evidences,*/
      fileName: String(evidence.name),
      fileSize: String(evidence.size),
      /*index: String(index),*/
      type: String(type),
      url: evidence.url,
    }
  }

  /** Set object evidence for list evidences of units **/
  _setEvidenceUnit(index, evidences, type){
    return {
      extension: String(evidences.extension),
      file: evidences,
      fileName: String(evidences.name),
      fileSize: String(evidences.size),
      index: index,
      type: String(type),
      url: evidences.url,
    }
  };

  render() {
    return (
      <UserData>
        {({ user }) => {
          return (
            <div className="finbe-app-container">
              {
                !this.state.reviewUnits &&
                (
                  <Stepper
                    className="finbe-stepper-horizontal"
                    value={this.state.step}
                    onChange={this.handleChangeStep}
                    items={stepperItems}
                  />
                )
              }
              <div>{this._renderStep(this.state.step)}</div>
              <StepFooter
                step={this.state.step + 1}
                stepLength={stepperItems.length}
                backOnClick={this.handleClickBack}
                cancelOnclick={this.handleClickCancel}
                saveOrNextOnclick={this.handleClickNextOrSave}
                saveOrNextIcon={
                  this.state.step === stepperItems.length - 1
                    ? "check"
                    : "arrow-chevron-right"
                }
                saveOrNextText={
                  this.state.step === stepperItems.length - 1
                    ? "Guardar"
                    : "Siguiente"
                }
                visibleBack = {!this.state.reviewUnits}
                saveAndSend={this.state.step === stepperItems.length - 1}
                handleSaveAndSend={this.handleDialogSaveAndSend}
              />
              <DialogBox
                buttonSuccessText = {"Aceptar"}
                handelClose = {this.handleDialogConfirm}
                handelCancel={this.handleDialogConfirm}
                handelAccept = {this.handleSave}
                title = {"Creación de disposición"}
                visible = {this.state.visibleConfirm}
                visibleCancelButton={true}
              >
                {
                  `Tienes ${this.state.units.filter(item=>item.check).length} de ${this.state.units.length} unidades seleccionadas, ¿Estás seguro de crear esta disposición?`
                }
              </DialogBox>
              <DialogBox
                buttonSuccessText = {"Aceptar"}
                handelClose = {this.handleDialogSaveAndSend}
                handelCancel={this.handleDialogSaveAndSend}
                handelAccept = {this.handleSaveAndSend}
                title = {"Creación y envió de disposición"}
                visible = {this.state.visibleSaveAndSend}
                visibleCancelButton={true}
              >
                {
                  `Tienes ${this.state.units.filter(item=>item.check).length} de ${this.state.units.length} unidades seleccionadas, ¿Estás seguro de crear y enviar esta disposición?`
                }
              </DialogBox>
              <DialogBox
                buttonSuccessText = {"Aceptar"}
                handelClose = {this.handleDialogResponse}
                handelAccept = {this.handleDialogResponse}
                title = {this.state.response.title}
                visible = {this.state.visibleResponse}
              >
                {this.state.response.message}
              </DialogBox>
            </div>
          );
        }}
      </UserData>
    );
  }
}

export default CreateProvisions;
