import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import { InputComboBox, InputFilterText } from "../Sections/Components/Inputs";
import { FilterBox, MoreFilterBox } from "../Sections/Filters";
import { useUsuario } from "../../Context/UserContext";
import { process as ProcessGrid } from "@progress/kendo-data-query";
import useLoader from "../../hooks/useLoader";
import RefinancingService from "../../Services/RefinancingService";
import AccountService from "../../Services/AccountService";
import FinbeService from "../../Services/Finbe/FinbeService";
import { ButtonAction } from "../Sections/Components/Buttons";
import { DialogBox } from "../Sections/Dialogs";
import UnitsService from "../../Services/UnitsService";
import AuditService from "../../Services/AuditService";
import CompanyService from "../../Services/CompanyService";
import moment from "moment";
import dotenv from "dotenv";
dotenv.config();

function Units() {
  const initialFilters = {
    company: null,
    creditLine: null,
    model: null,
    vinNumber: null,
    motor: null,
    isSold: { id: 1, text: "No vendido", value: false },
  };
  const deadlines = [
    { id: 1, text: "Plazo - 1", value: 1 },
    { id: 2, text: "Plazo - 2", value: 2 },
    { id: 3, text: "Plazo - 3", value: 3 },
    { id: 4, text: "Plazo - 4", value: 4 },
    { id: 5, text: "Plazo - 5", value: 5 },
    { id: 6, text: "Plazo - 6", value: 6 },
  ];
  const valuesSold = [
    { id: 1, text: "No vendido", value: false },
    { id: 2, text: "Vendido", value: true },
  ];
  const Loading = useLoader();
  const { usuario, signOutCognito } = useUsuario();
  const [filters, setFilters] = useState(initialFilters);
  const [clients, setClients] = useState([]);
  const [creditLines, setCreditLines] = useState([]);
  const [units, setUnits] = useState([]);
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [dataState, setDataState] = useState({
    sort: [{ field: "vinNumber", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });
  const [unit, setUnit] = useState(null);
  const [deadline, setDeadline] = useState(null);

  const [visibleSold, setVisibleSold] = useState(false);
  const [visibleDeadline, setVisibleDeadline] = useState(false);
  const [visibleResponse, setVisibleResponse] = useState(false);
  const [response, setResponse] = useState({
    title: "",
    message: "",
    success: false,
  });
  const [isDown, setIsDown] = useState(true);
  const [isMoreFilter, setIsMoreFilter] = useState(true);
  const [canCreate, setCanCreate] = useState(true);

  const numberFormat = (value) => new Intl.NumberFormat("en-US").format(value);

  const getUnitsReview = async (params) => {
    try {
      Loading();
      const { data } = await RefinancingService.getUnitsForRefinancings({
        params,
      });
      const units = data.map((unit, index) => {
        unit.check = false;
        return unit;
      });
      setUnits(units);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
      setUnits([]);
      console.info(error);
    }
  };

  const getClients = async (id) => {
    try {
      Loading();
      const res = await AccountService.getCompaniesByAssingned(id);
      setClients(
        res.data.map((item) => {
          return {
            id: item.id,
            text: `${item.legalName} (${item.clientId})`,
            accountId: item.accountId,
            clientId: item.clientId,
          };
        })
      );
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status === 401) signOutCognito();

    }
  };
  const getCreditLines = async (clientId) => {
    try {
      const { data } = await FinbeService.getConfigurationCreditLine({
        params: { numeroCliente: clientId },
      });

      const _creditLines = data.map((line, index) => {
        return {
          id: line.lineaCredito,
          text: `${line.lineaCredito} ${line.tipoDispLinea== process.env.REACT_APP_TYPE_PREOWNED?"(Seminuevos)":"(Nuevos)"}`,
          value: line.lineaCredito,
        };
      });

      setCreditLines(_creditLines);
    } catch (error) {
    }
  };

  const validations = async (account, company) => {
    let canProvision = true;
    let message = "";

    let _auditValid = false;
    let _exceptionValid = false;
    let audits = [];
    let showResponse = true;
    try {
      Loading();
      // Valida Auditoria
      let params = {
        account: account,
        company: company,
        date: moment().format("YYYY/MM/DD"),
      };
      if (params.account === undefined) {
        message = "El usuario no tiene relacionada una cuenta.";
      }
      if (params.company === undefined) {
        message =
          (message.length > 0 ? "" : message) +
          "El usuario no tiene relacionada una compañía.";
      }
      let _auresponse = await AuditService.validateProcess({ params });
      audits = _auresponse.data.filter((audit) => audit.isSuspended);
      if (audits.length > 0) {
        _auditValid = true;
        message =
          (message.length > 0 ? message + "" : message) +
          "Tienes [" +
          audits.length +
          "] pendientes de auditorías.";
      }

      // Valida una excepción aprobada
      if (_auditValid) {
        let _exresponse = await CompanyService.lastException(company, {
          params: { date: moment().format("YYYY/MM/DD") },
        });
        if (_exresponse.data.length > 0) {
          let _valid = _exresponse.data.filter(
            (exception) => exception.status === 2
          );
          if (_valid.length > 0) {
            _exceptionValid = true;
            message =
              (message.length > 0 ? message + "" : message) +
              " La última excepción está aprobada. Ya puedes disponer.";
          } else {
            message =
              (message.length > 0 ? message + "" : message) +
              " La última excepción no está aprobada. No puedes disponer";
          }
        } else {
          message =
            (message.length > 0 ? message + "" : message) +
            " No puedes disponer";
        }
      } else {
        _exceptionValid = false;
        showResponse = false;
        message =
          (message.length > 0 ? message + "" : message) + " Puedes disponer.";
      }
      canProvision = _exceptionValid ? false : _auditValid ? true : false;

      Loading();
    } catch (err) {
      Loading();
      if (err.response && err.response.status == 401) signOutCognito();
      message = err.message;
    }
    setResponse({
      title: true ? "Resultado de validación" : "Ocurrió un error",
      message: message,
      success: true,
    });
    setVisibleResponse(showResponse);
    setCanCreate(canProvision);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value !== null && name == undefined) {
      setFilters({
        ...filters,
        ["datePickerFilter"]: value,
      });
    } else if (typeof value === "object") {
      setFilters({ ...filters, [name]: value });
      if (name == "company" && value) {
        validations(value.accountId, value.id);
        getCreditLines(value.clientId);
      }
    }else{
      setFilters({ ...filters, [name]: value });
    }
  };
  const handleChangeDeadline = (e) => {
    setDeadline(e.value);
  };

  const handleClickSearch = () => {
    let data = {};
    if (filters.company !== null && filters.company !== undefined) {
      data.company = filters.company.id;
    }
    if (filters.vinNumber !== null && filters.vinNumber !== undefined && filters.vinNumber !== "") {
      data.vinNumber = filters.vinNumber;
    }
    if (filters.motor !== null && filters.motor !== undefined && filters.motor !== "") {
      data.motor = filters.motor;
    }
    if (filters.creditLine !== null && filters.creditLine !== undefined) {
      data.creditLine = filters.creditLine.value;
    }
    if (filters.isSold !== null && filters.isSold !== undefined) {
      data.isSold = filters.isSold.value;
    } else {
      data.isSold = false;
    }
    getUnitsReview(data);
  };
  const handleShowRegisterChange = (e) => {
    const { value } = e.target;
    setShowRegister(value);
    setDataState({ ...dataState, ["take"]: value.text, skip: 0 });
  };

  const handelSelectionChange = (event) => {
    let unit = event.dataItem;
    let unitsCheck = units.filter((item) => item.check);
    let isCorrect =
      unitsCheck.length > 0
        ? unitsCheck[0]?.deadlines === unit.deadlines
        : true;
    const _units = units.map((item) => {
      if (item.id === unit.id && item.deadlines && !item.isSold && isCorrect) {
        item.check = !unit.check;
      }
      return item;
    });
    const obj = {
      accountId: filters.company.accountId,
      companyId: filters.company.id,
      units: _units.filter((it) => it.check),
    };
    localStorage.setItem("dataR", JSON.stringify(obj));
    setUnits(_units);
  };

  const handleDialogResponse = (e) => {
    setVisibleResponse(!visibleResponse);
    setUnit(null);
    setDeadline(null);
  };
  const handleShowDeadline = () => {
    setVisibleDeadline(!visibleDeadline);
    setUnit(null);
    setDeadline(null);
  };

  const handleDialogDeadline = (e) => {
    const target = e.currentTarget;
    if (target !== undefined) {
      let index = target.getAttribute("data");
      let unit = units.find((it) => it.id == index);
      setUnit(unit);
      setDeadline(deadlines.find((item) => item.value === unit.deadlines));
      setVisibleDeadline(!visibleDeadline);
    }
  };
  const handleSetDealines = async () => {
    let message = "";
    let success = true;
    try {
      setVisibleDeadline(false);
      Loading();
      const { data } = await UnitsService.setDeadlines(unit.id, {
        deadline: deadline.value,
      });
      message = `La unidad con vin:[${data.vinNumber}] se actualizo correctamente.`;
      let _units = [...units].map((item, index) => {
        if (data.id === item.id) {
          item.deadlines = data.deadlines;
        }
        return item;
      });
      setUnits(_units);
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOutCognito();
      success = false;
      message = error.message;
    }
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message,
      success,
    });
    setVisibleResponse(true);
  };

  const handleShowSold = () => {
    setVisibleSold(!visibleSold);
    setUnit(null);
  };
  const handleDialogSold = (e) => {
    const target = e.currentTarget;
    if (target !== undefined) {
      let index = target.getAttribute("data");
      let unit = units.find((it) => it.id == index);
      setUnit(unit);
      setVisibleSold(!visibleSold);
    }
  };
  const handleSetSold = async () => {
    let message = "";
    let success = true;
    try {
      setVisibleSold(false);
      Loading();
      const { data } = await UnitsService.setSold(unit.id, {
        isSold: !unit.isSold,
      });
      message = `La unidad con vin:[${data.vinNumber}] se actualizo correctamente.`;
      let _units = [...units].filter((item) => item.id !== data.id);
      setUnits(_units);
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOutCognito();
      success = false;
      message = error.message;
    }
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message,
      success,
    });
    setVisibleResponse(true);
  };
  /** Click for More filters **/
  const handleClickMoreFilter = () => {
    // this.setState({ isDown: !this.state.isDown });
    setIsDown(!isDown);
    if (!isDown) {
      setFilters({
        ...filters,
        creditLine: null,
      });
    }
  };
  useEffect(() => {
    localStorage.setItem("dataR", JSON.stringify({}));

    if (usuario) {
      getClients(usuario.id);
    }
  }, []);
  return (
    <>
      <div className="finbe-app-container">
        <div className="finbe-app-section-top">
          <FilterBox
            buttonMoreFilterOnClick={handleClickMoreFilter}
            buttonSearchOnClick={handleClickSearch}
            inputDropDownValue={showRegister}
            inputFilterOnChange={handleShowRegisterChange}
            isDown={isDown}
            isMoreFilter={isMoreFilter}
          >
            <div style={{ display: "flex", width: "100%" }}>
              <Form
                render={(formRenderProps) => (
                  <FormElement style={{ display: "flex" }}>
                    <Field
                      component={InputComboBox}
                      dClassName="k-form-field-wrap finbe-form-combo"
                      id="company"
                      name="company"
                      onChange={handleChange}
                      placeholder="Seleccionar compañía"
                      data={clients}
                      value={filters.company}
                      wrapperStyle={{ width: "33%", paddingTop: "1.8em" }}
                    />
                    <Field
                      component={InputFilterText}
                      dClassName="k-form-field-wrap finbe-form-field-filter"
                      id="vinNumber"
                      nombre="vinNumber"
                        onChange={handleChange}
                      placeholder="Ingrese N° de VIN"
                      value={filters.vinNumber}
                      wrapperStyle={{ width: "33%", paddingTop: "1.8em" }}
                    />
                    <Field
                      component={InputFilterText}
                      dClassName="k-form-field-wrap finbe-form-field-filter"
                      id="motor"
                      nombre="motor"
                      onChange={handleChange}
                      placeholder="Ingrese N° de motor"
                      value={filters.motor}
                      wrapperStyle={{ width: "33%", paddingTop: "1.8em" }}
                    />
                  </FormElement>
                )}
              />
            </div>
          </FilterBox>
        </div>
        <div style={{ color: "#b0ada6" }}>
          {!isDown ? (
            <div
              style={{
                width: "95%",
                margin: "1em 1em 0 1em",
                background: "#e4e3e0",
              }}
            >
              <MoreFilterBox>
                <Form
                  render={(formRenderProps) => (
                    <FormElement style={{ display: "flex" }}>
                      <Field
                        component={InputComboBox}
                        dClassName="k-form-field-wrap finbe-form-combo"
                        id="creditLine"
                        name="creditLine"
                        onChange={handleChange}
                        placeholder="Seleccionar línea"
                        data={creditLines}
                        value={filters.creditLine}
                        wrapperStyle={{ width: "33%", paddingTop: "1.8em" }}
                      />
                      <Field
                        component={InputComboBox}
                        dClassName="k-form-field-wrap finbe-form-combo"
                        id="isSold"
                        name="isSold"
                        onChange={handleChange}
                        // placeholder="Seleccionar línea"
                        data={valuesSold}
                        value={filters.isSold}
                        wrapperStyle={{ width: "33%", paddingTop: "1.8em" }}
                      />

                      {/* <Field
                        component={InputRangeDatePicker}
                        dateRangePickerId="datePickerFilter"
                        dateRangePickerName="datePickerFilter"
                        dClassName="k-form-field-wrap finbe-form-date"
                        dateRangePickerOnChange={this.handleChangeRangeDate}
                        dateRangePickerValue={this.state.rangeDate}
                        fieldWrapperStyle={
                          user.type == 4
                            ? {
                                width: "50%",
                                paddingLeft: "1.2em",
                              }
                            : {
                                width: "66%",
                                paddingLeft: "1.2em",
                              }
                        }
                      /> */}
                    </FormElement>
                  )}
                />
              </MoreFilterBox>
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <Grid
            className="finbe-table"
            data={ProcessGrid(units, dataState)}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.data);
            }}
            onSelectionChange={handelSelectionChange}
            pageable={{
              info: false,
            }}
            selectedField="check"
            selectable={{
              enabled: canCreate,
            }}
            sortable
            resizable
          >
            <Column
              field={"check"}
              title="Incluir"
              headerSelectionValue={
                units.findIndex((it) => it.check === false) === -1
              }
            />
            <Column field="vinNumber" title="N° VIN" />
            <Column field="model" title="Modelo" />
            <Column field="motorNumber" title="N° Motor" />
            <Column field="color" title="Color" />
            <Column field="deadlines" title="Plazo" />
            <Column
              field="amount"
              title="Monto"
              cell={(props) => <td> {numberFormat(props.dataItem.amount)} </td>}
            />
            <Column
              field="createdAt"
              title="Fecha de alta"
              filter="date"
              format="{0:dd/MM/yyyy}"
            />
            <Column
              title="Acciones"
              cell={(props) => (
                <td>
                  <div style={{ display: "flex" }}>
                    {!props.dataItem.isSold && (
                      <ButtonAction
                        title={"Actualizar"}
                        data={props.dataItem.id}
                        icon={" icon editar"}
                        look="bare"
                        onClick={handleDialogDeadline}
                        primary={false}
                      />
                    )}
                    <ButtonAction
                      title={"Vendido"}
                      data={props.dataItem.id}
                      icon={" icon archivar"}
                      look="bare"
                      onClick={handleDialogSold}
                      primary={false}
                    />
                  </div>
                </td>
              )}
            />
          </Grid>
        </div>
        <DialogBox
          buttonSuccessText={"Aceptar"}
          handelClose={handleShowDeadline}
          handelCancel={handleShowDeadline}
          handelAccept={handleSetDealines}
          title="Actualizar plazo"
          visible={visibleDeadline}
          visibleCancelButton={true}
        >
          ¿Desea actualizar el plazo de esta unidad?
          <div>
            <InputComboBox
              data={deadlines}
              dClassName="k-form-field-wrap finbe-form-combo"
              id="deadline"
              label="* Plazo"
              name="deadline"
              onChange={handleChangeDeadline}
              placeholder="Seleccione un plazo"
              required={true}
              validityStyles={true}
              value={deadline}
              wrapperStyle={{ width: "90%" }}
            />
          </div>
        </DialogBox>
        <DialogBox
          buttonSuccessText={"Aceptar"}
          handelClose={handleShowSold}
          handelCancel={handleShowSold}
          handelAccept={handleSetSold}
          title="Actualizar unidad"
          visible={visibleSold}
          visibleCancelButton={true}
        >
          {`¿Desea marcar como ${
            unit?.isSold ? "no vendido" : "vendido"
          } esta unidad?`}
        </DialogBox>
        <DialogBox
          buttonSuccessText={"Aceptar"}
          handelClose={handleDialogResponse}
          handelAccept={handleDialogResponse}
          title={response.title}
          visible={visibleResponse}
        >
          {response.message}
        </DialogBox>
      </div>
    </>
  );
}

export default Units;
