import React, { useEffect } from "react";
import { useState } from "react";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ButtonAction } from "../Sections/Components/Buttons";
import { DialogBox, Loading } from "../Sections/Dialogs";
import EmailServiceDeskService from "../../Services/EmailServiceDeskService";
import { useUsuario } from "../../Context/UserContext";
import moment from "moment";
import { FilterBox, MoreFilterBox } from "../Sections/Filters";
import useLoader from "../../hooks/useLoader";

export default function Index(props) {
  const { usuario, signOutCognito } = useUsuario();

  const [id, setId] = useState(null);
  const [emails, setEmails] = useState([]);
  const [emailFilter, setEmailFilter] = useState("");
  const [visibleRemove, setVisibleRemove] = useState(false);
  const [visibleResponse, setVisibleResponse] = useState(false);
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [dataState, setDataState] = useState({
    sort: [{ field: "company.name", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });
  const [response, setResponse] = useState({
    title: "",
    message: "",
    success: "",
  });
  const Loading = useLoader();
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "emailFilter") {
      setEmailFilter(value);
    } else {
      setShowRegister(value);
      setDataState({ ...dataState, ["take"]: value.text, skip: 0 });
    }
  };
  const getEmails = async (params) => {
    try {
      Loading();
      const { data } = await EmailServiceDeskService.getAll({
        params: { params },
      });
      const _data = data.map((value) => {
        const { createdAt, ...res } = value;
        const date = moment(createdAt, "DD/MM/YYYY").toDate();
        return {
          ...res,
          createdAt: date,
        };
      });
      setEmails(_data);
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status === 401) signOutCognito();
    }
  };
  const handleSearch = async () => {
    if (emailFilter !== null) {
      await getEmails({ email: emailFilter });
    }
  };
  const handleRemove = async () => {
    let success = true;
    let message = "";
    try {
      Loading();
      setVisibleRemove(false);
      const res = await EmailServiceDeskService.remove(id);
      message = "El correo se elimino correctamente";
      const _emails = emails.filter((item) => item.id !== id);
      setEmails(_emails);
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOutCognito();
      success = false;
      message = error.message;
    }
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
    });
    setVisibleResponse(true);
  };
  useEffect(() => {
    getEmails();
  }, []);

  return (
    <>
      <div className="finbe-app-container">
        <div className="finbe-app-section-top">
          <FilterBox
            buttonSearchOnClick={handleSearch}
            inputFilterDivClassName="k-form-field-wrap finbe-form-field-filter"
            inputFilterId="emailFilter"
            inputFilterName="emailFilter"
            inputFilterValue={emailFilter}
            inputFilterPlaceholder="Ingresar correo"
            inputDropDownValue={showRegister}
            inputFilterOnChange={handleChange}
            isMoreFilter={false}
            inputFilterwWrapperStyle={{ width: "100%" }}
          ></FilterBox>
        </div>
      </div>
      <div>
        <Grid
          className="finbe-table"
          data={process(emails, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.data);
          }}
          pageable={{
            info: false,
          }}
          sortable
          skip={dataState.skip}
          take={dataState.take}
          resizable
        >
          <Column field="email" title="Email" />
          <Column
            field="createdAt"
            title="Fecha"
            filter="date"
            format="{0:dd/MM/yyyy}"
          />
          <Column
            title="Acciones"
            cell={(props) => (
              <td>
                <div style={{ display: "flex" }}>
                  <>
                    <ButtonAction
                      title={"Editar"}
                      icon=" icon editar"
                      isRedirect={true}
                      look="bare"
                      primary={false}
                      url={"/editar-emails-mesa-servicio/" + props.dataItem.id}
                    />
                    <ButtonAction
                      title={"Eliminar"}
                      icon=" icon remover-eliminar"
                      look="bare"
                      primary={false}
                      isRedirect={false}
                      onClick={() => {
                        setId(props.dataItem.id);
                        setVisibleRemove(true);
                      }}
                    />
                  </>
                </div>
              </td>
            )}
          />
        </Grid>
        <DialogBox
          buttonSuccessText="Aceptar"
          centerButtons={true}
          handelAccept={handleRemove}
          handelClose={() => setVisibleRemove(false)}
          title={"Eliminar correo"}
          visible={visibleRemove}
          visibleCancelButton={true}
        >
          ¿Seguro que desea eliminar este correo?
        </DialogBox>
        <DialogBox
          buttonSuccessText={"Aceptar"}
          handelClose={() => setVisibleResponse(false)}
          handelAccept={() => setVisibleResponse(false)}
          title={response.title}
          visible={visibleResponse}
        >
          {response.message}
        </DialogBox>
      </div>
    </>
  );
}
