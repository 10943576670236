import React from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { RadioGroupInput } from "../../Inputs/Inputs";
import { Label } from "@progress/kendo-react-labels";
import { StepBox } from "../../Sections/Steps";
import { InputText } from "../../Sections/Components/Inputs";
import { RadioButton } from "@progress/kendo-react-inputs";

const typesAccounts = [
  { label: "Holding", value: 1, className: "task red" },
  { label: "Persona moral / física", value: 2, className: "task red" },
];

export const Datos = (props) => {
  const { handleChange, account, disabled } = props;

  return (
    <StepBox title="Datos de la cuenta">
      <Form
        render={(formRenderProps) => (
          <FormElement>
            <div style={{ display: "flex" }}>
              {/* <div style={{ display: "flex" }}> */}
              <div>Seleccione el tipo de usuario:</div>
              <div style={{ paddingLeft: "1em" }}>
                <RadioButton
                  name="type"
                  value={1}
                  disabled={disabled}
                  checked={account.type === 1}
                  label="Holding"
                  onChange={handleChange}
                />
                <RadioButton
                  name="type"
                  value={2}
                  disabled={disabled}
                  checked={account.type === 2}
                  label="Persona moral / física"
                  onChange={handleChange}
                />
              </div>
              {/* </div>
                            <div style={{ width: '50%' }}>
                                <Label editorId={'type-user'}>
                                    Seleccione el tipo de usuario:&nbsp;
                                    </Label>
                                <Field
                                    layout={'horizontal'}
                                    data={typesAccounts}
                                    disabled={disabled}
                                    id="type"
                                    nombre="type"
                                    textField="Seleccionar tipo"
                                    component={RadioGroupInput}
                                    value={(account.type > 0) ? typesAccounts.find(x => x.value == account.type).value : ""}
                                    onChange={handleChange}
                                />
                            </div> */}
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="email"
                  label="Correo de contacto"
                  nombre="email"
                  onChange={handleChange}
                  placeholder="Ingresa correo de contacto"
                  required={true}
                  rules={"([a-z0-9_.-]+)@([da-z.-]+).([a-z.]{2,6})$"}
                  validityStyles={true}
                  maxLength={150}
                  minLength={1}
                  value={account.email}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            {account.type == 1 && (
              <div style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                  <Field
                    component={InputText}
                    dClassName="k-form-field-wrap finbe-form-field-filter"
                    id="name"
                    label="Nombre holding"
                    nombre="name"
                    onChange={handleChange}
                    placeholder="Nombre de holding"
                    value={account.name}
                    wrapperStyle={{ width: "90%" }}
                  />
                </div>
              </div>
            )}
          </FormElement>
        )}
      />
    </StepBox>
  );
};
