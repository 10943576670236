import { Field, Form, FormElement } from "@progress/kendo-react-form";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useUsuario } from "../../Context/UserContext";
import AccountService from "../../Services/AccountService";
import AuditService from "../../Services/AuditService";
import { RadioGroupInput } from "../Inputs/Inputs";
import {
  InputComboBox,
  InputRangeDatePicker,
  InputText,
} from "../Sections/Components/Inputs";
import { Label } from "@progress/kendo-react-labels";
import { ButtonPrimary, ButtonTertiary } from "../Sections/Components/Buttons";
import moment from "moment";
import { RadioButton } from "@progress/kendo-react-inputs";
import { DialogBox } from "../Sections/Dialogs";
import useLoader from "../../hooks/useLoader";
const isSuspendedData = [
  { label: "Si", value: true, className: "task red" },
  { label: "No", value: false, className: "task red" },
];
const CreateEditAudit = (props) => {
  let history = useHistory();
  const { usuario, signOutCognito } = useUsuario();

  const initialState = {
    id: null,
    description: "",
    auditor: "",
    accountId: null,
    companyId: null,
    isSuspended: false,
    registerBy: "",
    datePicker: { start: null, end: null },
  };

  const [audit, setAudit] = useState(initialState);
  const [accounts, setAccounts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [editing, setEditing] = useState(false);
  const Loading = useLoader();
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (event.value !== undefined) {
      if (event.value !== null && event.value.start) {
        setAudit({
          ...audit,
          ["datePicker"]: event.target.value,
        });
      } else if (name !== undefined) {
        if (typeof value === "object") {
          setAudit({ ...audit, [name]: value !== null ? value.id : "" });
          if (name == "accountId") {
            if (value !== null) {
              getCompanies(value.id);
            } else {
              setCompanies([]);
            }
          }
        } else {
          setAudit({ ...audit, [name]: value });
        }
      } else if (typeof value !== "object") {
        setAudit({ ...audit, ["isSuspended"]: event.value });
      }
    }
  };

  const getAccounts = async () => {
    try {
      Loading();
      const res = await AccountService.getAll();
      let list = res.data.map((item) => {
        return { id: item.id, text: item.name };
      });
      setAccounts(list);
      if(list.length===1){
        setAudit(prev=>({...prev,accountId:list[0].id}))
        await getCompanies(list[0].id);
      }
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };
  const getCompanies = async (id) => {
    try {
      Loading();
      const res = await AccountService.getCompanies(id);
      let _companies = res.data.map((item) => {
        return { id: item.id, text: item.name };
      });
      setCompanies(_companies);
      if(_companies.length===1){
        setAudit(prev=>({...prev,companyId:_companies[0].id}))
      }
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };

  const getUpdate = async (id) => {
    try {
      Loading();
      const { data } = await AuditService.getById(id);
      await getAccounts(data.accountId);
      // let account = accounts.find((item) => item.id === data.accountId);
      await getCompanies(data.accountId);
      // let company = companies.find((item) => item.id === data.companyId);
      let date = {
        start: moment(data.initialDate, "DD/MM/YYYY").toDate(),
        end: moment(data.endDate, "DD/MM/YYYY").toDate(),
      };
      setAudit({
        ...audit,
        id: data.id,
        folio: data.folio,
        accountId: data.accountId,
        companyId: data.companyId,
        auditor: data.auditor,
        description: data.description,
        registerBy: usuario.id,
        isSuspended: data.isSuspended,
        datePicker: date,
      });
      setEditing(!editing);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };

  const handleClickSave = async () => {
    setVisibleConfirm(false);
    let success = true;
    let message = "";
    let data = {};
    if (editing) {
      Loading();
      data.auditor = audit.auditor;
      data.accountId = audit.accountId;
      data.companyId = audit.companyId;
      data.description = audit.description;
      data.isSuspended = audit.isSuspended;
      data.registerBy = usuario.id;
      data.initialDate = moment(audit.datePicker.start).format("YYYY-MM-DD");
      data.endDate = moment(audit.datePicker.end).format("YYYY-MM-DD");
      try {
        const res = await AuditService.update(audit.id, data);
        message = "La información de la auditoria se ha actualizado";
        Loading();
      } catch (error) {
        Loading();
        if (error?.response?.status == 401) signOutCognito();
        success = false;
        message = error.message;
      }
      setDataResponse({
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: success,
      });
      setVisible(!visible);
    } else {
      Loading();
      data.auditor = audit.auditor;
      data.accountId = audit.accountId;
      data.companyId = audit.companyId;
      data.description = audit.description;
      data.isSuspended = audit.isSuspended;
      data.registerBy = usuario.id;
      data.initialDate = moment(audit.datePicker.start).format("YYYY-MM-DD");
      data.endDate = moment(audit.datePicker.end).format("YYYY-MM-DD");
      try {
        const res = await AuditService.create(data);
        message = "La auditoria se ha guardado";
        Loading();
      } catch (error) {
        Loading();

        if (error?.response?.status == 401) signOutCognito();
        success = false;
        message = error.message;
      }
      setDataResponse({
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: success,
      });
      setVisible(!visible);
    }
  };

  const handleClickCancel = () => {
    history.push("/auditorias");
  };
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [dataConfirm, setDataConfirm] = useState({});
  const openDialog = () => {
    setVisibleConfirm(true);

    if (editing) {
      setDataConfirm({
        title: "Edición de auditoria",
        message: "¿Desea guardar los cambios?",
      });
    } else {
      setDataConfirm({
        title: "Creación de auditoria",
        message: "¿Estás seguro de crear esta auditoria?",
      });
    }
  };
  const [visible, setVisible] = useState(false);
  const [dataResponse, setDataResponse] = useState({
    title: "",
    message: "",
    success: "",
  });
  const handleDialogResponse = () => {
    if (dataResponse.success) {
      history.push("/auditorias");
    } else {
      setVisible(!visible);
    }
  };
  useEffect(() => {
    if (usuario) {
      if (props.match.params.id !== undefined) {
        getUpdate(props.match.params.id);
      } else {
        getAccounts();
      }
    }

  }, [props.match.params.id, usuario]);
  return (
    <div className="finbe-app-container">
      <h3>Datos de la Auditoria</h3>
      <Form
        render={(formRenderProps) => (
          <FormElement>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="auditor"
                  label="* Auditor"
                  nombre="auditor"
                  onChange={handleChange}
                  placeholder="Ingresar nombres"
                  required={true}
                  validityStyles={true}
                  minLength={1}
                  maxLength={150}
                  value={audit.auditor}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="description"
                  label="* Descripción"
                  nombre="description"
                  onChange={handleChange}
                  placeholder="Ingresar descripción"
                  value={audit.description}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputComboBox}
                  data={accounts}
                  dClassName="k-form-field-wrap finbe-form-combo"
                  id="accountId"
                  label="* Cuenta"
                  name="accountId"
                  onChange={handleChange}
                  placeholder="Seleccionar cuenta"
                  required={true}
                  validityStyles={true}
                  value={accounts.find((item) => item.id === audit.accountId)}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                {/* <div style={{width: "100%"}}> */}
                <Field
                  dClassName="k-form-field-wrap finbe-form-date"
                  dateRangePickerId="datePickerFilter"
                  dateRangePickerName="datePickerFilter"
                  dateRangePickerOnChange={handleChange}
                  fieldWrapperStyle={{
                    width: "92%",
                    paddingLeft: "1em",
                  }}
                  dateRangePickerDefaultValue={audit.datePicker}
                  dateRangePickerValue={audit.datePicker}
                  component={InputRangeDatePicker}
                />
                {/* </div> */}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputComboBox}
                  data={companies}
                  dClassName="k-form-field-wrap finbe-form-combo"
                  id="companyId"
                  label="* Empresa"
                  name="companyId"
                  onChange={handleChange}
                  placeholder="Seleccionar empresa"
                  required={true}
                  validityStyles={true}
                  value={companies.find((item) => item.id === audit.companyId)}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                * ¿Suspender el panel de disposiciones durante la auditoria?:
              </div>
              <div style={{ paddingLeft: "1em" }}>
                <RadioButton
                  name="isSuspended"
                  value={true}
                  checked={audit.isSuspended === true}
                  label="Si"
                  onChange={handleChange}
                />
                <RadioButton
                  name="isSuspended"
                  value={false}
                  checked={audit.isSuspended === false}
                  label="No"
                  onChange={handleChange}
                />
              </div>
            </div>
            {/* <div style={{ display: "flex" }}>
              <Label>
                * ¿Suspender el panel de disposiciones durante la
                auditoria?:
              </Label>
              <Field
                layout={"horizontal"}
                data={isSuspendedData}
                id="isSuspended"
                nombre="isSuspended"
                component={RadioGroupInput}
                value={audit.isSuspended}
                onChange={handleChange}
                wrapperStyle={{ width: "90%", paddingTop:"5px" }}
              />
            </div> */}
          </FormElement>
        )}
      />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}></div>
        <div style={{ display: "flex", textAling: "left" }}>
          <ButtonTertiary
            className=""
            icon="close"
            onClick={handleClickCancel}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Cancelar"
          />
          <ButtonPrimary
            className=""
            icon="check"
            onClick={editing ? handleClickSave : openDialog}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Guardar"
          />
        </div>
      </div>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={dataResponse.title}
        visible={visible}
      >
        {dataResponse.message}
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => {
          setVisibleConfirm(false);
        }}
        handelCancel={() => setVisibleConfirm(false)}
        handelAccept={handleClickSave}
        title={dataConfirm.title}
        visible={visibleConfirm}
        visibleCancelButton={true}
      >
        {dataConfirm.message}
      </DialogBox>
    </div>
  );
};

export default CreateEditAudit;
