/**
 * @author: Alexander Chi
 * @description: Component for buttons with desing
 * @date: 18/sept/2020
 **/
import React, { Component } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router-dom";
import DocumentService from "../../../Services/DocumentService";
import { Tooltip } from '@progress/kendo-react-tooltip';

/** **/
export class TooltipTemplate extends Component {
    render() { return ( <span>{this.props.title}</span> ); }
}
/** Main button filter **/
export const ButtonTertiary = (props) => {
  const {
    className,
    disabled,
    icon,
    lock,
    onClick,
    pClassName,
    primary,
    text,
  } = props;
  return (
    <p className={pClassName}>
      <Button
        className={"finbe-button tertiary " + (className ? className : "")}
        disabled={disabled}
        icon={icon ? icon : ""}
        look={lock}
        onClick={onClick}
        primary={primary}
      >
        {text}
      </Button>
    </p>
  );
};

/** Values default of props **/
ButtonTertiary.defaultProps = {
  className: "",
  disabled: false,
  icon: "",
  lock: "default",
  pClassName: "",
  primary: true,
  text: "Search",
};

/** Secundary class css button **/
export const ButtonSecundary = (props) => {
  const {
    className,
    disabled,
    icon,
    lock,
    onClick,
    pClassName,
    primary,
    text
  } = props;
  return (
    <p className={pClassName}>
      <Button
        className={"finbe-button secondary " + (className ? className : "")}
        disabled={disabled}
        icon={icon ? icon : ""}
        look={lock}
        onClick={onClick}
        primary={primary}
      >
        {text}
      </Button>
    </p>
  );
};

/** Values default of props **/
ButtonSecundary.defaultProps = {
  className: "",
  icon: "",
  lock: "default",
  pClassName: "",
  primary: true,
  text: "Search",
};

/** Primary class css button **/
export const ButtonPrimary = (props) => {
  const {
    className,
    disabled,
    icon,
    lock,
    onClick,
    pClassName,
    primary,
    text,
  } = props;
  return (
    <p className={pClassName}>
      <Button
        className={"finbe-button primary " + (className ? className : "")}
        disabled={disabled}
        icon={icon ? icon : ""}
        look={lock}
        onClick={onClick}
        primary={primary}
      >
        {text}
      </Button>
    </p>
  );
};

/** Values default of props **/
ButtonPrimary.defaultProps = {
  className: "",
  disabled: false,
  icon: "",
  lock: "default",
  pClassName: "",
  primary: true,
  text: "Search",
};

/** Danger class css button **/
export const ButtonDanger = (props) => {
  const {
    className,
    disabled,
    icon,
    lock,
    onClick,
    pClassName,
    primary,
    text,
  } = props;
  return (
    <p className={pClassName}>
      <Button
        className={"finbe-button danger " + (className ? className : "")}
        disabled={disabled}
        icon={icon ? icon : ""}
        look={lock}
        onClick={onClick}
        primary={primary}
      >
        {text}
      </Button>
    </p>
  );
};

/** Values default of props **/
ButtonDanger.defaultProps = {
  className: "",
  icon: "",
  lock: "default",
  pClassName: "",
  primary: true,
  text: "Search",
};

/** Success class css button **/
export const ButtonSuccess = (props) => {
  const {
    className,
    disabled,
    icon,
    lock,
    onClick,
    pClassName,
    primary,
    text,
  } = props;
  return (
    <p className={pClassName}>
      <Button
        className={"finbe-button success " + (className ? className : "")}
        disabled={disabled}
        icon={icon ? icon : ""}
        look={lock}
        onClick={onClick}
        primary={primary}
      >
        {text}
      </Button>
    </p>
  );
};

/** Values default of props **/
ButtonSuccess.defaultProps = {
  className: "",
  icon: "",
  lock: "default",
  pClassName: "",
  primary: true,
  text: "Search",
};

/** Acction class css button open={true} **/
export const ButtonAction = (props) => {
  let history = useHistory();
  const {
    className,
    data,
    disabled,
    icon,
    isRedirect,
    lock,
    onClick,
    primary,
    text,
    url,
    value,
    title,
  } = props;

  function handleClick() {
    history.push(url);
  }

  return (
    <div className="finbe-div-button">
      <Tooltip content={ (props) => <TooltipTemplate title={props.title}/> }
      parentTitle={true}
      anchorElement="target" 
      position="bottom">
        <Button
          className={"finbe-button action " + (className ? className : "")}
          data={data}
          disabled={disabled}
          icon={icon ? icon : ""}
          look={lock}
          onClick={isRedirect ? handleClick : onClick}
          primary={primary}
          value={value}
          title = {title}
        >
          {text}
        </Button>
      </Tooltip>
    </div>
  );
};

ButtonAction.defaultProps = {
  className: "",
  data: null,
  disabled: false,
  icon: "",
  isRedirect: false,
  lock: "default",
  pClassName: "",
  primary: true,
  text: "",
  url: "",
  value: null,
  title: 'Nombre de la acción en {title}'
};

/** Acction title class css button **/
export const ButtonTitle = (props) => {
  let history = useHistory();
  const {
    className,
    disabled,
    icon,
    id,
    isRedirect,
    lock,
    onClick,
    primary,
    text,
    url,
    value,
    localStorageDisabled,
  } = props;

  function handleClick() {
    if(localStorageDisabled === undefined || localStorageDisabled == null || localStorageDisabled === ''){
      history.push(url);
    }else{
      let disabled = localStorage.getItem(localStorageDisabled);
      disabled = JSON.parse(disabled);
      if(!disabled){
        history.push(url);
      }
    }
  }

  return (
    <Button
      className={className ? className : ""}
      disabled={disabled}
      icon={icon ? icon : ""}
      id = {id ?? null}
      look={lock}
      onClick={isRedirect ? handleClick : onClick}
      primary={primary}
      value={value}
    >
      {text}
    </Button>
  );
};

/** Acction title default props **/
ButtonTitle.defaultProps = {
  isRedirect: false,
  className: "",
  icon: "",
  lock: "default",
  disabled: false,
  pClassName: "",
  primary: true,
  text: "",
  url: "",
};

/** Acction show document class css button **/
export const ButtonShow = (props) => {
  const {
    className,
    disabled,
    icon,
    lock,
    primary,
    text,
    url,
    value,
  } = props;

  async function handleClick() {
    try {
      const { data } = await DocumentService.showDocument({ path: url });
      window.open(data.url, "_blank");
    } catch (error) {
    }
  }

  return (
    <div className="finbe-div-button">
      <Button
        className={"finbe-button action " + (className ? className : "")}
        disabled={disabled}
        icon={icon ? icon : ""}
        look={lock}
        onClick={handleClick}
        primary={primary}
        value={value}
      >
        {text}
      </Button>
    </div>
  );
};

/** Acction show document default props **/
ButtonShow.defaultProps = {
  className: "",
  disabled: false,
  icon: "",
  lock: "default",
  pClassName: "",
  primary: true,
  text: "",
  url: "",
  value: null,
};
