import React from 'react';
import { useHistory } from "react-router-dom";
import { Button } from '@progress/kendo-react-buttons';


//Boton redirect
export const ButtonItem = (props)=>{
    //Este boton tiene un onClik
    return <Button className={props.className} disabled={props.disabled} data={props.data} icon={props.icon} look={props.lock} primary={props.primary} onClick={props.onClick}>{props.text}</Button>
}

export const ButtonRedirect = (props)=>{
    let history = useHistory();

    function handleClick() {
        history.push(props.uri);
    }
    //Este boton tiene un onClik
    return <Button className={props.className?props.className:''} icon={props.icon?props.icon:''} look={props.lock} primary={props.primary} onClick={handleClick}>{props.text}</Button>
}
