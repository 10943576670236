//Componente para la lista de los usuarios
import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import RoleService from "../../Services/RoleService";
import { useUsuario } from "../../Context/UserContext";
import { process } from "@progress/kendo-data-query";
import qs from "qs";
import moment from "moment";
import { FilterBox } from "../Sections/Filters";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import {
  InputFilterText,
  InputRangeDatePicker,
  InputSwitch,
} from "../Sections/Components/Inputs";
import { ButtonAction } from "../Sections/Components/Buttons";
import { DialogBox } from "../Sections/Dialogs";
import useLoader from "../../hooks/useLoader";

const Roles = () => {
  const { usuario, signOutCognito } = useUsuario();
  const initialFilters = {
    name: "",
    datePickerFilter: { start: null, end: null },
    type: null,
    registerBy: null,
  };
  const [roleId, setRoleId] = useState("");
  const [roles, setRoles] = useState([]);
  const [dataFilters, setDataFilters] = useState(initialFilters);
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });

  const [dataState, setDataState] = useState({
    sort: [{ field: "name", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });
  const Loading = useLoader();
  const onChange = (event) => {
    const { name, value } = event.target;
    if (typeof value !== "object") {
      setDataFilters({ ...dataFilters, [name]: value });
    } else if (value != null) {
      setDataFilters({ ...dataFilters, ["datePickerFilter"]: value });
    }
  };

  useEffect(() => {
    if (usuario) {
      let data = {};
      if (usuario.type == 2 || usuario.type == 3 || usuario.type == 4) {
        data.registerBy = usuario.id;
        getRoles(data);
      } else {
        getRoles();
      }
    }
  }, [usuario]);

  const getRoles = async (data) => {
    try {
      Loading();
      const res = await RoleService.getAll({
        params: data,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      setRoles(res.data);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();

    }
  };

  //#region
  // const archived = () => {
  //   let estatus = roles.find((x) => x.id === roleId).isArchived;
  //   RoleService.setArchive(roleId, { archive: !estatus })
  //     .then((res) => {
  //       let list = roles.filter((t) => t.id !== roleId);
  //       setRoles(list);
  //       setRoleId("");
  //     })
  //     .catch((error) => {

  //       setRoleId("");
  //     });
  //   setVisibleDialog(!visibleDialog);
  // };

  // //Control para mostrar dialogo
  // const toggleDialog = (e) => {
  //   let id;
  //   if (e == "Realizado") {
  //     //Para mensaje de realizado
  //     let _dD = {
  //       type: null,
  //       title: " ",
  //       classMessage: null,
  //       message: "Realizado",
  //       textConfirm: null,
  //       textCancel: null,
  //     };
  //     setDataDialog(_dD);
  //     setVisibleDialog(true);
  //   } else if (e.currentTarget != undefined) {
  //     if (e.currentTarget.getAttribute("data") != null) {
  //       //Para desplegar modal de confirmación para archivar
  //       id = e.currentTarget.getAttribute("data");
  //       setRoleId(id);
  //       setVisibleDialog(!visibleDialog);
  //       let _dD = {
  //         type: "archive",
  //         title: "Archivar rol",
  //         close: null,
  //         classMessage: null,
  //         message: "¿Seguro que deseas archivar al rol?",
  //         textConfirm: "Aceptar",
  //         textCancel: "Cancelar",
  //       };
  //       setDataDialog(_dD);
  //     } else {
  //       setVisibleDialog(!visibleDialog);
  //     }
  //   } else if (e.target._reactInternalFiber !== undefined) {
  //     if (e.target._reactInternalFiber.type.name == "Switch") {
  //       //Para desplegar modal de confirmación de activar/desactivar
  //       id = e.target.props.data;
  //       let estatus = roles.find((x) => x.id === id).isActive;
  //       let _dD = {
  //         type: "active",
  //         title: (estatus ? "Desactivar " : " Activar ")+"rol",
  //         close: null,
  //         classMessage: null,
  //         message:
  //           "¿Seguro que deseas " +
  //           (estatus ? "desactivar " : " activar ") +
  //           " al rol?",
  //         textConfirm: "Aceptar",
  //         textCancel: "Cancelar",
  //       };
  //       setDataDialog(_dD);
  //       setRoleId(id);
  //       setVisibleDialog(!visibleDialog);
  //     } else {
  //       setVisibleDialog(!visibleDialog);
  //     }
  //   } else {
  //     setVisibleDialog(!visibleDialog);
  //   }
  // };
  //#endregion

  const [response, setResponse] = useState({
    response: {
      title: "",
      message: "",
      success: false,
    },
  });
  const [visibleResponse, setVisibleResponse] = useState(false);
  const [visibleChangeStatus, setVisibleChangeStatus] = useState(false);
  const handleDialogResponse = (e) => {
    setVisibleResponse(!visibleResponse);
  };
  const handleChangeStatus = async (e) => {
    let response = {};
    let message = "";
    let success = true;
    try {
      Loading();
      let estatus = roles.find((x) => x.id === roleId).isActive;
      const res = await RoleService.setActive(roleId, { active: !estatus });
      roles.find((t) => t.id === roleId).isActive = res.data.isActive;
      message = "Se realizó el cambio de estatus del rol";
      setVisibleChangeStatus(!visibleChangeStatus);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
      success = false;
      message = error.message;
    }
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
    });
    setVisibleResponse(!visibleResponse);
  };
  const handleDialogChangeStatus = (e) => {
    const target = e.target;
    if (target !== undefined) {
      if (target.props !== undefined) {
        let id = target.props.data;
        setRoleId(id);
        setVisibleChangeStatus(!visibleChangeStatus);
      } else {
        setVisibleChangeStatus(!visibleChangeStatus);
      }
    } else {
      setVisibleChangeStatus(!visibleChangeStatus);
    }
  };
  const handleClickSearch = () => {
    let data = {};
    if (dataFilters.name !== "") data.name = dataFilters.name;
    if (dataFilters.datePickerFilter.start !== null)
      data.from = moment(dataFilters.datePickerFilter.start).format(
        "YYYY-MM-DD"
      );
    if (dataFilters.datePickerFilter.end !== null)
      data.to = moment(dataFilters.datePickerFilter.end).format("YYYY-MM-DD");
    if (dataFilters.type != null) data.type = dataFilters.type;
    if (usuario.type == 2 || usuario.type == 3 || usuario.type == 4)
      data.registerBy = usuario.id;
    setDataState({ ...dataState, skip: 0 });
    getRoles(data);
  };
  const handleShowRegisterChange = (e) => {
    const { value } = e.target;
    setShowRegister(value);
    setDataState({ ...dataState, ["take"]: value.text, skip: 0 });
  };

  return (
    <>
      <div className="finbe-app-container">
        <div className="finbe-app-section-top">
          <FilterBox
            buttonSearchOnClick={handleClickSearch}
            isMoreFilter={false}
            inputDropDownValue={showRegister}
            inputFilterOnChange={handleShowRegisterChange}
          >
            <Form
              render={(formRenderProps) => (
                <FormElement style={{ display: "flex" }}>
                  <Field
                    dClassName="k-form-field-wrap finbe-form-field-filter"
                    id="name"
                    nombre="name"
                    onChange={onChange}
                    placeholder="Ingresar nombre"
                    value={dataFilters.name}
                    wrapperStyle={{ width: "33%", margin: "1.2em" }}
                    component={InputFilterText}
                  />
                  <Field
                    dClassName="k-form-field-wrap finbe-form-date"
                    dateRangePickerId="datePickerFilter"
                    dateRangePickerName="datePickerFilter"
                    dateRangePickerOnChange={onChange}
                    fieldWrapperStyle={{ width: "66%", margin: "0.4em" }}
                    defaultValue={{
                      start: dataFilters.datePickerFilter.start,
                      end: dataFilters.datePickerFilter.end,
                    }}
                    dateRangePickerValue={dataFilters.datePickerFilter}
                    component={InputRangeDatePicker}
                  />
                </FormElement>
              )}
            />
          </FilterBox>
        </div>
        <div>
          <Grid
            className="finbe-table"
            data={process(roles, dataState)}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.data);
            }}
            pageable={{
              info: false,
            }}
            sortable
            skip={dataState.skip}
            take={dataState.take}
            resizable
          >
            <Column field="name" title="Rol" />
            <Column field="description" title="Descripción" />
            <Column field="createdAt" title="Fecha" />
            <Column
              title="Acciones"
              cell={(props) => (
                <td style={{ display: "table-cell" }}>
                  <ButtonAction
                    title={"Editar"}
                    icon=" icon editar"
                    look="bare"
                    primary={false}
                    isRedirect={true}
                    url={"/editar-rol/" + props.dataItem.id}
                  />
                </td>
              )}
            />
            <Column
              field="isActive"
              title="Estatus"
              cell={(props) => (
                <td>
                  <InputSwitch
                    checked={props.dataItem.isActive}
                    onChange={handleDialogChangeStatus}
                    data={props.dataItem.id}
                  />
                </td>
              )}
            />
          </Grid>
        </div>
      </div>
      <DialogBox
        buttonSuccessText="Aceptar"
        centerButtons={true}
        handelAccept={handleChangeStatus}
        handelClose={handleDialogChangeStatus}
        title={"Cambiar estado"}
        visible={visibleChangeStatus}
        visibleCancelButton={true}
      >
        ¿Seguro que desea cambiar el estatus del rol?
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={response.title}
        visible={visibleResponse}
      >
        {response.message}
      </DialogBox>
    </>
  );
};

export default Roles;
