import React from "react";
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useUsuario } from "../../Context/UserContext";
import routes from "../../Utils/Layout/Routes";
import Header from "./Header";
import Menu from "./Menu";
import Wrapper from "./Wrapper";
import Login from "../login/Login";
import { Auth } from "aws-amplify";
import jwt_decode from "jwt-decode";

const Layout = (props) => {
  const {
    signOutCognito,
    isAuthenticated,
  } = useUsuario();

  const getCurrentSession = async () => {
    try {
      const res = await Auth.currentAuthenticatedUser({
        bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const token = localStorage.getItem("aws");
      if (jwt_decode(token).exp * 1000 < Date.now()) {

        signOutCognito();
      }

    } catch (error) {
      signOutCognito();
    }
  };


  return (
    <>
      {isAuthenticated && <Header />}
      {isAuthenticated && <Menu />}
      <Switch>
        {/* {isAuthenticated ? ( */}
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={true}
              name={route.name}
              render={(props) => (
                <Wrapper
                  getCurrentSession={getCurrentSession}
                  title={route.name}
                  parentRoute={route.parentRoute}
                  titleComponent={route.titleComponent}
                  visibleCompenent={route.visibleCompenent}
                >
                  {" "}
                  {/** Falta logica de botones */}
                  <route.component {...props} />
                </Wrapper>
              )}
            />
          );
        })}
        <Route
          path="/login"
          name="Login"
          render={(props) =>
            !isAuthenticated ? <Login {...props} /> : <Redirect to="/" />
          }
        />
        <Route
          path="*"
          name="Login"
          render={(props) =>
            isAuthenticated ? <Redirect to="/" /> : <Redirect to="/login" />
          }
        />
      </Switch>
    </>
  );
};

export default Layout;
