import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { process } from "@progress/kendo-data-query";
import CreditFailureService from "../../Services/CreditFailureService";
import {
  InputFilterText,
  InputRangeDatePicker,
} from "../Sections/Components/Inputs";
import { FilterBox } from "../Sections/Filters";
import { ButtonAction } from "../Sections/Components/Buttons";
import moment from "moment";
import { useUsuario } from "../../Context/UserContext";
import { DialogBox } from "../Sections/Dialogs";
import useLoader from "../../hooks/useLoader";
const BadCredits = () => {
  const { usuario, signOutCognito } = useUsuario();

  const initialFilters = {
    folio: "",
    datePickerFilter: { start: null, end: null },
  };
  const [dataFilters, setDataFilters] = useState(initialFilters);

  const [credits, setCredits] = useState([]);
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });

  const [dataState, setDataState] = useState({
    sort: [{ field: "name", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });
  const [dataResponse, setDataResponse] = useState({
    title: "",
    message: "",
    success: "",
  });
  const [visible, setVisible] = useState(false);
  const [creditId, setCreditId] = useState(null);
  const Loading = useLoader();

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (event.value !== undefined) {
      if (event.value !== null && name === undefined) {
        setDataFilters({
          ...dataFilters,
          ["datePickerFilter"]: event.target.value,
        });
      } else if (typeof value === "object") {
        setDataFilters({
          ...dataFilters,
          [name]: value !== null ? value.id : "",
        });
      } else {
        setDataFilters({ ...dataFilters, [name]: value });
      }
    }
  };
  const handleClickSearch = () => {
    const data = {};
    if (dataFilters.folio != "") data.folio = dataFilters.folio;
    if (dataFilters.datePickerFilter.start !== null)
      data.from = moment(dataFilters.datePickerFilter.start).format(
        "YYYY-MM-DD"
      );
    if (dataFilters.datePickerFilter.end !== null)
      data.to = moment(dataFilters.datePickerFilter.end).format("YYYY-MM-DD");
    setDataState({ ...dataState, skip: 0 });
    getBadCredits({ params: data });
  };
  const handleShowRegisterChange = (e) => {
    const { value } = e.target;
    setShowRegister(value);
    setDataState({ ...dataState, ["take"]: value.text, ["skip"]: 0 });
  };
  const getBadCredits = async (params) => {
    try {
      Loading();
      const { data } = await CreditFailureService.getAll(params);
      setCredits(data);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };

  const insertCreditCRM = async (e) => {
    setVisibleConfirm(false);
    let success = true;
    let message = "";
    const target = e.currentTarget;
    if (target !== undefined) {
      let id = target.getAttribute("data");
      try {
        Loading();
        const { data } = await CreditFailureService.insertCredit(id);
        message = "La unidad se inserto en CRM";
        getBadCredits();
        Loading();
      } catch (error) {
        Loading();
        if (error?.response?.status == 401) signOutCognito();
        success = false;
        message = error.message;
      }
      setDataResponse({
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: success,
      });
      setVisible(!visible);
    }
  };

  const retryInsertion = async (e) => {
    setVisibleConfirm(false);
    let success = true;
    let message = "";
    const target = e.currentTarget;
    if (target !== undefined) {
      let id = target.getAttribute("data");
      try {
        Loading();
        const { data } = await CreditFailureService.retryInsertion(id, {
          retry: true,
        });
        message =
          "La unidad se pondra en la cola de inserción cuando se inicie un nuevo ciclo";
        getBadCredits();
        Loading();
      } catch (error) {
        Loading();
        if (error?.response?.status == 401) signOutCognito();
        success = false;
        message = error.message;
      }
      setDataResponse({
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: success,
      });
      setVisible(!visible);
    }
  };
  const setArchived = async (e) => {
    setVisibleConfirm(false);
    let success = true;
    let message = "";
    try {
      Loading();
      const { data } = await CreditFailureService.setArchived(creditId, {
        archive: true,
      });
      message = "El intento de inserción se removio de la cola de intentos";
      getBadCredits();
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
      success = false;
      message = error.message;
    }
    setDataResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
    });
    setVisible(!visible);
  };

  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [dataConfirm, setDataConfirm] = useState({});
  const openDialog = (e) => {
    const target = e.currentTarget;
    if (target !== undefined) {
      let id = target.getAttribute("data");
      setCreditId(id);
      setVisibleConfirm(true);
      setDataConfirm({
        title: "Remover",
        message: "¿Seguro que desea remover el intento de insercion?",
      });
    }
  };
  const handleDialogResponse = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    if (usuario) {
      getBadCredits();
    }
  }, []);

  return (
    <div className="finbe-app-container">
      <div className="finbe-app-section-top">
        <FilterBox
          buttonSearchOnClick={handleClickSearch}
          isMoreFilter={false}
          inputDropDownValue={showRegister}
          inputFilterOnChange={handleShowRegisterChange}
        >
          <Form
            render={(formRenderProps) => (
              <FormElement style={{ display: "flex" }}>
                <Field
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="folio"
                  nombre="folio"
                  onChange={handleChange}
                  placeholder="Ingresar folio"
                  value={dataFilters.folio}
                  wrapperStyle={{ width: "50%", margin: "1em" }}
                  component={InputFilterText}
                />
                <Field
                  dClassName="k-form-field-wrap finbe-form-date"
                  dateRangePickerId="datePickerFilter"
                  dateRangePickerName="datePickerFilter"
                  dateRangePickerOnChange={handleChange}
                  fieldWrapperStyle={{ width: "50%", paddingLeft: "1.2em" }}
                  defaultValue={{
                    start: dataFilters.datePickerFilter.start,
                    end: dataFilters.datePickerFilter.end,
                  }}
                  dateRangePickerValue={dataFilters.datePickerFilter}
                  component={InputRangeDatePicker}
                />
              </FormElement>
            )}
          />
        </FilterBox>
      </div>
      <div>
        <Grid
          className="finbe-table"
          data={process(credits, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.data);
          }}
          pageable={{
            info: false,
          }}
          sortable
          skip={dataState.skip}
          take={dataState.take}
          resizable
        >
          <Column field="folio" title="Folio" cell={(props) => <td>{props.dataItem.useManualFolio?props.dataItem.manualFolio:props.dataItem.folio}</td>} />
          <Column field="legalName" title="Cliente" />
          <Column field="type" title="Tipo" cell={props=><td>{props.dataItem.type}</td>} />
          <Column field="creditLine" title="Línea de crédito" />
          <Column field="vinNumer" title="N° VIN" />
          <Column
            field="lastDate"
            title="Fecha"
            cell={(props) => <td>{props.dataItem.lastDate.split(" ")[0]}</td>}
          />
          <Column
            field="lastDate"
            title="Hora"
            cell={(props) => <td>{props.dataItem.lastDate.split(" ")[1]}</td>}
          />

          <Column
            title="Acciones"
            cell={(props) => (
              <td>
                <div style={{ display: "flex" }}>
                  <ButtonAction
                    title={"Reintentar"}
                    data={props.dataItem.id}
                    icon=" icon reintentar"
                    look="bare"
                    primary={false}
                    onClick={retryInsertion}
                  />
                  <ButtonAction
                    title={"Insertar"}
                    data={props.dataItem.id}
                    icon=" icon enviar"
                    look="bare"
                    primary={false}
                    onClick={insertCreditCRM}
                  />
                  <ButtonAction
                    title={"Remover/Eliminar"}
                    data={props.dataItem.id}
                    icon=" icon remover-eliminar"
                    look="bare"
                    onClick={openDialog}
                    primary={false}
                  />
                </div>
              </td>
            )}
          />
        </Grid>
      </div>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={dataResponse.title}
        visible={visible}
      >
        {dataResponse.message}
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => {
          setVisibleConfirm(false);
        }}
        handelCancel={() => setVisibleConfirm(false)}
        handelAccept={setArchived}
        title={dataConfirm.title}
        visible={visibleConfirm}
        visibleCancelButton={true}
      >
        {dataConfirm.message}
      </DialogBox>
    </div>
  );
};

export default BadCredits;
