import httpCommon from "../Utils/http-common";

const getHistoricalValidations = (params) => {
  const client = httpCommon();
  return client.get("/reports/historical-validations", params);
};

/**
 * @description: Get stadistics
 * @return: response
 **/
const stadistics = (params) => {
  const client = httpCommon();
  return client.get("/reports/provisions-stadistics", params);
};
export default {
  getHistoricalValidations,
  stadistics,
};
