import React, { useState } from "react";

import { InputText } from "../Sections/Components/Inputs";
import { useUsuario } from "../../Context/UserContext";
import { useHistory } from "react-router-dom";
import "./login.css";
import { ButtonPrimary } from "../Sections/Components/Buttons";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Auth } from "aws-amplify";
import useLoader from "../../hooks/useLoader";
import jwt_decode from "jwt-decode";
import moment from "moment";

export const Login = () => {
  const intialData = {
    email: "",
    password: "",
    newPassword: "",
    code: "",
    statusForm: 1,
  };

  let history = useHistory();

  const { login, setExpired, sigInCognito } = useUsuario();
  const [dataLogin, setDataLogin] = useState(intialData);
  const [user, setUser] = useState(null);
  const [dataResponse, setDataResponse] = useState(null);
  const Loading = useLoader();

  const onChange = (event) => {
    const { name, value } = event.target;
    setDataLogin({ ...dataLogin, [name]: value });
  };

  const handleSetPassword = async () => {
    try {
      Loading();
      const { email } = user.challengeParam;
      const res = await Auth.completeNewPassword(
        user,
        dataLogin.newPassword,
        {
          email: email,
        }
      );
      //Consultar auth para recuperar informacion de usuarios
      setUser(res);
      if (
        res.challengeParam.userAttributes !== undefined &&
        res.challengeParam.userAttributes.email_verified === "true"
      ) {
        const auth = await Auth.currentSession();
        const { exp, auth_time } = jwt_decode(auth.accessToken.jwtToken);
        const diffTime = moment(exp).diff(auth_time,'milliseconds');

        localStorage.setItem("aws", auth.accessToken.jwtToken);
        const _expired = (diffTime * 1000)-6000;
        setExpired(_expired);
        const resAuth = await login({
          email: res.challengeParam.userAttributes.email,
        });
        Loading();

        if (resAuth?.statusCode === undefined) {
          setDataResponse(null);
          history.push("/");
        } else {
        }
      }
    } catch (error) {
      Loading();
      let message = "";
      if (error.code === "InvalidPasswordException") {
          message = "La nueva contraseña con cumple con el formato establecido";
      }
      setDataResponse({
        success: false,
        message: message,
      });
    }
  };
  const recoverPassword = () => {
    setDataLogin({
      ...setDataLogin,
      ["statusForm"]: 3,
    });
  };
  const forgotPassword = async () => {
    try {
      Loading();
      const res = await Auth.forgotPassword(dataLogin.email);
      setDataLogin({ ...dataLogin, ["statusForm"]: 4 });
      Loading();
    } catch (error) {
      Loading();
    }
  };
  const handleSubmit = async () => {
    try {
      Loading();
      const res = await sigInCognito(dataLogin);
      if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
        Loading();
        setUser(res);
        setDataLogin({ ...dataLogin, ["statusForm"]: 2 });
        setDataResponse({
          success: true,
          message: "Es necesario que actualice su contraseña",
        });
      } else {
        const auth = await Auth.currentSession();
        const { exp, auth_time } = jwt_decode(auth.accessToken.jwtToken);
        const diffTime = moment(exp).diff(auth_time,'milliseconds');
        localStorage.setItem("aws", auth.accessToken.jwtToken);
        localStorage.setItem("sesions","1");
        const _expired = (diffTime * 1000)-6000;
        await login({ email: res.attributes.email });
        setExpired(_expired);

        setDataResponse(null);
        history.push("/");
        Loading();
      }
    } catch (error) {
      Loading();
      let message = "";
      if (error.code == "NotAuthorizedException") {
        if (error.message.includes("password has expired")) {
          message =
            "La contraseña temporal ha caducado, contacta a tu administrador";
        } else {
          message = "El correo o la contraseña son incorrectos";
        }
      }
      setDataResponse({
        success: false,
        message: message,
      });
    }
  };
  const forgotPasswordSubmit = async () => {
    try {
      Loading();
      const res = await Auth.forgotPasswordSubmit(
        dataLogin.email,
        dataLogin.code,
        dataLogin.newPassword
      );
      //Tomar como valido, mostrar mensaje y mandar al login
      setDataLogin(intialData);
      setDataResponse({
        success: false,
        message: "La contraseña se cambio con éxito, inicia sesión",
      });
      Loading();
    } catch (error) {
      Loading();
    }
  };
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()+_\-=}{\[\]|:;"\/\?\.><,`~])(?=.{8,})/gm;
  return (
    <>
      <div className="finbe-login-main">
        <div className="finbe-login-transparent"></div>

        <div className="finbe-login-content">
          <div className="finbe-login-logo">
            <img src="/icons/finbe-logo-alt.svg" alt="Financiera Bepensa" />
          </div>
          <div className="finbe-login-form">
            {dataResponse != null ? <span>{dataResponse.message}</span> : null}
            <Form
              render={(formRenderProps) => (
                <FormElement>
                  {(dataLogin.statusForm === 1 ||
                    dataLogin.statusForm === 3) && (
                    <Field
                      component={InputText}
                      dClassName="finbe-login-input"
                      id="email"
                      label="Correo"
                      nombre="email"
                      onChange={onChange}
                      placeholder="Ingresar email"
                      type={"email"}
                      value={dataLogin.email}
                      // wrapperStyle={{ width: "90%" }}
                    />
                  )}
                  {dataLogin.statusForm === 1 && (
                    <Field
                      component={InputText}
                      dClassName="finbe-login-input"
                      id="password"
                      label="Contraseña"
                      nombre="password"
                      onChange={onChange}
                      placeholder="Ingresar contraseña"
                      type={"password"}
                      value={dataLogin.password}
                      rules={regex}
                      validityStyles={true}
                      //   wrapperStyle={{ width: "100%" }}
                    />
                  )}
                  {dataLogin.statusForm === 4 && (
                    <Field
                      component={InputText}
                      dClassName="finbe-login-input"
                      id="code"
                      label="Código de verificación"
                      nombre="code"
                      onChange={onChange}
                      placeholder="Ingresar código de verificación"
                      value={dataLogin.code}
                    />
                  )}
                  {(dataLogin.statusForm === 2 ||
                    dataLogin.statusForm === 4) && (
                    <Field
                      component={InputText}
                      dClassName="finbe-login-input"
                      id="newPassword"
                      label="Nueva Contraseña"
                      nombre="newPassword"
                      onChange={onChange}
                      placeholder="Ingresar nueva contraseña"
                      type={"password"}
                      value={dataLogin.newPassword}
                      rules={
                        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                      }
                      validityStyles={true}
                      //   wrapperStyle={{ width: "100%" }}
                    />
                  )}
                </FormElement>
              )}
            />
            <div className="finbe-login-buttons">
              <ButtonPrimary
                className="finbe-login-button"
                primary={true}
                text={
                  dataLogin.statusForm === 2 || dataLogin.statusForm === 4
                    ? "Actualizar"
                    : dataLogin.statusForm === 3
                    ? "Enviar"
                    : "Iniciar Sesión"
                }
                onClick={
                  dataLogin.statusForm === 1
                    ? handleSubmit
                    : dataLogin.statusForm == 2
                    ? handleSetPassword
                    : dataLogin.statusForm === 3
                    ? forgotPassword
                    : forgotPasswordSubmit
                }
              />
              {(dataLogin.statusForm === 1 || dataLogin.statusForm === 4) && (
                <>
                  <div className="finbe-app-line"></div>
                  <br></br>
                  <span
                    className="finbe-login-button"
                    onClick={
                      dataLogin.statusForm === 1
                        ? recoverPassword
                        : forgotPassword
                    }
                  >
                    {dataLogin.statusForm === 1
                      ? "Recuperar contraseña"
                      : "Enviar otro código"}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
