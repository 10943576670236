import React, { useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
export const InfoAccount = (props) => {
  const [dataState, setDataState] = useState({
    sort: [{ field: "email", dir: "asc" }],
    take: 10,
    skip: 0,
  });
  return (
    <Dialog title=" " onClose={props.close} width={750} height={350}>
      {/* <button className="k-button" style={{float: "right"}} onClick={()=>props.setVisibleInfo(false)}>X</button> */}

      <p style={{ margin: "25px", textAlign: "left" }}>
        Datos de la organización
      </p>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "50%", marginRight: "18px" }}>
          <strong>Tipo:</strong>{" "}
          {props.account.type == 1 ? "Holding" : "Persona moral/física"}
        </div>
        <div style={{ width: "50%", marginRight: "18px" }}>
          <strong>Correo de contacto:</strong> {props.account.email}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "50%", marginRight: "18px" }}>
          <strong>Nombre:</strong> {props.account.name}
        </div>
      </div>

      {props.account.type === 1 && (
        <Grid
          className="finbe-table"
          pageable={{
            info: false,
          }}
          sortable
          data={process(props.account.companies, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.data);
          }}
        >
          <Column field="name" title="Empresa" />
          <Column field="legalName" title="Razón social" />
          <Column
            title="Repr. Legal"
            cell={(props) => (
              <td>
                {props.dataItem.legalPerson.firstname +
                  " " +
                  props.dataItem.legalPerson.lastname}
              </td>
            )}
          />
        </Grid>
      )}
    </Dialog>
  );
};
