import React, { useEffect, useState } from "react";
import useLoader from "../../hooks/useLoader";
import AccountService from "../../Services/AccountService";
import CompanyService from "../../Services/CompanyService";
import Company from "../Provisions/Sections/Company";
import { MoneyUnits } from "../Provisions/Sections/Units";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import {
  InputComboBox,
  InputDropDown,
  InputFile,
} from "../../Components/Sections/Components/Inputs";
import { StepBox } from "../Sections/Steps";
import FinbeService from "../../Services/Finbe/FinbeService";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import {
  ButtonAction,
  ButtonDanger,
  ButtonPrimary,
  ButtonSuccess,
} from "../Sections/Components/Buttons";
import { useHistory } from "react-router";
import { RadioButton } from "@progress/kendo-react-inputs";
import { DialogBox } from "../Sections/Dialogs";
import { useUsuario } from "../../Context/UserContext";
import UnitsService from "../../Services/UnitsService";
import RefinancingService from "../../Services/RefinancingService";
import Units from "./Sections/Units";
const numberFormat = (value) => new Intl.NumberFormat("en-US").format(value);

function CreateRefinancing(props) {
  const dataDropDown = [
    { id: 1, text: 10 },
    { id: 2, text: 25 },
    { id: 3, text: 50 },
    { id: 4, text: 100 },
  ];
  const Loading = useLoader();
  const { usuario, signOutCognito } = useUsuario();
  const history = useHistory();
  const [unit, setUnit] = useState(null);
  const [company, setCompany] = useState(null);
  const [agencies, setAgencies] = useState([]);
  const [agency, setAgency] = useState(null);
  const [legalRepresentative, setLegalRepresentative] = useState([]);
  const [creditLines, setCreditLines] = useState([]);
  const [creditLine, setCreditLine] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [deadlines, setDeadlines] = useState([]);
  const [deadline, setDeadline] = useState(null);
  const [reviewAF, setReviewAF] = useState(false);
  const [requireEvidence, setRequireEvidence] = useState(true);
  const [units, setUnits] = useState([]);
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [dataState, setDataState] = useState({
    sort: [{ field: "vinNumber", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });

  const [visibleDialogEvidences, setVisibleDialogEvidences] = useState(false);
  const [unitEvidences, setUnitEvidences] = useState([]);
  const [attachedDocumentEvidence, setAttachedDocumentEvidence] = useState([]);
  const [visibleDialogValid, setVisibleDialogValid] = useState(false);
  const [visibleDialogSave, setVisibleDialogSave] = useState(false);
  const [dataDialogConfirm, setDataDialogConfirm] = useState({
    title: "",
    body: "",
  });
  const [visibleResponse, setVisibleResponse] = useState(false);
  const [response, setResponse] = useState({
    title: "",
    message: "",
    success: false,
    isRedirect: false,
  });

  //handlers
  const customUnits = async ({ units }) => {
    const _units = [...units];
    // let total = 0;
    _units.map(async (ele, index) => {
      let unit = ele;
      delete unit.provisionId;
      delete unit.createdAt;
      delete unit.comisionEIva;
      delete unit.registerBy;
      delete unit.numeroDisposicion;
      delete unit.invoiceDate;
      delete unit.included;
      unit.check = false;
      // total += unit.amount;
      unit.index = index;
      unit.validRepuve = null;
      unit.validTransunion = null;
      unit.validAx = null;
      unit.status = 0;
      unit.evidences = await getUnitEvidences(unit.id);

      return unit;
    });
    setUnits(_units);
    // setTotalAmount(total);
  };
  const getUnitEvidences = async (unitId) => {
    try {
      Loading();
      const response = await UnitsService.getEvidenes(unitId);
      Loading();
      return response.data.map((item, index) => {
        item.index = index;
        delete item.createdAt;
        delete item.legalarioId;
        delete item.signedDocumentId;
        delete item.signedStatus;
        return item;
      });
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };
  const getCompany = async (id, companyId) => {
    try {
      Loading();
      const { data } = await AccountService.getCompanies(id);
      const company = data.find((item) => item.id === companyId);
      setCompany({
        id: company.id,
        text: `${company.legalName}`,
        clientId: company.clientId,
        accountId: id,
      });
      setLegalRepresentative({
        id: company.legal.id,
        text: `${company.legal.firstname} ${company.legal.lastname}`,
      });
      await getAgencies(companyId);
      await getCreditLines(company.clientId);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };
  const getAgencies = async (companyId) => {
    try {
      Loading();
      const { data } = await CompanyService.getAgencies(companyId);
      const agencies = data.map((agency, index) => {
        return {
          id: agency.id,
          text: agency.name,
          value: agency.id,
        };
      });
      const agency = agencies.length === 1 ? agencies[0] : null;
      setAgencies(agencies);
      setAgency(agency);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };

  const getCreditLines = async (clientId) => {
    try {
      Loading();
      const { data } = await FinbeService.getConfigurationCreditLine({
        params: { numeroCliente: clientId },
      });

      const _creditLines = data.map((line, index) => {
        let deadlines = line.plazos.split("|");
        deadlines.sort(function (a, b) {
          return a - b;
        });
        deadlines = deadlines.filter(item=>item.deadline<=3).map((deadline, index) => {
          return {
            id: index,
            text: "Plazo - " + deadline,
            value: deadline,
          };
        });
        return {
          id: line.lineaCredito,
          text: line.lineaCredito,
          value: line.lineaCredito,
          availableBalance: line.montoRestante,
          deadlines: deadlines,
        };
      });

      setCreditLines(_creditLines);
      if (_creditLines.length === 1) {
        setCreditLine(_creditLines[0]);
        setDeadlines(_creditLines[0].deadlines);
        setAvailableBalance(_creditLines[0].availableBalance);
      }
      Loading();
    } catch (error) {
      Loading();
    }
  };

  const handleChange = (e) => {
    const { name, value, element } = e.target;
    if (name === undefined && element) {
      if (element.current.id === "reviewAF") {
        setReviewAF(e.value);
      }
      if (element.current.id === "requireEvidence") {
        setRequireEvidence(e.value);
      }
    }
    if (name === "agency") {
      setAgency(value);
    }
    if (name === "creditLine") {
      setCreditLine(value);
      setDeadlines(value.deadlines);
      setAvailableBalance(value.availableBalance);
      setDeadline(null);
    }
    if (name === "deadline") {
      setDeadline(value);
    }
  };
  const handleChangeFile = (e) => {
    const { files } = e.target;
    setAttachedDocumentEvidence(files);
  };
  const handleTakeShowChange = (e) => {
    const { value } = e.target;
    setShowRegister(value);
    setDataState({ ...dataState, ["take"]: value.text, skip: 0 });
  };
  const renderCheck = (unit) => {
    if (unit !== undefined) {
      return unit.validRepuve && unit.validTransunion && unit.validAx;
    }
    return false;
  };
  const handleCalculate = (amount, check) => {
    let result = 0;
    if (check) {
      result = totalAmount + Number(amount.toFixed(2));
    } else {
      result = totalAmount - Number(amount.toFixed(2));
    }
    setTotalAmount(result);
  };
  const handelSelectionChange = (e) => {
    let unit = e.dataItem;
    const _units = [...units];
    let chekced = renderCheck(unit);
    _units.map((item) => {
      if (item.id === unit.id) {
        // if (chekced) {
          item.check = !item.check;
          handleCalculate(unit.amount, item.check);
          // item.included = item.check;
        // }
      }
      return item;
    });
    setUnits(_units);
  };

  const handleShowOrHideEvidence = (e) => {
    const target = e.currentTarget;
    if (target) {
      let index = target.getAttribute("data");
      let unit = units.find((item) => item.index == index);
      setUnit({ id: unit.id, model: unit.model });
      setUnitEvidences(unit.evidences);
      setVisibleDialogEvidences(true);
    } else {
      setVisibleDialogEvidences(false);
      setUnitEvidences([]);
      setUnit(null);
    }
  };

  const approveOrRejectUnit = (validation) => {
    return validation === null ? "" : validation ? "aprobado" : "desaprobado";
  };
  const renderApproveAdminFinBe = (status) => {
    if (status == 0 || status === undefined) {
      return "";
    } else if (status == 1) {
      return "aprobado";
    } else {
      return "desaprobado";
    }
  };

  const handleAddEvidenceUnit = async () => {
    const files = [...attachedDocumentEvidence];
    if (files.length > 0) {
      for (const file of files) {
        let formData = new FormData();
        formData.append("file", file.getRawFile());
        formData.append("fileName", file.name);
        formData.append("extension", file.extension);
        formData.append("fileSize", file.size);
        formData.append("type", 8);
        await uploadDocument(unit.id, formData);
      }
      //Recuperar las evidencias de la unidad.
      //Hacer un map para añadir las evidencias a la unidad
      setAttachedDocumentEvidence([]);
    }
  };
  const uploadDocument = async (id, formData) => {
    try {
      Loading()
      const response = await UnitsService.uploadDocument(id, formData);
      Loading();
    } catch (error) {
      Loading()
      if (error?.response?.status == 401) signOutCognito();
    }
  };

  const handleDialogResponse = (e) => {
    setVisibleResponse(!visibleResponse);
    if (response.isRedirect) {
      history.push("/refinanciamientos");
    }
  };
  const handleConfirmValid = () => {
    setDataDialogConfirm({
      title: "Validación de unidades",
      body: "¿Seguro que sea enviar las unidades a validar?",
    });
    setVisibleDialogValid(true);
  };
  const handleValidUnits = async () => {
    setVisibleDialogValid(false);
    try {
      Loading();
      const _units = [];
      for (const unit of units) {
        const validation = await handleValidUnit(unit.vinNumber);
        unit.validAx = validation.validAx;
        unit.validRepuve = validation.validRepuve;
        unit.validTransunion = validation.validTransunion;
        _units.push(unit);
      }
      Loading();
      setUnits(_units);
    } catch (error) {
      Loading();
    }
  };

  const handleValidUnit = async (vin) => {
    let data = {
      validRepuve: false,
      validTransunion: false,
      validAx: false,
    };
    try {
      try {
        const repuve = await FinbeService.validateRepuve(vin);
        if (repuve !== undefined) {
          data.validRepuve = repuve.data.veredict.valid;
        }
      } catch (error) {
        data.validRepuve = false;
      }
      try {
        const transunion = await FinbeService.validateTransunion(vin);
        if (transunion !== undefined) {
          data.validTransunion = transunion.data.veredict.valid;
        }
      } catch (error) {
        data.validTransunion = false;
      }

      try {
        const ax = await FinbeService.validateAX(vin);
        if (ax !== undefined) {
          if (
            ax.conteo == 1 /*||
            (ax.conteo == 1 && ax.detalle[0].estatus == "Liquidado")*/
          ) {
            data.validAx = true;
          }
        }
      } catch (error) {
        data.validAx = false;
      }
      return data;
    } catch (error) {
    }
  };
  const handleCancel = async () => {
    localStorage.setItem("dataR", JSON.stringify({}));
    history.push("/refinanciamientos");
  };

  const handleConfirmSave = () => {
    setDataDialogConfirm({
      title: "Guardar refinanciamiento",
      body: "¿Seguro que guardar este refinanciamiento?",
    });
    setVisibleDialogSave(true);
  };
  const handleSave = async () => {
    setVisibleDialogSave(false);
    let success = true;
    let message = "";
    let isRedirect = false;
    if (availableBalance - totalAmount < 0) {
      setResponse({
        title: "Ocurrió un error",
        message: "El monto total supera el saldo restante",
        success: false,
        isRedirect,
      });
      setVisibleResponse(true);
    } else {
      try {
        Loading();
        const data = {
          accountId: company.accountId,
          companyId: company.id,
          agencyId: agency.id,
          legalId: legalRepresentative.id,
          total: totalAmount,
          deadlines: null,
          registerBy: usuario.id,
          creditLine: creditLine.id,
          typeLine: "1", //
          reviewAF: true,
          requireEvidences: requireEvidence,
          units: [],
        };

        for (const unit of units.filter((item) => item.check)) {
          data.deadlines = unit.deadlines; 
          data.units.push({
            unitId: unit.id,
            // validAx: unit.validAx,
            // validRepuve: unit.validRepuve,
            // validTransunion: unit.validTransunion,
            comment: null,
            status: 0,
          });
        }
        const res = await RefinancingService.create(data);
        message =
          "Refinanciamiento con folio " +
          res.data.folio +
          " generado exitosamente.";
        isRedirect = true;
        Loading()
      } catch (error) {
        Loading()
        if (error?.response?.status == 401) signOutCognito();
        success = false;
        message = error.response ? error.response.data.message : error.message;
      }
      setVisibleResponse(!visibleResponse);
      setResponse({
        isRedirect: isRedirect,
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: success,
      });
    }
  };

  useEffect(() => {
    let data = localStorage.getItem("dataR");
    const { accountId, companyId, ...rest } = JSON.parse(data);
    if (accountId) {
      getCompany(accountId, companyId);
      customUnits(rest);
      localStorage.setItem("dataR", JSON.stringify({}));
    } else {
      history.push("/refinanciamientos/unidades");
    }
  }, []);

  return (
    <div>
      <Company
        agencies={agencies}
        agency={agency}
        companies={[company]}
        company={company}
        legalRepresentative={legalRepresentative}
        legalRepresentatives={[legalRepresentative]}
        handleChange={handleChange}
        reviewUnits={true}
      />
      <div className="finbe-app-container">
        <StepBox title={"Seleccionar unidades"}>
          <Form
            render={(formRenderProps) => (
              <FormElement>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div style={{ width: "50%" }}>
                    <Field
                      component={InputComboBox}
                      data={creditLines}
                      dClassName="k-form-field-wrap finbe-form-combo"
                      id="creditLine"
                      label="* Línea de crédito"
                      name="creditLine"
                      onChange={handleChange}
                      placeholder="Seleccione línea de crédito"
                      required={true}
                      validityStyles={true}
                      value={creditLine}
                      wrapperStyle={{ width: "90%" }}
                    />
                  </div>
                  {/* <div style={{ width: "50%" }}>
                    <Field
                      component={InputComboBox}
                      data={deadlines}
                      dClassName="k-form-field-wrap finbe-form-combo"
                      id="deadline"
                      label="* Plazo"
                      name="deadline"
                      onChange={handleChange}
                      placeholder="Seleccione un plazo"
                      required={true}
                      validityStyles={true}
                      value={deadline}
                      wrapperStyle={{ width: "90%" }}
                    />
                  </div> */}
                  <div style={{ width: "100%" }}>
                    <div>
                      ¿Desea solicitar evidencias de este refinanciamiento al operador agencia?
                    </div>
                    <div style={{ paddingLeft: "1em" }}>
                      <RadioButton
                        name="requireEvidence"
                        id="requireEvidence"
                        value={true}
                        checked={requireEvidence === true}
                        label="Si"
                        onChange={handleChange}
                      />
                      <RadioButton
                        name="requireEvidence"
                        id="requireEvidence"
                        value={false}
                        checked={requireEvidence === false}
                        label="No"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/* {!requireEvidence && (
                    <div style={{ width: "50%" }}>
                      <div>¿Desea validación del administrador FinBe?</div>
                      <div style={{ paddingLeft: "1em" }}>
                        <RadioButton
                          name="reviewAF"
                          id="reviewAF"
                          value={true}
                          checked={reviewAF === true}
                          label="Si"
                          onChange={handleChange}
                        />
                        <RadioButton
                          name="reviewAF"
                          id="reviewAF"
                          value={false}
                          checked={reviewAF === false}
                          label="No"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  )} */}
                </div>
                <MoneyUnits
                  availableBalance={numberFormat(availableBalance)}
                  remainingBalance={numberFormat(
                    +(availableBalance - totalAmount).toFixed(2)
                  )}
                  totalAmount={numberFormat(totalAmount)}
                />
              </FormElement>
            )}
          />

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ display: "flex", textAling: "left" }}>
              <InputDropDown
                id="takeShow"
                name="takeShow"
                data={dataDropDown}
                onChange={handleTakeShowChange}
                value={showRegister}
                fieldWrapperClassName="finbe-drop-down-show-register"
              />
            </div>
            <p className="finbe-p-register" style={{ marginTop: "1.5em" }}>
              unidades
            </p>
          </div>
          <Units
            units={units}
            dataState={dataState}
            setDataState={setDataState}
            handelSelectionChange={handelSelectionChange}
            isCreate={true}
          />
          {/* <Grid
            className="finbe-table"
            data={process(units, dataState)}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.data);
            }}
            // onHeaderSelectionChange={this.headerSelectionChange}

            onSelectionChange={handelSelectionChange}
            pageable={{
              info: false,
            }}
            selectedField="check"
            sortable
            resizable
          >
            <Column
              field={"check"}
              title="Incluir"
              headerSelectionValue={
                units.findIndex((it) => it.check === false) === -1
              }
            />
            <Column width="220px" field="vinNumber" title="N° VIN" />
            <Column
              width="180px"
              field="agency"
              title="Sucursal de Asignación"
            />
            <Column width="180px" field="brandName" title="Marca" />
            <Column width="180px" field="type" title="Tipo de unidad" />
            <Column width="180px" field="model" title="Año Modelo" />
            <Column width="180px" field="description" title="Descripción" />
            <Column width="180px" field="color" title="Color Exterior" />
            <Column width="180px" field="insideColor" title="Color Interior" />
            <Column width="180px" field="motorNumber" title="N° Motor" />
            <Column
              width="180px"
              title="REPUVE"
              cell={(props) => (
                <td>
                  <span
                    className={
                      " icon " + approveOrRejectUnit(props.dataItem.validRepuve)
                    }
                  />
                </td>
              )}
            />
            <Column
              width="180px"
              title="TransUnion"
              cell={(props) => (
                <td>
                  <span
                    className={
                      " icon " +
                      approveOrRejectUnit(props.dataItem.validTransunion)
                    }
                  />
                </td>
              )}
            />
            <Column
              width="70px"
              title="AX"
              cell={(props) => (
                <td>
                  <span
                    className={
                      " icon " + approveOrRejectUnit(props.dataItem.validAx)
                    }
                  />
                </td>
              )}
            />
            <Column
              width="180px"
              title="Admin. FinBe"
              cell={(props) => (
                <td>
                  <span
                    className={
                      " icon " +
                      renderApproveAdminFinBe(
                        props.dataItem.status,
                        props.dataItem.reviewAF
                      )
                    }
                  />
                </td>
              )}
            />

            <Column
              width="120px"
              field="amount"
              title="Monto"
              cell={(props) => <td> {numberFormat(props.dataItem.amount)} </td>}
            />
            <Column
              width="180px"
              title="Acciones"
              cell={(props) => (
                <td>
                  <div style={{ display: "flex" }}>
                    <ButtonAction
                      title={"Cargar archivos"}
                      disabled={!props.dataItem.check}
                      icon={
                        " icon " +
                        (props.dataItem.check
                          ? "cargar_docs_activo"
                          : "cargar_docs_sin_doc")
                      }
                      look="bare"
                      primary={false}
                      data={props.dataItem.index}
                      onClick={handleShowOrHideEvidence}
                    />
                  </div>
                </td>
              )}
            />
          </Grid> */}
        </StepBox>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}></div>
          <div style={{ display: "flex", textAling: "left" }}>
            {/* <ButtonPrimary
              className=""
              icon="refresh"
              onClick={handleConfirmValid}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Validar unidades"
            /> */}
            <ButtonDanger
              className=""
              icon="close"
              onClick={handleCancel}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Cancelar"
            />
            <ButtonSuccess
              disabled={!(units.filter((item) => item.check).length > 0)}
              className=""
              icon="check"
              onClick={handleConfirmSave}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Guardar"
            />
          </div>
        </div>
      </div>
      {/* Modal para visualizar o cargar evidencias */}

      {/* Dialogo de confirmación para validar unidades */}
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => setVisibleDialogValid(false)}
        handelCancel={() => setVisibleDialogValid(false)}
        handelAccept={handleValidUnits}
        title={dataDialogConfirm.title}
        visible={visibleDialogValid}
        visibleCancelButton={true}
      >
        {dataDialogConfirm.body}
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => setVisibleDialogSave(false)}
        handelCancel={() => setVisibleDialogSave(false)}
        handelAccept={handleSave}
        title={dataDialogConfirm.title}
        visible={visibleDialogSave}
        visibleCancelButton={true}
      >
        {dataDialogConfirm.body}
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={response.title}
        visible={visibleResponse}
      >
        {response.message}
      </DialogBox>
    </div>
  );
}

export default CreateRefinancing;
