import React, { useState, useEffect } from "react";

import { FilterBox, MoreFilterBox } from "../Sections/Filters";

import {
  InputComboBox,
  InputFilterText,
  InputRangeDatePicker,
} from "../Sections/Components/Inputs";

import qs from "qs";

import { Field, Form, FormElement } from "@progress/kendo-react-form";

import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

import { ButtonAction } from "../Sections/Components/Buttons";

import { process as ProcessKendo } from "@progress/kendo-data-query";

import UserServices from "../../Services/UserServices";
import AccountService from "../../Services/AccountService";
import ProvisionService from "../../Services/ProvisionService";
import { useUsuario } from "../../Context/UserContext";
import moment from "moment"
import useLoader from "../../hooks/useLoader";
import dotenv from "dotenv";
dotenv.config();
const companies = [
  { id: 1, text: "VW" },
  { id: 2, text: "Ford" },
  { id: 3, text: "Tata" },
];

const operators = [
  { id: 1, name: "Hugo", text: "Hugo" },
  { id: 2, name: "Paco", text: "Paco" },
  { id: 3, name: "Luis", text: "Luis" },
];

const testData = [
  {
    id: 1,
    noDisp: "R00000001",
    empresa: "VW",
    company: {
      id: 1,
      name: "VW",
    },
    operador: "Lorep",
    operator: operators[0],
    unidades: 2,
    fecha: "10/12/2020",
  },
  {
    id: 2,
    noDisp: "R00000002",
    empresa: "Ford",
    company: {
      id: 2,
      name: "Ford",
    },
    operador: "Ipsum",
    operator: operators[1],
    unidades: 3,
    fecha: "11/12/2020",
  },
];

const initialFilters = {
  folio: "",
  assignedTo: "",
  account: "",
  company: "",
};

const ProvisionReview = () => {
  const { usuario, signOutCognito} = useUsuario();

  const [provisions, setProvisions] = useState([]);
  const [operators, setOperators] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [units, setUnits] = useState(testData);

  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [dataState, setDataState] = useState({
    sort: [{ field: "folio", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });

  const [dataFilters, setDataFilters] = useState(initialFilters);
  const [isDown, setIsDown] = useState(true);
  const Loading = useLoader();

  const getProvisionsReview = async (params) => {
    try {
      Loading();
      const { data } = await ProvisionService.getProvisionsReview(params);
      const _data = data.map((value) => {
        const {createdAt,typeLine, ... res} = value;
        const date = moment(createdAt, "DD/MM/YYYY HH:mm").toDate()

        return {
          ...res,
          typeLine: typeLine === process.env.REACT_APP_TYPE_PREOWNED?"Seminuevos":"Nuevos",
          createdAt: date,
        }
      })
      setProvisions(_data);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();

    }
  };

  const getAccounts = async () => {
    try {
      Loading();
      const { data } = await AccountService.getAll();
      const _accounts = data.map((item) => {
        return {
          id: item.id,
          text: item.name,
        };
      });
      setAccounts(_accounts);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();

    }
  };
  const getCompanies = async (id) => {
    try {
      Loading();
      const { data } = await AccountService.getCompanies(id);
      const _companies = data.map((item) => {
        return {
          id: item.id,
          text: item.name,
        };
      });
      setCompanies(_companies);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();

    }
  };

  const getOperators = async () => {
    try {
      Loading();
      const { data } = await UserServices.getAll({
        params: { type: [3] },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      const _operators = data.map((item) => {
        return {
          id: item.id,
          text: item.firstname + " " + item.lastname,
        };
      });
      setOperators(_operators);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();

    }
  };

  const handleClickMoreFilter = async () => {
    setIsDown(!isDown);
  };
  const handleClickSearch = async () => {
    let data = {};
    if (dataFilters.folio !== "") data.folio = dataFilters.folio;
    if (dataFilters.assignedTo !== "") data.assignedTo = dataFilters.assignedTo;
    if (dataFilters.account !== "") data.account = dataFilters.account;
    if (dataFilters.company !== "") data.company = dataFilters.company;
    setDataState({...dataState,skip:0});
    try {
      getProvisionsReview({ params: data });
    } catch (error) {
    }
  };

  const handleShowRegisterChange = (e) => {
    const { value } = e.target;
    setShowRegister(value);
    setDataState({ ...dataState, ["take"]: value.text,skip:0 });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.value !== undefined) {
      if (typeof value === "object") {
        setDataFilters({
          ...dataFilters,
          [name]: value !== null ? value.id : "",
        });
        if (name == "account") {
          if (value !== null) {
            getCompanies(value.id);
          } else {
            setCompanies([]);
          }
        }
      } else {
        setDataFilters({ ...dataFilters, [name]: value });
      }
    }
  };

  useEffect(() => {
    getProvisionsReview();
    getAccounts();
    getOperators();
  }, []);
  return (
    <div className="finbe-app-container">
      <div className="finbe-app-section-top">
        <FilterBox
          buttonMoreFilterOnClick={handleClickMoreFilter}
          buttonSearchOnClick={handleClickSearch}
          inputDropDownValue={showRegister}
          inputFilterOnChange={handleShowRegisterChange}
          isDown={isDown}
        >
          <Form
            render={(formRenderProps) => (
              <FormElement style={{ display: "flex" }}>
                <Field
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="folio"
                  nombre="folio"
                  onChange={handleChange}
                  placeholder="Ingresar N° de disposición"
                  value={dataFilters.folio}
                  wrapperStyle={{ width: "50%", margin: "1.2em" }}
                  component={InputFilterText}
                />

                <Field
                  dClassName="k-form-field-wrap finbe-form-combo"
                  id="assignedTo"
                  nombre="assignedTo"
                  name="assignedTo"
                  data={operators}
                  onChange={handleChange}
                  placeholder="Seleccionar Op. FinBe"
                  value={operators.find(
                    (item) => item.id == dataFilters.assignedTo
                  )}
                  wrapperStyle={{ width: "50%", margin: "1.55em" }}
                  component={InputComboBox}
                />
              </FormElement>
            )}
          />
        </FilterBox>
      </div>

      <div style={{ color: "#b0ada6" }}>
        {isDown ? null : (
          <div
            style={{
              width: "95%",
              margin: "1em 1em 0 1em",
              background: "#e4e3e0",
            }}
          >
            <MoreFilterBox>
              <Form
                render={(formRenderProps) => (
                  <FormElement style={{ display: "flex" }}>
                    <Field
                      dClassName="k-form-field-wrap finbe-form-combo"
                      id="account"
                      nombre="account"
                      name="account"
                      data={accounts}
                      onChange={handleChange}
                      placeholder={"Seleccionar Cuenta"}
                      value={accounts.find(
                        (item) => item.id == dataFilters.account
                      )}
                      wrapperStyle={{ width: "50%", margin: "1em" }}
                      component={InputComboBox}
                    />

                    <Field
                      dClassName="k-form-field-wrap finbe-form-combo"
                      id="company"
                      nombre="company"
                      name="company"
                      data={companies}
                      onChange={handleChange}
                      placeholder="Seleccionar Empresa"
                      value={companies.find(
                        (item) => item.id == dataFilters.company
                      )}
                      wrapperStyle={{ width: "50%", margin: "1.35em" }}
                      component={InputComboBox}
                    />
                  </FormElement>
                )}
              ></Form>
            </MoreFilterBox>
          </div>
        )}
      </div>

      <div>
        <Grid
          className="finbe-table"
          data={ProcessKendo(provisions, dataState)}
          {...dataState}
          onDataStateChange={(e) => setDataState(e.data)}
          sortable
          pageable={{
            info: false,
          }}
          skip={dataState.skip}
          take={dataState.take}
          resizable
        >
          <Column field="folio" title="N° de Disposición" />

          <Column title="Empresa" field="companyName" />

          <Column title="Operador FinBe" field="operator.name" />
          <Column title="Tipo" field="typeLine" />

          <Column field="units" title="Unidades"/>

          <Column field="createdAt" title="Fecha" filter="date" format="{0:dd/MM/yyyy HH:mm}" />

          <Column
            field="id"
            title="Acciones"
            cell={(props) => (
              <td style={{ display: "table-cell" }}>
                <ButtonAction
                  title = {'Visualizar'}
                  icon=" icon visualizar"
                  look="bare"
                  primary={false}
                  isRedirect={true}
                  url={`/disposiciones-revision/${props.dataItem.id}`}
                />
              </td>
            )}
          />
        </Grid>
      </div>
    </div>
  );
};

export default ProvisionReview;
