/**
 * @author: Alexander Chi
 * @description: Component request exceptions
 * @date: 05/oct/2020
 **/
import React, { Component } from "react";
import { DialogBox } from "../Sections/Dialogs";
import { InputText, InputFile } from "../Sections/Components/Inputs";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import { useUsuario } from "../../Context/UserContext";
import ExceptionService from "../../Services/ExceptionService";
import DocumentService from "../../Services/DocumentService";

let user = null;
let signOut = null;

/** Get user cotext hook **/
function UserData({ children }) {
  const { usuario,signOutCognito } = useUsuario();
  user = usuario;
  signOut = signOutCognito;
  return children({ user: usuario });
}

/** Main component RequestExceptions **/
export class RequestException extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachedDocument: [],
      comment: "",
      type: 1,
      visible: this.props.visible,
    };
  }

  /** Get new props **/
  componentWillReceiveProps (nextProps){
    const {visible} = nextProps;
    this.setState({visible});
  }

  /** Save document **/
  _uploadDocument = async (file, nameState) => {
    try{
      let formData = new FormData();
      formData.append('file', file.getRawFile());
      const response = await DocumentService.uploadDocument(formData);
      file.url = response.data.url;
    }catch(error){
      if (error.response.status == 401) signOut();
      console.info(error);
    }
  };

  /** Change for get value inputs **/
  handleChange = (e) => {
    const { value, props } = e.target;
    if(value === undefined){
        const { files, props } = e.target;
        this._uploadDocument(files[0])
        this.setState({ [props.id]: files });
    }else{
      this.setState({ [props.id]: value });
    }
  };

  /** handel send exception **/
  handelAccept = async (e) => {
    let response = {};
    let success = true;
    try {
      let params = {
        accountId: user.account.id,
        comment: this.state.comment,
        companyId: user.company.id,
        registerBy: user.id,
        type: this.state.type,
        evidences: []
      };
      let evidences = this.state.attachedDocument.map((evidence, index) => {
        return this._setEvidence(index, evidence, 2);
      });
      params.evidences = evidences;
      response = await ExceptionService.create(params);
      let exception = response.data;
    } catch (error) {
      if (error.response.status == 401) signOut();
      success = false;
      console.info(error);
    }
    if(success){
      this.setState({ visible: !this.state.visible });
    }
  };

  /** Set object evidence for list evidences of units **/
  _setEvidence(index, evidence, type){
    return {
      extension: String(evidence.extension),
      /**/
      fileName: String(evidence.name),
      fileSize: String(evidence.size),
      /*index: index,*/
      type: String(type),
      url: evidence.url,
    }
  }

  render() {
    const { handelClose, identificador } = this.props;
    return (
      <UserData>
        {({ user }) => {
          return (
            <DialogBox
              identificador={identificador}
              title={"Solicitar excepción"}
              visible={this.state.visible}
              handelClose={handelClose}
              handelAccept={this.handelAccept}
              buttonSuccessText={"Enviar"}
            >
              <div style={{ width: "637px" }}>
                <Form
                  render={(formRenderProps) => (
                    <FormElement>
                      <Field
                        component={InputText}
                        dClassName="k-form-field-wrap finbe-form-field-filter"
                        id="comment"
                        label="* Comentario"
                        name="comment"
                        onChange={this.handleChange}
                        placeholder="Comentario para la solicitud"
                        value={this.state.comment}
                        wrapperStyle={{ width: "60%" }}
                      />
                      <div style={{ margin: "0.4em" }}>
                        <InputFile
                          fieldWrapperStyle = {{ width: '100%' }}
                          uploadAllowedExtensions = {null}
                          uploadAutoUpload = {false}
                          uploadDefaultFiles = {this.state.attachedDocument}
                          uploadId = 'attachedDocument'
                          uploadLabel = 'Evidencia'
                          uploadOnAdd = {this.handleChange}
                        />
                      </div>
                    </FormElement>
                  )}
                />
              </div>
            </DialogBox>
          );
        }}
      </UserData>
    );
  }
}

export default RequestException;

/** default props file **/
File.RequestException = {};
