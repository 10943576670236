/**
 * @author: Alexander Chi
 * @description: Component of disposiciones list, create, edit, information CSV,
 *               upload XML o CSV, units evidences, FinBe validation
 * @date: 29/sept/2020
 **/
import React, { Component } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useUsuario } from "../../Context/UserContext";
import { ButtonTertiary, ButtonAction } from "../Sections/Components/Buttons";
import ProvisionService from "../../Services/ProvisionService";
import DocumentService from "../../Services/DocumentService";
import useLoader from "../../hooks/useLoader";

/** Varial global **/
let user = null;
let signOut = null;
let Loading =useLoader()

/** Get user cotext hook **/
function UserData({ children }) {
  const { usuario, signOutCognito } = useUsuario();
  user = usuario;
  signOut = signOutCognito;

  return children({ user: usuario });
}

/** Information data show registers **/
const dataDropDown = [
  { id: 1, text: 10 },
  { id: 2, text: 25 },
  { id: 3, text: 50 },
  { id: 4, text: 100 },
];

/** **/
const status = [
  { id: 0, text: <span style={{color: '#CA1D2E', fontWeight: 'bold'}}>No firmado</span>, key: 'NO_FIRMADO' },
  { id: 1, text: <span style={{color: '#9a9a9a', fontWeight: 'bold'}}>Enviado a firma</span>, key: 'ENVIADO_FIRMA' },
  { id: 2, text: <span style={{color: '#28a745', fontWeight: 'bold'}}>Firmado</span>, key: 'FIRMADO' }
];

/** Create, Edit main **/
class Show extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      skip: 0,
      take: 10,
      dropDown: dataDropDown[0],
      loading: true,
    };
  }

  componentWillMount() {
    this._getEvidencesSignature(this.props.match.params.id);
  }

  /** Get information provision **/
  _getEvidencesSignature = async (provisionId) => {
    try{
      Loading();
      const { data } = await ProvisionService.getEvidencesSignature(provisionId);
      //console.info(data)
      this.setState({
        documents: data.evidences,
        // loading:false
      });
      Loading();
    }catch(error){
      Loading();
      if (error?.response?.status == 401) signOut();
      console.info(error)
    }
  };

  /** Get information document **/
  _getDocument = async (url, download = false) => {
    try{
      Loading();
      const response = await DocumentService.showDocument({ path: url, download: download });
      window.open(response.data.url, "_blank");
      Loading();
    }catch(error){
      Loading();
      if (error?.response?.status == 401) signOut();
      console.info(error);
    }
  };

  /** Click cancel **/
  handleClickCancel = (e) => {
    this.props.history.push("/disposiciones");
  };

  /** Refresh information units **/
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  /** Convert bytes to MegaBytes or Kilobytes **/
  _renderFileSize(size){
    let sizeInt = Number(size);
    return sizeInt > 1000000
              ? (sizeInt / 1000000) + " MB"
              : (sizeInt / 1000) + " KB" ;
  }

  render() {
    return (
      <UserData>
        {({ user }) => {
          return (
            <div className="finbe-app-container">
              <h4>N° de disposición {this.state.folio}</h4>
              <div>
                <Grid
                  className="finbe-table"
                  data={this.state.documents.slice(
                    this.state.skip,
                    this.state.dropDown.text + this.state.skip
                  )}
                  onPageChange={this.handelPageChange}
                  pageable={{
                    info: false,
                  }}
                  skip={this.state.skip}
                  sortable
                  take={this.state.dropDown.text}
                  total={this.state.documents.length}
                  resizable
                >
                  <Column field="fileName" title="Documento" />
                  <Column
                    title="Tamaño"
                    cell={(props) => (
                      <td>
                        {this._renderFileSize(props.dataItem.fileSize)}
                      </td>
                    )}
                  />
                  <Column
                    title="Estatus"
                    cell={(props) => (
                      <td>
                        {status.find(it => it.id == props.dataItem.signedStatus).text}
                      </td>
                    )}
                  />
                  <Column
                    title="Acciones"
                    cell={(props) => (
                      <td>
                        <div style={{display: 'flex'}}>
                          <ButtonAction
                            title={"Visualizar"}
                            disabled = {props.dataItem.signedStatus == 2}
                            icon={" icon visualizar"}
                            look="bare"
                            onClick = { () => { this._getDocument(props.dataItem.url) }}
                            primary = {false}
                          />
                          <ButtonAction
                            title={"Descargar"}
                            icon={" icon descargar"}
                            look="bare"
                            primary = {false}
                            onClick = { () => { this._getDocument(props.dataItem.url, true) } }
                          />
                        </div>
                      </td>
                    )}
                  />
                </Grid>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}></div>
                <div style={{ display: "flex", textAling: "left" }}>
                  <ButtonTertiary
                    className=""
                    icon={"arrow-left"}
                    onClick={this.handleClickCancel}
                    pClassName="finbe-btn-margin"
                    primary={true}
                    text={"Regresar"}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </UserData>
    );
  }
}

export default Show;
