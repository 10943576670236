import { Avatar } from "@progress/kendo-react-layout";
import React from "react";
import "./principal.css";
import { useUsuario } from "../../Context/UserContext";
import manifest from './manifest.json'
const Principal = () => {
  const { usuario } = useUsuario();
  const getType = () => {
    switch (usuario.type) {
      case 1:
        return "Super usuario";
      case 2:
        return "Administrador Finbe";
      case 3:
        return "Operador Finbe";
      case 4:
        return "Representante Legal";
      case 5:
        return "Operador Agencia";
      default:
        return "-";
    }
  };
  return (
    <>
      <div className="finbe-principal-logo">
        <img src="/icons/finbe-logo-alt.svg" alt="Financiera Bepensa" />
      </div>
      <h1 className="finbe-pricipal-title">Bienvenido</h1>
      <div className="finbe-principal-avatar">
        <img src="/icons/MARCO_DE_IMAGEN_CIRCULAR.svg" alt="" />
      </div>
      <div className='finbe-principal-information'>
        <div className="finbe-principal-name">
          <h2>{usuario && usuario.firstname + " " + usuario.lastname}</h2>
        </div>
        <div className="finbe-principal-role">
          <h3>{usuario && getType()}</h3>
        </div>
      </div>
      <div style={{display:"flex",justifyContent:"flex-end"}}>
        <span>Plan piso versión: {manifest.version}</span>
      </div>
    </>
  );
};
export default Principal;
