/**
 * @author: Alexander Chi
 * @description: Component periodic validation
 * @date: 19/Nov/2020
 **/
import React, { Component } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  InputFilterText,
  InputRangeDatePicker,
  InputComboBox,
} from "../Sections/Components/Inputs";
import { FilterBox } from "../Sections/Filters";
import moment from "moment";
import { useUsuario } from "../../Context/UserContext";
import ReportService from "../../Services/ReportService";
import { DialogBox } from "../Sections/Dialogs";
import useLoader from "../../hooks/useLoader";

let signOut = null;
let Loading = useLoader();
/** Get user cotext hook **/
function UserData({ children }) {
  const { usuario, signOutCognito } = useUsuario();
  signOut = signOutCognito;
  //const history = useHistory();
  return children({ user: usuario });
}

const validations = [
  { id: 1, text: "REPUVE", type: 1 },
  { id: 2, text: "TransUnion", type: 2 },
  { id: 3, text: "AX", type: 3 },
];

/** Main component PeriodicValidation **/
export class PeriodicValidation extends Component {
  /** Varial for export document excel **/
  _export;

  constructor(props) {
    super(props);
    this.state = {
      endDate: "",
      model: "",
      numberProvision: "",
      rangeDate: null,
      startDate: "",
      validation: null,
      validations: [],
      skip: 0,
      take: 10,
      showRegister: { id: 1, text: 10 },
      visibleResponse: true,
      visibleConfirm: false,
      responseData: {
        title: "Búsqueda",
        message: "Ingrese información de búsqueda",
        success: false,
      },
    };
  }

  /** Get information validations **/
  _getPeriodicValidations = async (params) => {
    try {
      Loading();
      const response = await ReportService.getHistoricalValidations({ params });
      this.setState({ validations: response.data });
      Loading();
    } catch (e) {
      Loading();

      if (e?.response?.status == 401) signOut();

    }
  };

  /** Change input credit line, operator, status **/
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      ["skip"]: name == "showRegister" ? 0 : this.state.skip,
    });
  };

  /** Change input combo date range **/
  handleChangeRangeDate = (e) => {
    const { value } = e.target;
    this.setState({
      startDate:
        value.start !== null ? moment(value.start).format("YYYY-MM-DD") : "",
      endDate: value.end !== null ? moment(value.end).format("YYYY-MM-DD") : "",
      rangeDate: value,
    });
  };

  /** Click filter information validations **/
  handleClickSearch = (e) => {
    let params = {};
    const { endDate, model, numberProvision, startDate, validation } =
      this.state;
    if (numberProvision !== "") {
      params.folio = numberProvision;
    }
    if (model !== "") {
      params.model = model;
    }
    if (startDate !== "") {
      params.from = startDate;
    }
    if (endDate !== "") {
      params.to = endDate;
    }
    if (validation != null) {
      params.type = validation.type;
    }
    this._getPeriodicValidations(params);
    this.setState({ skip: 0 });
  };
  handleDialogResponse = (e) => {
    this.setState({ visibleResponse: !this.state.visibleResponse });
  };
  openDialog = () => {
    this.setState({
      visibleConfirm: !this.state.visibleConfirm,
    });
  };

  /** Create file for download after **/
  handleDownload = (e) => {
    this._export.save();
    this.setState({
      visibleConfirm: !this.state.visibleConfirm,
      responseData: {
        title: "Descarga completa",
        message: "El informe se descargo correctamente",
        success: false,
      },
      visibleResponse: !this.state.visibleResponse,
    });
  };
  /** Change of page grid **/
  handelPageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take,
    });
  };
  /** Set icon check or close **/
  _isApproved(validation) {
    return validation ? "aprobado" : "desaprobado";
  }

  render() {
    return (
      <UserData>
        {({ user }) => {
          return (
            <div>
              <div style={{ width: "100%" }}>
                <Form
                  render={(formRenderProps) => (
                    <FormElement style={{ display: "flex" }}>
                      <Field
                        component={InputFilterText}
                        dClassName="k-form-field-wrap finbe-form-field-filter"
                        id="numberProvision"
                        nombre="numberProvision"
                        onChange={this.handleChange}
                        placeholder="Ingrese N° de disposición"
                        value={this.state.numberProvision}
                        wrapperStyle={{ width: "25%", paddingTop: "1.8em" }}
                      />
                      <Field
                        component={InputFilterText}
                        dClassName="k-form-field-wrap finbe-form-field-filter"
                        id="model"
                        nombre="model"
                        onChange={this.handleChange}
                        placeholder="Ingrese modelo"
                        value={this.state.model}
                        wrapperStyle={{ width: "25%", paddingTop: "1.8em" }}
                      />
                      <Field
                        component={InputRangeDatePicker}
                        dateRangePickerId="datePickerFilter"
                        dateRangePickerName="datePickerFilter"
                        dClassName="k-form-field-wrap finbe-form-date"
                        dateRangePickerOnChange={this.handleChangeRangeDate}
                        dateRangePickerValue={this.state.rangeDate}
                        fieldWrapperStyle={{
                          width: "50%",
                          paddingLeft: "0em",
                          paddingTop: "0.7em",
                        }}
                      />
                    </FormElement>
                  )}
                />
              </div>
              <FilterBox
                buttonDownloadReportOnClick={this.openDialog}
                buttonSearchOnClick={this.handleClickSearch}
                inputDropDownValue={this.state.showRegister}
                inputFilterOnChange={this.handleChange}
                isDownloadReport={true}
                isMoreFilter={false}
              >
                <div style={{ width: "100%" }}>
                  <Form
                    render={(formRenderProps) => (
                      <FormElement style={{ display: "flex" }}>
                        <Field
                          component={InputComboBox}
                          data={validations}
                          dClassName="k-form-field-wrap finbe-form-combo"
                          id="validation"
                          name="validation"
                          onChange={this.handleChange}
                          placeholder="Seleccionar una validación"
                          value={this.state.validation}
                          wrapperStyle={{ width: "35%", paddingTop: "1.7em" }}
                        />
                      </FormElement>
                    )}
                  />
                </div>
              </FilterBox>
              <div>
                <ExcelExport
                  fileName={
                    "Validación periódicas (" +
                    moment().format("DD-MM-YYYY") +
                    ")"
                  }
                  data={this.state.validations}
                  ref={(exporter) => (this._export = exporter)}
                >
                  <Grid
                    className="finbe-table"
                    data={this.state.validations.slice(
                      this.state.skip,
                      this.state.showRegister.text + this.state.skip
                    )}
                    onHeaderSelectionChange={this.headerSelectionChange}
                    onPageChange={this.handelPageChange}
                    onSelectionChange={this.handelSelectionChange}
                    pageable={{
                      info: false,
                    }}
                    selectedField="check"
                    skip={this.state.skip}
                    sortable
                    take={this.state.showRegister.text}
                    total={this.state.validations.length}
                    resizable
                  >
                    <Column field="folio" title="N° de disposición" />
                    <Column field="model" title="Modelo" />
                    <Column field="vinNumber" title="N° VIN" />
                    <Column field="type" title="Validación" />
                    <Column
                      field="response"
                      title="Respuesta"
                      cell={(props) => (
                        <td>
                          <span
                            className={
                              " icon " +
                              this._isApproved(props.dataItem.response)
                            }
                          />
                        </td>
                      )}
                    />
                    <Column field="createdAt" title="Fecha" />
                  </Grid>
                </ExcelExport>
              </div>
              <DialogBox
                buttonSuccessText={"Aceptar"}
                handelClose={this.handleDialogResponse}
                handelAccept={this.handleDialogResponse}
                title={this.state.responseData.title}
                visible={this.state.visibleResponse}
              >
                {this.state.responseData.message}
              </DialogBox>
              <DialogBox
                buttonSuccessText={"Aceptar"}
                handelClose={() => {
                  this.setState({ visibleConfirm: !this.state.visibleConfirm });
                }}
                handelCancel={() => {
                  this.setState({ visibleConfirm: !this.state.visibleConfirm });
                }}
                handelAccept={this.handleDownload}
                title={"Descargar informe"}
                visible={this.state.visibleConfirm}
                visibleCancelButton={true}
              >
                {"¿Seguro que desea descargar el informe?"}
              </DialogBox>
            </div>
          );
        }}
      </UserData>
    );
  }
}

export default PeriodicValidation;

/** default props file **/
File.PeriodicValidation = {};
