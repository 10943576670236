/**
 * @author: Alexander Chi
 * @description: Component of section dialog
 * @date: 29/sept/2020
 **/
import React, {Component} from 'react'
import { Dialog } from '@progress/kendo-react-dialogs';
import { ButtonDanger, ButtonSuccess } from './Components/Buttons';

/** DialogBox main **/
export class DialogBox extends Component{
  constructor (props) {
    super(props)
    this.state = { };
  }

  /** Get new props **/
  componentWillReceiveProps (nextProps){
    const {visible} = nextProps;
    this.setState({visible: !visible});
  }

  render() {
    const {
      buttonDangerIcon,
      buttonDangerText,
      buttonSuccesIcon,
      buttonSuccessText,
      centerButtons,
      children,
      dialogClassName,
      enableSuccess,
      handelAccept,
      handelCancel,
      handelClose,
      identificador,
      styleContent,
      title,
      visible,
      visibleButtons,
      visibleCancelButton,
      visibleSuccessButton,
      width="",
      height=""
    } = this.props;
    return (
      <div>
        {
          visible  ?
            <Dialog
              className = {dialogClassName}
              identificador = {identificador ?? null}
              title={title ?? 'Title'}
              onClose={handelClose}
              width={width}
              height={height}
              >
              <div
                style={styleContent}>
                {children ?? 'Content'}
              </div>
              {
                visibleButtons ?
                <div className = {centerButtons ? 'finbe-dialog-center-buttons' : 'finbe-dialog-buttons'}>
                  {
                    visibleCancelButton ?
                    <ButtonDanger
                      className = ""
                      icon = {buttonDangerIcon}
                      onClick = {handelCancel == null ? handelClose : handelCancel}
                      pClassName = "finbe-btn-margin"
                      primary = {true}
                      text = {buttonDangerText}/> :
                      null
                  }
                  {
                    visibleSuccessButton ?
                    <ButtonSuccess
                      disabled = {enableSuccess}
                      className = ""
                      icon = {buttonSuccesIcon}
                      onClick = {handelAccept}
                      pClassName = "finbe-btn-margin"
                      primary = {true}
                      text = {buttonSuccessText}/>
                      : null
                  }
                </div>:
                null
              }
            </Dialog> :
            ''
        }
      </div>
    );
  }
}

/** Defacult Props StepBox **/
DialogBox.defaultProps = {
  buttonDangerIcon: 'close',
  buttonDangerText: 'Cancelar',
  buttonSuccesIcon: 'check',
  buttonSuccesText: 'Aceptar',
  centerButtons: false,
  children: null,
  dialogClassName: '',
  handelAccept: null,
  handelCancel: null,
  handelClose: null,
  styleContent: { margin: "0em"},
  title: 'Title of ...',
  visible: false,
  visibleButtons: true,
  visibleCancelButton: false,
  visibleSuccessButton: true,
};

export class Loading extends Component{
  render() {
    const {
      text
    } = this.props
    return (
      <div className="k-loading-mask">
          <span className="k-loading-text">{text}</span>
          <div className="k-loading-image"></div>
          <div className="k-loading-color"></div>
      </div>
    );
  }
}

Loading.defaultProps = {
  text: 'Loading'
};
