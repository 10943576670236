import React from "react";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

function NotificationBox(props) {
  const {
    visible = false,
    handleClose = null,
    message = "Default",
    data = "",
  } = props;

  return (
    <Fade enter={true} exit={true}>
      {visible && (
        <Notification
          data={data}
          autoHide
          type={{ style: "none", icon: false }}
          closable={true}
          onClose={handleClose}
        >
          <span>{message}</span>
        </Notification>
      )}
    </Fade>
  );
}

export default NotificationBox;
