import React from 'react'

import { TimePicker } from '@progress/kendo-react-dateinputs'
import { FieldWrapper } from '@progress/kendo-react-form';

import PropTypes from 'prop-types'

import './Timepicker.css'

const today = new Date()

const Picker = (props) => {

    const {
        id,
        label,
        name,
        value,
        required,
        wrapperStyle,
        onChange,
        format,
    } = props

    const renderDate = () => {
        const _today = today
        const { hours, minutes } = value
        _today.setHours(hours, minutes)
        return new Date(_today)
    }

    const onChangeTimePicker = (e) => {
        const { value } = e
        const hours = value.getHours()
        const minutes = value.getMinutes()

        if(onChange){
            const e = {
                name: name,
                value: {
                    hours: hours,
                    minutes: minutes,
                }
            }
            onChange(e)
        }

    }

    return (
        <FieldWrapper style={wrapperStyle}>
            <div className={'k-form-field-wrap'}>

                <span className='k-textbox-container'>
                
                    {label?<><span className='k-label'>{label}</span></>:null}
                    
                    <TimePicker
                        format={format}
                        onChange={onChangeTimePicker}
                        value={renderDate()}
                        className='finbe-timepicker'
                    />

                </span>
            </div>
        </FieldWrapper>
    )

}

Picker.propTypes = {
    value: {
        hours: PropTypes.number,
        minutes: PropTypes.number,
    },    
    format: PropTypes.string,
}

Picker.defaultProps = {
    value: {
        hours: 0,
        minutes: 0,
    },
    format: 'HH:mm',
}

export default Picker