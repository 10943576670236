import React, { useState } from "react";
import { process } from "@progress/kendo-data-query";
import { DialogBox } from "../Sections/Dialogs";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ButtonAction, ButtonSecundary } from "../Sections/Components/Buttons";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { InputComboBox } from "../Sections/Components/Inputs";
import { useEffect } from "react";
import AgencyService from "../../Services/AgencyService";
import UserServices from "../../Services/UserServices";
import qs from "qs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useUsuario } from "../../Context/UserContext";
import CompanyService from "../../Services/CompanyService";
import RoleService from "../../Services/RoleService";
import useLoader from "../../hooks/useLoader";
function AddUsers({
  visible,
  setVisible,
  account,
  company,
  agency,
  agencyName,
}) {
  const { usuario, signOutCognito } = useUsuario();
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [dataState, setDataState] = useState({
    sort: [{ field: "email", dir: "asc" }],
    take: 10,
    skip: 0,
  });
  const [visibleForm, setVisibleForm] = useState(false);
  const [dialog, setDialog] = useState({ visible: false });
  const Loading = useLoader();

  const getUsersAssigned = async () => {
    try {
      Loading();
      const res = await CompanyService.getUsers(company);
      setAssignedUsers(res.data);

      const { data } = await UserServices.getAll({
        params: { registerBy: usuario.id },
      });
      let userFilter = data.filter(
        (item) => !res.data.some((x) => x.id === item.id)
      );
      let _users = userFilter.map((x) => {
        return { id: x.id, text: x.firstname + " " + x.lastname };
      });
      setUsers(_users);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };

  const getRoles = async () => {
    try {
      Loading();
      const { data } = await RoleService.getAll({
        params: { type: [4, 5] },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      let list = data.map((x) => {
        return { id: x.id, text: x.name };
      });
      setRoles(list);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };

  const handleChangeUser = (event) => {
    setUser(event.target.value);
  };
  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };

  const handleShowResponse = () => {
    setDialog({ visible: !dialog.visible });
  };

  const handleAccept = () => {
    setDialog({
      visible: true,
      title: "Asignar usuario",
      body: `¿Seguro que desea asignar este usuario?`,
      handelAccept: handleAssignedUser,
      visibleCancelButton: true,
    });
  };

  const handleAssignedUser = async () => {
    let message = "";
    let title = "";
    let success = true;
    try {
      Loading();
      const { data } = await CompanyService.addUser(company, { userId: user.id, roleId:role.id });
      const _Ausers = [...assignedUsers, data];
      setAssignedUsers(_Ausers);
      const _users = [...users.filter((item) => item.id !== data.id)];
      setUsers(_users);
      title = "Acción realizada con éxito";
      message = <span>El usuario se agrego de forma correcta</span>;
      success = true;
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
      success = false;
      message = error.response.data;
    }

    setDialog({
      visible: true,
      title: title,
      body: message,
      handelAccept: () => {
        setDialog({ visible: false });
      },
      visibleCancelButton: false,
      success: success,
    });
    setUser(null);
    setRole(null);
  };

  const handleConfirmRemove = (e) => {
    const id = e.currentTarget.getAttribute("data");
    setDialog({
      visible: true,
      title: "Remover usuario",
      body: `¿Seguro que desea remover este usuario?`,
      handelAccept: () => {
        handleRemoveUser(id);
      },
      visibleCancelButton: true,
    });
  };

  const handleRemoveUser = async (id) => {
    let message = "";
    let title = "";
    let success = true;
    try {
      Loading();
      const { data } = await CompanyService.removeUser(company, id);
      const _Ausers = [...assignedUsers.filter((item) => item.id !== data.id)];
      setAssignedUsers(_Ausers);
      const _users = [
        ...users,
        { id: data.id, text: data.firstname + " " + data.lastname },
      ];
      setUsers(_users);
      title = "Acción realizada con éxito";
      message = <span>El usuario se removió de forma correcta</span>;
      success = true;
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
      success = false;
      message = error.response.data;
    }
    setDialog({
      visible: true,
      title: title,
      body: message,
      handelAccept: () => {
        setDialog({ visible: false });
      },
      visibleCancelButton: false,
      success: success,
    });
  };
  const handleClose = () => {
    setVisibleForm(false);
    setVisible(false);
    setUser(null);
  };
  const handleCancel = () => {
    setUser(null);
    setVisibleForm(false);
  };
  const handleShowForm = async () => {
    setVisibleForm(true);
  };

  useEffect(() => {
    if (company) {
      getUsersAssigned();
      getRoles();
    }
  }, [company]);
  return (
    <DialogBox
      handelAccept={handleAccept}
      handelCancel={handleCancel}
      handelClose={handleClose}
      visibleButtons={visibleForm}
      visibleCancelButton={visibleForm}
      buttonSuccessText="Guardar"
      buttonDangerText="Cancelar"
      title="Asignar usuarios"
      visible={visible}
      styleContent={{ width: "600px" }}
      width={650}
      height={550}
    >
      <div style={{ display: "flex", flexDirection: "column", width: "600px" }}>
        {/* <h4>{agencyName}</h4> */}
        <div style={{ display: "flex", alignSelf: "flex-end" }}>
          <ButtonSecundary onClick={handleShowForm} text="+ Asignar Nuevo" />
        </div>
        <div>
          <Grid
            className="finbe-table"
            // style={{height:"250px"}}
            pageable={{
              info: false,
              //   pageSizes:true,
            }}
            resizable
            sortable
            data={process(assignedUsers, dataState)}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.data);
            }}
          >
            <Column
              width="250px"
              title="Usuario"
              cell={(props) => (
                <td>
                  {`${props.dataItem.firstname} ${props.dataItem.lastname}`}
                </td>
              )}
            />
            <Column width="250px" field="email" title="Correo" />
            <Column
              width="100px"
              title="Acciones"
              cell={(props) => (
                <td>
                  {(!props.dataItem.isDefault)&&
                    <ButtonAction
                      title={"Remover/Eliminar"}
                      data={props.dataItem.id}
                      icon={" icon remover-eliminar "}
                      look="bare"
                      primary={false}
                      onClick={handleConfirmRemove}
                    />

                  }
                </td>
              )}
            />
          </Grid>
        </div>
        {visibleForm && (
          <div>
            <h5>Asignar nuevo</h5>
            <Form
              render={(formRenderProps) => (
                <FormElement>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                      <Field
                        allowCustom={true}
                        component={InputComboBox}
                        data={users}
                        dClassName="k-form-field-wrap finbe-form-combo"
                        id="user"
                        label="Usuario"
                        name="user"
                        onChange={handleChangeUser}
                        placeholder="Seleccionar usuario"
                        value={user}
                        wrapperStyle={{ width: "90%" }}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <Field
                        allowCustom={true}
                        component={InputComboBox}
                        data={roles}
                        dClassName="k-form-field-wrap finbe-form-combo"
                        id="role"
                        label="Rol"
                        name="role"
                        onChange={handleChangeRole}
                        placeholder="Seleccionar rol"
                        value={role}
                        wrapperStyle={{ width: "90%" }}
                      />
                    </div>
                  </div>
                </FormElement>
              )}
            />
          </div>
        )}
      </div>
      <DialogBox
        title={dialog.title}
        handelClose={handleShowResponse}
        handelAccept={dialog.handelAccept}
        buttonSuccessText="Aceptar"
        visibleCancelButton={dialog.visibleCancelButton}
        centerButtons={true}
        visible={dialog.visible}
      >
        {dialog.body}
      </DialogBox>
    </DialogBox>
  );
}

export default AddUsers;
