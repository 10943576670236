import axios from "axios";
import dotenv from "dotenv";
// import { Auth } from "aws-amplify";

dotenv.config();

function create() {
  const token = localStorage.getItem('aws')
  const _axios = axios.create({
    baseURL: process.env.REACT_APP_URL_CONNECTION_API || "http://localhost:8000",
    headers: {
      "Content-type": "application/json",
      "Authorization": token,
    },
  });
  return _axios
}

// async function create() {
//   const token = await Auth.currentSession() // localStorage.getItem('aws') || ''
//   const _axios = axios.create({
//     baseURL: process.env.REACT_APP_URL_CONECCTION_API || "http://localhost:8000",
//     headers: {
//       "Content-type": "application/json",
//       "Authorization": token.getAccessToken().getJwtToken(),
//     },
//   });
//   return _axios
// }

export default create

