import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import Avatar from "./Avatar";
import { useUsuario } from "../../Context/UserContext";
import { InputDropDown } from "../Sections/Components/Inputs";
import { useHistory } from "react-router-dom";
const Header = (props) => {
  const { usuario, signOutCognito, setInformation } = useUsuario();
  const [company, setCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const history = useHistory();
  
  const onClick = () => {
    signOutCognito();
  };

  //   const getCompanies = async () => {
  //     let companies = [];
  //     try {
  //       const { data } = await UserServices.getCompanies(usuario.id);
  //       companies = data.map((company, index) => {
  //         return {
  //           id: company.id,
  //           text: company.name,
  //         };
  //       });
  //       setCompanies(companies);
  //     } catch (error) {
  //       if (error?.response?.status == 401) signOutCognito();

  //     }
  //   };
  const handleChange = async (e) => {
    const { name, value } = e.target;
    if (name == "companyId") {
      setInformation(usuario.id, value.id);
      history.push("/");
    }
    // setCompany(value);
  };
  useEffect(() => {
    if (
      usuario !== null &&
      usuario !== undefined &&
      Object.keys(usuario).length > 0 &&
      (usuario.type === 5 || usuario.type === 4)
    ) {
      setCompany({ id: usuario.company.id, text: usuario.company.name });
      setCompanies(
        usuario.companies.map((it) => ({ id: it.id, text: it.name }))
      );
    }
  }, [usuario]);
  return (
    <header className="finbe-header">
      <div className="finbe-header-logo">
        <img src="/icons/finbe-logo-alt.svg" alt="logo finbe" />
      </div>
      <div className="finbe-nav">
        {/* DropDown companies*/}
        {usuario && (usuario.type == 4 || usuario.type == 5) && (
          <InputDropDown
            //   fieldWrapperClassName="finbe-drop-down-show-register"
            fieldWrapperStyle={{ display: "flex", margin: "0" }}
            name="companyId"
            data={companies}
            value={company}
            onChange={handleChange}
          />
        )}
        <Avatar />
        <Button look="flat" className="finbe-header-burger" onClick={onClick}>
          <img src="./icons/ICONOGRAFIA/logout-header.svg"></img>
        </Button>
      </div>
    </header>
  );
};
export default Header;
