/**
 * @author: Alexander Chi
 * @description: Component of disposiciones list, create, edit, information CSV,
 *               upload XML o CSV, units evidences, FinBe validation
 * @date: 17/sept/2020
 **/
import React, { Component } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import {
  InputMoney,
  InputComboBox,
  InputRangeDatePicker,
  InputFilterText,
} from "../Sections/Components/Inputs";
import { ButtonAction } from "../Sections/Components/Buttons";
import { FilterBox, MoreFilterBox } from "../Sections/Filters";
import { ModalBootstrap, DialogBox } from "../Sections/Dialogs";
import moment from "moment";
import { useUsuario } from "../../Context/UserContext";
import FinbeService from "../../Services/Finbe/FinbeService";
import ProvisionService from "../../Services/ProvisionService";
import AuditService from "../../Services/AuditService";
import UserService from "../../Services/UserServices";
import AgencyService from "../../Services/AgencyService";
import CompanyService from "../../Services/CompanyService";
import qs from "qs";
import { process as ProcessKendo } from "@progress/kendo-data-query";
import useLoader from "../../hooks/useLoader";
import dotenv from "dotenv";
import useOperation from "../../hooks/useOperation";
dotenv.config();

/** Global variables **/
let user = null;
let signOut = null;
let Loading = useLoader();
let operation = null;
/** Get user cotext hook **/
function UserData({ children }) {
  const { usuario, signOutCognito } = useUsuario();
  operation = useOperation();
  user = usuario;
  signOut = signOutCognito;
  return children({ user: usuario });
}

/** all status provisions **/
let statusProvisions = [
  { id: 1, text: "Creada", value: "CREADA" },
  { id: 2, text: "Enviada a validar", value: "ENVIADA_VALIDAR" },
  { id: 3, text: "Validación FinBe", value: "VALIDACION_FINBE" },
  { id: 4, text: "Aceptada", value: "ACEPTADA" },
  { id: 5, text: "Enviada a firmar", value: "ENVIADA_FIRMA" },
  { id: 6, text: "Proceso firma", value: "PROCESO_FIRMA" },
  { id: 7, text: "Firmado", value: "FIRMADO" },
  { id: 8, text: "Autorizado", value: "AUTORIZADO" },
  { id: 9, text: "Rechazada", value: "RECHAZADO" },
  { id: 10, text: "Autorizado", value: "LIBERADO" },
  { id: 11, text: "Cancelado", value: "CANCELADO" },
];

/** Status valid of type user **/
const statusOperatorFibe = [3, 4, 5, 6, 7, 8, 9, 10]; // type: 3
const statusLegalReprese = [5, 6, 7, 8, 9, 10]; // type: 4
let status = [];

/** Format number **/
const numberFormat = (value) => new Intl.NumberFormat("en-US").format(value);

/** Main view **/
class Provisions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agency: null,
      audits: [],
      creditLine: null,
      creditLines: [],
      customerName: "",
      customers: [],
      dataState: {
        sort: [{ field: "createdAt", dir: "desc" }],
        take: 10,
        skip: 0,
      },
      endDate: "",
      isDown: true,
      isMoreFilter: true,
      loading: true,
      numberProvision: "",
      operator: null,
      provisionId: 0,
      provisions: [],
      rangeDate: null,
      response: { title: "", message: "", success: false },
      showRegister: { id: 1, text: 10 },
      sort: [{ field: "date", dir: "desc" }],
      skip: 0,
      startDate: "",
      status: null,
      take: 10,
      visibleDocumentsAndSend: false,
      visibleResponse: false,
      visibleSendFinbe: false,
      enableSuccess: false,
    };
    localStorage.setItem("createDisabled", false);
    localStorage.setItem("exceptionDisabled", true);
  }

  /** Get information after render  **/
  componentDidMount() {
    if (user.type == 2 || user.type === 1) {
      this._getProvisions({ status: statusOperatorFibe });
      status = statusProvisions.filter((it) =>
        statusOperatorFibe.includes(it.id)
      );
    } else if (user.type == 4) {
      this._getProvisions({
        account: user.account.id,
        company: user.company.id,
        status: statusLegalReprese,
      });
      this._getOperartos();
      this._getAgencies();
    } else if (user.type == 3) {
      /** Operator FinBe **/
      this._getProvisionsByAssignedTo({ status: statusOperatorFibe });
      status = statusProvisions.filter((it) =>
        statusOperatorFibe.includes(it.id)
      );
    } else {
      this._getProvisions({
        account: user.account.id,
        company: user.company.id,
        registerBy: user.id,
      });
      status = statusProvisions.filter((it) =>
        statusOperatorFibe.includes(it.id)
      );
      this._getAgencies();

      // const _agencies = user.agency.map((agency, index) => {
      //   return {
      //     id: agency.id,
      //     text: agency.name,
      //     value: agency.id,
      //   };
      // });
      // this.setState({agencies:_agencies});
    }
    if (user.type == 4 || user.type == 5) {
      this._getCreditLines(user.company.clientId);
      if (user.type == 5) {
        // Se comenta para utilizar la validación única _validations
        // this._validateProcess(user);
        this._validations(user);
        status = statusProvisions;
      } else {
        status = statusProvisions.filter((it) =>
          statusLegalReprese.includes(it.id)
        );
      }
    }
  }

  /** Event change props news **/
  /*shouldComponentUpdate (nextProps){
    return true;
  }*/

  /** Get all credit lines of customer **/
  _getCreditLines = async (customerId) => {
    let creditLines = [];
    Loading();
    if (this.state.creditLines.length === 0) {
      try {
        const res = await FinbeService.getConfigurationCreditLine({
          params: { numeroCliente: customerId },
        });
        creditLines = res.data.map((line, index) => {
          return {
            id: line.lineaCredito,
            text: `${line.lineaCredito} ${
              line.tipoDispLinea == process.env.REACT_APP_TYPE_PREOWNED
                ? "(Seminuevos)"
                : "(Nuevos)"
            }`,
            value: line.lineaCredito,
            availableBalance: line.montoRestante,
            outstandingbalance: line.tienesSaldosPendientes,
          };
        });
        if (user.type == 5) {
          // Se comenta para utilizar la validación única _validations
          //this._validateOutstandingBalances(creditLines);
          // this.setState({ loading: true });
        }
        this.setState({ creditLines });
        Loading();
      } catch (error) {
        let response = error.response;
        this.setState({
          // loading: false,
          response: {
            title: "Ocurrió un error API externa",
            message: "<<ConfiguracionClientesDispLinea>>: " + response.data,
            success: false,
          },
          visibleResponse: true,
        });
      }
    }
  };

  /** Get All with filter inputs or not **/
  _getProvisions = async (params) => {
    let provisions = [];
    // this.setState({ loading: true });
    try {
      Loading();
      const res = await ProvisionService.getAll({
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      provisions = res.data.map((provision, index) => {
        return {
          id: provision.id,
          typeProvision:
            provision.typeLine === process.env.REACT_APP_TYPE_PREOWNED
              ? "Seminuevos"
              : "Nuevos",
          numProvision: provision.useManualFolio?provision.manualFolio: provision.folio,
          nameOperator: provision.operator.name,
          amount: numberFormat(provision.total),
          status: provision.status,
          units: provision.countUnits,
          date: moment(provision.createdAt, "DD/MM/YYYY HH:mm").toDate(),
        };
      });
      this.setState({ provisions });
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOut();
      // this.setState({loading: false });

    }
  };

  /** Get all with filter inputs or not type user Operator FinBe **/
  _getProvisionsByAssignedTo = async (params) => {
    let provisions = [];
    try {
      Loading();
      const res = await ProvisionService.getAllByAssignedTo(user.id, {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      provisions = res.data.map((provision, index) => {
        return {
          amount: numberFormat(provision.total),
          company: provision.company,
          date: moment(provision.createdAt, "DD/MM/YYYY HH:mm").toDate(),
          id: provision.id,
          nameOperator: provision.operator.name,
          numProvision:  provision.useManualFolio?provision.manualFolio: provision.folio,
          typeProvision:
            provision.typeLine === process.env.REACT_APP_TYPE_PREOWNED
              ? "Seminuevos"
              : "Nuevos",
          units: provision.countUnits,
          status: provision.status,
        };
      });
      this.setState({ provisions });
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOut();
      // this.setState({loading: false });

    }
  };

  /** Get All operators agency **/
  _getOperartos = async () => {
    let operators = [];
    try {
      Loading();
      const { data } = await UserService.getAll({
        params: { type: [5], account: user.account.id },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      operators = data.map((user, index) => {
        return {
          id: user.id,
          text: user.firstname + " " + user.lastname,
          value: user.id,
        };
      });
      this.setState({ operators });
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOut();

    }
  };

  /** Get All agencies **/
  _getAgencies = async () => {
    let agencies = [];
    try {
      Loading();
      const { data } = await AgencyService.getAll({
        params: { account: user.account.id, company: user.company.id },
      });
      agencies = data.map((agency, index) => {
        return {
          id: agency.id,
          text: agency.name,
          value: agency.id,
        };
      });
      this.setState({ agencies });
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOut();

    }
  };

  _getReservedAmount = async (params) => {
    try {
      // this.setState({ loading: true });
      Loading();
      const res = await ProvisionService.getReservedAmount(params);
      Loading();
      return res.data.total || 0;
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOut();
    }
  };

  /** Validación de Saldos, Auditoria y Excepción Autorizada
   * Permite hacer la validación en un solo proceso
   * **/
  _validations = async (user) => {
    let canProvision = true;
    let canException = false;
    let message = "";

    let _outstandingbalanceValid = false;
    let _auditValid = false;
    let _exceptionValid = false;
    let audits = [];
    let showResponse = true;

    try {
      Loading();
      // Valida los saldos pendientes
      let _credits = this.state.creditLines;
      let valid = _credits.filter((detail) => detail.outstandingbalance);
      if (valid.length > 0) {
        _outstandingbalanceValid = true;
        message += "Tienes [" + valid.length + "] saldos pendientes.";
      }
      // Valida Auditoria
      let params = {
        account: user.account.id,
        company: user.company.id,
        date: moment().format("YYYY/MM/DD"),
      };
      if (params.account === undefined) {
        message = "El usuario no tiene relacionada una cuenta.";
      }
      if (params.company === undefined) {
        message =
          (message.length > 0 ? "" : message) +
          "El usuario no tiene relacionada una compañía.";
      }
      let _auresponse = await AuditService.validateProcess({ params });
      audits = _auresponse.data.filter((audit) => audit.isSuspended);
      if (audits.length > 0) {
        _auditValid = true;
        message =
          (message.length > 0 ? message + "" : message) +
          "Tienes [" +
          audits.length +
          "] pendientes de auditorías.";
      }

      // Valida una excepción aprobada
      if (_auditValid || _outstandingbalanceValid) {
        let _exresponse = await CompanyService.lastException(user.company.id, {
          params: { date: moment().format("YYYY/MM/DD") },
        });
        if (_exresponse.data.length > 0) {
          let _valid = _exresponse.data.filter(
            (exception) => exception.status === 2
          );
          if (_valid.length > 0) {
            _exceptionValid = true;
            message =
              (message.length > 0 ? message + "" : message) +
              " La última excepción está aprobada. Ya puedes disponer.";
          } else {
            message =
              (message.length > 0 ? message + "" : message) +
              " La última excepción no está aprobada. No puedes disponer";
          }
        } else {
          message =
            (message.length > 0 ? message + "" : message) +
            " No puedes disponer";
        }
      } else {
        _exceptionValid = false;
        showResponse = false;
        message =
          (message.length > 0 ? message + "" : message) + " Puedes disponer.";
      }

      canProvision = _exceptionValid
        ? false
        : _auditValid || _outstandingbalanceValid
        ? true
        : false;
      canException = !canProvision;
      // Actualiza valor para acciones

      localStorage.setItem("createDisabled", canProvision);
      localStorage.setItem("exceptionDisabled", canException);
      Loading();
    } catch (err) {
      Loading();
      if (err.response && err.response.status == 401) signOut();
      message = err.message;
    }
    this.setState({
      audits: audits,
      outstandingBalances: _outstandingbalanceValid,
      lastException: _exceptionValid,
      visibleResponse: showResponse,
      response: {
        title: true ? "Resultado de validación" : "Ocurrió un error",
        message: message,
        success: true,
      },
      // loading: false
    });
  };

  /** Retorna booleano si tiene o no saldos pendientes **/
  _validateOutstandingBalances = async (data) => {
    let valid = [];
    let message = "";
    let success = true;
    this.setState({ loading: true });
    try {
      /** if is true enable actions **/
      valid = data.filter((detail) => detail.outstandingbalance);

      if (valid.length > 0) {
        message =
          "Tienes [" +
          valid.length +
          "] saldos pendientes. No puedes disponer.";
        //this._validateLastException(user.company.id);

        // Sí success es True, no puede disponser
        localStorage.setItem("createDisabled", success);
        // Sí success es True, no puede realizar excepción
        localStorage.setItem("exceptionDisabled", !success);
      } else {
        success = false;
      }
      if (localStorage.getItem("createDisabled") === "true") {
        this._validateLastException(user.company.id);
      }
    } catch (error) {
      if (error.response && error.response.status == 401) signOut();
      success = false;
      message = error.message;
    }
    this.setState({
      outstandingBalances: valid.length > 0,
      visibleResponse: success,
      response: {
        title: success ? "¡Pendientes!" : "Ocurrió un error",
        message: message,
        success: success,
      },
      loading: false,
    });
  };

  /** Validate process AuditService **/
  _validateProcess = async (user) => {
    let response = {};
    let audits = [];
    let message = "";
    let success = true;
    let code = 0;
    this.setState({ loading: true });
    try {
      let params = {
        account: user.account.id,
        company: user.company.id,
        date: moment().format("YYYY/MM/DD"),
      };
      /** Validate params show message error **/
      if (params.account === undefined) {
        success = false;
        message = "El usuario no tiene relacionada una cuenta.";
      }
      if (params.company === undefined) {
        success = false;
        message =
          (message.length > 0 ? ", " : message) +
          "El usuario no tiene relacionada una compañía.";
      }
      /** Validate error before **/
      if (success) {
        response = await AuditService.validateProcess({ params });
        audits = response.data.filter((audit) => audit.isSuspended);
        success = audits.length > 0;
        code = success ? 0 : 100;
        if (audits.length > 0) {
          message =
            "Tienes [" +
            audits.length +
            "] pendientes de auditorías. No puedes disponer.";
          // Sí success es True, no puede disponser
          localStorage.setItem("createDisabled", success);
          // Sí success es True, no puede realizar excepción
          localStorage.setItem("exceptionDisabled", !success);
          // if(success)
          //   this._validateLastException(user.company.id);
        }
      }
    } catch (error) {
      if (error.response && error.response.status == 401) signOut();
      success = false;
      message = error.message;
      code = error.status;
    }
    this.setState({
      audits: audits,
      visibleResponse: success,
      response: {
        title: success
          ? "Acción realizada exitosamente"
          : code == 100
          ? "¡Pendientes!"
          : "Ocurrió un error",
        message: message,
        success: success,
      },
      loading: false,
    });
  };

  /** Validate last excepcion **/
  _validateLastException = async (companyId) => {
    let response = {};
    let valid = [];
    let message = "";
    let success = true;
    this.setState({ loading: true });
    try {
      /** LAST EXCEPTION **/
      response = await CompanyService.lastException(companyId, {
        params: { date: moment().format("YYYY/MM/DD") },
      });
      if (response.data.length > 0) {
        valid = response.data.filter((exception) => exception.status == 3);
        console.info(valid);
        if (valid.length > 0) {
          message =
            this.state.response.message +
            " La última excepción no está aprobada. No puedes disponer.";
        } else {
          message =
            this.state.response.message +
            " La última excepción está aprobada. Ya puedes disponer.";
          success = false;
        }
        // Sí success es True, no puede disponser
        localStorage.setItem("createDisabled", success);
        // Sí success es True, no puede realizar excepción
        localStorage.setItem("exceptionDisabled", !success);
      }
    } catch (error) {
      if (error.response && error.response.status == 401) signOut();
      success = false;
      message = error.message;
    }
    if (response.data.length > 0) {
      this.setState({
        lastException: valid.length == 0,
        visibleResponse: success,
        response: {
          title: success ? "Excepción no aprobada!" : "Ocurrió un error",
          message: message,
          success: success,
        },
        loading: false,
      });
    }
  };

  /** Update status provision **/
  _setSatus = async (provisionId, statusNum) => {
    let provisions = [...this.state.provisions];
    let response = {};
    let message = "";
    let success = true;
    let oStatus = status.find((it) => it.id == statusNum);
    this.setState({ enableSuccess: true });
    try {
      Loading();
      operation();
      response = await ProvisionService.setStatus(provisionId, {
        status: statusNum,
      });
      let resultProvision = response.data;
      message =
        "N° de Disposición [" + resultProvision.folio + "]: " + oStatus.text;
      provisions = this.state.provisions.map((provision, index) => {
        if (resultProvision.id === provision.id) {
          provision.status = resultProvision.status;
        }
        return provision;
      });
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOut();
      success = false;
      message = error.response.data.message || error.message;
    }
    this.setState({
      provisions,
      response: {
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: success,
      },
      visibleResponse: true,
      visibleSendFinbe:
        success && statusNum == 2
          ? !this.state.visibleSendFinbe
          : this.state.visibleSendFinbe,
      visibleDocumentsAndSend:
        success && statusNum == 5
          ? !this.state.visibleDocumentsAndSend
          : this.state.visibleDocumentsAndSend,
      // loading: false,
      enableSuccess: false,
    });
  };

  /** Click for More filters **/
  handleClickMoreFilter = () => {
    this.setState({ isDown: !this.state.isDown });
    if (!this.state.isDown) {
      this.setState({
        endDate: "",
        operator: null,
        rangeDate: null,
        startDate: "",
        status: null,
      });
    }
  };

  /** Click Search **/
  handleClickSearch = (e) => {
    let params = {};

    if (this.state.creditLine != null && this.state.creditLine.id !== "")
      params.creditLine = this.state.creditLine.id;
    if (this.state.numberProvision !== "")
      params.folio = this.state.numberProvision;
    if (this.state.operator != null) params.registerBy = this.state.operator.id;
    if (this.state.startDate !== "") params.from = this.state.startDate;
    if (this.state.endDate !== "") params.to = this.state.endDate;
    if (this.state.agency != null) {
      params.agency = this.state.agency.id;
    }
    if (this.state.status != null) {
      params.status = this.state.status.id;
    } else {
      if (user.type === 4) {
        params.status = statusLegalReprese;
      } else if (user.type == 3) {
        params.status = statusOperatorFibe;
        if (this.state.customerName != null && this.state.customerName !== "") {
          params.companyName = this.state.customerName;
        }
      } else if (user.type === 2) {
        params.status = statusOperatorFibe;
      }
    }
    if (user.type === 2 || user.type === 1) {
      this._getProvisions(params);
    } else if (user.type == 3) {
      this._getProvisionsByAssignedTo(params);
    } else {
      if (user.type !== 4) {
        params.registerBy = user.id;
      }
      params.account = user.account.id;
      params.company = user.company.id;
      this._getProvisions(params);
    }
    this.setState({ skip: 0 });
  };

  /** Change input credit line, operator, status **/
  handleChange = async (e) => {
    const { name, value } = e.target;
    if (name === "creditLine") {
      if (value !== null) {
        let montoReservado = await this._getReservedAmount({
          params: { creditLine: value.value },
        });
        let _availableBalance = +(
          value.availableBalance - +montoReservado
        ).toFixed(2);
        this.setState({
          availableBalance: _availableBalance > 0 ? _availableBalance : 0,
          [name]: value,
        });
      } else {
        this.setState({ availableBalance: 0.0, [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  /** Change input filter **/
  handleinputFilterOnChange = (e) => {
    this.setState({
      showRegister: e.value,
      skip: 0,
    });
  };

  /** Change input combo date range **/
  handleChangeRangeDate = (e) => {
    const { value } = e.target;
    this.setState({
      startDate:
        value.start !== null ? moment(value.start).format("YYYY-MM-DD") : "",
      endDate: value.end !== null ? moment(value.end).format("YYYY-MM-DD") : "",
      rangeDate: value,
    });
  };

  /** Handel click show or hide dialog send finbe **/
  handleDialogSendFinbe = (e) => {
    const target = e.currentTarget;
    if (target !== undefined) {
      let provisionId = target.getAttribute("data");
      this.setState({
        provisionId: provisionId,
        visibleSendFinbe: !this.state.visibleSendFinbe,
      });
    } else {
      this.setState({ visibleSendFinbe: !this.state.visibleSendFinbe });
    }
  };

  /** Handel click send finbe validation **/
  handleSendFinbe = (e) => {
    this._setSatus(this.state.provisionId, 2);
  };

  /** Handel click show or hide dialog generated documents and send signature **/
  handleDialogDocumentsAndSend = (e) => {
    const target = e.currentTarget;
    if (target !== undefined) {
      let provisionId = target.getAttribute("data");
      this.setState({
        provisionId: provisionId,
        visibleDocumentsAndSend: !this.state.visibleDocumentsAndSend,
      });
    }
  };

  /** Handel click create documents and send signature **/
  handleDocumentsAndSend = (e) => {
    this._setSatus(this.state.provisionId, 5);
  };

  /** Handel click show or hide dialog response backend **/
  handleDialogResponse = (e) => {
    this.setState({ visibleResponse: !this.state.visibleResponse });
  };

  // /** Change of page grid **/
  // handelPageChange = (event) => {
  //   this.setState({
  //     skip: event.page.skip,
  //     take: event.page.take,
  //   });
  // };

  handleShowRegisterChange = (e) => {
    const { value } = e.target;
    this.setState({
      showRegister: value,
      dataState: { ...this.state.dataState, take: value.text },
    });
  };

  /** Handel show or hide of request excepcion**/
  handelRequest = (e) => {
    this.setState({ visibleRequest: !this.state.visibleRequest });
  };

  render() {
    return (
      <UserData>
        {({ user }) => {
          return (
            <div className="finbe-app-container">
              {user.type != 3 && user.type !== 2 && user.type !== 1 && (
                <>
                  <div className="finbe-app-section-bottom">
                    <Form
                      render={(formRenderProps) => (
                        <FormElement>
                          <div style={{ display: "flex" }}>
                            <Field
                              component={InputComboBox}
                              data={this.state.creditLines}
                              dClassName="k-form-field-wrap finbe-form-combo"
                              id="creditLine"
                              label="Línea de crédito"
                              name="creditLine"
                              onChange={this.handleChange}
                              placeholder="Seleccione línea de crédito"
                              value={this.state.creditLine}
                              wrapperStyle={{ width: "30%" }}
                            />
                            <Field
                              component={InputMoney}
                              dClassName="k-form-field-wrap finbe-form-field-wrap finbe-input-money"
                              id="available"
                              label="Saldo disponible"
                              nombre="available"
                              value={this.state.availableBalance}
                              disabled={true}
                              wrapperStyle={{
                                width: "25%",
                                paddingLeft: "1.2em",
                              }}
                            />
                          </div>
                        </FormElement>
                      )}
                    />
                  </div>
                  <div className="finbe-app-line"> </div>
                </>
              )}
              <div className="finbe-app-section-top">
                <FilterBox
                  buttonMoreFilterOnClick={this.handleClickMoreFilter}
                  buttonSearchOnClick={this.handleClickSearch}
                  inputDropDownValue={this.state.showRegister}
                  inputFilterOnChange={this.handleinputFilterOnChange}
                  isDown={this.state.isDown}
                  isMoreFilter={this.state.isMoreFilter}
                >
                  <div style={{ width: "100%" }}>
                    <Form
                      render={(formRenderProps) => (
                        <FormElement style={{ display: "flex" }}>
                          <Field
                            component={InputFilterText}
                            dClassName="k-form-field-wrap finbe-form-field-filter"
                            id="numberProvision"
                            nombre="numberProvision"
                            onChange={this.handleChange}
                            placeholder="Ingrese N° de disposición"
                            value={this.state.numberProvision}
                            wrapperStyle={{ width: "50%", paddingTop: "1.8em" }}
                          />
                          {(user.type == 3 || user.type === 1) && (
                            <Field
                              component={InputFilterText}
                              dClassName="k-form-field-wrap finbe-form-field-filter"
                              id="customerName"
                              nombre="customerName"
                              onChange={this.handleChange}
                              placeholder="Ingrese nombre del cliente"
                              value={this.state.customerName}
                              wrapperStyle={{
                                width: "50%",
                                paddingTop: "1.8em",
                              }}
                            />
                          )}
                          {(user.type == 4 || user.type == 5) && (
                            <Field
                              component={InputComboBox}
                              data={this.state.agencies}
                              dClassName="k-form-field-wrap finbe-form-combo"
                              id="agency"
                              name="agency"
                              onChange={this.handleChange}
                              placeholder="Seleccionar sucursal"
                              value={this.state.agency}
                              wrapperStyle={{
                                width: "50%",
                                marginLeft: "1em",
                                marginTop: "1.59em",
                              }}
                            />
                          )}
                        </FormElement>
                      )}
                    />
                  </div>
                </FilterBox>
              </div>
              <div style={{ color: "#b0ada6" }}>
                {!this.state.isDown ? (
                  <div
                    style={{
                      width: "95%",
                      margin: "1em 1em 0 1em",
                      background: "#e4e3e0",
                    }}
                  >
                    <MoreFilterBox>
                      <Form
                        render={(formRenderProps) => (
                          <FormElement style={{ display: "flex" }}>
                            {user.type == 4 && (
                              <Field
                                component={InputComboBox}
                                data={this.state.operators}
                                dClassName="k-form-field-wrap finbe-form-combo"
                                id="operator"
                                name="operator"
                                onChange={this.handleChange}
                                placeholder="Seleccionar operador"
                                value={this.state.operator}
                                wrapperStyle={{
                                  width: "25%",
                                  marginLeft: "1em",
                                  marginTop: "0.79em",
                                }}
                              />
                            )}
                            <Field
                              component={InputComboBox}
                              data={status}
                              dClassName="k-form-field-wrap finbe-form-combo"
                              id="status"
                              name="status"
                              onChange={this.handleChange}
                              placeholder="Seleccione estatus"
                              value={this.state.status}
                              wrapperStyle={
                                user.type == 4
                                  ? {
                                      width: "25%",
                                      marginLeft: "1em",
                                      marginTop: "1.1em",
                                    }
                                  : {
                                      width: "33%",
                                      marginLeft: "1em",
                                      marginTop: "0.79em",
                                    }
                              }
                            />
                            <Field
                              component={InputRangeDatePicker}
                              dateRangePickerId="datePickerFilter"
                              dateRangePickerName="datePickerFilter"
                              dClassName="k-form-field-wrap finbe-form-date"
                              dateRangePickerOnChange={
                                this.handleChangeRangeDate
                              }
                              dateRangePickerValue={this.state.rangeDate}
                              fieldWrapperStyle={
                                user.type == 4
                                  ? {
                                      width: "50%",
                                      paddingLeft: "1.2em",
                                    }
                                  : {
                                      width: "66%",
                                      paddingLeft: "1.2em",
                                    }
                              }
                            />
                          </FormElement>
                        )}
                      />
                    </MoreFilterBox>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div>
                <Grid
                  className="finbe-table"
                  // data={process(this.state.provisions, this.state.dataState)}
                  // {...this.state.dataState}
                  // onDataStateChange={(e) => {
                  //   this.setState({dataState:e.data});
                  // }}
                  // data={this.state.provisions.slice(
                  //   this.state.skip,
                  //   this.state.showRegister.text + this.state.skip
                  // )}

                  data={ProcessKendo(this.state.provisions, {
                    take: +this.state.showRegister.text,
                    skip: this.state.skip,
                    sort: this.state.sort,
                  })}
                  onDataStateChange={(e) => {
                    this.setState({
                      sort: e.data.sort,
                      take: e.data.take,
                      skip: e.data.skip,
                    });
                  }}
                  onPageChange={this.handelPageChange}
                  pageable={{
                    info: false,
                  }}
                  skip={this.state.skip}
                  sortable
                  sort={this.state.sort}
                  take={this.state.showRegister.text}
                  total={this.state.provisions.length}
                  resizable
                  onSortChange={(e) => {
                    this.setState({
                      sort: e.sort,
                    });
                  }}
                >
                  <Column field="numProvision" title="N° de Disposición" />
                  {user.type == 3 ? (
                    <Column field="company.name" title="Clientes" />
                  ) : (
                    <Column field="nameOperator" title="Operador" />
                  )}
                  <Column field="typeProvision" title="Tipo" />
                  <Column field="amount" title="Monto dispuesto" />
                  <Column
                    field="status"
                    title="Estatus"
                    cell={(props) => (
                      <td>
                        {
                          statusProvisions.find(
                            (it) => it.id == props.dataItem.status
                          ).text
                        }
                      </td>
                    )}
                  />
                  <Column
                    field="units"
                    title="N. Unidades"
                    // cell={(props) => (
                    //   <td>
                    //     {this.state.uni}
                    //     {/* { statusProvisions.find((it) => it.id == props.dataItem.status).text } */}
                    //   </td>
                    // )}
                  />
                  <Column
                    field="date"
                    title="Fecha"
                    filter="date"
                    format="{0:dd/MM/yyyy}"
                  />
                  <Column
                    field="date"
                    title="Hora"
                    // filter="date"
                    format="{0:HH:mm}"
                    
                  />
                  <Column
                    title="Acciones"
                    width="200px"
                    cell={(props) => (
                      <td>
                        <div style={{ display: "flex" }}>
                          {user.type == 5 && (
                            <ButtonAction
                              title={"Editar"}
                              disabled={
                                props.dataItem.status != 1 ||
                                ((this.state.audits.length > 0 ||
                                  this.state.outstandingBalances) &&
                                  !this.state.lastException)
                              }
                              icon=" icon editar"
                              isRedirect={true}
                              look="bare"
                              primary={false}
                              url={"/editar-disposicion/" + props.dataItem.id}
                            />
                          )}
                          {user.type == 5 && (
                            <ButtonAction
                              title={"Enviar a FinBe"}
                              data={props.dataItem.id}
                              disabled={
                                props.dataItem.status != 1 ||
                                ((this.state.audits.length > 0 ||
                                  this.state.outstandingBalances) &&
                                  !this.state.lastException)
                              }
                              icon={
                                " icon " +
                                (props.dataItem.status == 5
                                  ? "enviar_a_finbe_enviado"
                                  : "enviar_a_finbe")
                              }
                              look="bare"
                              onClick={this.handleDialogSendFinbe}
                              primary={false}
                            />
                          )}
                          <ButtonAction
                            title={
                              (user.type == 3 || user.type === 1) &&
                              (props.dataItem.status != 3 ||
                                props.dataItem.status > 4)
                                ? "Observaciones"
                                : "Visualizar"
                            }
                            icon={
                              " icon " +
                              ((user.type == 3 || user.type === 1) &&
                              (props.dataItem.status != 3 ||
                                props.dataItem.status > 4)
                                ? "observaciones"
                                : "visualizar")
                            }
                            isRedirect={true}
                            look="bare"
                            primary={false}
                            url={
                              (user.type == 3 || user.type === 1) &&
                              props.dataItem.status != 3
                                ? "/vizualizar-expediente/" + props.dataItem.id
                                : "/vizualizar-disposicion/" + props.dataItem.id
                            }
                          />
                          {user.type == 5 && (
                            <ButtonAction
                              title={"Enviar"}
                              data={props.dataItem.id}
                              disabled={
                                props.dataItem.status != 4 ||
                                ((this.state.audits.length > 0 ||
                                  this.state.outstandingBalances) &&
                                  !this.state.lastException)
                              }
                              icon=" icon enviar"
                              look="bare"
                              onClick={this.handleDialogDocumentsAndSend}
                              primary={false}
                            />
                          )}
                          {user.type == 4 && (
                            <ButtonAction
                              title={"Firma"}
                              icon=" icon firmar"
                              isRedirect={true}
                              look="bare"
                              primary={false}
                              url={"/firmar-disposicion/" + props.dataItem.id}
                            />
                          )}
                        </div>
                      </td>
                    )}
                  />
                </Grid>
              </div>
              <DialogBox
                buttonSuccessText="Aceptar"
                centerButtons={true}
                enableSuccess={this.state.enableSuccess}
                handelAccept={this.handleSendFinbe}
                handelClose={this.handleDialogSendFinbe}
                title={"Enviar a FinBe"}
                visible={this.state.visibleSendFinbe}
                visibleCancelButton={true}
              >
                ¿Seguro que desea enviar a FinBe para validar está disposición?
              </DialogBox>
              <DialogBox
                buttonSuccessText="Aceptar"
                centerButtons={true}
                enableSuccess={this.state.enableSuccess}
                handelAccept={this.handleDocumentsAndSend}
                handelClose={this.handleDialogDocumentsAndSend}
                title={"Generar documentos y enviar a firma"}
                visible={this.state.visibleDocumentsAndSend}
                visibleCancelButton={true}
              >
                <p>¿Seguro que desea generar documentos y enviar a firma?</p>
                <p>
                  <span style={{ fontWeight: "bold" }}> NOTA:</span> Se
                  generarán los documentos y el Representante podrá descargar
                  los documentos para su firma.{" "}
                </p>
              </DialogBox>

              <DialogBox
                buttonSuccessText={"Aceptar"}
                handelClose={this.handleDialogResponse}
                handelAccept={this.handleDialogResponse}
                title={this.state.response.title}
                visible={this.state.visibleResponse}
              >
                {this.state.response.message}
              </DialogBox>
              {/* {this.state.loading && <Loading />} */}
            </div>
          );
        }}
      </UserData>
    );
  }
}

export default Provisions;
