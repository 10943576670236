import React from 'react'
import { useUsuario } from '../../Context/UserContext';
// import { Loading } from '../Sections/Dialogs';
const Content = (props) => {
    // const { cargando } = useUsuario()
    return (
        <>
        <div className="finbe-content-card">
            {props.children}
        </div>
        {/* <div style={{outline:"none"}} tabIndex="-1">
        {cargando&&<Loading />}
        </div> */}
        </>
    )
}

export default Content;