import httpCommon from "../Utils/http-common";

const getAll = (params) => {
  const client = httpCommon();
  return client.get("/emails-service-desk", params);
};

const getById = (id) => {
  const client = httpCommon();
  return client.get(`/emails-service-desk/${id}`);
};

const create = (data) => {
  const client = httpCommon();
  return client.post("/emails-service-desk", data);
};

const update = (id, data) => {
  const client = httpCommon();
  return client.put(`/emails-service-desk/${id}`, data);
};

const remove = (id) => {
  const client = httpCommon();
  return client.delete(`/emails-service-desk/${id}`);
};

export default {
  getAll,
  getById,
  create,
  update,
  remove,
};
