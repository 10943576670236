import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import ModalDialog from "../Common/Dialog";
import RoleService from "../../Services/RoleService";
import RoleButtons from "./roleButtons";
import { useUsuario } from "../../Context/UserContext";

// import '../users/form.css';
import { InputComboBox, InputText } from "../Sections/Components/Inputs";
import { DialogBox } from "../Sections/Dialogs";

const CreateEditRole = (props) => {
  let history = useHistory();
  const { usuario, signOutCognito,setCargando } = useUsuario();

  let initialState = {
    id: "",
    name: "",
    description: "",
    type: 0,
    registerBy: "",
  };

  const [role, setRole] = useState(initialState);
  const [editing, setEditing] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [dataConfirm, setDataConfirm] = useState({});
  const [dataResponse, setDataResponse] = useState({});
  const [tiposRol, setTiposRol] = useState([]);
  const onChange = (event) => {
    const { name, value } = event.target;
    if (typeof value !== "object") {
      setRole({ ...role, [name]: value });
    } else if (value != null) {
      setRole({ ...role, [name]: value.id });
    }
  };

  const onSave = async () => {
    setVisibleConfirm(false);
    let success = true;
    let message = "";
    setCargando(true)
    if (editing) {
      const { id, name, description, type, registerBy } = role;

      try {
        const { data } = await RoleService.update(id, {
          name,
          description,
          type,
          registerBy,
        });
        message = "La informacion del rol " + data.name + " se ha actualizado";
      } catch (error) {
        if (error?.response?.status == 401) signOutCognito();
        success = false;
        message = error.message;
      }
      setDataResponse({
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: success,
      });
      setVisible(!visible);
      setCargando(false)
    } else {
      const { id, ...rest } = role;
      try {
        const { data } = await RoleService.create(rest);
        message = "El rol " + data.name + " se creo correctamente";
      } catch (error) {
        if (error?.response?.status == 401) signOutCognito();
        success = false;
        message = error.message;
      }
      setDataResponse({
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: success,
      });
      setVisible(!visible);
      setCargando(false)
    }
  };
  const handleDialogResponse = () => {
    if (dataResponse.success) {
      history.push("/roles");
    } else {
      setVisible(!visible);
    }
  };

  const openDialog = () => {
    setVisibleConfirm(true);

    if (editing) {
      setDataConfirm({
        title: "Edición de rol",
        message: "¿Desea guardar los cambios?",
        textCancel: "Cancelar",
        textConfirm: "Aceptar",
      });
    } else {
      setDataConfirm({
        title: "Creación de rol",
        message: "¿Desea guardar el nuevo rol?",
        textCancel: "Cancelar",
        textConfirm: "Aceptar",
      });
    }
  };

  const getUpdate = async (id) => {
    try {
      setCargando(true)
      const res = await RoleService.getById(id);
      setRole(res.data);
      setEditing(!editing);
      setCargando(false)
    } catch (error) {
      setCargando(false)
      if (error?.response?.status == 401) signOutCognito();

    }
  };

  useEffect(() => {
    if (usuario !== null) {
      setRole({ ["registerBy"]: usuario.id });
      if (usuario.type === 1) {
        setTiposRol([
          {
            id: 2,
            text: "Admin Finbe",
          },
          {
            id: 3,
            text: "Operador Finbe",
          },
          {
            id: 4,
            text: "Representante Legal",
          },
          {
            id: 5,
            text: "Operador Agencia",
          },
        ]);
      } else if (usuario.type === 2) {
        setTiposRol([
          {
            id: 3,
            text: "Operador Finbe",
          },
          {
            id: 4,
            text: "Representante Legal",
          },
          {
            id: 5,
            text: "Operador Agencia",
          },
        ]);
      } else if (usuario.type === 3) {
        setTiposRol([
          {
            id: 4,
            text: "Representante Legal",
          },
          {
            id: 5,
            text: "Operador Agencia",
          },
        ]);
      }
    }
    if (props.match.params.id != undefined) {
      getUpdate(props.match.params.id);
    }
  }, [props.match.params.id]);

  // const displayErrors = (error) => {
  //   if (error)
  //     if (error.response)
  //       if (error.response.data)
  //         if (error.response.data.validation)
  //           if (error.response.data.validation.keys) {
  //             const keys = error.response.data.validation.keys;
  //             const key = keys[0];

  //             switch (key) {
  //               case "name":
  //                 setDataDialog({
  //                   ...dataDialog,
  //                   message: "Verifique el campo de Nombre",
  //                 });
  //                 break;

  //               case "description":
  //                 setDataDialog({
  //                   ...dataDialog,
  //                   message: "Verifique el campo de Descripción",
  //                 });
  //                 break;

  //               default:
  //                 setDataDialog({
  //                   ...dataDialog,
  //                   message: "Verifique los campos obligatorios",
  //                 });
  //                 break;
  //             }
  //           }
  // };

  return (
    <div className="finbe-app-container">
      <h3>Datos del rol</h3>
      <Form
        render={(formRenderProps) => (
          <FormElement>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="name"
                  label="* Nombre"
                  nombre="name"
                  onChange={onChange}
                  placeholder="Ingresar nombre"
                  required={true}
                  validityStyles={true}
                  minLength={1}
                  maxLength={150}
                  value={role.name}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="description"
                  label="* Descripción"
                  nombre="description"
                  onChange={onChange}
                  placeholder="Ingresar descripción"
                  value={role.description}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            {/* {usuario ? (
              usuario.type == 3 ? null : ( */}
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputComboBox}
                  data={tiposRol}
                  dClassName="k-form-field-wrap finbe-form-combo"
                  id="type"
                  label="* Tipo de rol"
                  name="type"
                  onChange={onChange}
                  placeholder="Seleccionar tipo"
                  disabled={editing ? true : false}
                  required={true}
                  validityStyles={true}
                  value={tiposRol.find((x) => x.id === role.type)}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            {/* )
            ) : null} */}
          </FormElement>
        )}
      />

      <RoleButtons onClick={editing ? onSave : openDialog} />
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={dataResponse.title}
        visible={visible}
      >
        {dataResponse.message}
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => setVisibleConfirm(false)}
        handelCancel={() => setVisibleConfirm(false)}
        handelAccept={onSave}
        title={dataConfirm.title}
        visible={visibleConfirm}
        visibleCancelButton={true}
      >
        {dataConfirm.message}
      </DialogBox>

      {/* {visible ? (
        <ModalDialog
          title={dataDialog.title}
          close={() => setVisible(false)}
          message={dataDialog.message}
          onSave={onSave}
          onCancel={() => setVisible(false)}
          textCancel={dataDialog.textCancel}
          textConfirm={dataDialog.textConfirm}
        />
      ) : null} */}
    </div>
  );
};

export default CreateEditRole;
