/**
 * @author: Alexander Chi
 * @description: Component of section filters
 * @date: 21/sept/2020
 **/
import React, {Component} from 'react'
import {ButtonPrimary} from './Components/Buttons';
import {InputFilterText, InputDropDown} from './Components/Inputs';

/** Information data show registers **/
const dataShowRegister = [
  {id: 1, text: 10},
  {id: 2, text: 25},
  {id: 3, text: 50},
  {id: 4, text: 100},
];

/** Filter single main **/
export class FilterBox extends Component{
  constructor (props) {
    super(props)
    this.state = {
      isDown: true,
      showRegister: dataShowRegister[0]
    };
  }

  handleClick = () => {
    this.setState({ isDown: !this.state.isDown});
  }

  render() {
    const {
      buttonDownloadReportOnClick,
      buttonMoreFilterOnClick,
      buttonSearchOnClick,
      inputDropDownChange,
      inputDropDownValue,
      inputFilterDivClassName,
      inputFilterId,
      inputFilterName,
      inputFilterOnChange,
      inputFilterPlaceholder,
      inputFilterValue,
      inputFilterwWrapperStyle,
      isDown,
      isDownloadReport,
      isMoreFilter,
      visibleDropDown,
    } = this.props;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex',  width: (this.props.children != null ? '100%' : '25%')}} className = ''>
        {this.props.children != null ?
           this.props.children :
           <InputFilterText
              dClassName = {inputFilterDivClassName}
              identificador = {inputFilterId}
              nombre = {inputFilterName}
              onChange = {inputFilterOnChange}
              placeholder = {inputFilterPlaceholder}
              value = {inputFilterValue}
              wrapperStyle = {inputFilterwWrapperStyle}
            />
         }
       </div>
       <div style={{ display: 'flex', textAling: 'left', marginRight: '3.5em' , marginTop: '1.1em'}}>
          <ButtonPrimary
            onClick = {buttonSearchOnClick}
            pClassName = "finbe-btn-margin"
            primary = {true}
            text = 'Buscar'
          />
          {isMoreFilter ?
            <ButtonPrimary
              className = "k-icon-left"
              icon = {'arrow-chevron-' + (isDown ? 'down' : 'up')}
              onClick = {buttonMoreFilterOnClick}
              pClassName = "finbe-btn-margin"
              primary = {true}
              text = 'Filtros'
            /> :
            ''
          }
            {isDownloadReport ?
            <ButtonPrimary
              onClick = {buttonDownloadReportOnClick}
              pClassName = "finbe-btn-margin"
              primary = {true}
              text = "Descargar Informe"
            /> :
            ''
          }
          {
            visibleDropDown &&
            (<>
              <InputDropDown
                id = 'showRegister'
                name = 'showRegister'
                data = {dataShowRegister}
                onChange = {inputFilterOnChange}
                value = {inputDropDownValue}
                fieldWrapperClassName = 'finbe-drop-down-show-register'
              />
              <p className = 'finbe-p-register'>
                registros
              </p>
            </>)
          }
        </div>
      </div>
    );
  }
}

/** Defacult Props FilterBox **/
FilterBox.defaultProps = {
  buttonDownloadReportOnClick: null,
  buttonFilterOnClick: null,
  buttonMoreFilterOnClick: null,
  inputDropDownChange: null,
  inputDropDownValue: dataShowRegister[0],
  inputFilterDivClassName: '',
  inputFilterId: 'default',
  inputFilterName: 'default',
  inputFilterOnChange: null,
  inputFilterPlaceholder: 'default',
  inputFilterValue: '',
  inputFilterwWrapperStyle: null,
  isDownloadReport: false,
  isMoreFilter: true,
  visibleDropDown: true
};

/** More filter or default single filter **/
export class MoreFilterBox extends Component{
  render() {
    const {
      inputFilterDivClassName,
      inputFilterId,
      inputFilterName,
      inputFilterOnChange,
      inputFilterPlaceholder,
      inputFilterValue,
      inputFilterwWrapperStyle,
    } = this.props;
    return (
      <div >
      {this.props.children != null ?
         this.props.children :
         <InputFilterText
            dClassName = {inputFilterDivClassName}
            id = {inputFilterId}
            nombre = {inputFilterName}
            onChange = {inputFilterOnChange}
            placeholder = {inputFilterPlaceholder}
            value = {inputFilterValue}
            wrapperStyle = {inputFilterwWrapperStyle}
          />
       }
      </div>
    );
  }
}

/** Defacult Props FilterBox **/
MoreFilterBox.defaultProps = {
  buttonFilterOnClick: null,
  buttonMoreFilterOnClick: null,
  inputFilterDivClassName: '',
  inputFilterId: 'default',
  inputFilterName: 'default',
  inputFilterOnChange: null,
  inputFilterPlaceholder: 'default',
  inputFilterValue: '',
  inputFilterwWrapperStyle: null,
};
