import React from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { InputComboBox, InputText } from "../../Sections/Components/Inputs";

export const FormEdit = (props) => {
  const {
    handleChangeLegal,
    handleChangeCompany,
    company,
    moralPersons,
    legalPersonsFinbe,
    legalPerson,
    roles,
  } = props;

  let _moralPersons = moralPersons.map((x) => {
    return { id: x.numeroCliente, text: x.numeroCliente };
  });
  let _legalPersons = legalPersonsFinbe.map((x) => {
    return { id: x.numeroCliente, text: x.nombres + " " + x.apellidos };
  });

  return (
    <div>
      <Form
        render={(formRenderProps) => (
          <FormElement>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputComboBox}
                  data={_moralPersons}
                  dClassName="k-form-field-wrap finbe-form-combo"
                  id="clientId"
                  label="Persona moral"
                  name="clientId"
                  onChange={handleChangeCompany}
                  placeholder="Seleccionar persona moral"
                  value={_moralPersons.find((x) => x.id === company.clientId)}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  disabled={true}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="name"
                  label="Nombre de la empresa"
                  nombre="name"
                  placeholder=""
                  onChange={handleChangeCompany}
                  value={company.name}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  disabled={true}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="legalName"
                  label="Razón social"
                  nombre="legalName"
                  placeholder=""
                  onChange={handleChangeCompany}
                  value={company.legalName}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              {/* {typeAccount == 1 && (
                <div style={{ width: "50%" }}>
                  <Field
                    component={InputComboBox}
                    data={_listCompanies}
                    dClassName="k-form-field-wrap finbe-form-combo"
                    id="companyId"
                    label="Empresa"
                    name="companyId"
                    onChange={handleChange}
                    placeholder="Seleccionar empresa"
                    value={_listCompanies.find(
                      (x) => x.id == legalPerson.companyId
                    )}
                    wrapperStyle={{ width: "90%" }}
                  />
                </div>
              )} */}
              <div style={{ width: "50%" }}>
                <Field
                  component={InputComboBox}
                  data={_legalPersons}
                  dClassName="k-form-field-wrap finbe-form-combo"
                  id="legalId"
                  label="* Representante Legal"
                  name="legalId"
                  onChange={handleChangeLegal}
                  placeholder="Seleccionar representante legal"
                  value={_legalPersons.find(
                    (x) => x.id === legalPerson.legalId
                  )}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="firstname"
                  label="Nombres"
                  nombre="firstname"
                  onChange={handleChangeLegal}
                  placeholder="Ingresar nombre"
                  value={legalPerson.firstname}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="lastname"
                  label="Apellidos"
                  nombre="lastname"
                  onChange={handleChangeLegal}
                  placeholder="Ingresar apellidos"
                  value={legalPerson.lastname}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  disabled={true}
                  id="email"
                  label="Correo electronico"
                  nombre="email"
                  onChange={handleChangeLegal}
                  placeholder="Ingresar correo"
                  required={true}
                  rules={"([a-z0-9_.-]+)@([da-z.-]+).([a-z.]{2,6})$"}
                  validityStyles={true}
                  maxLength={150}
                  minLength={1}
                  value={legalPerson.email}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="phone"
                  label="Teléfono"
                  nombre="phone"
                  onChange={handleChangeLegal}
                  rules={"^[0-9]{8,13}$"}
                  validityStyles={true}
                  minLength={0}
                  maxLength={13}
                  placeholder="Ingresar teléfono"
                  value={legalPerson.phone}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputComboBox}
                  data={roles}
                  dClassName="k-form-field-wrap finbe-form-combo"
                  id="roleId"
                  label="* Rol"
                  name="roleId"
                  onChange={handleChangeLegal}
                  placeholder="Seleccionar rol"
                  value={roles.find((x) => x.id === legalPerson.roleId)}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
};
