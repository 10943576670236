import { useUsuario } from "../Context/UserContext";
import moment from "moment";
export default function useOperation() {
  const { operation } = useUsuario();
  const { startOperation, endOperation, nonWorkings } = operation;
  const L = () => {
    const now = moment().format("DD-MM-YYYY HH:mm:ss");
    let key = moment(now,'DD/MM/YYYY').format('DD/MM/YYYY');
    const _non = {};

    if (nonWorkings[key]) {

      throw Error(
        `No se pudo realizar la acción, el día es inhabil por motivo del ${
          nonWorkings[key]
        }`
      );
    } else if (moment(now).isBefore(startOperation)) {
      throw Error(
        `No se pudo realizar la acción, el horario de operaciones de Plan Piso aun no inicia. Por favor intente lo más tarde en un horario de ${moment(
          startOperation
        ).format("HH:mm")} a ${moment(endOperation).format("HH:mm")}`
      );
    } else if (moment(now).isAfter(endOperation)) {
      throw Error(
        `No se pudo realizar la acción, el horario de operaciones de Plan Piso ha concluido. Por favor intente lo más tarde en un horario de ${moment(
          startOperation
        ).format("HH:mm")} a ${moment(endOperation).format("HH:mm")}`
      );
    }
  };

  return L;
}
