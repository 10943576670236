/**
 * @author: Alexander Chi
 * @description: Component for inputs whit general desing
 * @date: 18/sept/2020
 **/
import React from "react";
import {
  Input,
  RadioGroup,
  Switch,
  Checkbox,
} from "@progress/kendo-react-inputs";
import { ComboBox, DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { DatePicker, DateRangePicker } from "@progress/kendo-react-dateinputs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Upload } from "@progress/kendo-react-upload";
import { Error } from "@progress/kendo-react-labels";
import * as RandExp from "randexp";
import { ButtonAction } from "./Buttons";

/** Input for money, label center, input with left position $ **/
export const InputMoney = (props) => {
  const {
    dClassName,
    disabled,
    id,
    inputStyle,
    label,
    nombre,
    onChange,
    required,
    value,
    wrapperStyle,
  } = props;
  return (
    <FieldWrapper style={wrapperStyle}>
      <div className={dClassName}>
        <Input
          disabled={disabled}
          id={id}
          label={label}
          name={nombre}
          onChange={onChange}
          required={required}
          style={inputStyle}
          value={value}
        />
      </div>
    </FieldWrapper>
  );
};

/** Values default of props **/
InputMoney.defaultProps = {
  allowCustom: null,
  data: "",
  dClassName: "",
  disabled: false,
  id: "",
  inputStyle: null,
  label: "",
  nombre: "",
  onChange: null,
  value: "",
  wrapperStyle: null,
};

/** Input for filter text **/
export const InputFilterText = (props) => {
  const {
    dClassName,
    id,
    label,
    nombre,
    onChange,
    placeholder,
    required,
    value,
    wrapperStyle,
  } = props;
  return (
    <FieldWrapper style={wrapperStyle}>
      <div className={dClassName}>
        <Input
          id={id}
          label={label}
          name={nombre}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          value={value}
        />
      </div>
    </FieldWrapper>
  );
};

/** Values default of props **/
InputFilterText.defaultProps = {
  dClassName: "",
  id: "",
  label: "",
  nombre: "",
  onChange: null,
  placeholder: "",
  required: false,
  value: "",
  wrapperStyle: null,
};

/** Input for input text, number, etc **/
export const InputText = (props) => {
  const email = "([a-z0-9_.-]+)@([da-z.-]+).([a-z.]{2,6})$";
  const {
    dClassName,
    disabled,
    id,
    label,
    nombre,
    onChange,
    placeholder,
    required,
    type,
    validationMessage,
    validityStyles,
    value,
    wrapperStyle,
    minLength,
    maxLength,
    rules = "",
  } = props;

  function _renderMessage(value, req, min, max, rules) {
    if (req) {
      if (value == null || value === "") {
        return "Campo requerido.";
      }
    }
    if (min != null) {
      if (value != null) {
        if (value.length < min) {
          return "Mínimo " + min + " caracteres";
        }
      }
    }
    if (max != null) {
      if (value != null) {
        if (value.length > max) {
          return "Maxímo " + max + " caracteres";
        }
      }
    }
    if (rules != null && typeof rules == "string") {
      if (rules.trim() !== "") {
        const regExp = RegExp(rules);
        if (!regExp.test(value)) {
          if (type == "password") {
            return `La contraseña debe tener al menos 8 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter especial, ejemplo:3Jemp|o@`;
          }
          //let rand = new RandExp(rules);
          return "Formato " + value + " incorrecto"; //. Ejemplo: " + rand.gen();
        }
      }
    } else {
      if (!rules.test(value)) {
        if (type == "password") {
          return `La contraseña debe tener al menos 8 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter especial, ejemplo:3Jemp|o@`;
        }
        return "Formato " + value + " incorrecto";
      }
    }
    return "";
  }
  return (
    <FieldWrapper style={wrapperStyle}>
      <div className={dClassName}>
        <Input
          disabled={disabled}
          id={id}
          label={label}
          name={nombre}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          type={type}
          validationMessage={validationMessage}
          validityStyles={validityStyles}
          value={value}
        />
      </div>
      <Error style={{ paddingLeft: "0.5em" }}>
        {validityStyles &&
          _renderMessage(value, required, minLength, maxLength, rules)}
      </Error>
    </FieldWrapper>
  );
};

/** Values default of props **/
InputText.defaultProps = {
  dClassName: "",
  disabled: false,
  editable: true,
  id: "",
  label: "",
  nombre: "",
  onChange: null,
  required: false,
  type: "",
  validationMessage: "Campo requerido",
  validityStyles: false,
  value: "",
  wrapperStyle: null,
};
/**MultiSelect */
export const InputMultiSelect = ({
  allowCustom = null,
  data = [],
  dataItemKey = "id",
  dClassName= "",
  disabled= false,
  id = "",
  label = "",
  name = "",
  onChange=null,
  placeholder="",
  required= false,
  validationMessage= "",
  validityStyles= false,
  value="",
  wrapperStyle="",
}) => {
  return (<FieldWrapper style={wrapperStyle}>
      <div className={dClassName}>
        <MultiSelect
          allowCustom={allowCustom}
          data={data}
          dataItemKey={dataItemKey}
          disabled={disabled}
          id={id}
          label={label}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          // suggest={true}
          textField="text"
          validationMessage={validationMessage}
          validityStyles={validityStyles}
          value={value}
        />
      </div>
      <Error style={{ paddingLeft: "0.5em" }}>
        {(value == null || value === "") && validityStyles
          ? "Campo requerido."
          : validationMessage}
      </Error>
    </FieldWrapper>)
};

/** ComboBox **/
export const InputComboBox = (props) => {
  const {
    allowCustom,
    data,
    dataItemKey,
    dClassName,
    defaultValue,
    disabled,
    id,
    label,
    name,
    onChange,
    placeholder,
    required,
    validationMessage,
    validityStyles,
    value,
    wrapperStyle,
  } = props;
  return (
    <FieldWrapper style={wrapperStyle}>
      <div className={dClassName}>
        <ComboBox
          allowCustom={allowCustom}
          data={data}
          dataItemKey={dataItemKey}
          disabled={disabled}
          id={id}
          label={label}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          suggest={true}
          textField="text"
          validationMessage={validationMessage}
          validityStyles={validityStyles}
          value={value}
        />
      </div>
      <Error style={{ paddingLeft: "0.5em" }}>
        {(value == null || value === "") && validityStyles
          ? "Campo requerido."
          : validationMessage}
      </Error>
    </FieldWrapper>
  );
};

/** Values default of props **/
InputComboBox.defaultProps = {
  allowCustom: null,
  data: [],
  dataItemKey: "id",
  dClassName: "",
  disabled: false,
  id: "",
  label: "",
  nombre: "",
  onChange: null,
  placeholder: "default",
  required: false,
  validationMessage: "",
  validityStyles: false,
  value: "",
  wrapperStyle: "",
};

/** Drop down list **/
export const InputDropDown = (props) => {
  const {
    allowCustom,
    data,
    disabled,
    divClassName,
    fieldWrapperClassName,
    fieldWrapperStyle,
    id,
    label,
    name,
    onChange,
    placeholder,
    validationMessage,
    validityStyles,
    value,
  } = props;
  return (
    <FieldWrapper style={fieldWrapperStyle} className={fieldWrapperClassName}>
      <div className={divClassName}>
        <DropDownList
          allowCustom={allowCustom}
          data={data}
          dataItemKey="id"
          disabled={disabled}
          id={id}
          label={label}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          style={{ width: "100%" }}
          suggest={true}
          textField="text"
          validationMessage={validationMessage}
          validityStyles={validityStyles}
          value={value}
        />
      </div>
    </FieldWrapper>
  );
};

/** Values default of props **/
InputDropDown.defaultProps = {
  allowCustom: null,
  data: [],
  disabled: false,
  divClassName: "",
  fieldWrapperClassName: "",
  fieldWrapperStyle: null,
  id: "",
  label: "",
  nombre: "",
  onChange: null,
  placeholder: "default",
  validationMessage: "Mensaje de error en prop {validationMessage}",
  validityStyles: true,
  value: "",
  wrapperStyle: "",
};

/** DateRangePicker **/
export const InputRangeDatePicker = (props) => {
  const {
    dateRangePickerValue,
    dateRangePickerDefaultValue,
    dateRangePickerId,
    dateRangePickerName,
    dateRangePickerOnChange,
    fieldWrapperStyle,
    startSettingsId,
    endSettingsId,
  } = props;

  const dateRangePickerStartSettings = {
    label: "Inicia",
    format: "dd/M/yyyy",
    id: startSettingsId,
    className: "finbe-filter-date-picker-color finbe-filter-date-picker-one",
  };

  const dateRangePickerEndSettings = {
    label: "Finaliza",
    format: "dd/M/yyyy",
    id: endSettingsId,
    className: "finbe-filter-date-picker-color finbe-filter-date-picker-two",
  };

  return (
    <FieldWrapper style={fieldWrapperStyle}>
      <DateRangePicker
        id={dateRangePickerId}
        name={dateRangePickerName}
        onChange={dateRangePickerOnChange}
        startDateInputSettings={dateRangePickerStartSettings}
        endDateInputSettings={dateRangePickerEndSettings}
        defaultValue={dateRangePickerDefaultValue}
        value={dateRangePickerValue}
      />
    </FieldWrapper>
  );
};

/** Values default of props **/
InputRangeDatePicker.defaultProps = {
  dateRangePickerDefaultValue: null,
  dateRangePickerId: "defaultR",
  dateRangePickerName: "defaultR",
  dateRangePickerOnChange: null,
  dateRangePickerValue: null,
  endSettingsId: "datePickerEnd",
  fieldWrapperStyle: null,
  startSettingsId: "datePickerStart",
};


export const InputDatePicker =(props)=>{
  const {
    dClassName,
    id,
    label,
    nombre,
    onChange,
    placeholder,
    // required,
    value,
    wrapperStyle,
  } = props;
  return <FieldWrapper style={wrapperStyle}>
  <div className={dClassName}>
  <label className="k-form-field">
                  <span>Fecha</span>
    <DatePicker
      id={id}
      label={label}
      name={nombre}
      onChange={onChange}
      placeholder={placeholder}
      // required={required}
      value={value}
    />
    </label>
  </div>
</FieldWrapper>

}

/**InputFile HTML5 */
export const InputFileH = (props) => {
  const {
    accept,
    disabled,
    files,
    handleChange,
    handleDelete,
    id,
    multiple,
    name,
    uploadLabel,
    refFile
  } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label>{uploadLabel}</label>
      <input
        style={{
          border: "solid 1px #dee2e6",
          width: "90%",
          padding: "10px",
          cursor: "pointer",
          borderRadius:"5px 5px 0 0",
        }}
        ref={refFile}
        accept={accept}
        // disabled={disabled}
        multiple={multiple}
        name={name}
        onChange={handleChange}
        type="file"
        value={null}
        id={id}
      />
      {files && files.length > 0 && (
        <ul style={{width:"90%",padding:"10px",border:"solid 1px #dee2e6",borderTop:"none",margin:"0", borderRadius:"0 0 5px 5px"}}>
          {files?.map((item,index) => (
            <li key={index} style={{ display: "flex", justifyContent: "space-between" }}>
              <span>{item.name||item.fileName}</span>
              <ButtonAction
                title = {'Cargar archivos'}
                // disabled = {!props.dataItem.check}
                icon = {" icon eliminar"}
                look="bare"
                primary = {false}
                // data = {props.dataItem.index}
                onClick = {()=>{handleDelete(item,name)}}
              />
              {/* <button type="button" onClick={()=>{handleDelete(item,name)}}>
                X
              </button> */}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
InputFileH.defaultProps={
  files:[],
  handleChange:null,
  handleDelete:null,
}

/** Input for file **/
export const InputFile = (props) => {
  const baseUrl =
    process.env.REACT_APP_URL_CONECCTION_API || "http://localhost:8000";
  const {
    files,
    divClassName,
    fieldWrapperStyle,
    uploadAllowedExtensions,
    uploadAutoUpload,
    uploadBatch,
    uploadDefaultFiles,
    uploadDisabled,
    uploadId,
    uploadLabel,
    uploadMultiple,
    uploadOnAdd,
    uploadOnProgress,
    uploadOnRemove,
    uploadOnStatusChange,
    uploadRemoveUrl,
    uploadSaveUrl,
    uploadWithCredentials,
  } = props;

  return (
    <div className="k-form-field-wrap finbe-file">
      <span className="k-textbox-container">
        {uploadLabel ?? (
          <label className="k-label" style={{ marginTop: "0.8em" }}>
            {uploadLabel}
          </label>
        )}
        <FieldWrapper
          style={fieldWrapperStyle}
          className="finbe-fieldWrapperStyle"
        >
          <div className={divClassName}>
            <Upload
              autoUpload={uploadAutoUpload}
              batch={uploadBatch}
              defaultFiles={uploadDefaultFiles}
              id={uploadId}
              disabled={uploadDisabled}
              multiple={uploadMultiple}
              onAdd={uploadOnAdd}
              onProgress={uploadOnProgress}
              onRemove={uploadOnRemove}
              onStatusChange={uploadOnStatusChange}
              removeUrl={uploadRemoveUrl}
              restrictions={{
                allowedExtensions: uploadAllowedExtensions,
              }}
              saveUrl={uploadSaveUrl}
              withCredentials={uploadWithCredentials}
              files={files}
              
            />
          </div>
        </FieldWrapper>
      </span>
    </div>
  );
};

/** Default props input file **/
InputFile.defaultProps = {
  files:[],
  divClassName: "",
  fieldWrapperStyle: null,
  uploadAllowedExtensions: [".jpg", ".png"],
  uploadAutoUpload: false,
  uploadBatch: false,
  uploadDefaultFiles: [],
  uploadDisabled: false,
  uploadId: "defaultFile",
  uploadLabel: "",
  uploadMultiple: false,
  uploadOnAdd: null,
  uploadOnProgress: null,
  uploadOnRemove: null,
  uploadOnStatusChange: null,
  uploadRemoveUrl: "",
  uploadSaveUrl: "",
  uploadWithCredentials: false,
};

/** Input for switch **/
export const InputSwitch = (props) => {
  const { checked, data, defaultValue, onChange } = props;
  return (
    <Switch
      checked={checked}
      defaultChecked={defaultValue}
      onChange={onChange}
      data={data}
    />
  );
};

InputSwitch.defaultProps = {
  checked: false,
  defaultValue: false,
  onChange: null,
};
