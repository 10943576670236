import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import NonWorkingService from "../../Services/NonWorkingService";
import { useUsuario } from "../../Context/UserContext";
import useLoader from "../../hooks/useLoader";
import moment from "moment";
import { DialogBox } from "../Sections/Dialogs";
import { ButtonAction } from "../Sections/Components/Buttons";
function Index() {
  const { usuario, signOutCognito } = useUsuario();
  const Loading = useLoader();

  const [id, setId] = useState(null);
  const [nonWorkings, setNonWorkings] = useState([]);
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [dataState, setDataState] = useState({
    sort: [{ field: "name", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });
  const [visibleRemove, setVisibleRemove] = useState(false);
  const [visibleResponse, setVisibleResponse] = useState(false);
  const [response, setResponse] = useState({
    title: "",
    message: "",
    success: "",
  });

  useEffect(() => {
    getNonWorkigs();
  }, []);

  const getNonWorkigs = async (data) => {
    try {
      Loading();
      const res = await NonWorkingService.getAll();
      const _data = res.data.map((value) => {
        const { date, createdAt, ...res } = value;
        const _date = moment(date, "DD/MM/YYYY HH:mm").toDate();
        const _createdAt = moment(createdAt, "DD/MM/YYYY HH:mm").toDate();

        return {
          ...res,
          date: _date,
          createdAt: _createdAt,
        };
      });
      setNonWorkings(_data);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };
  const handleRemove = async () => {
    let success = true;
    let message = "";
    try {
      Loading();
      setVisibleRemove(false);
      const res = await NonWorkingService.remove(id);
      message = "El día inhabil se elimino correctamente";
      const _nonWorkings = nonWorkings.filter((item) => item.id !== id);
      setNonWorkings(_nonWorkings);
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOutCognito();
      success = false;
      message = error.message;
    }
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
    });
    setVisibleResponse(true);
  };
  return (
    <>
      <div className="finbe-app-container">
        <div>
          <Grid
            className="finbe-table"
            data={process(nonWorkings, dataState)}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.data);
            }}
            pageable={{
              info: false,
            }}
            sortable
            skip={dataState.skip}
            take={dataState.take}
            resizable
          >
            <Column
              field="date"
              title="Fecha"
              filter="date"
              format="{0:dd/MM/yyyy}"
            />
            <Column field="reason" title="Motivo" />
            <Column
              field="createdAt"
              title="Fecha de alta"
              filter="date"
              format="{0:dd/MM/yyyy}"
            />
            <Column
              title="Acciones"
              cell={(props) => (
                <td>
                  <div style={{ display: "flex" }}>
                    <>
                      {/* <ButtonAction
                      title={"Editar"}
                      icon=" icon editar"
                      isRedirect={true}
                      look="bare"
                      primary={false}
                      url={"/editar-emails-mesa-servicio/" + props.dataItem.id}
                    /> */}
                      <ButtonAction
                        title={"Eliminar"}
                        icon=" icon remover-eliminar"
                        look="bare"
                        primary={false}
                        isRedirect={false}
                        onClick={() => {
                          setId(props.dataItem.id);
                          setVisibleRemove(true);
                        }}
                      />
                    </>
                  </div>
                </td>
              )}
            />
          </Grid>
          <DialogBox
            buttonSuccessText="Aceptar"
            centerButtons={true}
            handelAccept={handleRemove}
            handelClose={() => setVisibleRemove(false)}
            title={"Eliminar día inhabil"}
            visible={visibleRemove}
            visibleCancelButton={true}
          >
            ¿Seguro que desea eliminar este día inhabil?
          </DialogBox>
          <DialogBox
            buttonSuccessText={"Aceptar"}
            handelClose={() => setVisibleResponse(false)}
            handelAccept={() => setVisibleResponse(false)}
            title={response.title}
            visible={visibleResponse}
          >
            {response.message}
          </DialogBox>
        </div>
      </div>
    </>
  );
}

export default Index;
