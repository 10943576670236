import React from 'react'
import { ButtonRedirect } from '../Inputs/Buttons'
import { ButtonTitle } from '../Sections/Components/Buttons'
import { useUsuario } from "../../Context/UserContext";

const Title = (props) => {
  const { usuario } = useUsuario();
  return(
    <div className="finbe-title-card">
      {
        props.parentRoute ?
          <ButtonRedirect
            className = "finbe-title-return icon regresar"
            primary = {false}
            uri = {props.parentRoute}
            /> :
          ''
      }
      <span className="finbe-text-color">
        {props.title}
      </span>
      <div className="finbe-title-buttons">
        {(props.visibleCompenent === undefined || props.visibleCompenent == null)
          ? props.titleComponent && props.titleComponent.map((button, index)=>(
            (button.uri !== undefined && button.uri !== null) ?
              <ButtonTitle
                className = {"finbe-button " + button.class}
                icon = {button.icon}
                id = {"button" + index}
                key = {index}
                look = {button.lock}
                primary = {button.primary}
                text = {button.name}
                url = {button.uri}
                localStorageDisabled = {button.localStorage}
                isRedirect = {true}
              /> :
              <ButtonTitle
                className = {"finbe-button " + button.class}
                icon = {button.icon}
                id = {"button" + index}
                isRedirect = {false}
                key = {index}
                localStorageDisabled = {button.localStorage}
                look = {button.lock}
                onClick = {props.handelTitleAcction}
                primary = {button.primary}
                text = {button.name}
                value = {button.dialog}
              />
          ))
          : props.visibleCompenent.includes(usuario.type)
            ? (
                props.titleComponent && props.titleComponent.map((button, index)=>(
                  (button.uri !== undefined && button.uri !== null) ?
                    <ButtonTitle
                      className = {"finbe-button " + button.class}
                      icon = {button.icon}
                      id = {"button" + index}
                      key = {index}
                      look = {button.lock}
                      primary = {button.primary}
                      text = {button.name}
                      url = {button.uri}
                      localStorageDisabled = {button.localStorage}
                      isRedirect = {true}
                    /> :
                    <ButtonTitle
                      className = {"finbe-button " + button.class}
                      id = {"button" + index}
                      icon = {button.icon}
                      value = {button.dialog}
                      key = {index}
                      look = {button.lock}
                      primary = {button.primary}
                      text = {button.name}
                      isRedirect = {false}
                      onClick = {props.handelTitleAcction}
                    />
                ))
              )
              : null
        }
        </div>
      </div>
    )
}

export default Title;
