/**
 * @author: Alexander Chi
 * @description: Component request exceptions
 * @date: 19/Nov/2020
 **/
import React, { Component } from "react";
import { DialogBox } from "../Sections/Dialogs";
import { useUsuario } from "../../Context/UserContext";
import { useHistory } from "react-router-dom";

/** Get user cotext hook **/
function UserData({ children }) {
  const { usuario } = useUsuario();
  const history = useHistory();
  return children({ user: usuario, history });
}

/** Main component RequestExceptions **/
export class DialogConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      units: [],
      visible: this.props.visible,
    };
  }

  /** Get new props **/
  componentWillReceiveProps (nextProps){
    const {visible} = nextProps;
    let units = localStorage.getItem('units');
    this.setState({visible, units: JSON.parse(units)});
  }

  render() {
    const {
      handelClose,
      identificador,
      visible,
    } = this.props;
    return (
      <UserData>
        {({ user, history }) => {
          return (
            <DialogBox
              identificador={identificador}
              buttonSuccessText={"Aceptar"}
              handelAccept={(e) => {
                let units = localStorage.getItem('units')
                history.push({
                  pathname: "/crear-disposicion/",
                  state: {units: JSON.parse(units)}
                })
              }}
              handelClose={handelClose}
              title={"Creación de disposición"}
              visible={visible}
              visibleCancelButton = {true}
              visibleButtons = {this.state.units.length > 0}
              >
                {
                  this.state.units.length > 0
                  ? <p>¿Está seguro de incluir la(s) unidad(es) seleccionada(s) en una nueva disposición?</p>
                  : <p>Seleccione al menos una unidad</p>
                }
            </DialogBox>
          );
        }}
      </UserData>
    );
  }
}

export default DialogConfirm;

/** default props file **/
File.DialogConfirm = {};
