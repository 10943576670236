import httpCommon from "../Utils/http-common";

const getAll = (params) => {
  const client = httpCommon();
  return client.get("/accounts", params);
};

const getById = (id) => {
  const client = httpCommon();
  return client.get(`/accounts/${id}`);
};

const create = (data) => {
  const client = httpCommon();
  return client.post("/accounts", data);
};

const update = (id, data) => {
  const client = httpCommon();
  return client.put(`/accounts/${id}`, data);
};

const setActive = (id, data) => {
  const client = httpCommon();
  return client.patch(`/accounts/${id}/active`, data);
};

const setArchived = (id, data) => {
  const client = httpCommon();
  return client.patch(`/accounts/${id}/archive`, data);
};

const getArchived = (params) => {
  const client = httpCommon();
  return client.get("/accounts/archived",params);
};

const getAgencies = (id) => {
  const client = httpCommon();
  return client.get(`/accounts/${id}/agencies`);
};

const getCompanies = (id) => {
  const client = httpCommon();
  return client.get(`/accounts/${id}/companies`);
};

const getUsers = (id) => {
  const client = httpCommon();
  return client.get(`/accounts/${id}/users`);
};

const setOperatorAssign = (id, data) => {
  const client = httpCommon();
  return client.patch(`/accounts/${id}/assign`, data);
};

const getCompaniesByAssingned = (id,params) => {
  const client = httpCommon();
  return client.get(`/accounts/assigned/${id}/companies`,params);
};

const getConfiguration=(params)=>{
  const client = httpCommon();
  return client.get('/accounts/signatureConfig',params)
}
const saveConfiguration=(data)=>{
  const client = httpCommon();
  return client.post('/accounts/signatureConfig',data)
}
export default {
  create,
  getAgencies,
  getAll,
  getArchived,
  getById,
  getCompanies,
  getCompaniesByAssingned,
  getUsers,
  setActive,
  setArchived,
  setOperatorAssign,
  update,
  getConfiguration,
  saveConfiguration,
};
