/**
 * @author: Alexander Chi
 * @description: Component Wrapper
 * @update: 02/oct/2020
 **/
import React, { Component } from "react";
import Title from "./Title";
import Content from "./Content";
import { RequestException } from "../Provisions/RequestException";
import { DialogConfirm } from "../Exceptions/DialogConfirm";
import {
  IntlProvider,
  load,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import esMessages from "./es.json";
import { DialogBox } from "../Sections/Dialogs";
import socketIOClient from "socket.io-client";
import dotenv from "dotenv";
import { useUsuario } from "../../Context/UserContext";
import { NotificationGroup } from "@progress/kendo-react-notification";
import Notification from "../Sections/Notification";
import ConfirmRefinancing from "../Exceptions/ConfirmRefinancing";

dotenv.config();

load(
  require("cldr-data/supplemental/likelySubtags.json"),
  require("cldr-data/supplemental/currencyData.json"),
  require("cldr-data/supplemental/weekData.json"),

  require("cldr-data/main/es/numbers.json"),
  require("cldr-data/main/es/currencies.json"),
  require("cldr-data/main/es/ca-gregorian.json"),
  require("cldr-data/main/es/dateFields.json"),
  require("cldr-data/main/es/timeZoneNames.json")
);
loadMessages(esMessages, "es");

let user = null;

/** Get user cotext hook **/
function UserData({ children }) {
  const { usuario } = useUsuario();
  user = usuario;
  return children({ user: usuario });
}

export class Wrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibles: [],
      locale: "es",
      visibleResponse: false,
      notifications: [],
    };
  }

  /** Get value visible buttons in title **/
  _getVisibleState(dialog, ref) {
    let visibles = this.state.visibles;
    return visibles !== undefined || visibles !== null
      ? visibles.map((it, i) => (it.dialog === dialog ? it[ref] : null))[0]
      : null;
  }

  /** Set value visible button title**/
  _setVisibleState(componentName) {
    let visibles = this.state.visibles;
    if (visibles !== undefined || visibles !== null) {
      let visible = this._getVisibleState(componentName, "visible");
      visibles.map((it, i) => (it.visible = !visible));
    }
    this.setState({ visibles });
  }

  _handleClose = (e) => {
    const target = e.target;
    if (target !== undefined) {
      const _notifications = [
        ...this.state.notifications.filter(
          (item) => item.id !== target.props.data
        ),
      ];
      this.setState({
        notifications: _notifications,
      });
    }
  };

  /** handel acction title for all button in title show modal(dialog) **/
  _handelTitleAcction(componentName) {
    this._setVisibleState(componentName);
  }

  handelTitleAcction = (e) => {
    let componentName =
      e.target.value !== undefined
        ? e.target.value
        : e.target.props.identificador;
    if (componentName === "RequestException") {
      let disabled = localStorage.getItem("exceptionDisabled");
      disabled = JSON.parse(disabled);
      if (!disabled) {
        this._setVisibleState(componentName);
      }
    } else {
      this._setVisibleState(componentName);
    }
  };

  componentDidMount() {
    const socket = socketIOClient(process.env.REACT_APP_URL_CONNECTION_API);

    if (user != null) {
      socket.on(user.id, (data) => {
        const _notifications = [...this.state.notifications];
        data.visible = true;
        _notifications.push(data);
        this.setState({
          visibleResponse: !this.state.visibleResponse,
          notifications: _notifications,
        });
      });
    }
  }
  componentWillMount() {
    this.props.getCurrentSession();
    if (
      this.props.titleComponent !== undefined &&
      this.props.titleComponent !== null
    ) {
      let visibles = [];
      this.props.titleComponent.map((it, index) =>
        it.dialog !== undefined && it.dialog !== null
          ? visibles.push({ ["dialog"]: it.dialog, ["visible"]: false })
          : null
      );
      this.setState({ visibles });
    }
  }

  _renderDialog(component) {
    switch (component) {
      case "RequestException":
        return (
          <RequestException
            identificador={component}
            key={component}
            visible={this._getVisibleState(component, "visible")}
            handelClose={this.handelTitleAcction}
          />
        );
      case "CreateProvisions":
        return (
          <DialogConfirm
            buttonSuccessText={"Aceptar"}
            handelClose={() => {
              this._handelTitleAcction(component);
            }}
            identificador={component}
            key={component}
            title={"Creación de disposición"}
            visible={this._getVisibleState(component, "visible")}
            visibleCancelButton={true}
          />
        );
      case "CreateRefinancing":
        return (
        <ConfirmRefinancing
          buttonSuccessText={"Aceptar"}
          handelClose={() => {
            this._handelTitleAcction(component);
          }}
          identificador={component}
          key={component}
          title={"Creación de refinanciamiento"}
          visible={this._getVisibleState(component, "visible")}
          visibleCancelButton={true}
        />
        )
      default:
        return null;
    }
  }
  render() {
    return (
      <UserData>
        {({ user }) => {
          return (
            <LocalizationProvider language={this.state.locale}>
              <IntlProvider locale={this.state.locale}>
                <main className="finbe-wrapper">
                  {this.props.title && (
                    <Title
                      parentRoute={this.props.parentRoute}
                      title={this.props.title}
                      titleComponent={this.props.titleComponent}
                      handelTitleAcction={this.handelTitleAcction}
                      visibleCompenent={this.props.visibleCompenent}
                    />
                  )}
                  <Content>{this.props.children}</Content>
                  {this.props.titleComponent !== undefined &&
                  this.props.titleComponent !== null
                    ? this.props.titleComponent.map((it, index) =>
                        it.dialog !== undefined && it.dialog !== null
                          ? this._renderDialog(it.dialog, index)
                          : null
                      )
                    : null}
                </main>
                <NotificationGroup
                  style={{
                    right: 0,
                    bottom: 0,
                    alignItems: "flex-start",
                    flexWrap: "wrap-reverse",
                  }}
                >
                  {this.state.notifications.map((item) => {
                    return (
                      <Notification
                        visible={item.visible}
                        handleClose={this._handleClose}
                        message={item.message}
                        data={item.id}
                      />
                    );
                  })}
                </NotificationGroup>
              </IntlProvider>
            </LocalizationProvider>
          );
        }}
      </UserData>
    );
  }
}

export default Wrapper;
