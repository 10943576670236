/**
 * @author: Alexander Chi
 * @description: Component of Exceptions
 * @date: 17/sept/2020
 **/
import React, { Component } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import { RadioButton } from "@progress/kendo-react-inputs";
import {
  InputFilterText,
  InputRangeDatePicker,
  InputText,
  InputFile,
  InputComboBox,
} from "../Sections/Components/Inputs";
import { LabelWithTextBorder } from "../Sections/Components/Tags";
import { FilterBox, MoreFilterBox } from "../Sections/Filters";
import { ButtonAction } from "../Sections/Components/Buttons";
import { DialogBox } from "../Sections/Dialogs";
import { RowTextLinkSize, RowIconText } from "../Sections/Components/Tags";
import moment from "moment";
import { useUsuario } from "../../Context/UserContext";
import AccountService from "../../Services/AccountService";
import ExceptionService from "../../Services/ExceptionService";
import DocumentService from "../../Services/DocumentService";
import qs from "qs";

import { process } from "@progress/kendo-data-query";
import useLoader from "../../hooks/useLoader";

/** payment type list **/
const paymentType = [{ id: 1, text: "Falta de pago" }];

/** Global variables **/
let user = null;
let signOut = null;
let Loading = useLoader();
/** Get user cotext hook **/
function UserData({ children }) {
  const { usuario, signOutCognito } = useUsuario();
  user = usuario;
  signOut = signOutCognito;
  return children({ user: usuario });
}

/** Main view **/
class Exceptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: null,
      accounts: [],
      approve: false,
      attachedDocument: [],
      comment: "",
      companies: [],
      company: null,
      endDate: "",
      exception: null,
      exceptions: [],
      good: false,
      isMoreFilter: false,
      numberException: "",
      paymentType: null,
      response: { title: "", message: "", success: false },
      rangeDate: null,
      showRegister: { d: 1, text: 10 },
      skip: 0,
      startDate: "",
      take: 10,
      sort: [{ field: "date", dir: "desc" }],
      visible: false,
      visibleAlert: false,
      visibleObservations: false,
      visibleResponse: false,
      visibleSend: false,
      loading: false,
    };
  }

  /** After render **/
  componentDidMount() {
    this._getAccounts();
    this._getExceptions();
  }

  /** Get all accounts **/
  _getAccounts = async () => {
    if (this.state.accounts.length === 0) {
      try {
        Loading();
        let accounts = [];
        const res = await AccountService.getAll();
        accounts = res.data.map((account, index) => {
          return {
            id: account.id,
            text: account.name,
            value: account.id,
          };
        });
        this.setState({ accounts });
        Loading();
      } catch (error) {
        Loading();
        if (error?.response?.status == 401) signOut();
      }
    }
  };

  /** Get companies of account **/
  _getCompanies = async (companyId) => {
    try {
      Loading();
      let companies = [];
      const res = await AccountService.getCompanies(companyId);
      companies = res.data.map((company, index) => {
        return {
          id: company.id,
          text: company.clientId,
          value: company.clientId,
        };
      });
      this.setState({ companies });
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOut();
    }
  };

  /** Get list exceptions **/
  _getExceptions = async (params = {}) => {
    try {
      Loading();
      let exceptions = [];
      if (user.type === 5) {
        params.status = [0, 2, 3];
      } else if (user.type === 3) {
        params.status = [0];
      } else if (user.type === 2) {
        params.status = [1];
      }
      const res = await ExceptionService.getAll({
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      exceptions = res.data.map((exception, index) => {
        return {
          id: exception.id,
          folio: exception.folio,
          comment: exception.comment,
          nameCustomer: exception.company.name,
          type: exception.type,
          date: moment(exception.createdAt, "DD/MM/YYYY").toDate(),
          status: exception.status,
          commentOF: exception.commentOF,
          commentAF: exception.commentAF,
          isApproved: exception.isApproved,
          status: exception.status,
          type: exception.type,
        };
      });
      // exceptions = [{id: 0}]
      this.setState({ exceptions });
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOut();
      let response = {
        title: "¡Ocurrió un error!",
        message: error.message,
        success: true,
      };
      this.setState({ response, visibleResponse: true });
    }
  };

  /**Get exception by ID **/
  _getException = async (id) => {
    try {
      Loading();
      const { data } = await ExceptionService.getById(id);
      let evidenceOA = data.evidences.filter((evidence) => evidence.type == 4);
      let evidenceOF = data.evidences.filter((evidence) => evidence.type == 5);
      let evidenceAF = data.evidences.filter((evidence) => evidence.type == 6);

      if (data.operatorAgency != undefined) {
        data.operatorAgency.evidence =
          evidenceOA.length > 0 ? evidenceOA[0] : null;
      }
      if (data.operatorFinbe != undefined) {
        data.operatorFinbe.evidence =
          evidenceOF.length > 0 ? evidenceOF[0] : null;
      }
      // if(data.operatorFinbe != undefined){
      data.adminFinbeEvidence = evidenceAF.length > 0 ? evidenceAF[0] : null;
      // }
      delete data.evidences;
      this.setState({ exception: data });
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOut();

      let response = {
        title: "¡Ocurrió un error!",
        message: error.message,
        success: true,
      };
      this.setState({ response, visibleResponse: true });
    }
  };

  /** Get information document **/
  _getDocument = async (url, download = false) => {
    try {
      Loading();
      const response = await DocumentService.showDocument({
        path: url,
        download: download,
      });
      window.open(response.data.url, "_blank");
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOut();
      console.info(error);
    }
  };

  /** Save document **/
  _uploadDocument = async (file, nameState) => {
    try {
      Loading();
      let formData = new FormData();
      formData.append("file", file.getRawFile());
      const response = await DocumentService.uploadDocument(formData);
      file.url = response.data.url;
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOut();
      console.info(error);
    }
  };

  /** Update information exception **/
  _updateException = async () => {
    let data = {};
    const { approve, attachedDocument, comment, exception, good } = this.state;
    try {
      Loading();
      let id = exception.id;
      if (user.type == 3) {
        data.commentOF = comment;
        data.isApproved = good;
        data.operatorId = user.id;
        data.status = 1;
      } else if (user.type == 2) {
        data.commentAF = approve ? "Aprobación de excepción" : comment;
        data.adminId = user.id;
        data.isApproved = approve;
        data.status = approve ? 2 : 3;
      }
      if (attachedDocument.length > 0) {
        let docType = user.type == 2 ? 6 : user.type == 3 ? 5 : 4;
        data.evidences = attachedDocument.map((evidence, index) => {
          return this._setEvidence(index, evidence, docType);
        });
      }
      const response = await ExceptionService.update(id, data);
      this.setState({ loading: false });
      if (response.data.status == data.status) {
        if (approve) {
          this.setState({
            visibleAlert: false,
            approve: false,
            attachedDocument: [],
            comment: "",
            exception: {},
            good: false,
          });
        } else {
          this.setState({
            visibleObservations: false,
            approve: false,
            attachedDocument: [],
            comment: "",
            exception: {},
            good: false,
          });
        }
        this._getExceptions();
      }
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOut();
      this.setState({ loading: false });

    }
  };

  /** Click Search **/
  handleClickSearch = (e) => {
    //this.setState({loading: !this.state.loading})
    let params = {};
    if (this.state.account !== null && this.state.account.id !== "")
      params.account = this.state.account.id;
    if (this.state.company !== null && this.state.company.id != "")
      params.company = this.state.company.id;
    if (this.state.numberException != "")
      params.folio = this.state.numberException;
    if (this.state.paymentType != null) {
      if (this.state.paymentType.id !== "") {
        params.type = this.state.paymentType.id;
      }
    }
    if (this.state.startDate !== "") params.from = this.state.startDate;
    if (this.state.endDate !== "") params.to = this.state.endDate;
    this._getExceptions(params);
    this.setState({ skip: 0 });
  };

  /** Change input credit line, operator, status **/
  handleChange = (e) => {
    const { props, value, files } = e.target;
    if (value != undefined) {
      if (props.id === "account") {
        this._getCompanies(value.id);
      }
      this.setState({ [props.id]: value });
    } else if (files !== undefined) {
      const { files } = e.target;
      this._uploadDocument(files[0]);
      this.setState({ [props.id]: files });
    } else {
      this.setState({ [props.id]: "" });
    }
  };

  /** Change input filter **/
  handleinputFilterOnChange = (e) => {
    this.setState({
      showRegister: e.value,
      skip: 0,
    });
  };

  /** Change input combo date range **/
  handleChangeRangeDate = (e) => {
    const { value } = e.target;
    this.setState({
      startDate:
        value.start !== null ? moment(value.start).format("YYYY-MM-DD") : "",
      endDate: value.end !== null ? moment(value.end).format("YYYY-MM-DD") : "",
      rangeDate: value,
    });
  };

  /** Handel click show or hide dialog Exceptions**/
  handleShowOrHideDialog = (e) => {
    if (!this.state.visible) {
      let id = e.currentTarget.getAttribute("data");
      this._getException(id);
    }
    this.setState({ visible: !this.state.visible });
  };

  /** Handel click show or hide dialog response backend **/
  handleShowOrHideDialogResponse = (e) => {
    if (!this.state.visibleResponse) {
      let id = e.currentTarget.getAttribute("data");
      this._getException(id);
    }
    this.setState({ visibleResponse: !this.state.visibleResponse });
  };

  /** Hide or show alert dialog **/
  handleShowOrHideAlert = (e) => {
    this.setState({ visibleAlert: !this.state.visibleAlert });
  };

  /** Click show or hide **/
  handleShowOrHideSend = (e) => {
    if (!this.state.visibleSend) {
      let id = e.currentTarget.getAttribute("data");
      this.setState({ exception: { id } });
    }
    this.setState({ visibleObservations: !this.state.visibleObservations });
  };

  /** Click send  **/
  handelSend = async () => {
    this.setState({
      visibleObservations: !this.state.visibleObservations,
      visibleAlert: !this.state.visibleAlert,
    });
  };

  /** Dialog alert with response **/
  handleConfirm = (e) => {
    if (user.type === 2) {
      this._updateException();
      this.setState({ visibleAlert: !this.state.visibleAlert });
    } else if (user.type === 3) {
      this._updateException();
      this.setState({ visibleAlert: !this.state.visibleAlert });
    } else if (user.type === 5) {
      this._getDocument(this.state.exception.adminFinbeEvidence.url, true);
      this.setState({ visibleAlert: !this.state.visibleAlert });
    }
  };

  /** Download file **/
  handleDownload = (e) => {
    this.setState({
      approve: true,
      visible: !this.state.visible,
      visibleAlert: !this.state.visibleAlert,
    });
  };

  /** change page grid **/
  handlePageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  /** Select radio button **/
  handelChangeRadioButton = (e) => {
    this.setState({ good: e.value });
  };

  /** click reject add observations  **/
  handelObservations = (e) => {
    this.setState({
      approve: false,
      visibleObservations: !this.state.visibleObservations,
      visible: !this.state.visible,
    });
  };

  /** Click close observations **/
  handelClose = (e) => {
    this.setState({
      visibleObservations: !this.state.visibleObservations,
    });
  };

  /** Convert bytes to MegaBytes or Kilobytes **/
  _renderFileSize(size) {
    let sizeInt = Number(size);
    return sizeInt > 1000000
      ? sizeInt / 1000000 + " MB"
      : sizeInt / 1000 + " KB";
  }

  /** Get filter by label type user **/
  _renderFilter(user) {
    switch (user.type) {
      case 5:
        return (
          <>
            <Field
              component={InputFilterText}
              dClassName="k-form-field-wrap finbe-form-field-filter"
              id="numberException"
              name="numberException"
              onChange={this.handleChange}
              placeholder="Ingrese N° de excepción"
              value={this.state.numberException}
              wrapperStyle={{ width: "33%", paddingTop: "1.8em" }}
            />
            <Field
              component={InputRangeDatePicker}
              dateRangePickerId="datePickerFilter"
              dateRangePickerName="datePickerFilter"
              dClassName="k-form-field-wrap finbe-form-date"
              dateRangePickerOnChange={this.handleChangeRangeDate}
              dateRangePickerValue={this.state.rangeDate}
              fieldWrapperStyle={{
                width: "66%",
                paddingLeft: "1.3em",
                paddingTop: "0.71em",
              }}
            />
          </>
        );
      case 2:
      case 3:
        return (
          <>
            <Field
              component={InputComboBox}
              data={this.state.accounts}
              dClassName="k-form-field-wrap finbe-form-combo"
              id="account"
              name="account"
              onChange={this.handleChange}
              placeholder="Seleccionar cuenta"
              value={this.state.account}
              wrapperStyle={{ width: "30%", marginTop: "1.05em" }}
            />
            <Field
              component={InputComboBox}
              data={this.state.companies}
              dClassName="k-form-field-wrap finbe-form-combo"
              id="company"
              name="company"
              onChange={this.handleChange}
              placeholder="Seleccionar cliente"
              value={this.state.company}
              wrapperStyle={{
                width: "30%",
                paddingLeft: "1.2em",
                marginTop: "1.35em",
              }}
            />
            {/* <Field
              component={InputComboBox}
              data={paymentType}
              dClassName="k-form-field-wrap finbe-form-combo"
              id="paymentType"
              name="paymentType"
              onChange={this.handleChange}
              placeholder="Seleccionar tipo"
              value={this.state.paymentType}
              wrapperStyle={{
                width: "30%",
                paddingLeft: "1.2em",
                marginTop: "1.35em",
              }}
            /> */}
          </>
        );
      default:
        return null;
    }
  }

  /** Content dialog box by type user **/
  _renderDialogVisualize(user) {
    let { exception } = this.state;
    switch (user.type) {
      case 5:
        return (
          <>
            <LabelWithTextBorder
              bText={exception != null ? exception.comment : ""}
              divStyle={{ width: "637px" }}
              labelStyle={{
                color: "#a2a2a2",
                fontWeight: "bold",
                fontSize: "12px",
              }}
              labelText={"Comentario:"}
              pBorderRadius="0.5em"
              textStyle={{ fontSize: "12px" }}
            />
            <LabelWithTextBorder
              bText={exception != null ? exception.commentAF : ""}
              divStyle={{ width: "637px" }}
              labelStyle={{
                color: "#0c78d6",
                fontWeight: "bold",
                fontSize: "12px",
              }}
              labelText={"Respuesta:"}
              pBorderRadius="0.5em"
              textBold={true}
              textStyle={{ fontSize: "12px" }}
            />
          </>
        );
      case 3:
        return (
          <>
            <label>
              {exception != null
                ? exception.operatorAgency !== undefined
                  ? exception.operatorAgency.name
                  : ""
                : ""}
            </label>
            <LabelWithTextBorder
              bText={
                exception != null
                  ? exception.comment !== undefined
                    ? exception.comment
                    : ""
                  : ""
              }
              divStyle={{ width: "637px" }}
              labelStyle={{
                color: "#a2a2a2",
                fontWeight: "bold",
                fontSize: "12px",
              }}
              labelText={"Comentario:"}
              pBorderRadius="0.5em"
              textStyle={{ fontSize: "12px" }}
            />
            <div style={{ display: "grid", marginBottom: "1.5em" }}>
              <label>Evidencia</label>
              <div style={{ display: "flex" }}>
                <RowTextLinkSize
                  bText={""}
                  aText={
                    exception != null
                      ? exception.operatorAgency !== undefined
                        ? exception.operatorAgency.evidence != null
                          ? exception.operatorAgency.evidence.fileName
                          : ""
                        : null
                      : null
                  }
                  spanText={
                    exception != null
                      ? exception.operatorAgency !== undefined
                        ? exception.operatorAgency.evidence != null
                          ? this._renderFileSize(
                              exception.operatorAgency.evidence.fileSize
                            )
                          : ""
                        : null
                      : null
                  }
                  aOnclick={
                    exception != null
                      ? exception.operatorAgency !== undefined
                        ? exception.operatorAgency.evidence != null
                          ? () => {
                              this._getDocument(
                                exception.operatorAgency.evidence.url
                              );
                            }
                          : null
                        : null
                      : null
                  }
                  aHref={"#"}
                />
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <RowTextLinkSize
              bText={
                exception != null
                  ? exception.operatorAgency !== undefined
                    ? exception.operatorAgency.name
                    : null
                  : null
              }
              aText={
                exception != null
                  ? exception.operatorAgency !== undefined
                    ? exception.operatorAgency.evidence != null
                      ? exception.operatorAgency.evidence.fileName
                      : ""
                    : null
                  : null
              }
              spanText={
                exception != null
                  ? exception.operatorAgency !== undefined
                    ? exception.operatorAgency.evidence != null
                      ? this._renderFileSize(
                          exception.operatorAgency.evidence.fileSize
                        )
                      : ""
                    : null
                  : null
              }
              aOnclick={
                exception != null
                  ? exception.operatorAgency !== undefined
                    ? exception.operatorAgency.evidence != null
                      ? () => {
                          this._getDocument(
                            exception.operatorAgency.evidence.url
                          );
                        }
                      : null
                    : null
                  : null
              }
              aHref={"#"}
            />
            <RowTextLinkSize
              bText={
                exception != null
                  ? exception.operatorFinbe !== undefined
                    ? exception.operatorFinbe.name
                    : null
                  : null
              }
              aText={
                exception != null
                  ? exception.operatorFinbe !== undefined
                    ? exception.operatorFinbe.evidence != null
                      ? exception.operatorFinbe.evidence.fileName
                      : ""
                    : null
                  : null
              }
              spanText={
                exception != null
                  ? exception.operatorFinbe !== undefined
                    ? exception.operatorFinbe.evidence != null
                      ? this._renderFileSize(
                          exception.operatorFinbe.evidence.fileSize
                        )
                      : ""
                    : null
                  : null
              }
              aHref={"#"}
              aOnclick={
                exception != null
                  ? exception.operatorFinbe !== undefined
                    ? exception.operatorFinbe.evidence != null
                      ? () => {
                          this._getDocument(
                            exception.operatorFinbe.evidence.url
                          );
                        }
                      : null
                    : null
                  : null
              }
            />
            <LabelWithTextBorder
              bText={
                exception != null
                  ? exception.commentOF !== undefined
                    ? exception.commentOF
                    : ""
                  : ""
              }
              divStyle={{ width: "637px" }}
              labelStyle={{
                color: "#a2a2a2",
                fontWeight: "bold",
                fontSize: "12px",
              }}
              labelText={"Comentario del operador FINBE:"}
              pBorderRadius="0.5em"
              textStyle={{ fontSize: "12px" }}
            />
            {exception != null ? (
              exception.isApproved ? (
                <RowIconText
                  bText={"Visto bueno comercialización"}
                  spanIcon={"visto_bueno"}
                />
              ) : null
            ) : null}
          </>
        );
      default:
        return null;
    }
  }

  /** Set object evidence for list evidences of units **/
  _setEvidence(index, evidence, type) {
    return {
      extension: String(evidence.extension),
      fileName: String(evidence.name),
      fileSize: String(evidence.size),
      type: String(type),
      url: evidence.url,
    };
  }

  render() {
    return (
      <UserData>
        {({ user }) => {
          return (
            <div className="finbe-app-container">
              <div className="finbe-app-section-top">
                <FilterBox
                  buttonSearchOnClick={this.handleClickSearch}
                  isMoreFilter={this.state.isMoreFilter}
                  inputDropDownValue={this.state.showRegister}
                  inputFilterOnChange={this.handleinputFilterOnChange}
                >
                  <div style={{ width: "100%" }}>
                    <MoreFilterBox>
                      <Form
                        render={(formRenderProps) => (
                          <FormElement style={{ display: "flex" }}>
                            {this._renderFilter(user)}
                          </FormElement>
                        )}
                      />
                    </MoreFilterBox>
                  </div>
                </FilterBox>
              </div>
              <div>
                <Grid
                  className="finbe-table"
                  // data={this.state.exceptions.slice(
                  //   this.state.skip,
                  //   this.state.showRegister.text + this.state.skip
                  // )}
                  data={process(this.state.exceptions, {
                    take: +this.state.showRegister.text,
                    skip: this.state.skip,
                    sort: this.state.sort,
                  })}
                  onDataStateChange={(e) => {
                    this.setState({
                      sort: e.data.sort,
                      take: e.data.take,
                      skip: e.data.skip,
                    });
                  }}
                  onPageChange={this.handelPageChange}
                  pageable={{
                    info: false,
                  }}
                  skip={this.state.skip}
                  sortable
                  sort={this.state.sort}
                  take={this.state.showRegister.text}
                  total={this.state.exceptions.length}
                  resizable
                  onSortChange={(e) => {
                    this.setState({
                      sort: e.sort,
                    });
                  }}
                >
                  {user.type === 5 ? (
                    <Column
                      field="numberException"
                      title="N° de excepción"
                      cell={(props) => (
                        <td>
                          {/* {props.dataItem.read ? (
                            ""
                          ) : (
                            <span className="icon circulo-rojo-notificacion"></span>
                          )}{" "} */}
                          {props.dataItem.folio}
                        </td>
                      )}
                    />
                  ) : (
                    <Column field="nameCustomer" title="Cliente" />
                  )}
                  <Column field="comment" title="Comentario" />
                  {/* {user.type !== 5 ? (
                    <Column field="type" title="Tipo" />
                  ) : null} */}
                  <Column
                    field="date"
                    title="Fecha"
                    filter="date"
                    format="{0:dd/MM/yyyy}"
                  />
                  <Column
                    title="Acciones"
                    cell={(props) => (
                      <td>
                        <div style={{ display: "flex" }}>
                          <ButtonAction
                            title={"Visualizar"}
                            data={props.dataItem.id}
                            icon=" icon visualizar"
                            look="bare"
                            primary={false}
                            onClick={this.handleShowOrHideDialog}
                          />
                          {user.type === 3 ? (
                            <ButtonAction
                              title={"Enviar"}
                              data={props.dataItem.id}
                              icon=" icon enviar"
                              look="bare"
                              primary={false}
                              onClick={this.handleShowOrHideSend}
                            />
                          ) : null}
                        </div>
                      </td>
                    )}
                  />
                  <Column
                    field="status"
                    title="Estatus"
                    cell={(props) => (
                      <td style={{ paddingBottom: "0em" }}>
                        <span
                          className={
                            " icon " +
                            (props.dataItem.status == 2
                              ? "aprobado"
                              : "desaprobado")
                          }
                        />
                      </td>
                    )}
                  />
                </Grid>
                <DialogBox
                  buttonDangerText={"Rechazar"}
                  buttonSuccesIcon={user.type == 2 ? "check" : ""}
                  buttonSuccessText={user.type == 2 ? "Aprobar" : "Descargar"}
                  handelAccept={this.handleDownload}
                  handelCancel={this.handelObservations}
                  handelClose={this.handleShowOrHideDialog}
                  title={
                    "Visualizar " + (user.type == 5 ? "respuesta" : "solicitud")
                  }
                  visible={this.state.visible}
                  visibleButtons={
                    user.type != 3
                      ? user.type == 5
                        ? this.state.exception
                          ? this.state.exception.status === 3 &&
                            this.state.exception.adminFinbeEvidence != null
                            ? true
                            : false
                          : false
                        : true
                      : false
                  }
                  visibleCancelButton={user.type == 2}
                >
                  {this._renderDialogVisualize(user)}
                </DialogBox>

                <DialogBox
                  buttonSuccessText={"Aceptar"}
                  centerButtons={true}
                  handelAccept={this.handleConfirm}
                  handelClose={this.handleShowOrHideAlert}
                  title={"Confirmación"}
                  visible={this.state.visibleAlert}
                  visibleCancelButton={true}
                >
                  {user.type == 2
                    ? `¿Está seguro de ${
                        this.state.approve ? "aprobar" : "rechazar"
                      } la solicitud de excepción?`
                    : user.type == 5
                    ? "¿Está seguro de descargar?"
                    : "¿Está seguro de enviar las observaciones al Administrador FinBe?"}
                </DialogBox>

                <DialogBox
                  buttonSuccessText={"Enviar"}
                  handelAccept={this.handelSend}
                  handelClose={this.handelClose}
                  title={"Observaciones"}
                  visible={this.state.visibleObservations}
                >
                  <div style={{ width: "637px" }}>
                    <Form
                      render={(formRenderProps) => (
                        <FormElement>
                          <Field
                            component={InputText}
                            dClassName="k-form-field-wrap finbe-form-field-filter"
                            id="comment"
                            label="* Comentario"
                            name="comment"
                            onChange={this.handleChange}
                            placeholder={
                              user.type == 3
                                ? "Comentario para la solicitud"
                                : "Agregar comentario"
                            }
                            value={this.state.comment}
                            wrapperStyle={{ width: "60%" }}
                          />
                          {user.type == 3 && (
                            <div style={{ margin: "0.5em", display: "flex" }}>
                              <div>Visto bueno</div>
                              <div style={{ paddingLeft: "1em" }}>
                                <RadioButton
                                  name="group1"
                                  value={true}
                                  checked={this.state.good === true}
                                  label="Si"
                                  onChange={this.handelChangeRadioButton}
                                />
                                <RadioButton
                                  name="group1"
                                  value={false}
                                  checked={this.state.good === false}
                                  label="No"
                                  onChange={this.handelChangeRadioButton}
                                />
                              </div>
                            </div>
                          )}
                          <div style={{ margin: "0.4em" }}>
                            <InputFile
                              fieldWrapperStyle={{ width: "100%" }}
                              uploadAllowedExtensions={null}
                              uploadAutoUpload={false}
                              uploadDefaultFiles={this.state.attachedDocument}
                              uploadId="attachedDocument"
                              uploadLabel="Archivo"
                              uploadOnAdd={this.handleChange}
                            />
                          </div>
                        </FormElement>
                      )}
                    />
                  </div>
                </DialogBox>

                <DialogBox
                  handelClose={this.handleShowOrHideDialogResponse}
                  title={this.state.response.title}
                  visible={this.state.visibleResponse}
                  visibleButtons={false}
                >
                  {this.state.response.message}
                </DialogBox>
              </div>
            </div>
          );
        }}
      </UserData>
    );
  }
}

export default Exceptions;
