import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import UserService from "../../Services/UserServices";
import RoleService from "../../Services/RoleService";
import AgencieService from "../../Services/AgencyService";
import AccountService from "../../Services/AccountService";
import UserButtons from "./userButtons";
import { FormAdmin } from "./Forms/FormAdmin";
import { FormRL } from "./Forms/FormRL";
import { FormOF } from "./Forms/FormOF";
import { useUsuario } from "../../Context/UserContext";
import qs from "qs";
import { DialogBox } from "../Sections/Dialogs";
import CompanyService from "../../Services/CompanyService";
import { EMAIL_REGEX, PHONE_REGEX } from "../../Utils/Validators/regex";
import useLoader from "../../hooks/useLoader";
const CreateEditUser = (props) => {
  let history = useHistory();
  const { usuario, signOutCognito } = useUsuario();

  let initialState = {
    id: null,
    firstname: "",
    lastname: "",
    email: "",
    additionalEmails: null,
    phone: "",
    type: 0,
    roleId: null,
    accountId: null,
    companyId: null,
    agencyId: null,
    registerBy: null,
  };
  const [user, setUser] = useState(initialState);
  const [roles, setRoles] = useState([]);
  const [editing, setEditing] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [dataResponse, setDataResponse] = useState({
    title: "",
    message: "",
    success: "",
  });
  const [visible, setVisible] = useState(false);
  const Loading = useLoader();
  const handleDialogResponse = () => {
    if (dataResponse.success) {
      history.push("/usuarios");
    } else {
      setVisible(!visible);
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;


    if (typeof value !== "object") {
      setUser({ ...user, [name]: value });
    } else {
      setUser({ ...user, [name]: value !== null ? value.id : "" });
      if (name === "accountId" && value !== null) getCompanies(value.id);
    }
  };

  const onSave = async () => {
    setVisibleConfirm(false);
    let success = true;
    let message = "";
    try {
      Loading();
      let _user = user;
      // let hasAdditionalEmails=false
      _user.registerBy = usuario.id;

      if (_user.additionalEmails) {
        let emails = _user.additionalEmails.split(";");

        emails.map((item) => {
          if (!EMAIL_REGEX.test(item)) {
            throw new Error(
              `Alguno de los correos adicionales no cumple el formato [${item}]`
            );
          }
        });
      }
      if (_user.phone) {
        if (!PHONE_REGEX.test(_user.phone)) {
          throw new Error("El teléfono ingresado no cumple con el formato");
        }
      }
      if (editing) {
        const { id, legalId, isArchived, isActive, createdAt, awsId, ...rest } =
          _user;
        // rest.additionalEmails=hasAdditionalEmails?rest.additionalEmails:null;
        const { data } = await UserService.update(id, rest);
        message =
          "La información del usuario [" +
          data.firstname +
          " " +
          data.lastname +
          "] se ha actualizado";
      } else {
        // Super usuario
        if (usuario.type === 1) _user.type = 2; //Admin finbe

        //Admin Finbe
        if (usuario.type === 2) _user.type = 3; //Operedor finbe

        //Operador Finbe
        if (usuario.type === 3) {
          
          _user.type = roles.find(e=>e.id==user.roleId).type;

        } //operador agencia

        //Representante legal
        if (usuario.type === 4) {
          _user.type = 5; //operador agencia
          _user.accountId = usuario.account.id;
          _user.companyId = usuario.company.id;
        }

        const { id, ...rest } = _user;
        // rest.additionalEmails=hasAdditionalEmails?rest.additionalEmails:null;

        const { data } = await UserService.create(rest);
        message =
          "El usuario [" +
          data.firstname +
          " " +
          data.lastname +
          "] se ha guardado";
      }
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOutCognito();
      success = false;
      message = error.response?error.response.data.message:error.message;

    }
    setDataResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
    });
    setVisible(!visible);
  };

  const getUpdate = async (id) => {
    try {
      Loading();
      const res = await UserService.getById(id);
      setUser(res.data);
      if (usuario.type === 3) getCompanies(res.data.accountId);
      setEditing(!editing);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();

    }
  };

  const getRoles = async () => {
    let data = {};
    switch (usuario.type) {
      case 1:
        data.type = [2];
        break;
      case 2:
        data.type = [3];
        break;
      case 3:
        data.type = [4, 5];
        break;
      case 4:
        data.type = [5];
        break;
    }
    try {
      Loading();
      const res = await RoleService.getAll({
        params: data,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      let list = res.data.map((x) => {
        return { id: x.id, text: x.name, type:x.type };
      });
      setRoles(list);
      if(list.length===1){
        setUser(prev=>({...prev,roleId:list[0].id}));
      }
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();

    }
  };

  const getAgencies = async (companyId) => {
    try {
      Loading();
      const { data } = await CompanyService.getAgencies(companyId);
      let list = data.map((x) => {
        return { id: x.id, text: x.name };
      });
      setAgencies(list);
      if(list.length===1){
        setUser(prev=>({...prev,agencyId:list[0].id}));
      }
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();

    }
  };

  const getAccounts = async () => {
    try {
      Loading();
      const { data } = await AccountService.getAll();
      let list = data.map((x) => {
        return { id: x.id, text: x.name };
      });
      setAccounts(list);
      if(list.length===1){
        setUser(prev=>({...prev,accountId:list[0].id}));
        await getCompanies(list[0].id);
      }
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();

    }
  };

  const getCompanies = async (id) => {
    try {
      Loading();
      const res = await AccountService.getCompanies(id);
      let _companies = res.data.map((x) => {
        return { id: x.id, text: x.name };
      });
      setCompanies(_companies);
      if(_companies.length===1){
        setUser(prev=>({...prev,companyId:_companies[0].id}));
        // await getAgencies(_companies[0].id);
      }
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();

    }
  };
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [dataConfirm, setDataConfirm] = useState({});
  const openDialog = () => {
    setVisibleConfirm(true);

    if (editing) {
      setDataConfirm({
        title: "Edición de usuario",
        message: "¿Desea guardar los cambios?",
      });
    } else {
      setDataConfirm({
        title: "Creación de usuario",
        message: "¿Estás seguro de crear este usuario?",
      });
    }
  };
  useEffect(() => {
    if (usuario) {
      getRoles();

      if (usuario.type == 3) {
        getAccounts({ params: { assigned: usuario.id } });
      }

      if (usuario.type == 4) getAgencies(usuario.companyId);

      if (props.match.params.id != undefined) {
        getUpdate(props.match.params.id);
      }
    }
  }, [props.match.params.id, usuario]);

  return (
    <div className="finbe-app-container">
      {usuario && (usuario.type === 1 || usuario.type === 2) && (
        <FormAdmin
          user={user}
          handleChange={handleChange}
          roles={roles}
          editing={editing}
        />
      )}
      {usuario && usuario.type === 3 && (
        <FormOF
          companies={companies}
          user={user}
          handleChange={handleChange}
          roles={roles}
          accounts={accounts}
          editing={editing}
        />
      )}
      {usuario && usuario.type === 4 && (
        <FormRL
          user={user}
          handleChange={handleChange}
          roles={roles}
          agencies={agencies}
          nameAccount={usuario.account.name}
          editing={editing}
        />
      )}
      <UserButtons onClick={editing ? onSave : openDialog} />
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={dataResponse.title}
        visible={visible}
      >
        {dataResponse.message}
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => {
          setVisibleConfirm(false);
        }}
        handelCancel={() => setVisibleConfirm(false)}
        handelAccept={onSave}
        title={dataConfirm.title}
        visible={visibleConfirm}
        visibleCancelButton={true}
      >
        {dataConfirm.message}
      </DialogBox>
    </div>
  );
};

export default CreateEditUser;
