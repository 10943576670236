/**
 * @author: Alexander Chi
 * @description: Component provisions stadistics
 * @date: 19/Nov/2020
 **/
import React, { Component } from "react";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import { InputRangeDatePicker, InputComboBox } from "../Sections/Components/Inputs";
import { FilterBox } from "../Sections/Filters";
import moment from "moment";
import { useUsuario } from "../../Context/UserContext";
import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
    exportVisual
} from "@progress/kendo-react-charts";
import { exportPDF } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import ReportService from "../../Services/ReportService";
import AccountService from "../../Services/AccountService";
import FinbeService from "../../Services/Finbe/FinbeService";
import { DialogBox } from "../Sections/Dialogs";
import useLoader from "../../hooks/useLoader";
let user = null;
let signOut = null;
let Loading=useLoader();
 
/** Get user cotext hook **/
function UserData({ children }) {
  const { usuario,signOutCognito } = useUsuario();
  user = usuario;
  signOut = signOutCognito;

  return children({ user: usuario });
}

/** Main component ProvisionsStadistics **/
export class ProvisionsStadistics extends Component {
  /** Varial for export document pdf**/
  _chart;

  constructor(props) {
    super(props);
    this.state = {
      creditLine: null,
      creditLines: [],
      customer: null,
      customers: [],
      endDate: "",
      graph: { categories: [], series: [], },
      loading: true,
      rangeDate: null,
      response: { title:  "", message: "", success: false,},
      startDate: "",
      visibleCancelButton: false,
      visibleConfirm: false,
    };
  }

  /** After render **/
  componentDidMount() {
    this._getCompaniesByAssingned(user.id);
  }

  /** Get information stadistics **/
  _getStadistics = async (params) =>{
    let response = {};
    try {
      Loading();
      response = await ReportService.stadistics({params});
      this.setState({graph: response.data})
      Loading();
    } catch (error) {
      Loading();
      if (error.response.status == 401) signOut();
      console.info(error.response.data.message)
      this.setState({
        response: {
          title:  "Ocurrió un error",
          message: error.response.data.message,
          success: false,
        },
        visibleResponse: true,
        // loading: false
      });
    }
  }

  /** Get all clientes **/
  _getCompaniesByAssingned = async (userId) => {
    let customers = [];
    try {
      Loading();
      const res = await AccountService.getCompaniesByAssingned(userId);
      customers = res.data.map((company, index) => {
        return {
          accountId: company.accountId,
          id: company.id,
          legal: company.legal,
          text: company.name,
          clientId: company.clientId
        };
      });
      this.setState({ customers });
      this.setState({
        response: {
          title:  "Búsqueda",
          message: "Ingrese información de búsqueda",
          success: true,
        },
        visibleResponse: true,
      })
      Loading();
    } catch (error) {
      Loading();
      if (error.response.status == 401) signOut();

    }
  };

  /** Get all credit lines of customer **/
  _getCreditLines = async (customerId) => {
    let creditLines = [];
    // this.setState({ loading: true });
    try {
      Loading();
      const res = await FinbeService.getConfigurationCreditLine({
        params: { numeroCliente: customerId },
      });
      creditLines = res.data.map((line, index) => {
        let deadlines = line.plazos.split("|")
        deadlines.sort(function(a, b){return a-b})
        deadlines = deadlines.map((deadline, index) => {
          return {
            id: index,
            text: "Plazo - " + deadline,
            value: deadline
          };
        });
        return {
          id: line.lineaCredito,
          text: line.lineaCredito,
          value: line.lineaCredito,
          availableBalance: line.disponible,
          deadlines: deadlines
        };
      });
      this.setState({ creditLines, creditLine: null });
      Loading();
    } catch (error) {
      Loading();
      this.setState({
        response: {
          title:  "Ocurrió un error API externa",
          message: "<<ConfiguracionClientesDispLinea>>: " + error.response.data,
          success: false,
        },
        visibleResponse: true,
        // loading: false
      });
    }
  };

  /** Change input credit line, operator, status **/
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if(name === "customer"){
      if(value != null){
        this._getCreditLines(value.clientId);
      }else{
        this.setState({ creditLines: [], creditLine: null });
      }
    }
  };

  /** Change input combo date range **/
  handleChangeRangeDate = (e) => {
    const { value } = e.target;
    let start = moment(value.start);
    let end = moment(value.end);
    this.setState({
      startDate: value.start!==null?moment(value.start).format("YYYY-MM-DD"):"",
      endDate: value.end!==null?moment(value.end).format("YYYY-MM-DD"):"",
      rangeDate: value,
    });
  };
  openDialog=()=>{
    this.setState({
      visibleConfirm:!this.state.visibleConfirm
    })
  };
  /** Create file for download after **/
  handleDownload = (e) => {
    const chartVisual = exportVisual(this._chart);
    if (chartVisual) {
      exportPDF(chartVisual, {
        /*paperSize: "A4",*/
        landscape: true
      }).then(dataURI => {
        saveAs(dataURI, "Estadísticas de disposiciones (" + moment().format("DD-MM-YYYY") + ").pdf")
        this.setState({
          visibleConfirm:!this.state.visibleConfirm,
          response: {
            title: "Descarga completa",
            message: "El informe se descargo correctamente",
            success: false,
          },
          visibleResponse:!this.state.visibleResponse
        })
      });
    }
  }

  /** filter information **/
  handleClickSearch = (e) =>{
    let params = {};
    this.setState({ loading: true });
    const {
      customer,
      endDate,
      creditLine,
      startDate
    } = this.state;
    if(creditLine != null){ params.creditLine = creditLine.id; }
    if(startDate !== ""){ params.from = startDate; }
    if(endDate !== ""){ params.to = endDate; }
    if(customer != null){ params.clientId  = customer.id }
    this._getStadistics(params);
  };

  handleDialogResponse = (e) => {
    this.setState({ visibleResponse: !this.state.visibleResponse });
  };

  render() {
    return (
      <UserData>
        {({ user }) => {
          return (
            <div>
              <FilterBox
                buttonDownloadReportOnClick = {this.openDialog}
                buttonSearchOnClick={this.handleClickSearch}
                inputDropDownValue={this.state.showRegister}
                inputFilterOnChange={this.handleChange}
                isDownloadReport = {true}
                isMoreFilter={false}
                visibleDropDown = {false}
              >
                <div style={{ width: "100%" }}>
                  <Form
                    render={(formRenderProps) => (
                      <FormElement style={{ display: "flex" }}>
                      <Field
                        component={InputComboBox}
                        data={this.state.customers}
                        dClassName="k-form-field-wrap finbe-form-combo"
                        id="customer"
                        name="customer"
                        onChange={this.handleChange}
                        placeholder="Seleccionar una cliente"
                        value={this.state.customer}
                        wrapperStyle={{width: "25%", paddingTop: "1.8em"}}
                      />
                      <Field
                        component={InputComboBox}
                        data={this.state.creditLines}
                        dClassName="k-form-field-wrap finbe-form-combo"
                        id="creditLine"
                        name="creditLine"
                        onChange={this.handleChange}
                        placeholder="Seleccionar una línea"
                        value={this.state.creditLine}
                        wrapperStyle={{width: "25%", paddingTop: "1.8em"}}
                      />
                      <Field
                        component={InputRangeDatePicker}
                        dateRangePickerId="datePickerFilter"
                        dateRangePickerName="datePickerFilter"
                        dClassName="k-form-field-wrap finbe-form-date"
                        dateRangePickerOnChange={this.handleChangeRangeDate}
                        dateRangePickerValue = {this.state.rangeDate}
                        fieldWrapperStyle={{width: "50%", paddingLeft: "0em", paddingTop: "0.7em"}}
                      />
                      </FormElement>
                    )}
                  />
                </div>
              </FilterBox>
              <div>

              </div>
              <div>
                <Chart ref={(cmp) => this._chart = cmp}>
                  <ChartTitle text={"Gráfica de disposiciones promedio en un lapso de 12 meses"} />
                  <ChartLegend position="bottom" orientation="horizontal" />
                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem categories={this.state.graph.categories}/>
                  </ChartCategoryAxis>
                  <ChartSeries>
                    {this.state.graph.series.map((item, index) => (
                      <ChartSeriesItem
                        key={index}
                        type="line"
                        tooltip={{ visible: true }}
                        data={item.data}
                        name={item.name}
                      />
                    ))}
                  </ChartSeries>
                </Chart>
              </div>
              <DialogBox
                buttonSuccessText={"Aceptar"}
                handelClose={this.handleDialogResponse}
                handelAccept={this.handleDialogResponse}
                title={this.state.response.title}
                visible={this.state.visibleResponse}
              >
                {this.state.response.message}
              </DialogBox>
              <DialogBox
                buttonSuccessText={"Aceptar"}
                handelClose={()=>{this.setState({visibleConfirm:!this.state.visibleConfirm})}}
                handelCancel={()=>{this.setState({visibleConfirm:!this.state.visibleConfirm})}}
                handelAccept={this.handleDownload}
                title={'Descargar informe'}
                visible={this.state.visibleConfirm}
                visibleCancelButton={true}

              >
                {'¿Seguro que desea descargar el informe?'}
              </DialogBox>
            </div>
          );
        }}
      </UserData>
    );
  }
}

export default ProvisionsStadistics;

/** default props file **/
File.ProvisionsStadistics = {};
