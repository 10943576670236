import axios from "axios";
import dotenv from 'dotenv'

dotenv.config()


export default axios.create({
  baseURL: process.env.REACT_APP_API_VALIDATION_URL || "https://finbe-vehicle-api-stage.herokuapp.com",
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Authorization": process.env.REACT_APP_API_VALIDATION_TOKEN || '',
  },
});
