import React, { useState, useEffect } from "react";

import { FilterBox, MoreFilterBox } from "../Sections/Filters";

import {
  InputFilterText,
  InputRangeDatePicker,
  InputComboBox,
} from "../Sections/Components/Inputs";

import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

import { process } from "@progress/kendo-data-query";
import moment from "moment";
import ReportService from "../../Services/ReportService";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { DialogBox } from "../Sections/Dialogs";
import { useHistory } from "react-router-dom";
const Validations = (props) => {
  const validaciones = [
    { id: 1, text: "REPUVE" },
    { id: 2, text: "TransUnion" },
    { id: 3, text: "AX" },
  ];
  const initialFilters = {
    folio: "",
    model: "",
    type: 0,
    datePickerFilter: { start: null, end: null },
  };

  const [data, setData] = useState([]);

  const [dataFilters, setDataFilters] = useState(initialFilters);
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [dataState, setDataState] = useState({
    sort: [{ field: "createdAt", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });
  const [isDown, setIsDown] = useState(true);
  const [visibleResponse, setVisibleResponse] = useState(false);

  const handleClickMoreFilter = async () => {
    setIsDown(!isDown);
  };

  const onChange = (event) => {
    const { name, value } = event.target;

    if (event.value !== undefined) {
      if (event.value !== null && event.value.start) {
        setDataFilters({
          ...dataFilters,
          ["datePickerFilter"]: event.target.value,
        });
      } else if (typeof value === "object") {
        setDataFilters({
          ...dataFilters,
          [name]: value !== null ? value.id : "",
        });
      } else {
        setDataFilters({ ...dataFilters, [name]: value });
      }
    }
  };

  const getHistoricalValidations = async (params) => {
    try {
      const { data } = await ReportService.getHistoricalValidations(params);
      setData(data);
    } catch (e) {
    }
  };

  const handleClickSearch = async () => {
    let data = {};
    if (dataFilters.folio != "") data.folio = dataFilters.folio;
    if (dataFilters.model != "") data.mode = dataFilters.model;
    if (dataFilters.type != "") data.type = dataFilters.type;
    if (dataFilters.datePickerFilter.start !== null)
      data.from = moment(dataFilters.datePickerFilter.start).format(
        "YYYY-MM-DD"
      );
    if (dataFilters.datePickerFilter.end !== null)
      data.to = moment(dataFilters.datePickerFilter.end).format("YYYY-MM-DD");

    getHistoricalValidations({ params: data });
  };
  const handleShowRegisterChange = (e) => {
    const { value } = e.target;
    setShowRegister(value);
    setDataState({ ...dataState, ["take"]: value.text });
  };

  let _export;
  const downloadReport = async () => {
    setVisibleConfirm(!visibleConfirm);
    _export.save(process(data, dataState));
    setResponseData({
      title: "Descarga completa",
      message: "El informe se descargo correctamente",
      success: false,
    })
    setVisibleResponse(!visibleResponse);
  };
  const getStatus = (validation) => {
    return validation ? "aprobado" : "desaprobado";
  };
  const [responseData, setResponseData] = useState({
    title: "Búsqueda",
    message: "Ingrese información de busqueda",
    success: false,
  });
  const handleDialogResponse = (e) => {
    // if (responseData.success) {
    //   setVisibleResponse(!visibleResponse);
    //   useHistory.push("/disposiciones-revision");
    // } else {
      setVisibleResponse(!visibleResponse);
    // }
  };
  const [visibleConfirm,setVisibleConfirm]=useState(false)
  const openDialog = () => {
    setVisibleConfirm(true);
  };
  useEffect(() => {
    setVisibleResponse(!visibleResponse);
  }, []);

  return (
    <div className="finbe-app-container">
      <div className="finbe-app-section-top">
        <FilterBox
          buttonDownloadReportOnClick={openDialog}
          buttonMoreFilterOnClick={handleClickMoreFilter}
          buttonSearchOnClick={handleClickSearch}
          inputDropDownValue={showRegister}
          inputFilterOnChange={handleShowRegisterChange}
          isDown={isDown}
          isDownloadReport={true}
        >
          <Form
            render={(formRenderProps) => (
              <FormElement style={{ display: "flex" }}>
                <Field
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="folio"
                  nombre="folio"
                  onChange={onChange}
                  placeholder="Ingresar N° de disposición"
                  value={dataFilters.folio}
                  wrapperStyle={{ width: "100%", margin: "1em" }}
                  component={InputFilterText}
                />
              </FormElement>
            )}
          />
        </FilterBox>
      </div>

      <div style={{ color: "#b0ada6" }}>
        {isDown ? null : (
          <div
            style={{
              width: "95%",
              margin: "1em 1em 0 1em",
              background: "#e4e3e0",
            }}
          >
            <MoreFilterBox>
              <Form
                render={(formRenderProps) => (
                  <FormElement style={{ display: "flex" }}>
                    <Field
                      dClassName="k-form-field-wrap finbe-form-field-filter"
                      id="model"
                      nombre="model"
                      onChange={onChange}
                      placeholder="Ingresar modelo"
                      value={dataFilters.model}
                      wrapperStyle={{ width: "25%", margin: "1em" }}
                      component={InputFilterText}
                    />

                    <Field
                      dClassName="k-form-field-wrap finbe-form-combo"
                      id="type"
                      name="type"
                      data={validaciones}
                      onChange={onChange}
                      placeholder="Seleccionar Validación"
                      value={validaciones.find((t) => t.id == dataFilters.type)}
                      wrapperStyle={{ width: "25%", margin: "1em" }}
                      component={InputComboBox}
                    />

                    <Field
                      dClassName="k-form-field-wrap finbe-form-date"
                      dateRangePickerId="datePickerFilter"
                      dateRangePickerName="datePickerFilter"
                      dateRangePickerOnChange={onChange}
                      fieldWrapperStyle={{ width: "50%", paddingLeft: "1.2em" }}
                      defaultValue={{
                        start: dataFilters.datePickerFilter.start,
                        end: dataFilters.datePickerFilter.end,
                      }}
                      dateRangePickerValue={dataFilters.datePickerFilter}
                      component={InputRangeDatePicker}
                    />
                  </FormElement>
                )}
              />
            </MoreFilterBox>
          </div>
        )}
      </div>

      <div>
        <ExcelExport
          // data={process(data, dataState)}
          fileName={
            "Validaciones periódicas_" +
            new Date().getTime().toString() +
            ".xlsx"
          }
          ref={(exporter) => (_export = exporter)}
        >
          <Grid
            className="finbe-table"
            data={process(data, dataState)}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.data);
            }}
            sortable
            pageable={{
              info: false,
            }}
            skip={dataState.skip}
            take={dataState.take}
            resizable
          >
            <Column field="folio" title="N° de Disposición" resizable={true} />

            <Column field="model" title="Modelo" resizable={true} />

            <Column field="vinNumber" title="Vin" resizable={true} />

            <Column field="type" title="Validación" resizable={true} />

            <Column
              field="response"
              title="Respuesta"
              resizable={true}
              cell={(props) => (
                <td>
                  <span
                    className={" icon " + getStatus(props.dataItem.response)}
                  />
                </td>
              )}
            />

            <Column field="createdAt" title="Fecha" resizable={true} />
          </Grid>
        </ExcelExport>
      </div>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={responseData.title}
        visible={visibleResponse}
      >
        {responseData.message}
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={()=>{setVisibleConfirm(false)}}
        handelCancel={() => setVisibleConfirm(false)}
        handelAccept={downloadReport}
        title={'Descargar informe'}
        visible={visibleConfirm}
        visibleCancelButton={true}

      >
        {'¿Seguro que desea descargar el informe?'}
      </DialogBox>
    </div>
  );
};

export default Validations;
