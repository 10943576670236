import React, { useEffect, useState } from "react";
import {
  Scheduler,
  AgendaView,
  TimelineView,
  DayView,
  WeekView,
  MonthView,
} from "@progress/kendo-react-scheduler";
import { useUsuario } from "../../Context/UserContext";
import AuditService from "../../Services/AuditService";
import moment from "moment";
import useLoader from "../../hooks/useLoader";
const Schedule = (props) => {
  const { usuario, signOutCognito } = useUsuario();
  const [audits, setAudits] = useState([]);

  const currentYear = new Date().getFullYear();
  const parseAdjust = (eventDate) => {
    const date = new Date(eventDate);
    date.setFullYear(currentYear);
    return date;
  };
  const Loading = useLoader();
  const getAudits = async (data) => {
    try {
      Loading();
      const res = await AuditService.getAll({ params: data });
      const sampleData = res.data.map((dataItem) => ({
        id: dataItem.id,
        start: moment(dataItem.initialDate, "DD/MM/YYYY HH:mm").toDate(),
        startTimezone: null,
        end:
          dataItem.initialDate == dataItem.endDate
            ? moment(dataItem.endDate, "DD/MM/YYYY HH:mm")
                .add(1, "days")
                .toDate()
            : moment(dataItem.endDate, "DD/MM/YYYY HH:mm").toDate(),
        endTimezone: null,
        isAllDay: true,
        title: dataItem.folio + "-" + dataItem.auditor,
        description: dataItem.description,
        // recurrenceRule: dataItem.RecurrenceRule,
        // recurrenceId: dataItem.RecurrenceID,
        // recurrenceExceptions: dataItem.RecurrenceException,

        // roomId: dataItem.RoomID,
        // ownerID: dataItem.OwnerID,
        // personId: dataItem.OwnerID,
      }));
      setAudits(sampleData);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };
  const displayDate = new Date();

  useEffect(() => {
    if (usuario) {
      getAudits();
    }
  }, [usuario]);

  return (
    <Scheduler data={audits} defaultDate={displayDate}>
      {/* <AgendaView />
      <TimelineView />
    <DayView />
    <WeekView /> 
    */}
      <MonthView />
    </Scheduler>
  );
};
export default Schedule;
