/**
 * @author: Alexander Chi
 * @description: Component create step Company
 * @date: 24/sept/2020
 **/
import React, { Component } from 'react';
import { StepBox } from '../../Sections/Steps';
import { Form, FormElement, Field } from '@progress/kendo-react-form';
import {InputComboBox, InputText, InputFile, InputFileH} from '../../Sections/Components/Inputs';
import { RadioButton } from "@progress/kendo-react-inputs";

/** Main step company **/
class Company extends Component {

  constructor (props) {
    super(props);
    this.state = { };
  };

  render () {
    const {
      agencies,
      agency,
      attachedDocument,
      companies,
      company,
      companyTitle,
      description,
      handleChange,
      handleChangeFile,
      handleDeleteFile,
      fileName,
      includeAll,
      legalRepresentative,
      legalRepresentatives,
      reviewUnits,
      styleContainer,
      refFile,

    } = this.props;
    return (
    <div className="finbe-app-container" style = {styleContainer} >
      <StepBox
        title = {companyTitle ?? 'Empresa' }
      >
      <Form
        render={(formRenderProps) => (
          <FormElement>
          <div style = {{display: 'flex'}}>
            <div style = {{width: '50%'}}>
              <Field
                component = {InputComboBox}
                data = {companies}
                dClassName='k-form-field-wrap finbe-form-combo'
                disabled = {true}
                id = 'company'
                label = '* Empresa'
                name = 'company'
                onChange = {handleChange}
                placeholder = 'Seleccione empresa'
                required = {true}
                validityStyles = {true}
                value = {company}
                wrapperStyle = {{ width: '90%' }}
              />
            </div>
            <div style = {{width: '50%'}}>
              <Field
                component = {InputComboBox}
                data = {agencies}
                dClassName='k-form-field-wrap finbe-form-combo'
                disabled = {false}
                id = 'agency'
                label = '* Sucursal'
                name = 'agency'
                onChange = {handleChange}
                placeholder = 'Seleccione sucursal'
                required = {true}
                validityStyles = {true}
                value = {agency}
                wrapperStyle = {{ width: '90%' }}
              />
            </div>
          </div>
          <div style = {{display: 'flex', marginTop: '1em'}}>
            <div style = {{width: '50%'}}>
              <Field
                component = {InputComboBox}
                data = {legalRepresentatives}
                dClassName='k-form-field-wrap finbe-form-combo'
                disabled = {true}
                id = 'legalRepresentative'
                label = '* Representante legal'
                name = 'legalRepresentative'
                onChange = {handleChange}
                placeholder = 'Seleccione repr. legal'
                required = {true}
                validityStyles = {true}
                value = {legalRepresentative}
                wrapperStyle = {{ width: '90%' }}
              />
            </div>
            <div style = {{width: '50%'}}>

            </div>
          </div>
          <div className="finbe-app-line" style = {{marginTop: '1em', marginBottom: '1em'}}> </div>
          {
            !reviewUnits &&
            (
              <>
              <div style = {{display: 'flex'}}>

                <div style = {{width: '50%'}}>
                  
                  <InputFileH
                  refFile={refFile}
                  handleChange={handleChangeFile}
                  name='attachedDocument'
                  id='attachedDocument'
                  files={[...attachedDocument]}
                  accept={".pdf"}
                  uploadLabel={"Documento anexo"}
                  handleDelete={handleDeleteFile}
                  />

                </div>
                <div style = {{width: '50%'}}>
                  <Field
                    component = {InputText}
                    dClassName='k-form-field-wrap finbe-form-field-filter'
                    id = 'description'
                    label = 'Descripción'
                    name = 'description'
                    onChange = {handleChange}
                    editable = {false}
                    placeholder = 'Agregue la descripción de documento anexo'
                    value = {description}
                    validityStyles = {true}
                    minLength = {0}
                    maxLength = {250}
                    wrapperStyle = {{ width: '90%' }}
                  />
                </div>
              </div>
              {/* {fileName&&<div style = {{display: 'flex', marginTop: '1em'}}>
                <div style = {{width: '50%'}}><strong>Documento anexo: </strong>{fileName}</div>
              </div>} */}
              <div style = {{display: 'flex', marginTop: '1em'}}>
                <div style = {{width: '50%'}}>
                  <div>¿Desea agregar este documento para todas las unidades del CSV?</div>
                  <div style={{ paddingLeft: "1em" }}>
                    <RadioButton
                      name = "group1"
                      id = "includeAll"
                      value={true}
                      checked={includeAll === true}
                      label="Si"
                      onChange={handleChange}
                    />
                    <RadioButton
                      name="group1"
                      id = "includeAll"
                      value={false}
                      checked={includeAll === false}
                      label="No"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              </>
            )
          }
          </FormElement>
        )}
      />
      </StepBox>
    </div>
    );
  }
};

Company.defaultProps = {
  agencies: [],
  agency: '',
  attachedDocument: null,
  companies: [],
  company: '',
  companyTitle: '',
  description: '',
  handleChange: null,
  handleChangeFile: null,
  legalRepresentative: '',
  legalRepresentatives: [],
  styleContainer: null,
}

export default Company;
