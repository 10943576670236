import React, { useState, useEffect, useMemo } from "react";
import AuthService from "../Services/AuthService";
import ConfigurationService from "../Services/ConfigurationService";
import jwt_decode from "jwt-decode";
import jwt from "jsonwebtoken";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import moment from "moment";

const UsuarioContext = React.createContext();

export const UsuarioProvider = (props) => {
  let history = useHistory();
  const jwtSecret = "somesecretkeyforjsonwebtoken"; //Remover

  const [usuario, setUsuario] = useState(() =>
    localStorage.jwt ? jwt_decode(localStorage.jwt) : null
  );
  const [operation, setOperation] = useState(() =>
    localStorage.operation ? JSON.parse(localStorage.operation) : null
  );
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [expired, setExpired] = useState(
    localStorage.aws
      ? () => {
          const { exp, auth_time } = localStorage.aws
            ? jwt_decode(localStorage.aws)
            : null;
          if (exp) {
            const diffTime = moment(exp).diff(auth_time, "milliseconds");
            return diffTime * 1000 - 6000;
          }
          return null;
        }
      : null
  );
  const [countSesions, setCountSesions] = useState(
    localStorage.sesions ? +localStorage.sesions : 1
  );

  useEffect(() => {
    if (localStorage.jwt) {
      const decoded = localStorage.jwt ? localStorage.jwt : "";
      setUsuario(jwt_decode(decoded));
      setIsAuthenticated(true);
    }
    if (localStorage.operation) {
      const ope = localStorage.operation ? localStorage.operation : "";
      setOperation(JSON.parse(ope));
    }
  }, []);

  const tokenRefresh = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {

          const { idToken, refreshToken, accessToken } = session;

          const { exp, auth_time } = jwt_decode(accessToken.jwtToken);
          const diffTime = moment(exp).diff(auth_time, "milliseconds");

          localStorage.setItem("aws", accessToken.jwtToken);
          localStorage.setItem("sesions", countSesions + 1);
          const _expired = diffTime * 1000 - 6000;
          setCountSesions(countSesions + 1);
          setExpired(_expired);
          // do whatever you want to do now :)
        }
      );
    } catch (e) {
      signOutCognito();
    }
  };

  const onSetToke = () => {
    const _u = localStorage.jwt ? jwt_decode(localStorage.jwt) : null;
    if(_u)
    if (_u.type == 2) {
      if (localStorage.sesions < 3) {
        tokenRefresh();
      } else {
        signOutCognito();
      }
    } else {
      signOutCognito();
    }
  };

  useEffect(() => {
    if (expired) setInterval(onSetToke, expired);
  }, [expired]);

  const login = async (data) => {
    try {
      const res = await AuthService.getAuth(data);
      const _ope = await ConfigurationService.getOperation();
      if (!res.statusCode) {
        const _data = res.data;
        const token = jwt.sign(
          {
            id: _data.id,
            firstname: _data.firstname,
            lastname: _data.lastname,
            email: _data.email,
            type: _data.type,
            account: _data.account,
            company: _data.company,
            companies: _data.companies,
            agency: _data.agency,
            role: _data.role,
          },
          jwtSecret
        );
        localStorage.setItem("jwt", token);

        setIsAuthenticated(true);
        setUsuario(_data);
        // history.push("/");
      }
      if (_ope) {
        const _d = _ope.data;
        localStorage.setItem("operation", JSON.stringify(_d));
        setOperation(_d);
      }
    } catch (error) {
    }
  };
  const logout = () => {
    localStorage.removeItem("jwt");
    setIsAuthenticated(false);
    setUsuario({});
    setExpired(null);
    setCountSesions(1);
  };

  const sigInCognito = async (data) => {
    return await Auth.signIn(data.email, data.password);
  };

  const signOutCognito = async () => {
    try {
      setIsAuthenticated(false);
      setUsuario({});
      setOperation(null);
      setExpired(null);
      setCountSesions(1);
      localStorage.removeItem("jwt");
      localStorage.removeItem("aws");
      localStorage.removeItem("operation");
      localStorage.removeItem("sesions")
      Auth.signOut();
      history.push("/login");
    } catch (error) {
    }
  };

  const setInformation = async (userId, companyId) => {
    try {
      const res = await AuthService.newInformation({ userId, companyId });
      if (!res.statusCode) {
        const _data = res.data;
        const token = jwt.sign(
          {
            id: _data.id,
            firstname: _data.firstname,
            lastname: _data.lastname,
            email: _data.email,
            type: _data.type, //Set with role type,
            account: _data.account,
            company: _data.company,
            agency: _data.agency,
            companies: _data.companies,
            role: _data.role,
          },
          jwtSecret
        );
        localStorage.setItem("jwt", token);
        setIsAuthenticated(true);
        setUsuario(_data);
        // history.push("/");
      }
    } catch (error) {
    }
  };

  // const isLogged = () => {
  //   if(token){
  //     if((jwt_decode(token).exp * 1000)< Date.now()){
  //       logout();
  //       return false
  //     }
  //   }
  //   return !!token;
  // };

  const value = useMemo(() => {
    return {
      usuario,
      operation,
      isAuthenticated,
      login,
      logout,
      sigInCognito,
      signOutCognito,
      setInformation,
      cargando,
      setCargando,
      expired,
      setExpired,
    };
  }, [usuario, expired, operation, cargando, isAuthenticated]);

  return <UsuarioContext.Provider value={value} {...props} />;
};

export function useUsuario() {
  const context = React.useContext(UsuarioContext);

  if (!context) {
    throw new Error(
      "useUsuario debe estar dentro del proveedor UsuarioContext"
    );
  }

  return context;
}
