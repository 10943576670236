import httpCommon from "../Utils/http-common";

const getUnitsForRefinancings = (params) => {
  const client = httpCommon();
  return client.get("/refinancings/units", params);
};
const getRefinancings = (params) => {
  const client = httpCommon();
  return client.get("/refinancings", params);
};
const getRefinancingsAF = (params) => {
  const client = httpCommon();
  return client.get("/refinancings/review", params);
};
const create = (data) => {
  const client = httpCommon();
  return client.post("/refinancings", data);
};
const get = (id) => {
  const client = httpCommon();
  return client.get(`/refinancings/${id}`);
};
const setStatus = (id, data) => {
  const client = httpCommon();
  return client.patch(`/refinancings/${id}/set-status`, data);
};

const confirmUnits = (id, data) => {
  const client = httpCommon();
  return client.patch(`/refinancings/${id}/confirm-units`, data);
};
const acceptRefinancing = (id, data) => {
  const client = httpCommon();
  return client.post(`/refinancings/${id}/accept`, data);
};
const sendCRM = (data) => {
  const client = httpCommon();
  return client.post(`/refinancings/send-units`, data);
};
const updateManualFolio=(id,data)=>{
  const client = httpCommon();
  return client.patch(`/refinancings/${id}/update-folio`,data);
}
export default {
  getUnitsForRefinancings,
  getRefinancings,
  getRefinancingsAF,
  create,
  get,
  setStatus,
  confirmUnits,
  acceptRefinancing,
  sendCRM,
  updateManualFolio
};
