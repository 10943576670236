import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useUsuario } from "../../Context/UserContext";
import ProvisionService from "../../Services/ProvisionService";
import { LabelWithText } from "../Sections/Components/Tags";
import { StepBox } from "../Sections/Steps";
import { process as ProcessKendo } from "@progress/kendo-data-query";
import { ButtonAction, ButtonSuccess } from "../Sections/Components/Buttons";
import { DialogBox } from "../../Components/Sections/Dialogs";
import DocumentService from "../../Services/DocumentService";
import { InputText, RadioGroupInput } from "../Inputs/Inputs";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import { useHistory } from "react-router-dom";
import { RadioButton } from "@progress/kendo-react-inputs";
import { MoneyUnits } from "../Provisions/Sections/Units";
import FinbeService from "../../Services/Finbe/FinbeService";
import useLoader from "../../hooks/useLoader";
import dotenv from "dotenv";
dotenv.config();
const status = [
  { label: "Si", value: 1, className: "task red" },
  { label: "No", value: 2, className: "task red" },
  { label: "Ajuste", value: 3, className: "task red" },
];
const numberFormat = (value) => new Intl.NumberFormat("en-US").format(value);

const UnitsReview = (props) => {
  const { usuario, signOutCognito } = useUsuario();
  let history = useHistory();

  const [units, setUnits] = useState([]);
  const [unit, setUnit] = useState({});
  const [provision, setProvision] = useState({});
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [visibleEvidence, setVisibleEvidence] = useState(false);
  const [visibleResponse, setVisibleResponse] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [countUnits, setCountUnits] = useState(0);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [responseData, setResponseData] = useState({
    title: "",
    message: "",
    success: "",
  });
  const [dataState, setDataState] = useState({
    sort: [{ field: "email", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });
  const Loading = useLoader();
  const getProvision = async (_id) => {
    try {
      Loading();
      const { data } = await ProvisionService.getProvisionWithUnitsReview(_id);
      const { units, ...rest } = data;
      setCountUnits(units.length);
      setUnits(units);
      setProvision(rest);
      const _creditLines = await getCreditLines(data.company.clientId);

      const _availableBalance = _creditLines.find(
        (x) => x.lineaCredito == rest.creditLine
      ).montoRestante;
      let montoReservado = await getReservedAmount({
        params: { creditLine: rest.creditLine },
      });
      let _resAvailableBalance = _availableBalance - +montoReservado;
      setAvailableBalance(_resAvailableBalance > 0 ? _resAvailableBalance : 0);
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOutCognito();

    }
  };
  //Recuperar monto reservado
  const getReservedAmount = async (params) => {
    try {
      Loading();
      const res = await ProvisionService.getReservedAmount(params);
      Loading();
      return res.data.total || 0;
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOutCognito();
    }
  };
  const getCreditLines = async (customerId, type) => {
    try {
      Loading();
      const res = await FinbeService.getConfigurationCreditLine({
        params: { numeroCliente: customerId },
      });
      Loading();
      return res.data;
    } catch (error) {
      Loading();
    }
  };
  /** Convert bytes to MegaBytes or Kilobytes **/
  const _renderFileSize = (size) => {
    let sizeInt = Number(size);
    return sizeInt > 1000000
      ? sizeInt / 1000000 + " MB"
      : sizeInt / 1000 + " KB";
  };
  /** Set icon check or close **/
  const _approvedReject = (validation) => {
    return validation ? "aprobado" : "desaprobado";
  };

  /** Set icon approve or not REPUVE **/
  const _renderApproveRepuve = (validation) => {
    if (validation !== undefined) {
      return _approvedReject(validation.validRepuve);
    } else {
      return "desaprobado";
    }
  };

  /** Set icon approve or not **/
  const _renderApproveTransunion = (validation) => {
    if (validation !== undefined) {
      return _approvedReject(validation.validTransunion);
    } else {
      return "desaprobado";
    }
  };

  /** Set icon approve or not **/
  const _renderApproveAx = (validation) => {
    if (validation !== undefined) {
      return _approvedReject(validation.validAx);
    } else {
      return "desaprobado";
    }
  };
  /** Set icon approve, rejection or not**/
  const _renderApproveAdminFinBe = (status, adminFinBe) => {
    if (!adminFinBe || status == 0 || status === undefined) {
      return "";
    } else if (status == 1) {
      return "aprobado";
    } else {
      return "desaprobado";
    }
  };

  const _renderApproveInvoice = (unit) => {
    if (unit.validation !== undefined) {
      if (unit.validation.validInvoice) {
        return "aprobado";
      } else {
        return unit.invoiceDate != null ? "desaprobado" : "interrogacion";
      }
    }
  };
  /** Click close response dialog **/
  const handleDialogResponse = (e) => {
    if (responseData.success) {
      history.push("/disposiciones-revision");
    } else {
      setVisibleResponse(!visibleResponse);
    }
  };

  const handleShowOrHideAddEvidence = (e) => {
    if (e) {
      const target = e.currentTarget;
      if (target !== undefined) {
        let index = target.getAttribute("data");
        let unit = units.find((it) => it.id == index);
        setUnit(unit);
        setVisibleEvidence(!visibleEvidence);
      } else {
        setVisibleEvidence(!visibleEvidence);
        setUnit({});
      }
    } else {
      setVisibleEvidence(!visibleEvidence);
      setUnit({});
    }
  };

  const getDocument = async (url, download = false) => {
    try {
      Loading();
      const response = await DocumentService.showDocument({
        path: url,
        download: download,
      });
      window.open(response.data.url, "_blank");
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status === 401) signOutCognito();
      console.info(error);
    }
  };
  const handleConfirm = async () => {
    const {
      id,
      status,
      isArchived,
      createdAt,
      legalPerson,
      company,
      agency,
      ...res
    } = provision;
    let success = true;
    let message = "";
    let _data = res;
    _data.deadlines = 2;
    _data.units = units.map((item) => {
      const { validation, evidences, createdAt, ...res } = item;
      res.evidences = [];
      return res;
    });
    try {
      Loading();
      const { data } = await ProvisionService.confirmUnitsReview(_data);
      message = "Disposición actualizada correctamente";
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOutCognito();
      success = false;
      message = error.response.data.message || error.message;
    }
    setResponseData({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
    });
    setVisibleResponse(!visibleResponse);
  };
  const handleAccept = () => {
    const _units = units.filter((item) => item.id !== unit.id);
    _units.push(unit);
    setCountUnits(_units.filter((item) => item.status === 0).length);
    setUnits(_units);
    handleShowOrHideAddEvidence();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== undefined) setUnit({ ...unit, [name]: value });
    else setUnit({ ...unit, ["status"]: e.value });
  };
  useEffect(() => {
    if (usuario) {
      if (props.match.params.id != undefined) {
        getProvision(props.match.params.id);
      }
    }
  }, [props.match.params.id, usuario]);

  return (
    <div className="finbe-app-container">
      <StepBox title="Datos de la empresa">
        <div style={{ display: "flex", marginBottom: "1.5em" }}>
          <LabelWithText
            bText={
              provision.company !== undefined ? provision.company.name : ""
            }
            divStyle={{ width: "50%" }}
            labelText={"Empresa"}
          />
          <LabelWithText
            bText={provision.agency !== undefined ? provision.agency.name : ""}
            divStyle={{ width: "50%" }}
            labelText={"Agencia"}
          />
        </div>
        <div style={{ display: "flex", marginBottom: "1.5em" }}>
          <LabelWithText
            divStyle={{ width: "50%" }}
            labelText={"Representante legal"}
            bText={
              provision.legalPerson !== undefined
                ? provision.legalPerson.name
                : ""
            }
          />
        </div>
      </StepBox>

      <StepBox title="Unidades para revisión">
        <div
          style={{
            display: "flex",
            marginTop: "1em",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "30%" }}>
            <LabelWithText
              divStyle={{ width: "100%" }}
              labelText={"Línea de crédito"}
              bText={
                provision.creditLine !== undefined ? provision.creditLine : ""
              }
            />
            <LabelWithText
              divStyle={{ width: "100%" }}
              labelText={"Tipo de línea"}
              bText={
                provision.typeLine !== undefined ? provision.typeLine == process.env.REACT_APP_TYPE_PREOWNED?"Seminuevos":"Nuevos"
                : ""
              }
            />
          </div>
        </div>
        <MoneyUnits
          availableBalance={numberFormat(availableBalance)}
          remainingBalance={numberFormat(availableBalance - provision.total)}
          totalAmount={numberFormat(provision.total)}
        />
        <Grid
          className="finbe-table"
          data={ProcessKendo(units, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.data);
          }}
          sortable
          pageable={{
            info: false,
          }}
          skip={dataState.skip}
          take={dataState.take}
          resizable
        >
          <Column field="model" title="Modelo" />
          <Column field="motorNumber" title="N° Motor" />
          <Column field="vinNumber" title="N° VIN" />
          <Column field="amount" title="Monto" />
          <Column
            field="validInvoice"
            title="Factura"
            cell={(props) => (
              <td>
                <span
                  className={" icon " + _renderApproveInvoice(props.dataItem)}
                />
              </td>
            )}
          />
          <Column
            field="valiRepuve"
            title="REPUVE"
            cell={(props) => (
              <td>
                <span
                  className={
                    " icon " + _renderApproveRepuve(props.dataItem.validation)
                  }
                />
              </td>
            )}
          />
          <Column
            field="validTransunion"
            title="Transunion"
            cell={(props) => (
              <td>
                <span
                  className={
                    " icon " +
                    _renderApproveTransunion(props.dataItem.validation)
                  }
                />
              </td>
            )}
          />
          <Column
            field="validAx"
            title="AX"
            cell={(props) => (
              <td>
                <span
                  className={
                    " icon " + _renderApproveAx(props.dataItem.validation)
                  }
                />
              </td>
            )}
          />
          <Column
            field="status"
            title="Admin. FinBe"
            cell={(props) => (
              <td>
                <span
                  className={
                    " icon " +
                    _renderApproveAdminFinBe(
                      props.dataItem.status,
                      props.dataItem.reviewAF
                    )
                  }
                />
              </td>
            )}
          />
          <Column
            title="Acciones"
            cell={(props) => (
              <td>
                <div style={{ display: "flex" }}>
                  <ButtonAction
                    title={"Visualizar evidencia"}
                    icon=" icon visualizar_evidencia"
                    look="bare"
                    primary={false}
                    data={props.dataItem.id}
                    onClick={handleShowOrHideAddEvidence}
                  />
                </div>
              </td>
            )}
          />
        </Grid>

        <DialogBox
          handelClose={handleShowOrHideAddEvidence}
          handelAccept={handleAccept}
          title={"Evidencia"}
          visible={visibleEvidence}
          visibleButtons={true}
          buttonSuccessText={"Aceptar"}
          styleContent={{ width: "600px" }}
          width={650}
          height={550}
        >
          <p>
            Modelo de la unidad: <strong>{unit.model}</strong>
          </p>

          <div style={{ width: "637px", paddingTop: "1em" }}>
            <Grid
              className="finbe-table"
              data={unit.evidences}
              {...dataState}
              onDataStateChange={(e) => {
                setDataState(e.data);
              }}
              sortable
              pageable={{
                info: false,
              }}
              skip={dataState.skip}
              take={dataState.take}
              resizable
            >
              <Column field="fileName" title="Documento" />
              <Column
                title="Tamaño"
                cell={(props) => (
                  <td>{_renderFileSize(props.dataItem.fileSize)}</td>
                )}
              />
              <Column
                title="Acciones"
                cell={(props) => (
                  <td>
                    <div style={{ display: "flex" }}>
                      <>
                        <ButtonAction
                          title={"Visualizar"}
                          icon={" icon visualizar"}
                          look="bare"
                          primary={false}
                          onClick={() => {
                            getDocument(props.dataItem.url);
                          }}
                        />
                        <ButtonAction
                          title={"Descargar"}
                          icon={" icon descargar"}
                          look="bare"
                          primary={false}
                          onClick={() => {
                            getDocument(props.dataItem.url, true);
                          }}
                        />
                      </>
                    </div>
                  </td>
                )}
              />
            </Grid>
          </div>
          <div>
            <Form
              render={(formRenderProps) => (
                <FormElement>
                  <div style={{ display: "flex" }}>
                    <div>¿Las evidencias son correctas?</div>
                    <div style={{ paddingLeft: "1em" }}>
                      <RadioButton
                        name="status"
                        value={1}
                        checked={unit.status === 1}
                        label="Si"
                        onChange={handleChange}
                      />
                      <RadioButton
                        name="status"
                        value={2}
                        checked={unit.status === 2}
                        label="No"
                        onChange={handleChange}
                      />
                      <RadioButton
                        name="status"
                        value={3}
                        checked={unit.status === 3}
                        label="Ajuste"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/* <div style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      <Label editorId={"type-user"}>
                        ¿Las evidencias son correctas?
                      </Label>
                      <Field
                        layout={"horizontal"}
                        data={status}
                        disabled={false}
                        id="status"
                        nombre="status"
                        textField=""
                        component={RadioGroupInput}
                        value={
                          unit.status > 0
                            ? status.find((x) => x.value == unit.status).value
                            : ""
                        }
                        onChange={handleChange}
                      />
                    </div>
                  </div> */}
                  <br></br>
                  {(unit.status === 3 || unit.status === 2) && (
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <Field
                          component={InputText}
                          dClassName="k-form-field-wrap finbe-form-field-filter"
                          id="comment"
                          label="Comentario"
                          nombre="comment"
                          onChange={handleChange}
                          placeholder="Ingresa comentario"
                          value={unit.comment != null ? unit.comment : ""}
                          wrapperStyle={{ width: "90%" }}
                        />
                      </div>
                    </div>
                  )}
                </FormElement>
              )}
            />
          </div>
        </DialogBox>
      </StepBox>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}></div>
        <div style={{ display: "flex", textAling: "left" }}>
          <ButtonSuccess
            className=""
            icon="check"
            onClick={() => {
              setVisibleConfirm(true);
            }}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Confirmar"
          />
        </div>
      </div>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => {
          setVisibleConfirm(false);
        }}
        handelAccept={handleConfirm}
        visibleCancelButton={true}
        title={"Actualizar unidades en revisión"}
        visible={visibleConfirm}
      >
        {(countUnits > 0
          ? "Tienes " +
            countUnits +
            " unidad" +
            (countUnits > 1 ? "es" : "") +
            " pendiente" +
            (countUnits > 1 ? "s" : "") +
            " por revisar,"
          : "") + " ¿Seguro que deseas continuar con la actualización?"}
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={responseData.title}
        visible={visibleResponse}
      >
        {responseData.message}
      </DialogBox>
    </div>
  );
};

export default UnitsReview;
