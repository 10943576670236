/**
 * @author: Alexander Chi
 * @description: Service of exceptions
 * @date: 09/oct/2020
 **/
import httpCommon from "../Utils/http-common";

/**
 * @description: Get all data of exceptions
 * @return: response
 **/
const getAll = (params) => {
  const client = httpCommon();
  return client.get("/exceptions", params);
};

/**
 * @description: Get by ID of exception information
 * @return: response
 **/
const getById = (id) => {
  const client = httpCommon();
  return client.get(`/exceptions/${id}`);
};

/**
 * @description:
 * @return: response
 **/
const create = (data) => {
  const client = httpCommon();
  return client.post("/exceptions", data);
};

const update = (id, data) => {
  const client = httpCommon();
  return client.put(`/exceptions/${id}`, data);
};

/**
 * @description:
 * @return: response
 **/
const toDownload = (id) => {
  const client = httpCommon();
  return client.get(`/exceptions/${id}`);
};

/**
 * @description:
 * @return: response
 **/
const rejectOrApprove = (id) => {
  const client = httpCommon();
  return client.get(`/exceptions/${id}`);
};

export default {
  create,
  getAll,
  getById,
  rejectOrApprove,
  toDownload,
  update,
};
