import { Field, Form, FormElement } from "@progress/kendo-react-form"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useUsuario } from "../../Context/UserContext"
import AccountService from "../../Services/AccountService"
import CustomSignerService from "../../Services/CustomSignerService"
import FinbeService from "../../Services/Finbe/FinbeService"
import { ButtonPrimary, ButtonTertiary } from "../Sections/Components/Buttons"
import {
  InputComboBox,
  InputText,
  InputMultiSelect,
  InputSwitch,
} from "../Sections/Components/Inputs"
import { DialogBox } from "../Sections/Dialogs"
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid"
import { process as ProcessGrid } from "@progress/kendo-data-query"
import { ButtonAction } from "../Sections/Components/Buttons"
import dotenv from "dotenv"
import useLoader from "../../hooks/useLoader"

dotenv.config()
function Configuration(props) {
  const [versionSignature, setVersionSignature] = useState(
    process.env.REACT_APP_CONFIG_SIGNATURE_VERSION
  )
  const { signOutCognito, setCargando } = useUsuario()
  const [companies, setCompanies] = useState([])
  const [lines, setLines] = useState([])
  const [representantes, setRepresentantes] = useState([])
  const [avales, setAvales] = useState([])
  const [customSigners, setCustomSigners] = useState([])
  const [customSigner, setCustomSigner] = useState(null)
  const [idCustomSigner, setIdCustomSigner] = useState(null)
  const [action, setAction] = useState(null)
  const [company, setCompany] = useState(null)
  const [line, setLine] = useState(null)
  const [isElectronic, setIsElectronic] = useState(false)
  // const {data}=useSigners(line);

  const [signaturesR, setSignaturesR] = useState({})
  const [signaturesA, setSignaturesA] = useState({})

  const [visibleAdd, setVisibleAdd] = useState(false)
  const [visibleConfirm, setVisibleConfirm] = useState(false)
  const [dataConfirm, setDataConfirm] = useState({})
  const [visible, setVisible] = useState(false)
  const [dataResponse, setDataResponse] = useState({
    title: "",
    message: "",
    success: "",
  })

  const [dataFirmantesL, setDataFirmantesL] = useState({})
  const [dataFirmantesA, setDataFirmantesA] = useState({})

  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 })
  const [dataState, setDataState] = useState({
    sort: [{ field: "company.name", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  })

  const [avalesPM, setAvalesPM] = useState([])
  const [newList, setNewList] = useState([])
  const [newDataFirmantes, setNewDataFirmantes] = useState({})
  const [newObjSignatures, setNewObjSignatures] = useState({})
  const [objSignaturesJoint, setObjSignaturesJoint] = useState({})
  const Loading = useLoader()
  const handleConfirmRemove = () => {
    setDataConfirm({
      title: "Remover firmante personalizado",
      message: "¿Estás seguro eliminar al firmante?",
    })
    setVisibleConfirm(true)
  }
  const openDialog = () => {
    // if (visibleAdd) {
    //   setDataConfirm({
    //     title: "Guardar firmante personalizado",
    //     message: "¿Estás seguro de guardar este firmante?",
    //   });

    setDataConfirm({
      title: "Guardar configuración",
      message: "¿Estás seguro de guardar la configuración?",
    })

    setVisibleConfirm(true)
  }

  const handleDialogResponse = () => {
    if (dataResponse.isRedirect) {
      history.push("/cuentas")
    } else {
      setVisible(!visible)
    }
  }

  //Metodo para recuperar las companias asignadas a la cuenta del cliente
  const getCompanies = async (id) => {
    try {
      Loading()
      // setCargando(true)
      const { data } = await AccountService.getCompanies(id)
      const _companies = await data.map((item) => ({
        id: item.id,
        text: item.name,
        clientId: item.clientId,
        legalId: item.legal.legalId,
      }))
      if (_companies.length == 1) {
        setCompany((prev) => {
          return _companies[0]
        })
        await getCreditLines(_companies[0].clientId)
      }
      setCompanies((prev) => _companies)
      // setCargando(false)
      Loading()
    } catch (error) {
      Loading()
      if (error?.response?.status === 401) signOutCognito()
    }
  }

  const _newImplementation = async (creditLine = null, client = null) => {
    try {
      Loading()
      // setCargando(true)
      //Rcuperar avales
      const responseAvales = await FinbeService.getAvales({
        params: { lineaDeCredito: creditLine },
      })

      const avaPF = responseAvales.data
        .filter((item) => item.numeroCliente.startsWith("PF"))
        .map((item) => ({
          name: `${item.numeroCliente}.${client}.aval`,
          clientId: client,
          id: item.numeroCliente,
          isAval: true,
          isCustom: false,
          isRep: false,
          text: `${item.nombres} ${item.apellidos}`,
        }))

      const avaPM = responseAvales.data.filter((item) =>
        item.numeroCliente.startsWith("PM")
      )

      let repPM = []
      for (const elem of avaPM) {
        let _legals = await FinbeService.getLegalPersons({
          params: { numeroCliente: elem.numeroCliente },
        })
        _legals.data.map((item) => {
          repPM.push({
            name: `${item.numeroCliente}.${elem.numeroCliente}.representante`,
            clientId: elem.numeroCliente,
            id: item.numeroCliente,
            isAval: false,
            isCustom: false,
            isRep: true,
            text: `${item.nombres} ${item.apellidos} (${item.cargo || ""})`,
          })
        })
        repPM.push({
          name: `joint.${elem.numeroCliente}.representante`,
          clientId: elem.numeroCliente,
          id: null,
          isAval: false,
          isCustom: false,
          isRep: true,
          text: "Mancomunada",
        })
      }

      //Recuperar representantes legales
      const responseLegals = await FinbeService.getLegalPersons({
        params: { numeroCliente: client },
      })
      const rpL = responseLegals.data
        .map((item) => ({
          name: `${item.numeroCliente}.${client}.representante`,
          clientId: client,
          id: item.numeroCliente,
          isAval: false,
          isCustom: false,
          isRep: true,
          text: `${item.nombres} ${item.apellidos} (${item.cargo || ""})`,
        }))
        .concat([
          {
            name: `joint.${client}.representante`,
            clientId: client,
            id: null,
            isAval: false,
            isCustom: false,
            isRep: true,
            text: "Mancomunada",
          },
        ])

      //Recuperar firmantes personalizados
      const responseCustomSigners = await CustomSignerService.getAll({
        params: { creditLine: creditLine },
      })
      const custom = responseCustomSigners.data.map((item) => ({
        name: `${item.id}.${client}.custom`,
        clientId: client,
        id: item.id,
        isAval: false,
        isCustom: true,
        isRep: false,
        text: item.fullName,
      }))

      const principalList = [...custom, ...rpL, ...repPM, ...avaPF]
      //Data de firmantes
      const _dataFirmantes = {}
      const objSignatures = {}
      const _objSignaturesJoint = {}

      for (const value of principalList.filter((item) => !item.isCustom)) {
        if (!value.name.includes("joint"))
          objSignatures[value.name] = value.name
        //poner un filter en caso necesario
        if (value.name.includes("joint")) {
          _dataFirmantes[value.name] = {
            disabled: false,
            data: principalList.filter(
              (item) =>
                item.isRep &&
                item.clientId === value.clientId &&
                !item.name.includes("joint")
            ),
          }
        } else if (value.isAval) {
          _dataFirmantes[value.name] = {
            disabled: false,
            data: principalList
              .filter((item) => !item.name.includes("joint"))
              .map((item) => {
                let _item = { ...item }

                if (value.isAval) {
                  if (_item.id === value.id && _item.isAval) {
                    _item.text = `Por si mismo`
                  }
                }
                if (value.isRep) {
                  if (
                    value.id == _item.id &&
                    _item.isRep &&
                    _item.clientId === value.clientId
                  ) {
                    _item.text = `Por si mismo`
                  }
                }

                return _item
              }),
          }
        } else if (value.isRep) {
          _dataFirmantes[value.name] = {
            disabled: false,
            data: [
              {
                id: null,
                name: null,
                text: `No firma`,
                isCustom: false,
                isAval: false,
                isRep: true,
                clienteId: value.clientId,
              },
            ].concat(
              principalList
                .filter((item) => !item.name.includes("joint"))
                .map((item) => {
                  let _item = { ...item }

                  if (value.isAval) {
                    if (_item.id === value.id && _item.isAval) {
                      _item.text = `Por si mismo`
                    }
                  }
                  if (value.isRep) {
                    if (
                      value.id == _item.id &&
                      _item.isRep &&
                      _item.clientId === value.clientId
                    ) {
                      _item.text = `Por si mismo`
                    }
                  }

                  return _item
                })
            ),
          }
        }
      }

      //Recuperar configuración y hacer el map de la informacion

      const responseConfig = await AccountService.getConfiguration({
        params: { creditLine: creditLine },
      })

      if (
        responseConfig &&
        responseConfig.data.version === Number(versionSignature)
      ) {
        setIsElectronic(responseConfig.data.isElectronic)
        if (
          responseConfig.data.set != undefined ||
          responseConfig.data.set != null
        ) {
          responseConfig.data.set
            .filter((x) => Object.keys(objSignatures).includes(x.keySigner))
            .map((item) => {
              objSignatures[item.keySigner] = item.selectedValue
            })

          if (
            responseConfig.data.set.filter((item) => item.isJoint).length > 0
          ) {
            responseConfig.data.set
              .filter((item) => item.isJoint)
              .map((item) => {
                Object.keys(objSignatures)
                  .filter((x) => x.includes(item.clientId + ".representante"))
                  .map((x) => {
                    objSignatures[x] = null
                  })

                const _arrayManc = JSON.parse(item.selectedValue)
                const elementsArray = []
                for (const v of _arrayManc) {
                  let elm = _dataFirmantes[item.keySigner].data.find(
                    (e) => e.name === v.name
                  )
                  if (elm) {
                    elementsArray.push(elm)
                  }
                }
                _objSignaturesJoint[item.keySigner] = elementsArray
              })
          }
        }
      }

      /**----------------- */

      setCustomSigners((prev) => responseCustomSigners.data)
      setAvalesPM((prev) => avaPM)
      setNewObjSignatures((prev) => objSignatures)
      setNewDataFirmantes((prev) => _dataFirmantes)
      setNewList((prev) => principalList)
      setObjSignaturesJoint((prev) => _objSignaturesJoint)
      //firmantes asignados
      // setCargando(false)
      Loading()
    } catch (error) {
      Loading()
      if (error?.response?.status === 401) signOutCognito()
    }
  }

  //Metodo para recuperar los avales pertenecientes a la linea de credito del cliente
  const getAvales = async (linea, rl = null, customs = null) => {
    try {
      //Se recuperan loa avales de la linea de credito
      const { data } = await FinbeService.getAvales({
        params: { lineaDeCredito: linea },
      })

      //Se filtran avales personas fisicas
      const filterAvalesPF = data
        .filter((item) => item.numeroCliente.startsWith("PF"))
        .map((item) => ({
          id: item.numeroCliente,
          text: `${item.nombres} ${item.apellidos}`,
          isCustom: false,
        }))
      //Se filtran avales personas morales
      const filterAvalesPM = data
        .filter((item) => item.numeroCliente.startsWith("PM"))
        .map((item) => ({
          id: item.numeroCliente,
          text: `${item.nombres} ${item.apellidos}`,
          isCustom: false,
        }))

      for (const elem of filterAvalesPM) {
        let _legals = await FinbeService.getLegalPersons({
          params: { numeroCliente: elem.id },
        })
        let mapLegals = _legals.data.map((item) => ({
          id: item.numeroCliente,
          text: `${item.nombres} ${item.apellidos}`,
          isCustom: false,
        }))
        elem.legals = mapLegals
      }

      //Combinación de apoderados con avales que no firman como aval
      let _dataAyRL = []
      if (rl != null) {
        /**@comment Revisar logica para optimizar */
        _dataAyRL = [
          ...filterAvalesPF,
          ...rl.filter((item) => {
            if (
              filterAvalesPF.filter((_item) => item.id === _item.id).length <= 0
            ) {
              return item
            }
          }),
        ]
      }

      const objSignatureA = {}
      for (const item of filterAvalesPF) {
        objSignatureA[item.id] = item.id
      }

      const objDataFirmantesA = {}
      for (const key of filterAvalesPF) {
        objDataFirmantesA[key.id] = _dataAyRL.map((item) => {
          if (key.id == item.id) {
            return {
              id: item.id,
              text: `Por si mismo`,
              isCustom: false,
            }
          } else {
            return {
              id: item.id,
              text: item.text,
              isCustom: false,
            }
          }
        })
      }
      if (customs) {
        const _keys = Object.keys(objDataFirmantesA)
        for (const i of _keys) {
          objDataFirmantesA[i] = objDataFirmantesA[i].concat(
            customs.map((x) => {
              return {
                id: x.id,
                text: x.fullName,
                isCustom: true,
              }
            })
          )
        }
      }

      setSignaturesA((prev) => objSignatureA)

      setDataFirmantesA((prev) => objDataFirmantesA)

      setAvales(filterAvalesPF)

      setAvalesPM(filterAvalesPM)
    } catch (error) {}
  }

  const getCreditLines = async (client) => {
    try {
      Loading()
      const { data } = await FinbeService.getConfigurationCreditLine({
        params: { numeroCliente: client },
      })
      const _lines = await data.map((item) => ({
        id: item.lineaCredito,
        text: `${item.lineaCredito} ${
          item.tipoDispLinea == process.env.REACT_APP_TYPE_PREOWNED
            ? "(Seminuevos)"
            : "(Nuevos)"
        }`,
      }))
      if (_lines.length === 1) {
        setLine(_lines[0])
        await _newImplementation(_lines[0].id, client)
      }
      setLines(_lines)
      Loading()
    } catch (error) {
      Loading()
    }
  }
  const getLegales = async (customs = null) => {
    try {
      const { data } = await FinbeService.getLegalPersons({
        params: { numeroCliente: company.clientId },
      })
      // const _newData = data.concat(customSigners)
      const _repr = data.map((item) => ({
        id: item.numeroCliente,
        text: `${item.nombres} ${item.apellidos}`,
        isCustom: false,
      }))

      const objSignatureR = {}
      // objSignatureR[company.legalId]=_repr.find(item=>item.id === company.legalId)
      for (const item of data) {
        objSignatureR[item.numeroCliente] = item.numeroCliente
      }

      const objDataFirmantesL = {}
      for (const key of data) {
        objDataFirmantesL[key.numeroCliente] = [
          {
            id: null,
            text: `No firma`,
            isCustom: false,
          },
        ].concat(
          data.map((item) => {
            if (key.numeroCliente == item.numeroCliente) {
              return {
                id: item.numeroCliente,
                text: `Por si mismo`,
                isCustom: false,
              }
            } else {
              return {
                id: item.numeroCliente,
                text: `${item.nombres} ${item.apellidos}`,
                isCustom: false,
              }
            }
          })
        )
      }
      if (customs) {
        const _keys = Object.keys(objDataFirmantesL)
        for (const i of _keys) {
          objDataFirmantesL[i] = objDataFirmantesL[i].concat(
            customs.map((x) => {
              return {
                id: x.id,
                text: x.fullName,
                isCustom: true,
              }
            })
          )
        }
      }
      // setCount(prev=>(prev+1))

      await setSignaturesR((prev) => objSignatureR)

      await setDataFirmantesL((prev) => objDataFirmantesL)

      await setRepresentantes(_repr)
      return _repr
    } catch (error) {}
  }
  const getCustomSigners = async (creditLine) => {
    try {
      Loading()
      // setCargando(true)
      const { data } = await CustomSignerService.getAll({
        params: { creditLine: creditLine },
      })
      setCustomSigners(data)
      // setCargando(false)
      Loading()
      return data
    } catch (error) {
      Loading()
    }
  }
  const handleChange = async (e) => {
    const { name, value } = e.target
    if (name == "creditLine") {
      setLine(value)
      //   setConfig(prev=>{return{...config, creditLine: value.id }});
      if (value != null) {
        // const _customSigners = await getCustomSigners(value.id);
        // const legals = await getLegales(_customSigners);
        // await getAvales(value.id, legals, _customSigners);
        await _newImplementation(value.id, company.clientId)
        // await getConfig(value.id);
      } else {
        setNewList([])
        setAvales([])
        setRepresentantes([])
        setCustomSigners([])
      }
    } else {
      setCompany(value)
      if (value !== null) {
        getCreditLines(value.clientId)
      }
      //   setConfig({ ...config, companyId: value.id });
    }
  }
  const handleChangeCustomSigner = async (e) => {
    const { value } = e.target
    setCustomSigner(value)
  }

  const handeChangeSignatures = (e) => {
    const { name, value } = e.target
    setNewObjSignatures((prev) => ({
      ...prev,
      [name]: value ? value.name : name,
    }))
  }
  const handleChangeJointSignatures = (e) => {
    const { value, name } = e.target
    setObjSignaturesJoint((prev) => ({
      ...prev,
      [name]: value ? value : null,
    }))

    if (value.length > 0) {
      let company = name.split(".")[1]
      for (const value of Object.entries(newDataFirmantes).filter(
        ([key]) =>
          key.includes(company) &&
          key.includes("representante") &&
          !key.includes("joint")
      )) {
        let _newData = value[1]
        _newData.disabled = true
        setNewObjSignatures((prev) => ({
          ...prev,
          [value[0]]: null,
        }))
        setNewDataFirmantes((prev) => ({
          ...prev,
          [value[0]]: _newData,
        }))
      }
    } else {
      let company = name.split(".")[1]
      for (const value of Object.entries(newDataFirmantes).filter(
        ([key]) =>
          key.includes(company) &&
          key.includes("representante") &&
          !key.includes("joint")
      )) {
        let _newData = value[1]
        _newData.disabled = false
        setNewObjSignatures((prev) => ({
          ...prev,
          [value[0]]: value[0],
        }))
        setNewDataFirmantes((prev) => ({
          ...prev,
          [value[0]]: _newData,
        }))
      }
    }
  }

  const getConfig = async (creditLine) => {
    try {
      const { data } = await AccountService.getConfiguration({
        params: { creditLine: creditLine },
      })
      let _objSignature = { ...newObjSignatures }

      if (data.set != undefined || data.set != null) {
        if (data.set.filter((item) => item.signerId === "joint").length > 0) {
          data.set
            .filter((item) => !item.isJoint && item.keySigner !== null)
            .map((item) => {
              _objSignature[item.keySigner] =
                item.signedBy != "" ? item.selectedValue : null
              //   setNewObjSignatures((prev) => ({
              //     ...prev,
              //     [item.keySigner]: item.signedBy != "" ? item.selectedValue : null,
              //   }));
            })
          data.set
            .filter(
              (item) => item.signerId === "joint" && item.keySigner !== null
            )
            .map((item) => {
              //--- Logica para bloquear selectores cuano haya firmas mancomunadas
              let company = item.keySigner.split(".")[1]
              for (const value of Object.entries(newDataFirmantes).filter(
                ([key]) =>
                  key.includes(company) &&
                  key.includes("representante") &&
                  !key.includes("joint")
              )) {
                let _newData = value[1]
                _newData.disabled = true
                setNewDataFirmantes((prev) => ({
                  ...prev,
                  [value[0]]: _newData,
                }))
              }
              setObjSignaturesJoint((prev) => ({
                ...prev,
                [item.keySigner]: JSON.parse(item.selectedValue),
              }))
            })
          setNewObjSignatures(_objSignature)
        } else {
          data.set
            .filter((item) => item.keySigner !== null)
            .map((item) => {
              _objSignature[item.keySigner] =
                item.signedBy != "" ? item.selectedValue : null
              // setNewObjSignatures((prev) => ({
              //   ...prev,
              //   [item.keySigner]: item.signedBy != "" ? item.selectedValue : null,
              // }));
            })
          setNewObjSignatures(_objSignature)
        }
      }
    } catch (error) {}
  }

  const saveConfig = async () => {
    setVisibleConfirm(false)
    let success = true
    let message = ""
    let isRedirect = true
    // setCargando(true)
    try {
      Loading()
      const data = {
        creditLine: line.id,
        accountId: props.match.params.id,
        companyId: company.id,
        isElectronic: isElectronic,
        signatures: [],
      }

      /*Firmas normales*/

      //Firmantes avales
      const _fn = Object.entries(newObjSignatures).filter(
        ([key]) => !key.includes("joint")
      )

      //Crear objetos de firmantes avales
      for (const value of _fn) {
        let keySigner = value[0].split(".")
        let _signer = newDataFirmantes[value[0]].data.find(
          (item) => item.name === value[1]
        )
        data.signatures.push({
          signerId: keySigner[0],
          signedBy: _signer.id ? _signer.id : "",
          isLegal: _signer.isRep,
          isCustom: _signer.isCustom,
          clientId: _signer.clientId ? _signer.clientId : null,
          isJoint: false,
          keySigner: value[0],
          selectedValue: value[1],
        })
      }
      let firmantesMancomunados = Object.entries(objSignaturesJoint).filter(
        ([key, value]) => value.length > 0
      )
      if (firmantesMancomunados.length > 0) {
        for (const value of firmantesMancomunados) {
          if (value[1].length < 2) {
            throw Error(
              "Verifique la configuración de firmas mancomunadas, debe tener por lo menos dos elementos seleccionados"
            )
          }
          let keySigner = value[0].split(".")
          data.signatures.push({
            signerId: keySigner[0],
            signedBy: "",
            isLegal: true,
            isCustom: false,
            clientId: keySigner[1],
            isJoint: true,
            keySigner: value[0],
            selectedValue: JSON.stringify(
              value[1] /*.map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                  clientId: item.clientId,
                };
              })*/
            ),
          })
        }
      }

      if (data.signatures.length > 0) {
        const res = await AccountService.saveConfiguration(data)
        message = "La información se guardo correctamente"
      } else {
        success = false
        message = "La información de los firmantes esta incompleta"
      }
      Loading()
      // setCargando(false)
    } catch (error) {
      Loading()
      if (error.response && error.response.status == 401) signOutCognito()
      success = false
      message = error.message
      isRedirect = false
      // setCargando(false)
    }
    setDataResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
      isRedirect: isRedirect,
    })
    setVisible(!visible)
  }
  const handleCloseDialogAdd = () => {
    setVisibleAdd(false)
    setCustomSigner(null)
    setIdCustomSigner(null)
  }

  const saveCustomSigner = async () => {
    let success = true
    let message = ""
    let isRedirect = false
    // setCargando(true)
    try {
      Loading()
      const _data = {
        fullName: customSigner,
        creditLine: line.id,
        companyId: company.id,
      }
      if (idCustomSigner) {
        const res = await CustomSignerService.update(idCustomSigner, _data)
      } else {
        const res = await CustomSignerService.create(_data)
      }
      message = "La información se guardo correctamente"
      const _customSigners = await getCustomSigners(line.id)

      //Actualizar firmantes personalizados
      const _dta = Object.entries(newDataFirmantes).filter(
        ([key]) => !key.includes("joint")
      )
      for (const value of _dta) {
        let _newData = value[1]
        _newData.data = value[1].data
          .filter((item) => !item.isCustom)
          .concat(
            _customSigners.map((item) => ({
              name: `${item.id}.${company.clientId}.custom`,
              clientId: company.clientId,
              id: item.id,
              isAval: false,
              isCustom: true,
              isRep: false,
              text: item.fullName,
            }))
          )
        setNewDataFirmantes((prev) => ({
          ...prev,
          [value[0]]: _newData,
        }))
      }
      Loading()
      // setCargando(false)
    } catch (error) {
      Loading()
      if (error.response) {
        if (error.response.status == 401) signOutCognito()
      }
      success = false
      message = error.message
      // setCargando(false)
    }
    setDataResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
      isRedirect: isRedirect,
    })
    setCustomSigner(null)
    setIdCustomSigner(null)
    setVisibleAdd(false)
    setVisible(!visible)
  }
  const removeCustomSigner = async () => {
    setVisibleConfirm(false)
    let success = true
    let message = ""
    let isRedirect = false
    // setCargando(false)
    try {
      Loading()
      const res = await CustomSignerService.remove(idCustomSigner)
      message = "El registro se eliminó de forma correcta"
      const _customSigners = await getCustomSigners(line.id)
      //Actualizr firmantes personalizados
      const _dta = Object.entries(newDataFirmantes).filter(
        ([key]) => !key.includes("joint")
      )
      for (const value of _dta) {
        let _newData = value[1]
        _newData.data = value[1].data
          .filter((item) => !item.isCustom)
          .concat(
            _customSigners.map((item) => ({
              name: `${item.id}.${company.clientId}.custom`,
              clientId: company.clientId,
              id: item.id,
              isAval: false,
              isCustom: true,
              isRep: false,
              text: item.fullName,
            }))
          )
        setNewDataFirmantes((prev) => ({
          ...prev,
          [value[0]]: _newData,
        }))
      }

      let _objSignature = {}
      for (const item of Object.entries(newObjSignatures).filter(
        ([key]) => !key.includes("joint")
      )) {
        let _signer = newDataFirmantes[item[0]].data.find(
          (x) => x.name === item[1]
        )
        _objSignature[item[0]] = _signer ? _signer.name : item[0]
      }
      setNewObjSignatures(_objSignature)

      // _objSignature[item] =
      //     newDataFirmantes[item].find((x) => x.id === newObjSignatures[item])?.id ||
      //     item;
      // }
      // setNewObjSignatures(_objSignature);

      const data = {
        creditLine: line.id,
        accountId: props.match.params.id,
        companyId: company.id,
        signatures: [],
      }

      /*Firmas normales*/

      //Firmantes avales
      const _fn = Object.entries(_objSignature).filter(
        ([key]) => !key.includes("joint")
      )

      //Crear objetos de firmantes avales
      for (const value of _fn) {
        let keySigner = value[0].split(".")
        let _signer = newDataFirmantes[value[0]].data.find(
          (item) => item.name === value[1]
        )
        data.signatures.push({
          signerId: keySigner[0],
          signedBy: _signer.id ? _signer.id : "",
          isLegal: _signer.isRep,
          isCustom: _signer.isCustom,
          clientId: _signer.clientId ? _signer.clientId : null,
          isJoint: false,
          keySigner: value[0],
          selectedValue: value[1],
        })
      }
      let firmantesMancomunados = Object.entries(objSignaturesJoint).filter(
        ([key, value]) => value.length > 0
      )
      if (firmantesMancomunados.length > 0) {
        for (const value of firmantesMancomunados) {
          let keySigner = value[0].split(".")
          data.signatures.push({
            signerId: keySigner[0],
            signedBy: "",
            isLegal: true,
            isCustom: false,
            clientId: keySigner[1],
            isJoint: true,
            keySigner: value[0],
            selectedValue: JSON.stringify(
              value[1] /*.map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                  clientId: item.clientId,
                };
              })*/
            ),
          })
        }
      }

      const _res = await AccountService.saveConfiguration(data)
      // setCargando(false)
      Loading()
    } catch (error) {
      Loading()
      if (error.response && error.response.status == 401) signOutCognito()
      success = false
      message = error.message
      // setCargando(false)
    }
    setDataResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
      isRedirect: isRedirect,
    })
    setIdCustomSigner(null)
    setVisibleAdd(false)
    setVisible(!visible)
  }

  let history = useHistory()
  const handleClickCancel = () => {
    history.push("/cuentas")
  }
  useEffect(() => {
    if (props.match.params.id) {
      getCompanies(props.match.params.id)
    }
    // if(company){
    //   getCreditLines(company.clientId);
    // }
    // if(line){
    //   _newImplementation(line.id);
    // }
  }, [])
  return (
    <div className="finbe-app-container">
      <Form
        render={(formRenderProps) => (
          <FormElement>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputComboBox}
                  data={companies}
                  dClassName="k-form-field-wrap finbe-form-combo"
                  id="company"
                  label="Empresa"
                  name="company"
                  onChange={handleChange}
                  placeholder="Seleccionar empresa"
                  value={company}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputComboBox}
                  data={lines}
                  dClassName="k-form-field-wrap finbe-form-combo"
                  id="creditLine"
                  label="Línea de crédito"
                  name="creditLine"
                  onChange={handleChange}
                  placeholder="Seleccionar línea de crédito"
                  value={line}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>

            {/* Añadir switch de firma electronica */}
            {line && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "10px",
                  }}
                >
                  <label>Firma electronica</label>
                  <InputSwitch
                    checked={isElectronic}
                    onChange={() => {
                      setIsElectronic(!isElectronic)
                    }}
                    // data={props.dataItem.id}
                  />
                </div>
                <br />
                {/* Mostrar cuando sea firma manual */}
                {!isElectronic && (
                  <>
                    <legend className={"k-form-legend"}>Personalizados:</legend>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <ButtonPrimary
                        className=""
                        icon="add"
                        onClick={() => {
                          setVisibleAdd(true)
                        }}
                        pClassName="finbe-btn-margin"
                        primary={true}
                        text="Añadir nuevo"
                        disabled={line ? false : true}
                      />
                    </div>
                    <div style={{ marginBottom: "15px" }}>
                      <Grid
                        className="finbe-table"
                        data={ProcessGrid(customSigners, dataState)}
                        {...dataState}
                        onDataStateChange={(e) => {
                          setDataState(e.data)
                        }}
                        pageable={{
                          info: false,
                        }}
                        sortable
                        skip={dataState.skip}
                        take={dataState.take}
                        resizable
                      >
                        <Column field="fullName" title="Nombre" />
                        <Column
                          field="createdAt"
                          title="Fecha de creación"
                          filter="date"
                          format="{0:dd/MM/yyyy}"
                        />
                        <Column
                          title="Acciones"
                          cell={(props) => (
                            <td style={{ display: "flex" }}>
                              <ButtonAction
                                title={"Editar"}
                                icon=" icon editar"
                                isRedirect={false}
                                look="bare"
                                primary={false}
                                onClick={() => {
                                  const _cs = customSigners.find(
                                    (item) => item.id === props.dataItem.id
                                  )
                                  setCustomSigner(_cs.fullName)
                                  setIdCustomSigner(_cs.id)
                                  setVisibleAdd(true)
                                }}
                              />
                              <ButtonAction
                                title={"Eliminar"}
                                icon=" icon remover-eliminar"
                                isRedirect={false}
                                look="bare"
                                primary={false}
                                onClick={() => {
                                  const _cs = customSigners.find(
                                    (item) => item.id === props.dataItem.id
                                  )
                                  setIdCustomSigner(_cs.id)
                                  setAction("DELETE")
                                  handleConfirmRemove()
                                }}
                              />
                            </td>
                          )}
                        />
                      </Grid>
                    </div>
                    <legend className={"k-form-legend"}>
                      Avales Personas Fisicas:
                    </legend>
                    {newList
                      .filter((item) => item.isAval)
                      .map((item, index) => {
                        return (
                          <div key={index} style={{ display: "flex" }}>
                            <div style={{ width: "50%" }}>
                              <Field
                                component={InputComboBox}
                                data={newList.filter((item) => item.isAval)}
                                dClassName="k-form-field-wrap finbe-form-combo"
                                id="aval"
                                label="Avales"
                                placeholder="Seleccionar aval"
                                value={item}
                                disabled={true}
                                wrapperStyle={{ width: "90%" }}
                              />
                            </div>
                            <div style={{ width: "50%" }}>
                              <Field
                                component={InputComboBox}
                                data={newDataFirmantes[item.name].data}
                                dataItemKey={"name"}
                                dClassName="k-form-field-wrap finbe-form-combo"
                                id="firmante"
                                label="Firmante"
                                name={item.name}
                                onChange={handeChangeSignatures}
                                placeholder="Seleccionar firmante"
                                value={newDataFirmantes[item.name].data.find(
                                  (x) => x.name == newObjSignatures[item.name]
                                )}
                                wrapperStyle={{ width: "90%" }}
                              />
                            </div>
                          </div>
                        )
                      })}
                    <legend className={"k-form-legend"}>
                      Avales Personas Morales:
                    </legend>
                    {avalesPM &&
                      avalesPM.map((item, index) => {
                        return (
                          <div key={index}>
                            <h4>{`${item.nombres} ${item.apellidos}`}</h4>
                            {newList
                              .filter(
                                (x) =>
                                  x.isRep && x.clientId === item.numeroCliente
                              )
                              .map((ele, k) => {
                                return (
                                  <div key={k} style={{ display: "flex" }}>
                                    <div style={{ width: "50%" }}>
                                      <Field
                                        component={InputComboBox}
                                        data={newList.filter(
                                          (x) =>
                                            x.isRep &&
                                            x.clientId === item.numeroCliente
                                        )}
                                        dClassName="k-form-field-wrap finbe-form-combo"
                                        id="representante"
                                        label="Representante Legal"
                                        placeholder="Seleccionar representante"
                                        value={ele}
                                        disabled={true}
                                        wrapperStyle={{ width: "90%" }}
                                      />
                                    </div>
                                    <div style={{ width: "50%" }}>
                                      <Field
                                        component={
                                          ele.name.includes("joint")
                                            ? InputMultiSelect
                                            : InputComboBox
                                        }
                                        data={newDataFirmantes[ele.name].data}
                                        disabled={
                                          ele.name.includes("joint")
                                            ? newList.filter(
                                                (x) =>
                                                  !x.name.includes("joint") &&
                                                  x.clientId === ele.clientId &&
                                                  x.isRep
                                              ).length < 2
                                            : Object.entries(
                                                objSignaturesJoint
                                              ).find(
                                                ([key, value]) =>
                                                  key.includes(ele.clientId) &&
                                                  value.length > 0
                                              )
                                            ? true
                                            : newDataFirmantes[ele.name]
                                                .disabled
                                        }
                                        dataItemKey={"name"}
                                        dClassName="k-form-field-wrap finbe-form-combo"
                                        id="firmante"
                                        label="Firmante"
                                        name={ele.name}
                                        onChange={
                                          ele.name.includes("joint")
                                            ? handleChangeJointSignatures
                                            : handeChangeSignatures
                                        }
                                        placeholder="Seleccionar firmante"
                                        value={
                                          ele.name.includes("joint")
                                            ? objSignaturesJoint[ele.name]
                                            : newDataFirmantes[
                                                ele.name
                                              ].data.find(
                                                (x) =>
                                                  x.name ===
                                                  newObjSignatures[ele.name]
                                              )
                                        }
                                        wrapperStyle={{ width: "90%" }}
                                      />
                                    </div>
                                  </div>
                                )
                              })}
                          </div>
                        )
                      })}

                    <legend className={"k-form-legend"}>Representantes:</legend>
                    {company !== null &&
                      newList
                        .filter(
                          (item) =>
                            item.isRep && item.clientId === company.clientId
                        )
                        // .filter((x) => x.id == company.legalId)
                        .map((item, index) => {
                          return (
                            <div key={index} style={{ display: "flex" }}>
                              <div style={{ width: "50%" }}>
                                <Field
                                  component={InputComboBox}
                                  data={newList.filter(
                                    (item) =>
                                      item.isRep &&
                                      item.clientId === company.clientId
                                  )}
                                  dClassName="k-form-field-wrap finbe-form-combo"
                                  id="representante"
                                  label="Representante Legal"
                                  placeholder="Seleccionar representante"
                                  value={item}
                                  disabled={true}
                                  wrapperStyle={{ width: "90%" }}
                                />
                              </div>
                              <div style={{ width: "50%" }}>
                                <Field
                                  component={
                                    item.name.includes("joint")
                                      ? InputMultiSelect
                                      : InputComboBox
                                  }
                                  data={newDataFirmantes[item.name].data}
                                  disabled={
                                    item.name.includes("joint")
                                      ? newList.filter(
                                          (x) =>
                                            !x.name.includes("joint") &&
                                            x.clientId === company.clientId &&
                                            x.isRep
                                        ).length < 2
                                      : Object.entries(objSignaturesJoint).find(
                                          ([key, value]) =>
                                            key.includes(item.clientId) &&
                                            value.length > 0
                                        )
                                      ? true
                                      : newDataFirmantes[item.name].disabled
                                  }
                                  dataItemKey={"name"}
                                  dClassName="k-form-field-wrap finbe-form-combo"
                                  id="firmante"
                                  label="Firmante"
                                  name={item.name}
                                  onChange={
                                    item.name.includes("joint")
                                      ? handleChangeJointSignatures
                                      : handeChangeSignatures
                                  }
                                  placeholder="Seleccionar firmante"
                                  value={
                                    item.name.includes("joint")
                                      ? objSignaturesJoint[item.name]
                                      : newDataFirmantes[item.name].data.find(
                                          (x) =>
                                            x.name ===
                                            newObjSignatures[item.name]
                                        )
                                  }
                                  // value={dataFirmantesL[item.id].find(
                                  //   (x) => x.id == signaturesR[item.id]
                                  // )}
                                  wrapperStyle={{ width: "90%" }}
                                />
                              </div>
                            </div>
                          )
                        })}
                  </>
                )}
              </>
            )}
            {/* Fin Mostrar cuando sea firma Manual */}
          </FormElement>
        )}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}></div>
        <div style={{ display: "flex", textAling: "left" }}>
          <ButtonTertiary
            className=""
            icon="close"
            onClick={handleClickCancel}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Cancelar"
          />
          <ButtonPrimary
            className=""
            icon="check"
            onClick={openDialog}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Guardar"
          />
        </div>
      </div>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={dataResponse.title}
        visible={visible}
      >
        {dataResponse.message}
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => {
          setVisibleConfirm(false)
        }}
        handelCancel={() => setVisibleConfirm(false)}
        handelAccept={action === "DELETE" ? removeCustomSigner : saveConfig}
        title={dataConfirm.title}
        visible={visibleConfirm}
        visibleCancelButton={true}
      >
        {dataConfirm.message}
      </DialogBox>
      <DialogBox
        handelAccept={saveCustomSigner}
        handelCancel={handleCloseDialogAdd}
        handelClose={handleCloseDialogAdd}
        visibleButtons={true}
        visibleCancelButton={true}
        buttonSuccessText="Guardar"
        buttonDangerText="Cancelar"
        title="Asignar firmante personalizado"
        visible={visibleAdd}
      >
        <div>
          <Form
            render={(formRenderProps) => (
              <FormElement>
                <div style={{ width: "100%" }}>
                  <Field
                    component={InputText}
                    dClassName="k-form-field-wrap finbe-form-combo"
                    id="customSigner"
                    label="Nombre (s)"
                    name="customSigner"
                    onChange={handleChangeCustomSigner}
                    placeholder="ingresar nombre completo"
                    value={customSigner}
                    wrapperStyle={{ width: "90%" }}
                  />
                </div>
              </FormElement>
            )}
          />
        </div>
      </DialogBox>
    </div>
  )
}

export default Configuration
