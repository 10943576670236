import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { setReaded } from "../../Services/NotificationsService";
import UserServices from "../../Services/UserServices";
import { useUsuario } from "../../Context/UserContext";
import ModalNotificacion from "./modalNotificacion";

import moment from "moment";
import { FilterBox } from "../Sections/Filters";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import {
  InputComboBox,
  InputRangeDatePicker,
} from "../Sections/Components/Inputs";
import { ButtonAction } from "../Sections/Components/Buttons";
import { process } from "@progress/kendo-data-query";
import useLoader from "../../hooks/useLoader";

const Notifications = (props) => {
  const { usuario, signOutCognito } = useUsuario();
  const types = [
    { id: 0, text: "Seleccione tipo" },
    { id: 1, text: "Disposición" },
    { id: 2, text: "Excepción" },
  ];
  const initialFilters = {
    type: null,
    datePickerFilter: { start: null, end: null },
  };

  const [notification, setNotification] = useState("");
  const [text, setText] = useState("");
  const [notifications, setNotifications] = useState([]);

  const [dataFilters, setDataFilters] = useState(initialFilters);
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [dataState, setDataState] = useState({
    sort: [{ field: "createdAt", dir: "desc" }],
    take: showRegister.text,
    skip: 0,
  });
  const Loading = useLoader();
  const onChange = (event) => {
    const { name, value } = event.target;
    if (value != null) {
      if (value.id) {
        setDataFilters({ ...dataFilters, [name]: value.id });
      } else {
        setDataFilters({
          ...dataFilters,
          ["datePickerFilter"]: event.target.value,
        });
      }
    } else {
      setDataFilters({ ...dataFilters, [name]: "" });
    }
  };

  const handleClickSearch = async () => {
    let data = {};
    if (dataFilters.type !== null && dataFilters.type > 0)
      data.type = dataFilters.type;
    if (dataFilters.datePickerFilter.start !== null)
      data.from = moment(dataFilters.datePickerFilter.start).format(
        "YYYY-MM-DD"
      );
    if (dataFilters.datePickerFilter.end !== null)
      data.to = moment(dataFilters.datePickerFilter.end).format("YYYY-MM-DD");

    try {
      Loading();
      const res = await UserServices.getNotifications(usuario.id, {
        params: data,
      });
      setDataState({ ...dataState, skip: 0 });
      setNotifications(res.data);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();

    }
  };

  const removeNotification = () => {
    const nextNotifications = notifications.filter((t) => t.id != notification);
    setNotifications([...nextNotifications]);
    toggleDialog("Realizado");
  };

  const markAsRead = async () => {
    const nextNotifications = notifications.filter(
      (t) => t.id != notification.id
    );
    Loading();
    setReaded(notification.id)
      .then((res) => {
        const { createdAt, ...rest } = res.data;
        const date = moment(createdAt, "DD/MM/YYYY HH:mm").toDate();
        const _not = {
          ...rest,
          createdAt: date,
        };
        setNotifications([...nextNotifications, _not]);
        toggleDialog("Realizado");
        Loading();
      })
      .catch((e) => {
        Loading();
        if (e?.response?.status == 401) signOutCognito();
        toggleDialog("Realizado");
      });
  };

  const [visible, setVisible] = useState(false);
  const [dataDialog, setDataDialog] = useState({});

  const toggleDialog = (e) => {
    if (e == "Realizado") {
      setVisible(false);
    } else if (
      e.currentTarget != undefined &&
      e.currentTarget.getAttribute("data") != null
    ) {
      const id = e.currentTarget.getAttribute("data");

      setNotification(id);
      setVisible(!visible);
      let _dD = {
        type: "delete",
        title: null,
        close: null,
        classMessage: null,
        message: "¿Seguro que deseas borrar la notificación?",
        textConfirm: "Aceptar",
        textCancel: "Cancelar",
      };
      setDataDialog(_dD);
    } else {
      setVisible(!visible);
    }
  };

  const toggleMarkAsRead = (e) => {
    if (e == "Realizado") {
      setVisible(false);
    } else if (
      e.currentTarget != undefined &&
      e.currentTarget.getAttribute("data") != null
    ) {
      const id = e.currentTarget.getAttribute("data");

      setNotification(notifications.filter((t) => t.id === id)[0]);
      setVisible(!visible);
    } else {
      setVisible(!visible);
    }
  };

  useEffect(() => {
    if (usuario != null) {
      Loading();
      UserServices.getNotifications(usuario.id)
        .then((res) => {
          const _data = res.data.map((value) => {
            const { createdAt, ...res } = value;
            const date = moment(createdAt, "DD/MM/YYYY HH:mm").toDate();
            return {
              ...res,
              createdAt: date,
            };
          });
          setNotifications(_data);
          Loading();
        })
        .catch((e) => {
          Loading();
          if (e?.response?.status == 401) signOutCognito();
        });
    }
  }, [usuario]);

  const handleShowRegisterChange = (e) => {
    const { value } = e.target;
    setShowRegister(value);
    setDataState({ ...dataState, ["take"]: value.text, skip: 0 });
  };
  return (
    <>
      <div className="finbe-app-container">
        <div className="finbe-app-section-top">
          <FilterBox
            buttonSearchOnClick={handleClickSearch}
            inputDropDownValue={showRegister}
            inputFilterOnChange={handleShowRegisterChange}
            isMoreFilter={false}
          >
            <Form
              render={(formRenderProps) => (
                <FormElement style={{ display: "flex" }}>
                  <Field
                    dClassName="k-form-field-wrap finbe-form-combo"
                    id="type"
                    name="type"
                    data={types}
                    onChange={onChange}
                    placeholder="Selec. tipo"
                    value={types.find((x) => x.id == dataFilters.type)}
                    wrapperStyle={{ width: "25%", margin: "1em" }}
                    component={InputComboBox}
                  />
                  <Field
                    dClassName="k-form-field-wrap finbe-form-date"
                    dateRangePickerId="datePickerFilter"
                    dateRangePickerName="datePickerFilter"
                    dateRangePickerOnChange={onChange}
                    fieldWrapperStyle={{ width: "66%", paddingLeft: "1.2em" }}
                    defaultValue={{
                      start: dataFilters.datePickerFilter.start,
                      end: dataFilters.datePickerFilter.end,
                    }}
                    dateRangePickerValue={dataFilters.datePickerFilter}
                    component={InputRangeDatePicker}
                  />
                </FormElement>
              )}
            />
          </FilterBox>
        </div>
        <div>
          <Grid
            className="finbe-table"
            data={process(notifications, dataState)}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.data);
            }}
            sortable
            pageable={{
              info: false,
            }}
            skip={dataState.skip}
            take={dataState.take}
            resizable
          >
            <Column
              field="dataItem.type"
              title="Tipo"
              cell={(props) => (
                <td>
                  {!props.dataItem.isReaded && (
                    <span className="icon circulo-rojo-notificacion"></span>
                  )}
                  {props.dataItem.type == 1 ? "Disposición" : ""}
                  {props.dataItem.type == 2 ? "Excepción" : ""}
                </td>
              )}
            />

            <Column
              title="Descripción"
              cell={(props) => <td>{props.dataItem.message}</td>}
            />

            <Column
              field="createdAt"
              title="Fecha"
              filter="date"
              format="{0:dd/MM/yyyy HH:mm}"
            />
            <Column
              title="Acciones"
              cell={(props) => (
                <td style={{ display: "table-cell" }}>
                  <ButtonAction
                    title={"Visualizar"}
                    data={props.dataItem.id}
                    icon=" icon visualizar"
                    isRedirect={false}
                    look="bare"
                    onClick={toggleMarkAsRead}
                    primary={false}
                  />
                </td>
              )}
            />
          </Grid>
        </div>
      </div>
      {visible && (
        <ModalNotificacion
          type={
            notification.type
              ? notification.type === 1
                ? "Disposiciones"
                : notification.type === 2
                ? "Excepciones"
                : ""
              : ""
          }
          message={notification.message ? notification.message : ""}
          onClose={markAsRead}
        />
      )}
    </>
  );
};

export default Notifications;
