import React, { useState, useEffect } from "react";

import TimePicker from "../Inputs/HourMinutePicker";
import { Numeric } from "../Inputs/Inputs";

import { DialogBox } from "../Sections/Dialogs";

import ConfigurationService from "../../Services/ConfigurationService";

import "./config.css";
import { useUsuario } from "../../Context/UserContext";
import useLoader from "../../hooks/useLoader";
import { InputText } from "../Sections/Components/Inputs";

const baseElement = { days: 0, hours: 0, minutes: 0 };

const Element = (props) => {
  const { value, name, label, showDays = true, showTime=true, onChange } = props;

  const { days, hours, minutes } = value;

  const onChangeDays = (e) => {
    const { value } = e.target;
    const nextEvent = {
      name: name,
      value: {
        days: value,
        hours: hours,
        minutes: minutes,
      },
    };
    onChange(nextEvent);
  };

  const onChangeTimePicker = (e) => {
    const { value } = e;
    const nextEvent = {
      name,
      value: {
        ...value,
        days: days,
      },
    };

    onChange(nextEvent);
  };

  return (
    <div className="config-element">
      {label ? <span className="k-label element">{label}</span> : null}

      <div className="row">
        {showDays && (
          <Numeric label="Días" value={days} min={0} onChange={onChangeDays} />
        )}

        {showTime&&<TimePicker
          value={{ hours: hours, minutes, minutes }}
          onChange={onChangeTimePicker}
          label="Horas"
        />}
      </div>
    </div>
  );
};

const Config = () => {
  const { signOutCognito } = useUsuario();
  const [email, setEmail] = useState();
  const [provision, setProvision] = useState(baseElement);
  const [transunion, setTransunion] = useState(baseElement);
  const [repuve, setRepuve] = useState(baseElement);
  const [ax, setAx] = useState(baseElement);

  const [startOperation, setStartOperation] = useState(baseElement);
  const [endOperation, setEndOperation] = useState(baseElement);

  const [dialog, setDialog] = useState({
    isOpen: false,
    title: "",
  });
  const Loading = useLoader();

  /**Llamar servicio, obtener configuración de este */
  useEffect(() => {
    const todo = async () => {
      try {
        Loading();
        const response = await ConfigurationService.getAll();
        const { data } = response;
        extractData(data);
        Loading();
      } catch (error) {
        Loading();
        if (error?.response?.status == 401) signOutCognito();
      }
    };
    todo();
  }, []);

  /**Extra la data del servicio y la coloca en los estados correspondientes */
  const extractData = (data) => {
    const keys = [
      "startOperation",
      "endOperation",
      "provision",
      "transunion",
      "repuve",
      "ax",
      "email",
    ];

    keys.forEach((key) => {
      const next = data[key];

      if (next) {
        if (key == "email") {
          setEmail(next);
        } else {
          const { days, hours } = next;
          const [h, m] = hours.split(":");
          const value = {
            days: days,
            hours: Number(h),
            minutes: Number(m),
          };

          switch (key) {
            case "startOperation":
              setStartOperation(value);
              break;
            case "endOperation":
              setEndOperation(value);
              break;
            case "provision":
              setProvision(value);
              break;

            case "transunion":
              setTransunion(value);
              break;

            case "repuve":
              setRepuve(value);
              break;

            case "ax":
              setAx(value);
              break;
          }
        }
      }
    });
  };

  /**Genera la data de los estados para su guardado */
  const genData = () => {
    let data = {};

    const parseElement = (element) => {
      const { days, hours, minutes } = element;

      const nextHour = hours < 10 ? `0${hours}` : `${hours}`;
      const nextMinute = minutes < 10 ? `0${minutes}` : `${minutes}`;

      const next = {
        days: days,
        hours: `${nextHour}:${nextMinute}`,
      };

      return next;
    };

    data.startOperation = parseElement(startOperation);
    data.endOperation = parseElement(endOperation);
    data.provision = parseElement(provision);
    data.transunion = parseElement(transunion);
    data.repuve = parseElement(repuve);
    data.ax = parseElement(repuve);
    data.email = email;

    return data;
  };

  const requestSave = () => {
    setDialog({
      isOpen: true,
      title: "Guardar cambios",
      children: <div>¿Desea guardar la configuración actual?</div>,
      onCancel: () => setDialog({ ...dialog, isOpen: false }),
      onSave: save,
      textCancel: "Cancelar",
      textConfirm: "Aceptar",
    });
  };

  const save = async () => {
    try {
      Loading();
      const _da=genData()
      const response = await ConfigurationService.save(_da);

      setDialog({
        ...dialog,
        isOpen: true,
        title: "Guardado",
        children: <div>Configuración guardada</div>,
        onSave: () => setDialog({ ...dialog, isOpen: false }),
        onCancel: null,
        textConfirm: "Aceptar",
      });
      Loading();
    } catch (e) {
      Loading();
      if (e?.response?.status == 401) signOutCognito();
      setDialog({
        ...dialog,
        isOpen: true,
        title: "Error",
        children: <div>{String(e)}</div>,
        onSave: () => setDialog({ ...dialog, isOpen: false }),
        onCancel: null,
        textConfirm: "Aceptar",
      });
    }
  };

  const onChange = (e) => {
    const { name, value } = e;
    if (!name) setEmail(value);
    else
      switch (name) {
        case "startOperation":
          setStartOperation(value);
          break;
        case "endOperation":
          setEndOperation(value);
          break;
        case "provision":
          setProvision(value);
          break;

        case "transunion":
          setTransunion(value);
          return;

        case "repuve":
          setRepuve(value);
          break;

        case "ax":
          setAx(value);
          break;
        default:
          return;
      }
  };

  return (
    <div>
      <div className="finbe-app-container">
        <span className="k-label">Configuración de Disposiciones</span>
        <br></br>
        <div className="padding-right-1mm">
          <div className="config-element">
            <span className="k-label element">Correo de disposición</span>
            <div className="row">
              <InputText
                dClassName="k-form-field-wrap finbe-form-field-filter"
                id="email"
                label="Correo electrónico"
                nombre="email"
                onChange={onChange}
                placeholder="Ingresar correo electrónico"
                value={email}
                // required={true}
                rules={"([a-z0-9_.-]+)@([da-z.-]+).([a-z.]{2,6})$"}
                validityStyles={true}
                minLength={1}
                maxLength={150}
                wrapperStyle={{ width: "90%" }}
              />
            </div>
          </div>
        </div>
        <div className="padding-right-1mm">
          <Element
            name="startOperation"
            label="Inicio de operaciones"
            value={startOperation}
            onChange={onChange}
            showDays={false}
          />
        </div>
        <div className="padding-right-1mm">
          <Element
            name="endOperation"
            label="Termino de operaciones"
            value={endOperation}
            onChange={onChange}
            showDays={false}
          />
        </div>
        <div className="padding-right-1mm">
          <Element
            name="provision"
            label="Vigencia de disposición"
            value={provision}
            onChange={onChange}
            showTime={false}
          />
        </div>
      </div>
      <br></br>
      <br></br>

      <div className="finbe-app-container container">
        <span className="k-label">
          Configuración de validaciones periódicas
        </span>
        <br></br>

        <div className="col">
          <div className="row half-width padding-right-2mm">
            <Element
              name="ax"
              label="Validación AX"
              value={ax}
              onChange={onChange}
            />
          </div>

          <div className="row half-width padding-right-1mm">
            <Element
              name="transunion"
              label="Validación TransUnion"
              value={transunion}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="col">
          <div className="row padding-right-3mm">
            <Element
              name="repuve"
              label="Validación REPUVE"
              value={repuve}
              onChange={onChange}
            />
          </div>
        </div>
      </div>

      <div
        className="k-button-group formButtons"
        role="group"
        aria-multiselectable="true"
      >
        <button className="k-button k-group-end" onClick={requestSave}>
          Guardar
        </button>
      </div>

      <DialogBox
        visible={dialog.isOpen}
        title={dialog.title}
        visibleButtons={true}
        buttonSuccessText={dialog.textConfirm}
        handelAccept={dialog.onSave}
        visibleCancelButton={dialog.onCancel != null}
        handelClose={dialog.onCancel}
        children={dialog.children}
      />
    </div>
  );
};

export default Config;
