import React, { useEffect, useState } from "react";
import RefinancingService from "../../Services/RefinancingService";
import {
  LabelWithText,
  LabelWithTextBorder,
} from "../Sections/Components/Tags";
import { StepBox } from "../Sections/Steps";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { MoneyUnits } from "../Provisions/Sections/Units";
import Units from "./Sections/Units";
import UnitsService from "../../Services/UnitsService";
import FinbeService from "../../Services/Finbe/FinbeService";
import {
  ButtonAction,
  ButtonDanger,
  ButtonPrimary,
  ButtonSuccess,
} from "../Sections/Components/Buttons";
import { useUsuario } from "../../Context/UserContext";
import { DialogBox } from "../Sections/Dialogs";
import { useHistory } from "react-router";
import useLoader from "../../hooks/useLoader";
import DocumentService from "../../Services/DocumentService";
import { Field, Form } from "@progress/kendo-react-form";
import { InputText } from "../Inputs/Inputs";
import { RadioButton } from "@progress/kendo-react-inputs";
import useOperation from "../../hooks/useOperation";
const numberFormat = (value) => new Intl.NumberFormat("en-US").format(value);

export default function Visualize(props) {
  const history = useHistory();
  const Loading = useLoader();
  const Operation = useOperation();
  const { usuario, signOutCognito } = useUsuario();
  const [refinancing, setRefinancing] = useState({});
  const [units, setUnits] = useState([]);
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [availableBalance, setAvailableBalance] = useState(0);
  const [dataState, setDataState] = useState({
    sort: [{ field: "vinNumber", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });
  const [visibleResponse, setVisibleResponse] = useState(false);
  const [response, setResponse] = useState({
    title: "",
    message: "",
    success: false,
    isRedirect: false,
  });

  const [visibleCompleteEvidences, setVisibleCompleteEvidences] =
    useState(false);
  const [visibleSendCRM, setVisibleSendCRM] = useState(false);
  const [visibleLiberate, setVisibleLiberate] = useState(false);
  const [visibleRedo, setVisibleRedo] = useState(false);
  const [visibleDialogValid, setVisibleDialogValid] = useState(false);
  const [redoDocument, setRedoDocumet] = useState({ title: "", text: "" });
  const [visibleUpdateFolio, setVisibleUpdateFolio] = useState(false);
  const [updateFolio, setUpdateFolio] = useState("");
  const [useManualFolio, setUseManualfolio] = useState(false);

  const getRefinancing = async (id) => {
    try {
      Loading();
      const { data } = await RefinancingService.get(id);
      const { units, ...rest } = data;
      if (usuario.type == 3 && rest.status == 3) {
        rest.total = 0;
      }
      // units.map((item) => {
      //   item.check = false;
      //   if(rest.status<=3 && usuario.type==2){
      //     rest.total += item.amount;
      //   //   item.validAx=null
      //   //   item.validRepuve=null
      //   //   item.validTransunion=null

      //   }
      //   return item;
      // });
      setRefinancing(rest);
      setUnits(units);
      await getCreditLine(rest.company.clientId, rest.creditLine);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();

    }
  };
  const getCreditLine = async (clientId, cL) => {
    try {
      Loading();
      const { data } = await FinbeService.getConfigurationCreditLine({
        params: { numeroCliente: clientId },
      });

      const creditLine = data.find((item) => item.lineaCredito == cL);
      setAvailableBalance(creditLine.montoRestante);
      Loading();
    } catch (error) {
      Loading();
    }
  };

  const handleDialogCompleteEvidences = (e) => {
    setVisibleCompleteEvidences(!visibleSendCRM);
  };
  const handleCompleteEvidences = async () => {
    let success = true;
    let message = "";
    setVisibleCompleteEvidences(false);
    try {
      Loading();
      Operation();
      const { data } = await RefinancingService.setStatus(
        props.match.params.id,
        {
          status: 3,
        }
      );
      message = "Las unidades fueron enviadas";
      setRefinancing({ ...refinancing, status: data.status });
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
      success = false;
      message = error.message;
    }
    /** Show response dialog **/

    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: false,
      isRedirect: false,
    });
    setVisibleResponse(true);
  };

  const confirmUnit = (id, status, comment) => {
    const _units = [...units];
    _units.map((item) => {
      if (item.id == id) {
        item.status = status;
        item.comment = comment;
      }
      return item;
    });
    setUnits(_units);
  };

  const handleConfirmUnits = async () => {
    let success = true;
    let message = "";
    let isRedirect = false;
    try {
      Loading();
      const _units = [];
      for (const item of units) {
        _units.push({
          id: item.id,
          validAx: item.validAx,
          validRepuve: item.validRepuve,
          validTransunion: item.validTransunion,
          comment: item.comment,
          status: item.status,
        });
      }

      const { data } = await RefinancingService.confirmUnits(
        props.match.params.id,
        { units: _units }
      );
      message = "Refinanciamiento actualizado correctamente";
      isRedirect = true;
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOutCognito();
      success = false;
      message = error.message;
    }
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message,
      success,
      isRedirect,
    });
    setVisibleResponse(true);
  };
  const handleAccept = async () => {
    let success = true;
    let message = "";
    let isRedirect = false;
    if (availableBalance - refinancing.total < 0) {
      setResponse({
        title: "Ocurrió un error",
        message: "El monto total supera el saldo restante",
        success: false,
        isRedirect,
      });
      setVisibleResponse(true);
    } else {
      try {
        Loading();
        const _units = [];
        for (const item of [...units].filter((item) => item.check)) {
          _units.push({
            id: item.id,
            comment: item.comment,
            status: item.status,
          });
        }

        const { data } = await RefinancingService.acceptRefinancing(
          props.match.params.id,
          {
            units: _units,
            total: refinancing.total,
            useManualFolio: refinancing.useManualFolio,
            manualFolio: refinancing.manualFolio,
          }
        );
        message = "Refinanciamiento actualizado correctamente";
        isRedirect = true;
        Loading();
      } catch (error) {
        Loading();
        if (error.response && error.response.status == 401) signOutCognito();
        success = false;
        message = error.message;
      }
      setResponse({
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message,
        success,
        isRedirect,
      });
      setVisibleResponse(true);
    }
  };

  const handleDialogResponse = (e) => {
    setVisibleResponse(!visibleResponse);
    if (response.isRedirect) {
      history.push("/refinanciamientos");
    }
  };
  const handleCancel = () => {
    history.push("/refinanciamientos");
  };

  const renderCheck = (unit) => {
    if (unit !== undefined) {
      return (
        unit.status === 1 ||
        (unit.validRepuve && unit.validTransunion && unit.validAx)
      );
    }
    return false;
  };
  const handleCalculate = (amount, check) => {
    let result = 0;
    if (check) {
      result = refinancing.total + Number(amount.toFixed(2));
    } else {
      result = refinancing.total - Number(amount.toFixed(2));
    }
    setRefinancing({ ...refinancing, total: result });
  };
  const handelSelectionChange = (e) => {
    let unit = e.dataItem;
    const _units = [...units];
    let chekced = renderCheck(unit);
    _units.map((item) => {
      if (item.id === unit.id) {
        if (chekced) {
          item.check = !item.check;
          handleCalculate(unit.amount, item.check);
          // item.included = item.check;
        }
      }
      return item;
    });
    setUnits(_units);
  };
  const getDocument = async (url, download = false) => {
    try {
      Loading();
      const response = await DocumentService.showDocument({
        path: url,
        download: download,
      });
      window.open(response.data.url, "_blank");
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
      console.info(error);
    }
  };
  const handleConfirmValid = () => {
    // setDataDialogConfirm({
    //   title: "Validación de unidades",
    //   body: "¿Seguro que sea enviar las unidades a validar?",
    // });
    setVisibleDialogValid(!visibleDialogValid);
  };
  const handleValidUnits = async () => {
    setVisibleDialogValid(false);
    try {
      Loading();
      const _units = [];
      for (const unit of units) {
        const validation = await handleValidUnit(unit.vinNumber);
        unit.validAx = validation.validAx;
        unit.validRepuve = validation.validRepuve;
        unit.validTransunion = validation.validTransunion;
        _units.push(unit);
      }
      Loading();
      setUnits(_units);
    } catch (error) {
      Loading();
    }
  };

  const handleValidUnit = async (vin) => {
    let data = {
      validRepuve: false,
      validTransunion: false,
      validAx: false,
    };
    try {
      try {
        const repuve = await FinbeService.validateRepuve(vin);
        if (repuve !== undefined) {
          data.validRepuve = repuve.data.veredict.valid;
        }
      } catch (error) {
        data.validRepuve = false;
      }
      try {
        const transunion = await FinbeService.validateTransunion(vin);
        if (transunion !== undefined) {
          data.validTransunion = transunion.data.veredict.valid;
        }
      } catch (error) {
        data.validTransunion = false;
      }

      try {
        const ax = await FinbeService.validateAX(vin);
        if (ax !== undefined) {
          if (
            ax.conteo == 1 /*||
            (ax.conteo == 1 && ax.detalle[0].estatus == "Liquidado")*/
          ) {
            data.validAx = true;
          }
        }
      } catch (error) {
        data.validAx = false;
      }
      return data;
    } catch (error) {
    }
  };

  const handleDialogSendCRM = (e) => {
    setVisibleSendCRM(!visibleSendCRM);
  };
  const handleSendCRM = async () => {
    let success = true;
    let message = "";
    setVisibleSendCRM(false);
    try {
      Loading();
      let id = props.match.params.id;
      const { data } = await RefinancingService.sendCRM({ refinancingId: id });
      message = "Las unidades fueron enviadas a CRM";
      setRefinancing({ ...refinancing, status: data.status });
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
      success = false;
      message = error.message;
    }
    /** Show response dialog **/

    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: false,
      isRedirect: false,
    });
    setVisibleResponse(true);
  };

  const handleDialogLiberate = () => {
    setVisibleLiberate(!visibleLiberate);
  };

  const handleLiberate = async () => {
    let success = true;
    let message = "";
    setVisibleLiberate(false);
    try {
      Loading();
      let id = props.match.params.id;
      const { data } = await RefinancingService.setStatus(id, {
        status: 10,
      });
      message = "Las unidades fueron liberadas sin enviar a CRM";
      setRefinancing({ ...refinancing, status: data.status });
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
      success = false;
      message = error.message;
    }
    /** Show response dialog **/

    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: false,
      isRedirect: false,
    });
    setVisibleResponse(true);
  };

  const handleVisibleRedoDocument = (e) => {
    const target = e.currentTarget;
    if (target !== undefined) {
      let docId = target.getAttribute("data");
      const doc = refinancing.evidences.find((item) => item.id === docId);
      if (doc.typeSignDoc === 0) {
        setRedoDocumet({
          title: "Rehacer documento",
          text: "¿Seguro que desea rehacer el documento de firma?",
        });
      } else {
        setRedoDocumet({
          title: "Rehacer documentos",
          text: (
            <>
              <p>¿Seguro que desea rehacer el documento de firma?</p>
              <span>
                <strong>Nota:</strong> Todos los documentos se generaran
                nuevamente.
              </span>
            </>
          ),
        });
      }
      setVisibleRedo(true);
    }
  };

  const handleRedoDocument = async () => {
    let success = true;
    let message = "";
    setVisibleRedo(false);
    try {
      Loading();
      // alert("rehacer documento")
      const res = await DocumentService.redoDocumentRef({
        refinancingId: props.match.params.id,
      });
      message = "El documento se generó nuevamente";
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status === 401) signOutCognito();
      success = false;
      message = error.message;
    }
    /** Show response dialog **/
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: false,
      isRedirect: false,
    });
    setVisibleResponse(true);
  };
  const handleManualFolioCheck = (e) => {
    const { value, props } = e.target;

    if (props === undefined) {
      const { element } = e.target;
      setRefinancing({
        ...refinancing,
        useManualFolio: e.value,
      });
      // setUseManualfolio(e.value)
    } else {
      if(props.id === 'updateFolio'){
        setUpdateFolio(value);
      }else{
        setRefinancing({ ...refinancing, [props.id]: value });

      }
    }
  };
  const handleDialogUpdateFolio=()=>{
    setVisibleUpdateFolio(true)
  }
  const handleUpdateFolioManual = async()=>{
    let success = true;
    let message = "";
    let isRedirect = false;
    setVisibleUpdateFolio(false)

    try {
      Loading();
      let provisionId = props.match.params.id;
      if(updateFolio === "" || +updateFolio<=0)
      throw Error("El folio es requerido y debe de ser un numero mayor a 0");

      const {data} = await RefinancingService.updateManualFolio(provisionId,{manualFolio:+updateFolio});
      message = "El folio manual se actualizo correctamente"
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status === 401) signOutCognito();
      success = false;
      message = error.response ? error.response.data.message : error.message;
    }
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message,
      success,
      isRedirect,
    });
    setVisibleResponse(true);
    setUpdateFolio("");
  }
  useEffect(() => {
    if (props.match.params.id) {
      getRefinancing(props.match.params.id);
    }
  }, []);

  return (
    <div className="finbe-app-container">
      {/* {user.type != 3 && user.type !== 2 && user.type !== 1 && ( */}
      <h3>Seguimiento de refinanciamiento</h3>
      {/* )} */}
      <h4>
        N° de refinanciamiento{" "}
        {refinancing.useManualFolio
          ? refinancing.manualFolio
          : refinancing.folio}
      </h4>
      {/* {user.type != 3 && user.type !== 2 && user.type !== 1 && (
        <div>
          <Stepper
            className="finbe-stepper-horizontal"
            value={this.state.step}
            onChange={this.handleChangeStep}
            items={stepperItems}
          />
        </div>
      )} */}
      <div>
        <StepBox title="Datos de la empresa">
          <div style={{ display: "flex", marginBottom: "1.5em" }}>
            <LabelWithText
              bText={refinancing.company?.name}
              divStyle={{ width: "50%" }}
              labelText={"Empresa"}
            />
            <LabelWithText
              bText={refinancing.agency?.name}
              divStyle={{ width: "50%" }}
              labelText={"Sucursal"}
            />
          </div>
          <div style={{ display: "flex", marginBottom: "1.5em" }}>
            <LabelWithText
              divStyle={{ width: "50%" }}
              labelText={"Representante legal"}
              bText={refinancing.legalPerson?.name}
            />
          </div>
        </StepBox>
        <div
          className="finbe-app-line"
          style={{ marginTop: "1em", marginBottom: "1em" }}
        >
          {" "}
        </div>
        {(usuario.type == 3 || usuario.type == 4) && (
          <StepBox title="Descargar documentos firmados">
            {/* Falta los documentos de firma del refinanciamiento */}
            <Grid className="finbe-table" data={refinancing.evidences}>
              <Column field="fileName" title="Documento" />
              <Column
                title="Tamaño"
                cell={(props) => (
                  <td>{/*this._renderFileSize(props.dataItem.fileSize)*/}</td>
                )}
              />
              <Column
                title="Acciones"
                cell={(props) => (
                  <td>
                    <div style={{ display: "flex" }}>
                      <ButtonAction
                        title="Descargar"
                        icon={" icon descargar"}
                        look="bare"
                        primary={false}
                        onClick={() => {
                          getDocument(props.dataItem.url, true);
                        }}
                      />
                      {props.dataItem.type == 13 &&
                        (props.dataItem.typeSignDoc == 0 ||
                          props.dataItem.typeSignDoc == 2) && (
                          <ButtonAction
                            title={"Rehacer"}
                            data={props.dataItem.id}
                            disabled={false}
                            icon=" icon enviar"
                            look="bare"
                            onClick={handleVisibleRedoDocument}
                            primary={false}
                          />
                        )}
                    </div>
                  </td>
                )}
              />
            </Grid>
          </StepBox>
        )}
        <div
          className="finbe-app-line"
          style={{ marginTop: "1em", marginBottom: "1em" }}
        >
          {" "}
        </div>
        <StepBox title="Unidades Seleccionadas">
          <div
            style={{
              display: "flex",
              marginTop: "1em",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "50%" }}>
              <LabelWithText
                divStyle={{ width: "100%" }}
                labelText={"Línea de crédito"}
                bText={refinancing.creditLine}
              />
              <LabelWithText
                divStyle={{ width: "100%" }}
                labelText={"Plazo"}
                bText={refinancing.deadlines}
              />
              <LabelWithText
                divStyle={{ width: "100%" }}
                labelText={"Requiere evidencias"}
                bText={refinancing.requireEvidences ? "Si" : "No"}
              />
              {/* <LabelWithText
                divStyle={{ width: "100%" }}
                labelText={"Revisión administrador"}
                bText={refinancing.reviewAF ? "Si" : "No"}
              /> */}
              {/* {(user.type == 3 || user.type === 1) &&
              this.state.status === 3 ? (
                <LabelWithText
                  divStyle={{ width: "100%" }}
                  labelText={"Unidades en revisión"}
                  bText={`${
                    this.state.units.filter(
                      (item) => item.status === 1 || item.status === 2
                    ).length
                  } de ${this.state.units.length} unidades validadas`}
                />
              ) : null} */}
            </div>
          </div>
          <MoneyUnits
            availableBalance={numberFormat(availableBalance)}
            remainingBalance={numberFormat(
              +(availableBalance - refinancing.total).toFixed(2)
            )}
            totalAmount={numberFormat(refinancing.total)}
            showRemainingBalance={usuario.type == 3 || usuario.type == 2}
          />
          {usuario.type === 3 && refinancing.status === 3 ? (
            <div style={{ display: "flex", marginTop: "1em" }}>
              <div style={{ width: "50%" }}>
                <div>¿Desea agregar un folio manual a la disposición?</div>
                <div style={{ paddingLeft: "1em" }}>
                  <RadioButton
                    name="useManualFolio"
                    id="useManualFolio"
                    value={true}
                    checked={refinancing.useManualFolio === true}
                    label="Si"
                    onChange={handleManualFolioCheck}
                  />
                  <RadioButton
                    name="useManualFolio"
                    id="useManualFolio"
                    value={false}
                    checked={refinancing.useManualFolio === false}
                    label="No"
                    onChange={handleManualFolioCheck}
                  />
                </div>
              </div>
              <></>
            </div>
          ) : null}
          {usuario.type === 3 &&
          refinancing.status === 3 &&
          refinancing.useManualFolio ? (
            <div style={{ display: "flex" }}>
              <Form
                render={(formRenderProps) => (
                  <div style={{ width: "50%" }}>
                    <Field
                      component={InputText}
                      dClassName="k-form-field-wrap finbe-form-field-filter"
                      id="manualFolio"
                      label="Folio manual"
                      name="manualFolio"
                      onChange={handleManualFolioCheck}
                      editable={true}
                      placeholder="Agregue el folio manual"
                      value={refinancing.manualFolio}
                      validityStyles={true}
                      minLength={0}
                      maxLength={250}
                      type={"number"}
                      // wrapperStyle = {{ width: '90%' }}
                    />
                  </div>
                )}
              />
            </div>
          ) : null}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {usuario.type === 3 && refinancing.status === 6 && (
              <div style={{ display: "flex" }}>
                <ButtonSuccess
                  className=""
                  icon=""
                  onClick={handleDialogLiberate}
                  pClassName="finbe-btn-margin"
                  primary={true}
                  text="Liberar"
                  disabled={!units.length > 0}
                />
                <ButtonSuccess
                  className=""
                  icon=""
                  onClick={handleDialogSendCRM}
                  pClassName="finbe-btn-margin"
                  primary={true}
                  text="Enviar a CRM"
                  disabled={!units.length > 0}
                />
              </div>
            )}
          </div> */}
          <Units
            units={units}
            dataState={dataState}
            setDataState={setDataState}
            confirmUnit={confirmUnit}
            handelSelectionChange={handelSelectionChange}
            status={refinancing.status}
            requireEvidence={refinancing.requireEvidences}
            handleLiberate={handleDialogLiberate}
            handleSendCRM={handleDialogSendCRM}
            handleDialogUpdateFolio={handleDialogUpdateFolio}
          />
        </StepBox>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}></div>
        <div style={{ display: "flex", textAling: "left" }}>
          {usuario.type == 2 && refinancing.status == 3 && (
            <ButtonPrimary
              className=""
              icon="refresh"
              onClick={handleConfirmValid}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Validar unidades"
            />
          )}
          <ButtonDanger
            className=""
            icon="close"
            onClick={handleCancel}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Cerrar"
          />
          {usuario.type === 5 &&
            refinancing.status === 1 &&
            refinancing.requireEvidences && (
              <ButtonSuccess
                className=""
                icon="check"
                onClick={handleDialogCompleteEvidences}
                pClassName="finbe-btn-margin"
                primary={true}
                text="Evidencia completa"
              />
            )}
          {usuario.type === 3 && refinancing.status === 3 && (
            <ButtonSuccess
              disabled={!units.filter((item) => item.check).length > 0}
              className=""
              icon="check"
              onClick={handleAccept}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Aceptar"
            />
          )}
          {usuario.type === 2 && refinancing.status === 3 && (
            <ButtonSuccess
              className=""
              icon="check"
              onClick={handleConfirmUnits}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Confirmar"
            />
          )}
        </div>
      </div>
      {/* Actualización de folio manual */}
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => {
          setVisibleUpdateFolio(false);
          setUpdateFolio("")
        }}
        handelCancel={() => {
          setVisibleUpdateFolio(false);
          setUpdateFolio("")
        }}
        handelAccept={handleUpdateFolioManual}
        title="Actualización de folio manual"
        visible={visibleUpdateFolio}
        visibleCancelButton={true}
      >
        ¿Desea realizar la actualización del folio manual?
        <div>
          <InputText
            id="updateFolio"
            label="Folio manual"
            name="updateFolio"
            onChange={handleManualFolioCheck}
            editable={true}
            placeholder="Agregue el folio manual"
            value={updateFolio}
            validityStyles={true}
            required={true}
            // minLength={0}
            // maxLength={250}
            type={"number"}
          />
          <p>
            <span style={{ fontWeight: "bold", color: "red" }}> NOTA:</span>Este
            proceso no actualiza el folio en los documentos generados.
          </p>
        </div>
      </DialogBox>
      {/* Fin de actualización de folio manual */}
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => {
          setVisibleCompleteEvidences(false);
        }}
        handelCancel={() => {
          setVisibleCompleteEvidences(false);
        }}
        handelAccept={handleCompleteEvidences}
        title="Evidencia completa"
        visible={visibleCompleteEvidences}
        visibleCancelButton={true}
      >
        <p>¿Seguro que desea enviar a validación este refinanciamiento?</p>
        <p>
          <span style={{ fontWeight: "bold" }}> NOTA:</span> Las evidencias
          deben estar completas.
        </p>
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => {
          setVisibleSendCRM(false);
        }}
        handelCancel={() => {
          setVisibleSendCRM(false);
        }}
        handelAccept={handleSendCRM}
        title="Envió de unidades"
        visible={visibleSendCRM}
        visibleCancelButton={true}
      >
        ¿Desea realizar el envío de las unidades a CRM?
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => {
          setVisibleLiberate(false);
        }}
        handelCancel={() => {
          setVisibleLiberate(false);
        }}
        handelAccept={handleLiberate}
        title="Liberación de unidades"
        visible={visibleLiberate}
        visibleCancelButton={true}
      >
        ¿Desea realizar la liberación de las unidades sin enviar a CRM?
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => {
          setVisibleRedo(false);
        }}
        handelCancel={() => {
          setVisibleRedo(false);
        }}
        handelAccept={handleRedoDocument}
        title={redoDocument.title}
        visible={visibleRedo}
        visibleCancelButton={true}
      >
        {redoDocument.text}
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => setVisibleDialogValid(false)}
        handelCancel={() => setVisibleDialogValid(false)}
        handelAccept={handleValidUnits}
        title="Validación de unidades"
        visible={visibleDialogValid}
        visibleCancelButton={true}
      >
        ¿Seguro que sea enviar las unidades a validar?
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={response.title}
        visible={visibleResponse}
      >
        {response.message}
      </DialogBox>
    </div>
  );
}
