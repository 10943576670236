/**
 * @author: Alexander Chi
 * @description: Service of units, delete, add evidences, set review,
 *               upload document, units review, validate vin
 * @date: 17/nov/2020
 **/
import httpCommon from "../Utils/http-common";

/**
 * @description: delete unit for id in path
 * @return: response
 **/
const remove = (id) => {
  const client = httpCommon();
  return client.post(`/units/${id}/delete`);
};

/**
 * @description: get list evidences of unit for id in path
 * @return: response
 **/
const getEvidenes = (id) => {
  const client = httpCommon();
  return client.get(`/units/${id}/evidences`);
};

/**
 * @description: set review unit for id in path
 * @return: response
 **/
const setReview = (id, params) => {
  const client = httpCommon();
  return client.patch(`/units/${id}/set-review`, params);
};

/**
 * @description: set deadlines unit for id in path
 * @return: response
 **/
 const setDeadlines = (id, params) => {
  const client = httpCommon();
  return client.patch(`/units/${id}/set-deadlines`, params);
};

/**
 * @description: set is sold unit for id in path
 * @return: response
 **/
 const setSold = (id, params) => {
  const client = httpCommon();
  return client.patch(`/units/${id}/set-sold`, params);
};


/**
 * @description: set review unit for id in path
 * @return: response
 **/
const uploadDocument = (id, formData) => {
  const client = httpCommon();
  return client.post(`/units/${id}/uploadDocument`, formData);
};

/**
 * @description: get list review unit for id in path
 * @return: response
 **/
const reviews = (params) => {
  const client = httpCommon();
  return client.get(`/units/review`, params);
};

/**
 * @description: get unit validated for vin
 * @return: response
 **/
const validateVin = (params) => {
  const client = httpCommon();
  return client.get(`/units/validate-vin`, params);
};

/** **/
export default {
  getEvidenes,
  remove,
  reviews,
  setReview,
  setDeadlines,
  setSold,
  uploadDocument,
  validateVin,
};
