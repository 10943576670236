import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';

import './modal.css';

const modalNotificacion = (props) => {
    const {
        type,
        message,
        onClose,
    } = props;

    return (
        <div>
        <Dialog 
        // className="modal-notificacion
         title="Notificación" 
         width={"50%"} height={"30vh"} 
        //  closeIcon={true}
          onClose={onClose}>

            <div className="modal-notificacion-tipo">{type}</div>

            <div className={"modal-titulo-descripcion"}>Descripción:</div>

            <div className={"modal-contenido-descripcion"}>{message}</div>

        </Dialog>
        </div>
    );
}

export default modalNotificacion;