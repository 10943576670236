/**
 * @author: Alexander Chi
 * @description: Service of provisions get list, create, edit,
 *               send signature and generated documents, validated FinBe
 * @date: 17/sept/2020
 **/
import httpCommon from "../Utils/http-common";

/**
 * @description: Get all data of provisions user
 * @return: response
 **/
const getAll = (params) => {
  const client = httpCommon();
  return client.get("/provisions", params);
};

/**
 * @description: Get by ID of provision information
 * @return: response
 **/
const getById = (id) => {
  const client = httpCommon();
  return client.get(`/provisions/${id}`);
};

/**
 * @description:
 * @return: response
 **/
const create = (data) => {
  const client = httpCommon();
  return client.post("/provisions", data);
};

const createWithUnitsExistits = (data) => {
  const client = httpCommon();
  return client.post("/provisions/create/withUnitsExistits", data);
};

const createAndSend=(data)=>{
  const client = httpCommon();
  return client.post('/provisions/create-send',data);
}

/**
 * @description: Update provision
 * @return: response
 **/
const update = (id, data) => {
  const client = httpCommon();
  return client.put(`/provisions/${id}`, data);
};

/**
 * @description: send validated FinBe
 * @return: response
 **/
const setStatus = (id, data) => {
  const client = httpCommon();
  return client.patch(`/provisions/${id}/set-status`, data);
};

const getEvidencesSignature = (id) => {
  const client = httpCommon();
  return client.get(`/provisions/${id}/evidences-signature`);
};

/**
 * @description: Get provisions to operator FinBe
 * @return: response
 **/
const getAllByAssignedTo = (assignedTo, data) => {
  const client = httpCommon();
  return client.get(`/provisions/account-assigned/${assignedTo}`, data);
};

/**
 * @description: Get provisions to operator FinBe
 * @return: response
 **/
const approveReject = (id, params) => {
  const client = httpCommon();
  return client.post(`/provisions/${id}/confirm-reject`, params);
};
const getProvisionsReview = (params) => {
  const client = httpCommon();
  return client.get("/provisions/review", params);
};
const getProvisionWithUnitsReview = (id) => {
  const client = httpCommon();
  return client.get(`/provisions/${id}/revision`);
};
const confirmUnitsReview = (data) => {
  const client = httpCommon();
  return client.post("/provisions/confirmUnitsReview", data);
};
const sendCRM=(data)=>{
  const client = httpCommon();
  return client.post('/provisions/send-units',data);
}
const updateManualFolio=(id,data)=>{
  const client = httpCommon();
  return client.patch(`/provisions/${id}/update-folio`,data);
}
const getReservedAmount = (params) => {
  const client = httpCommon();
  return client.get("/provisions/reserved-amount", params);
};
/** **/
export default {
  approveReject,
  create,
  createWithUnitsExistits,
  createAndSend,
  getAll,
  getAllByAssignedTo,
  getById,
  setStatus,
  update,
  getEvidencesSignature,
  getProvisionsReview,
  getProvisionWithUnitsReview,
  confirmUnitsReview,
  sendCRM,
  getReservedAmount,
  updateManualFolio
};
