/**
 * @author: Alexander Chi
 * @description: Component of section filters
 * @date: 23/sept/2020
 **/
import React, {Component} from 'react'
import { Slide, Push, Expand, Fade, Zoom, Reveal } from '@progress/kendo-react-animation';
import { ButtonPrimary, ButtonTertiary, ButtonDanger, ButtonSuccess } from './Components/Buttons';

/** StepBox main **/
export class StepBox extends Component{
  constructor (props) {
    super(props)
    this.state = {
    };
  }

  handleClick = () => {
  }

  render() {
    const {
      children,
      divStyle,
      title,
    } = this.props;
    return (
      <div style = {divStyle} >
        <h3 > {title} </h3>
        {children ?? ''}
      </div>
    );
  }
}

/** Defacult Props StepBox **/
StepBox.defaultProps = {
  children: null,
  divStyle: {marginTop: '3.2em'},
  h3Text: 'Title',
};

/** StepFooter main **/
export class StepFooter extends Component{
  constructor (props) {
    super(props)
    this.state = { };
  }
  render() {
    const {
      backOnClick,
      cancelOnclick,
      disabledTertiary,
      disabledDanger,
      disabledPrimary,
      disabledSuccess,
      handleSaveAndSend,
      saveOrNextIcon,
      saveOrNextOnclick,
      saveOrNextText,
      step,
      stepLength,
      saveAndSend,
      visibleBack,
    } = this.props;
    return (
      <div style = {{display: 'flex', justifyContent: 'space-between' }}>
        <div style = {{display: 'flex'}}>
          <span style={{ alignSelf: 'center' }}> Paso {step} de {stepLength}</span>
        </div>
        <div style = {{display: 'flex', textAling: 'left'}} >
          {(step == 1 || visibleBack) &&
            <ButtonTertiary
              className = ""
              disabled = {disabledTertiary}
              icon = 'arrow-chevron-left'
              onClick = {backOnClick}
              pClassName = "finbe-btn-margin"
              primary = {true}
              text = 'Regresar'
              />
          }
          <ButtonDanger
            className = ""
            disabled = {disabledDanger}
            icon = 'close'
            onClick = {cancelOnclick}
            pClassName = "finbe-btn-margin"
            primary = {true}
            text = 'Cancelar'
          />
          {step === stepLength ?
             <ButtonSuccess
               className = ""
               disabled = {disabledSuccess}
               icon = {saveOrNextIcon}
               onClick = {saveOrNextOnclick}
               pClassName = "finbe-btn-margin"
               primary = {true}
               text = {saveOrNextText}
              /> :
            <ButtonPrimary
              className = "k-icon-left"
              disabled = {disabledPrimary}
              icon = {saveOrNextIcon}
              onClick = {saveOrNextOnclick}
              pClassName = "finbe-btn-margin"
              primary = {true}
              text = {saveOrNextText}
              />
          }

         {saveAndSend&& <ButtonPrimary
            className = ""
            // disabled = {disabledPrimary}
            icon = 'check'
            onClick = {handleSaveAndSend}
            pClassName = "finbe-btn-margin"
            primary = {true}
            text = 'Guardar y enviar'
            />}
          </div>
      </div>
    );
  }
}

/** Default Props StepFooter **/
StepFooter.defaultProps = {
  backOnClick: null,
  cancelOnclick: null,
  handleSaveAndSend:null,
  saveOrNextIcon: '',
  saveOrNextOnclick: null,
  saveOrNextText: '',
  step: 0,
  stepLength: 0,
  saveAndSend: false,
  visibleBack: true,
};
