import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ComboBoxInput, SwitchInput } from "../Inputs/Inputs";
import { useUsuario } from "../../Context/UserContext";
import AccountService from "../../Services/AccountService";
import UserService from "../../Services/UserServices";
import { ListCompanies } from "./Dialogs/ListCompanies";
import { InfoAccount } from "./Dialogs/InfoAccount";
import { UpdateOperator } from "./Dialogs/UpdateOperator";
import { DialogBox } from "../Sections/Dialogs";
import moment from "moment";
import qs from "qs";

//Refactor
import { FilterBox, MoreFilterBox } from "../Sections/Filters";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import {
  InputComboBox,
  InputFilterText,
  InputRangeDatePicker,
  InputSwitch,
} from "../Sections/Components/Inputs";
import { ButtonAction } from "../Sections/Components/Buttons";
import useLoader from "../../hooks/useLoader";
// import { Loading } from "react-loading-ui";

const Accounts = () => {
  //Provider
  const { usuario, signOutCognito,setCargando } = useUsuario();

  //Inicialización
  const types = [
    { id: 1, text: "Holding" },
    { id: 2, text: "Persona moral/física" },
  ];
  const initialFilters = {
    name: "",
    type: null,
    operator: null, //Operador finbe Asignado
    datePickerFilter: { start: null, end: null },
  };

  //Estado
  const [accountId, setAccountId] = useState(""); //Id de cuenta para operaciones
  const [accounts, setAccounts] = useState([]); //Listado de las cuentas
  const [companies, setCompanies] = useState([]); //Listado de companias para mostrar
  const [operators, setOperators] = useState([]); //Listado de operadores

  const [operator, setOperator] = useState({}); //Objeto operador asignado o que remplazara

  const [dataDialog, setDataDialog] = useState([]); //Informacion para poder desplegar dialogos de confrimación o mensaje
  const [visibleDialog, setVisibleDialog] = useState(false); //Dialogos de confirmación o mensaje
  const [visibleInfo, setVisibleInfo] = useState(false); //Mostrar o ocultar listado de empresas
  const [visibleShow, setVisibleShow] = useState(false); //Mostrar o ocultar info de cuenta
  const [infoAccount, setInfoAccount] = useState({}); //Informacion de la cuenta
  const [visibleChangeOp, setVisibleChangeOp] = useState(false); //Mostrar o ocultar dialogo para cambiar operador asignado
  const [visibleArchived, setVisibleArchived] = useState(false);

  const [isDown, setIsDown] = useState(true);
  const [dataFilters, setDataFilters] = useState(initialFilters); //Objeto para filtrar las cuentas
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [dataState, setDataState] = useState({
    sort: [{ field: "name", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  }); //Configuración de GRID

  const Loading = useLoader();
  //Control del componente
  useEffect(() => {
    if (usuario) {
      if (usuario.type == 3) {
        getAccounts({ assigned: usuario.id });
      } else {
        getAccounts();
      }

      getOperators();
    }
  }, [usuario]);

  const onChange = (event) => {
    const { name, value } = event.target;
    if (typeof value !== "object") {
      setDataFilters({ ...dataFilters, [name]: value });
    } else if (value != null) {
      if (value.id) {
        setDataFilters({ ...dataFilters, [name]: value.id });
      } else {
        setDataFilters({
          ...dataFilters,
          ["datePickerFilter"]: event.target.value,
        });
      }
    } else {
      setDataFilters({ ...dataFilters, [name]: "" });
    }
  };
  const handleClickMoreFilter = async () => {
    setIsDown(!isDown);
  };

  //Consultas de api
  const getAccounts = async (data) => {
    try {
      Loading();
      const res = await AccountService.getAll({ params: data });
      const _data = res.data.map((value) => {
        const { createdAt, ...res } = value;
        const date = moment(createdAt, "DD/MM/YYYY").toDate();
        return {
          ...res,
          createdAt: date,
        };
      });
      setAccounts(_data);
      Loading();

    } catch (error) {
      if (error?.response?.status == 401) signOutCognito();
      Loading();
    }
  };
  const getOperators = async () => {
    try {
      Loading();
      const res = await UserService.getAll({
        params: { type: [3] },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      let _op = res.data.map((x) => {
        return { id: x.id, text: x.firstname + " " + x.lastname };
      });
      setOperators(_op);
      Loading();
    } catch (error) {
      if (error?.response?.status == 401) signOutCognito();
      Loading();
    }
  };
  const handleClickSearch = () => {
    let data = {};
    if (dataFilters.name !== "") data.name = dataFilters.name;
    if (dataFilters.type !== null && dataFilters.type > 0)
      data.type = dataFilters.type;
    if (usuario === 3) {
      data.assigned = usuario.id;
    } else {
      if (dataFilters.operator !== null && dataFilters.operator != "")
        data.assigned = dataFilters.operator;
    }
    if (dataFilters.datePickerFilter.start !== null)
      data.from = moment(dataFilters.datePickerFilter.start).format(
        "YYYY-MM-DD"
      );
    if (dataFilters.datePickerFilter.end !== null)
      data.to = moment(dataFilters.datePickerFilter.end).format("YYYY-MM-DD");

    setDataState({ ...dataState, skip: 0 });
    getAccounts(data);
  };
  const getCompanies = async (id) => {
    try {
      Loading();
      const res = await AccountService.getCompanies(id);
      setCompanies(res.data);
      setCargando(false);
    } catch (error) {
      if (error?.response?.status == 401) signOutCognito();
      setCargando(false);
    }
  };

  //Acciones a registros
  //#region
  // const archived = () => {
  //   let estatus = accounts.find((x) => x.id === accountId).isArchived;
  //   AccountService.setArchived(accountId, { archive: !estatus })
  //     .then((res) => {
  //       let list = accounts.filter((t) => t.id !== accountId);
  //       setAccounts(list);
  //       setAccountId("");
  //     })
  //     .catch((error) => {
  //       setAccountId("");
  //     });
  //   setVisibleDialog(!visibleDialog);
  // };
  // //Control de dialogos
  // const toggleDialog = (e) => {
  //   let id;
  //   if (e == "changeOP") {
  //     setVisibleChangeOp(!visibleChangeOp);
  //     let _dD = {
  //       type: "changeOP",
  //       title: null,
  //       close: null,
  //       classMessage: null,
  //       message: "¿Seguro que deseas guardar el cambio de Operador FINBE?",
  //       textConfirm: "Aceptar",
  //       textCancel: "Cancelar",
  //     };
  //     setDataDialog(_dD);
  //     setVisibleDialog(!visibleDialog);
  //   } else if (e === "Realizado") {
  //     let _dD = {
  //       type: null,
  //       title: " ",
  //       classMessage: null,
  //       message: "Realizado",
  //       textConfirm: null,
  //       textCancel: null,
  //     };

  //     setDataDialog(_dD);
  //     setVisibleDialog(true);
  //   } else if (
  //     e.currentTarget !== undefined &&
  //     e.currentTarget.getAttribute("data") !== null
  //   ) {
  //     id = e.currentTarget.getAttribute("data");
  //     setAccountId(id);
  //     let _dD = {
  //       type: "archive",
  //       title: "Archivar cuenta",
  //       close: null,
  //       classMessage: null,
  //       message: "¿Seguro que deseas archivar la cuenta?",
  //       textConfirm: "Aceptar",
  //       textCancel: "Cancelar",
  //     };
  //     setDataDialog(_dD);
  //     setVisibleDialog(!visibleDialog);
  //   } else if (
  //     e.target._reactInternalFiber != undefined &&
  //     e.target._reactInternalFiber.type.name === "Switch"
  //   ) {
  //     id = e.target.props.data;
  //     setAccountId(id);
  //     let estatus = accounts.find((x) => x.id === id).isActive;
  //     let _dD = {
  //       type: "active",
  //       title: (estatus ? "Desactivar " : " Activar ")+"cuenta",
  //       close: null,
  //       classMessage: null,
  //       message:
  //         "¿Seguro que deseas " +
  //         (estatus ? "desactivar " : " Activar ") +
  //         " la cuenta?",
  //       textConfirm: "Aceptar",
  //       textCancel: "Cancelar",
  //     };
  //     setDataDialog(_dD);
  //     setVisibleDialog(!visibleDialog);
  //   } else {
  //     setVisibleDialog(!visibleDialog);
  //     if (operator.id != undefined) setVisibleChangeOp(!visibleChangeOp);
  //   }
  // };
  //#endregion
  // const [visibleChangeOp, setVisibleChangeOp] = useState(false);
  const [response, setResponse] = useState({
    response: {
      title: "",
      message: "",
      success: false,
    },
  });
  const [visibleResponse, setVisibleResponse] = useState(false);
  const [visibleChangeStatus, setVisibleChangeStatus] = useState(false);
  const handleDialogResponse = (e) => {
    setVisibleResponse(!visibleResponse);
  };
  const handleChangeStatus = async (e) => {
    let response = {};
    let message = "";
    let success = true;
    try {
      // setCargando(true);
      Loading();
      let estatus = accounts.find((x) => x.id === accountId).isActive;
      const res = await AccountService.setActive(accountId, {
        active: !estatus,
      });
      accounts.find((t) => t.id === accountId).isActive = res.data.isActive;
      message = "Se realizó el cambio de estatus de la cuenta";
      setVisibleChangeStatus(!visibleChangeStatus);
      // setCargando(false);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
      success = false;
      message = error.message;

    }
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
    });
    setVisibleResponse(!visibleResponse);
  };
  const handleDialogChangeStatus = (e) => {
    const target = e.target;
    if (target !== undefined) {
      if (target.props !== undefined) {
        let id = target.props.data;
        setAccountId(id);
        setVisibleChangeStatus(!visibleChangeStatus);
      } else {
        setVisibleChangeStatus(!visibleChangeStatus);
      }
    } else {
      setVisibleChangeStatus(!visibleChangeStatus);
    }
  };

  const handleDialogArchived = (e) => {
    const target = e.currentTarget;
    if (target !== undefined) {
      let id = target.getAttribute("data");
      if (id != null && id != undefined) {
        setAccountId(id);
        setVisibleArchived(!visibleArchived);
      } else {
        setVisibleArchived(!visibleArchived);
      }
    } else {
      setVisibleArchived(!visibleArchived);
    }
  };
  const handleArchived = async (e) => {
    let response = {};
    let message = "";
    let success = true;
    let estatus = accounts.find((x) => x.id === accountId).isArchived;
    try {
      const res = await AccountService.setArchived(accountId, { archive: !estatus });
      let _accounts = accounts.filter(x=>x.id !== accountId); 
      setAccounts(_accounts);
      // users.find((t) => t.id === userId).isArchived = res.data.isArchived;
      message = "Se realizó el cambio de estatus de la cuenta";
      setVisibleArchived(!visibleArchived);
    } catch (error) {
      success = false;
      message = error.message;
      if(error?.response?.status == 401) signOutCognito()
    }
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
    });
    setVisibleResponse(!visibleResponse);
  };

  const toggleInfo = async (e) => {
    if (!visibleInfo) {
      const id = e.target.getAttribute("data");

      try {
      Loading();
        await getCompanies(id);
      Loading();
      } catch (error) {
      Loading();
        if (error?.response?.status == 401) signOutCognito();
      }
    }
    setVisibleInfo(!visibleInfo);
  };
  //Datos de la organizacion (ojito en la tabla)
  const toggleShow = async (e) => {
    if (!visibleShow) {
      const _id = e.currentTarget.getAttribute("data");
      try {
      Loading();
        const res = await AccountService.getById(_id);
        const { id, name, email, type, assignedTo, registerBy } = res.data;
        let data = { id, name, email, type, assignedTo, registerBy };
        data.companies = [];
        if (type === 1) {
          await res.data.legals.map(async (user) => {
            const _company = res.data.companies.find(
              (c) => c.id == user.companyId
            );
            let dataCompany = {
              name: _company.name,
              legalName: _company.legalName,
            };
            let dataLP = {
              firstname: user.firstname,
              lastname: user.lastname,
            };

            dataCompany.legalPerson = dataLP;
            data.companies.push(dataCompany);
          });
        }
        setInfoAccount(data);
      Loading();
      } catch (error) {
      Loading();
        if (error?.response?.status == 401) signOutCognito();
      }
    }
    setVisibleShow(!visibleShow);
  };

  // const handleChangeOperator = () => {
  //   setVisibleChangeOp(!visibleChangeOp);
  //   if (!setVisibleChangeOperatorConfirm) {
  //     setVisibleChangeOperatorConfirm(!setVisibleChangeOperatorConfirm);
  //   }
  //   // handleDialogChangeOperatorConfirm();
  // };
  const handleDialogChangeOperator = (e) => {
    if (!visibleChangeOp) {
      const _id = e.currentTarget.getAttribute("data");
      setAccountId(_id);
      let _assignedTo = accounts.find((ac) => ac.id == _id);
      setOperator(_assignedTo.assignedTo);
    } else {
      setOperator({});
    }
    setVisibleChangeOp(!visibleChangeOp);
  };

  // const [
  //   visibleChangeOperatorConfirm,
  //   setVisibleChangeOperatorConfirm,
  // ] = useState(false);
  const handleChangeOperatorConfirm = async () => {
    let response = {};
    let message = "";
    let success = true;
    try {
      // setCargando(true);
      Loading();
      const res = await AccountService.setOperatorAssign(accountId, {
        userId: operator.id,
      });
      setOperator({});
      setVisibleChangeOp(!visibleChangeOp);
      getAccounts();
      // setCargando(false);
      message = "Se realizó el cambio del operador asignado a la cuenta";
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
      success = false;
      message = error.message;
      // setCargando(false);
    }
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
    });
    setVisibleResponse(!visibleResponse);
  };
  // const handleDialogChangeOperatorConfirm = () => {
  //   setVisibleChangeOperatorConfirm(!setVisibleChangeOperatorConfirm);
  // };

  const getTypeAccount = (props) => {
    switch (props.dataItem.type) {
      case 1:
        return (
          <div data={props.dataItem.id} onClick={toggleInfo}>
            Holding
          </div>
        );
      case 2:
        return "Persona moral/física";
      default:
        return "-";
    }
  };

  const changeOperator = (event) => {
    const { name, value } = event.target;
    if (typeof value === "object")
      if (value != null) setOperator({ ...operator, [name]: value.id });
  };

  const handleShowRegisterChange = (e) => {
    const { value } = e.target;
    setShowRegister(value);
    setDataState({ ...dataState, ["take"]: value.text, skip: 0 });
  };
  return (
    <>
      <div className="finbe-app-container">
        <div className="finbe-app-section-top">
          <FilterBox
            buttonMoreFilterOnClick={handleClickMoreFilter}
            buttonSearchOnClick={handleClickSearch}
            // inputFilterDivClassName="k-form-field-wrap finbe-form-field-filter"
            // inputFilterId="name"
            // inputFilterName="name"
            // inputFilterOnChange={onChange}
            // inputFilterValue={dataFilters.name}
            // inputFilterPlaceholder="Ingresar nombre"
            inputDropDownValue={showRegister}
            inputFilterOnChange={handleShowRegisterChange}
            isDown={isDown}
            inputFilterwWrapperStyle={{ width: "100%" }}
          >
            <Form
              render={(formRenderProps) => (
                <FormElement style={{ display: "flex" }}>
                  <Field
                    dClassName="k-form-field-wrap finbe-form-field-filter"
                    id="name"
                    nombre="name"
                    onChange={onChange}
                    placeholder="Ingresar nombre"
                    value={dataFilters.name}
                    wrapperStyle={{ width: "90%", margin: "1em" }}
                    component={InputFilterText}
                  />
                </FormElement>
              )}
            />
          </FilterBox>
        </div>
        <div style={{ color: "#b0ada6" }}>
          {!isDown && (
            <div
              style={{
                width: "95%",
                margin: "1em 1em 0 1em",
                background: "#e4e3e0",
              }}
            >
              <MoreFilterBox>
                <Form
                  render={(formRenderProps) => (
                    <FormElement style={{ display: "flex" }}>
                      <Field
                        dClassName="k-form-field-wrap finbe-form-combo"
                        id="type"
                        name="type"
                        data={types}
                        onChange={onChange}
                        placeholder="Selec. tipo"
                        value={types.find((x) => x.id == dataFilters.type)}
                        wrapperStyle={{ width: "25%", margin: "1em" }}
                        component={InputComboBox}
                      />
                      {usuario && (usuario.type == 1 || usuario.type == 2) && (
                        <Field
                          dClassName="k-form-field-wrap finbe-form-combo"
                          id="operator"
                          name="operator"
                          data={operators}
                          onChange={onChange}
                          placeholder="Selec. operador FinBe"
                          value={operators.find(
                            (x) => x.id == dataFilters.operator
                          )}
                          wrapperStyle={{ width: "25%", margin: "1em" }}
                          component={InputComboBox}
                        />
                      )}
                      <Field
                        dClassName="k-form-field-wrap finbe-form-date"
                        dateRangePickerId="datePickerFilter"
                        dateRangePickerName="datePickerFilter"
                        dateRangePickerOnChange={onChange}
                        fieldWrapperStyle={{
                          width: "50%",
                          paddingLeft: "1.2em",
                        }}
                        defaultValue={{
                          start: dataFilters.datePickerFilter.start,
                          end: dataFilters.datePickerFilter.end,
                        }}
                        dateRangePickerValue={dataFilters.datePickerFilter}
                        component={InputRangeDatePicker}
                      />
                    </FormElement>
                  )}
                />
              </MoreFilterBox>
            </div>
          )}
        </div>
        <div>
          <Grid
            className="finbe-table"
            data={process(accounts, dataState)}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.data);
            }}
            pageable={{
              info: false,
            }}
            sortable
            skip={dataState.skip}
            take={dataState.take}
            resizable
          >
            <Column field="name" title="Nombre" />
            {usuario && (usuario.type == 1 || usuario.type == 2) && (
              <Column
                field="dataItem.assignedTo.name"
                title="Op. FinBe"
                cell={(props) => <td>{props.dataItem.assignedTo.name}</td>}
              />
            )}
            <Column
              field="type"
              title="Tipo"
              cell={(props) => <td>{getTypeAccount(props)}</td>}
            />
            <Column
              field="createdAt"
              title="Fecha de alta"
              filter="date"
              format="{0:dd/MM/yyyy}"
            />
            <Column
              title="Acciones"
              cell={(props) => (
                <td>
                  <div style={{ display: "flex" }}>
                  {usuario && usuario.type == 3 && (
                    <>
                    <ButtonAction
                      title={"Editar"}
                      icon=" icon editar"
                      isRedirect={true}
                      look="bare"
                      primary={false}
                      url={"/editar-cuenta/" + props.dataItem.id}
                    />
                    <ButtonAction
                      title={"Configuración"}
                      icon=" icon firmar"
                      isRedirect={true}
                      look="bare"
                      primary={false}
                      url={"/configurar-cuenta/" + props.dataItem.id}
                    />
                    {/* <ButtonAction
                      title={"Archivar"}
                      icon=" icon archivar"
                      look="bare"
                      primary={false}
                      isRedirect={false}
                      data={props.dataItem.id}
                      onClick={handleDialogArchived}
                    /> */}
                    </>
                  )}
                  {usuario && (usuario.type == 1 || usuario.type == 2) && (
                    <ButtonAction
                      title={"Visualizar"}
                      data={props.dataItem.id}
                      icon=" icon visualizar"
                      isRedirect={false}
                      look="bare"
                      onClick={toggleShow}
                      primary={false}
                    />
                  )}
                  {usuario && usuario.type == 1 && (
                    <ButtonAction
                      title={"Editar"}
                      data={props.dataItem.id}
                      icon=" icon editar"
                      isRedirect={false}
                      look="bare"
                      onClick={handleDialogChangeOperator}
                      primary={false}
                    />
                  )}
                  </div>
                </td>
              )}
            />
            {usuario && usuario.type == 3 && (
              <Column
                title="Estatus"
                field="isActive"
                cell={(props) => (
                  <td>
                    <InputSwitch
                      checked={props.dataItem.isActive}
                      onChange={handleDialogChangeStatus}
                      data={props.dataItem.id}
                    />
                  </td>
                )}
              />
            )}
          </Grid>
        </div>
      </div>
      <DialogBox
        buttonSuccessText="Aceptar"
        centerButtons={true}
        handelAccept={handleChangeStatus}
        handelClose={handleDialogChangeStatus}
        title={"Cambiar estado"}
        visible={visibleChangeStatus}
        visibleCancelButton={true}
      >
        ¿Seguro que desea cambiar el estatus de la cuenta?
      </DialogBox>
      <DialogBox
        buttonSuccessText="Aceptar"
        centerButtons={true}
        handelAccept={handleArchived}
        handelClose={handleDialogArchived}
        title={"Archivar cuenta"}
        visible={visibleArchived}
        visibleCancelButton={true}
      >
        ¿Seguro que desea archivar esta cuenta?
      </DialogBox>
      {/* <DialogBox
        buttonSuccessText="Aceptar"
        centerButtons={true}
        handelAccept={handleChangeOperatorConfirm}
        handelClose={handleDialogChangeOperatorConfirm}
        title={"Cambiar estado"}
        visible={visibleChangeOperatorConfirm}
        visibleCancelButton={true}
      >
        ¿Seguro que desea cambiar el opererador asignado a la cuenta?
      </DialogBox> */}
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={response.title}
        visible={visibleResponse}
      >
        {response.message}
      </DialogBox>

      <DialogBox
        buttonSuccessText="Aceptar"
        centerButtons={true}
        handelAccept={handleChangeOperatorConfirm}
        handelClose={handleDialogChangeOperator}
        title={"Cambiar operador asignado"}
        visible={visibleChangeOp}
        visibleCancelButton={true}
      >
        <p style={{ textAlign: "left" }}>
          <strong>Editar operador FINBE:</strong>
        </p>
        <InputComboBox
          data={operators}
          dClassName="k-form-field-wrap finbe-form-combo"
          id="id"
          label="Operador de FINBE asignado"
          name="id"
          onChange={changeOperator}
          placeholder="Seleccionar operador FinBe"
          value={operators.find((x) => x.id == operator.id)}
          wrapperStyle={{ width: "100%" }}
        />
      </DialogBox>

      {visibleInfo && (
        <ListCompanies companies={companies} close={toggleInfo}></ListCompanies>
      )}
      {visibleShow && (
        <InfoAccount account={infoAccount} close={toggleShow}></InfoAccount>
      )}
    </>
  );
};
export default Accounts;
