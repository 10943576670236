//Componente para la lista de los usuarios
import React, { useState, useEffect } from "react";
import { SwitchInput } from "../Inputs/Inputs";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import AccountService from "../../Services/AccountService";
import CompanyService from "../../Services/CompanyService";
import UserService from "../../Services/UserServices";
import RoleService from "../../Services/RoleService";
import ModalDialog from "../Common/Dialog";
import moment from "moment";
import { useUsuario } from "../../Context/UserContext";
import {
  InputComboBox,
  InputFilterText,
  InputRangeDatePicker,
  InputSwitch,
} from "../Sections/Components/Inputs";
import { FilterBox, MoreFilterBox } from "../Sections/Filters";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import qs from "qs";
import { ButtonAction } from "../Sections/Components/Buttons";
import { DialogBox } from "../Sections/Dialogs";
import UserServices from "../../Services/UserServices";
import useLoader from "../../hooks/useLoader";
// import { Button, Modal } from "bootstrap";

const Users = () => {
  //Provider
  const { usuario, signOutCognito } = useUsuario();

  //Inicialización
  const initialFilters = {
    name: "",
    correo: "",
    role: null,
    account: null,
    company: null,
    agency: null,
    datePickerFilter: { start: null, end: null },
    registerBy: null,
  };

  const [visibleExample, setVisibleExample] = useState(false);
  //Estados
  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [agencies, setAgencies] = useState([]);

  const [dataDialog, setDataDialog] = useState({});
  const [visibleDialog, setVisibleDialog] = useState(false);

  const [isDown, setIsDown] = useState(true);
  const [dataFilters, setDataFilters] = useState(initialFilters); //Objeto para filtrar las cuentas
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [dataState, setDataState] = useState({
    sort: [{ field: "email", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });
  const Loading = useLoader();

  //Control del componente
  useEffect(() => {
    if (usuario) {
      switch (usuario.type) {
        case 2:
          getUsers({ registerBy: usuario.id });
          getRoles({ type: [3] });
          break;
        case 3:
          getUsers({ registerBy: usuario.id });
          getRoles({ type: [4, 5] });
          getAccounts({ assigned: usuario.id });
          break;
        case 4:
          let data = {};
          // data.registerBy = usuario.id;
          if (usuario.account !== null) data.account = usuario.account.id;
          if (usuario.company !== null) {
            data.company = usuario.company.id;
            getAgencies(usuario.company.id);
          }
          getUsers(data);
          getRoles({ type: [5] });
          break;
        default:
          getUsers();
          getRoles();
          getAccounts();
          break;
      }
    }
  }, [usuario]);

  const onChange = (event) => {
    const { name, value } = event.target;

    if (event.value !== undefined) {
      if (event.value !== null && name == undefined) {
        setDataFilters({
          ...dataFilters,
          ["datePickerFilter"]: event.target.value,
        });
      } else if (typeof value === "object") {
        if (name === "account") {
          if (value !== null) {
            getCompanies(value.id);
            setDataFilters({
              ...dataFilters,
              [name]: value !== null ? value.id : "",
            });
          } else {
            setCompanies([]);
            setAgencies([]);
            setDataFilters({
              ...dataFilters,
              ["company"]: "",
              ["agency"]: "",
              ["account"]: "",
            });
          }
        } else if (name === "company") {
          if (value !== null) {
            getAgencies(value.id);
            setDataFilters({
              ...dataFilters,
              [name]: value.id,
            });
          } else {
            setAgencies([]);
            setDataFilters({
              ...dataFilters,
              ["company"]: "",
              ["agency"]: "",
            });
          }
        } else {
          setDataFilters({
            ...dataFilters,
            [name]: value !== null ? value.id : "",
          });
        }
      } else {
        setDataFilters({ ...dataFilters, [name]: value });
      }
    }
  };
  const handleClickMoreFilter = async () => {
    setIsDown(!isDown);
    setDataFilters(initialFilters);
  };

  //Cosultas de api
  const getUsers = async (data = {}) => {
    try {
      Loading();
      const res = await UserService.getAll({ params: data });
      const _data = res.data.map((value) => {
        const { createdAt, ...res } = value;
        const date = moment(createdAt, "DD/MM/YYYY").toDate();
        return {
          ...res,
          createdAt: date,
        };
      });
      setUsers(_data);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();

    }
  };
  const getRoles = async (data) => {
    try {
      Loading();
      const res = await RoleService.getAll({
        params: data,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      let roles = res.data.map((x) => {
        return { id: x.id, text: x.name };
      });
      setRoles(roles);
      Loading();
    } catch (error) {
      Loading();

      if (error?.response?.status == 401) signOutCognito();
    }
  };
  const getAccounts = async (data = {}) => {
    try {
      Loading();
      const res = await AccountService.getAll({ params: data });
      let list = res.data.map((x) => {
        return { id: x.id, text: x.name };
      });
      setAccounts(list);
      Loading();
    } catch (error) {
      Loading();

      if (error?.response?.status == 401) signOutCognito();
    }
  };
  const getCompanies = async (id) => {
    try {
      Loading();
      let res = await AccountService.getCompanies(id);
      let list = res.data.map((x) => {
        return { id: x.id, text: x.name };
      });
      setCompanies(list);
      Loading();
    } catch (error) {
      Loading();

      if (error?.response?.status == 401) signOutCognito();
    }
  };
  const getAgencies = async (id) => {
    try {
      Loading();
      let res = await CompanyService.getAgencies(id);
      let list = res.data.map((x) => {
        return { id: x.id, text: x.name };
      });
      setAgencies(list);
      Loading();
    } catch (error) {
      Loading();

      if (error?.response?.status == 401) signOutCognito();
    }
  };
  const handleClickSearch = async () => {
    let data = {};
    if (dataFilters.name !== "") data.name = dataFilters.name;
    if (dataFilters.correo !== "") data.email = dataFilters.correo;
    if (usuario.type === 2 || usuario.type === 3) {
      data.registerBy = usuario.id;
    }

    if (usuario.type === 4) {
      data.account = usuario.account !== null ? usuario.account.id : "";
      data.company = usuario.company !== null ? usuario.company.id : "";
    } else {
      if (dataFilters.account !== null && dataFilters.account != "")
        data.account = dataFilters.account;
      if (dataFilters.company !== null && dataFilters.company != "")
        data.company = dataFilters.company;
      if (dataFilters.agency !== null && dataFilters.agency != "")
        data.agency = dataFilters.agency;
    }
    if (dataFilters.role !== null && dataFilters.role != "")
      data.role = dataFilters.role;
    if (dataFilters.datePickerFilter.start !== null)
      data.from = moment(dataFilters.datePickerFilter.start).format(
        "YYYY-MM-DD"
      );
    if (dataFilters.datePickerFilter.end !== null)
      data.to = moment(dataFilters.datePickerFilter.end).format("YYYY-MM-DD");

    try {
      Loading();
      const res = await UserService.getAll({ params: data });
      setDataState({ ...dataState, skip: 0 });
      setUsers(res.data);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();

    }
  };
  //#region

  // const archived = async () => {
  //   let estatus = users.find((x) => x.id === userId).isArchived;
  //   try {
  //     const res = await UserService.setArchive(userId, { archive: !estatus });
  //     let list = users.filter((t) => t.id !== userId);
  //     setUsers(list);
  //     setUserId("");
  //     setVisibleDialog(!visibleDialog);
  //   } catch (error) {

  //     setUserId("");
  //   }
  // };

  //Control de dialogos
  // const toggleDialog = (e) => {
  //   let id;
  //   if (e == "Realizado") {
  //     //Para mensaje de realizado
  //     let _dD = {
  //       type: null,
  //       title: " ",
  //       classMessage: null,
  //       message: "Realizado",
  //       textConfirm: null,
  //       textCancel: null,
  //     };
  //     setDataDialog(_dD);
  //     setVisibleDialog(true);
  //   } else if (e.currentTarget != undefined) {
  //     if (e.currentTarget.getAttribute("data") != null) {
  //       //Para desplegar modal de confirmación para archivar
  //       id = e.currentTarget.getAttribute("data");
  //       setUserId(id);
  //       setVisibleDialog(!visibleDialog);
  //       let _dD = {
  //         type: "archive",
  //         title: "",
  //         close: null,
  //         classMessage: null,
  //         message: "¿Seguro que deseas archivar al usuario?",
  //         textConfirm: "Aceptar",
  //         textCancel: "Cancelar",
  //       };
  //       setDataDialog(_dD);
  //     } else {
  //       setVisibleDialog(!visibleDialog);
  //     }
  //   } else if (e.target._reactInternalFiber !== undefined) {
  //     if (e.target._reactInternalFiber.type.name == "Switch") {
  //       //Para desplegar modal de confirmación de activar/desactivar
  //       id = e.target.props.data;
  //       let estatus = users.find((x) => x.id === id).isActive;
  //       let _dD = {
  //         type: "active",
  //         title: "",
  //         close: null,
  //         classMessage: null,
  //         message:
  //           "¿Seguro que deseas " +
  //           (estatus ? "desactivar " : " Activar ") +
  //           " al usuario?",
  //         textConfirm: "Aceptar",
  //         textCancel: "Cancelar",
  //       };
  //       // setDataDialog(_dD);
  //       setDataDialog((prevState) => _dD);
  //       setUserId(id);
  //       setVisibleDialog(!visibleDialog);
  //     } else {
  //       setVisibleDialog(!visibleDialog);
  //     }
  //   } else {
  //     setVisibleDialog(!visibleDialog);
  //   }
  // };

  //#endregion
  const [response, setResponse] = useState({
    response: {
      title: "",
      message: "",
      success: false,
    },
  });
  const [visibleResponse, setVisibleResponse] = useState(false);
  const [visibleChangeStatus, setVisibleChangeStatus] = useState(false);
  const [visibleSendPassword, setVisibleSendPassword] = useState(false);
  const [visibleArchived, setVisibleArchived] = useState(false);

  const handleDialogResponse = (e) => {
    setVisibleResponse(!visibleResponse);
  };
  const handleChangeStatus = async (e) => {
    let response = {};
    let message = "";
    let success = true;
    try {
      Loading();
      let estatus = users.find((x) => x.id === userId).isActive;
      const res = await UserService.setActive(userId, { active: !estatus });
      users.find((t) => t.id === userId).isActive = res.data.isActive;
      message = "Se realizó el cambio de estatus del usuario";
      setVisibleChangeStatus(!visibleChangeStatus);
      Loading();
    } catch (error) {
      Loading();
      success = false;
      message = error.message;

      if (error?.response?.status == 401) signOutCognito();
    }
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
    });
    setVisibleResponse(!visibleResponse);
  };
  const handleDialogChangeStatus = (e) => {
    const target = e.target;
    if (target !== undefined) {
      if (target.props !== undefined) {
        let id = target.props.data;
        setUserId(id);
        setVisibleChangeStatus(!visibleChangeStatus);
      } else {
        setVisibleChangeStatus(!visibleChangeStatus);
      }
    } else {
      setVisibleChangeStatus(!visibleChangeStatus);
    }
  };
  const handleDialogSendPassword = (e) => {
    const target = e.currentTarget;
    if (target !== undefined) {
      let id = target.getAttribute("data");
      if (id != null && id != undefined) {
        setUserId(id);
        setVisibleSendPassword(!visibleSendPassword);
      } else {
        setVisibleSendPassword(!visibleSendPassword);
      }
    } else {
      setVisibleSendPassword(!visibleSendPassword);
    }
  };

  // const handleDialogArchived = (e) => {
  //   const target = e.currentTarget;
  //   if (target !== undefined) {
  //     let id = target.getAttribute("data");
  //     if (id != null && id != undefined) {
  //       setUserId(id);
  //       setVisibleArchived(!visibleArchived);
  //     } else {
  //       setVisibleArchived(!visibleArchived);
  //     }
  //   } else {
  //     setVisibleArchived(!visibleArchived);
  //   }
  // };
  // const handleArchived = async (e) => {
  //   let response = {};
  //   let message = "";
  //   let success = true;
  //   let estatus = users.find((x) => x.id === userId).isArchived;
  //   try {
  //     const res = await UserService.setArchive(userId, { archive: !estatus });
  //     let _users = users.filter(x=>x.id !== userId);
  //     setUsers(_users);
  //     // users.find((t) => t.id === userId).isArchived = res.data.isArchived;
  //     message = "Se realizó el cambio de estatus del usuario";
  //     setVisibleArchived(!visibleArchived);
  //   } catch (error) {
  //     success = false;
  //     message = error.message;

  //     if(error.response.status == 401) signOutCognito()
  //   }
  //   setResponse({
  //     title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
  //     message: message,
  //     success: success,
  //   });
  //   setVisibleResponse(!visibleResponse);
  // };

  const handleSendPassword = async () => {
    let message = "";
    let success = true;
    try {
      Loading();
      const res = await UserServices.resendPassword(userId);
      message = `Se realizó el reenvio de la contraseña temporal del usuario ${res.data.firstname} ${res.data.lastname}`;
      setVisibleSendPassword(!visibleSendPassword);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();

      success = false;
      message =
        "Ocurrio un error durante el cambio de contraseña tamporal, intentelo mas tarde o contacte a su administrador de AWS";
    }
    setResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
    });
    setVisibleResponse(!visibleResponse);
  };
  const handleShowRegisterChange = (e) => {
    const { value } = e.target;
    setShowRegister(value);
    setDataState({ ...dataState, ["take"]: value.text, ["skip"]: 0 });
  };

  return (
    <div className="finbe-app-container">
      <div className="finbe-app-section-top">
        <FilterBox
          buttonMoreFilterOnClick={handleClickMoreFilter}
          buttonSearchOnClick={handleClickSearch}
          inputDropDownValue={showRegister}
          inputFilterOnChange={handleShowRegisterChange}
          isDown={isDown}
        >
          <Form
            render={(formRenderProps) => (
              <FormElement style={{ display: "flex" }}>
                <Field
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="name"
                  nombre="name"
                  onChange={onChange}
                  placeholder="Ingresar solo nombre"
                  value={dataFilters.name}
                  wrapperStyle={{ width: "50%", margin: "1em" }}
                  component={InputFilterText}
                />
                <Field
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="correo"
                  nombre="correo"
                  onChange={onChange}
                  placeholder="Ingresar Correo"
                  value={dataFilters.correo}
                  wrapperStyle={{ width: "50%", margin: "1.35em" }}
                  component={InputFilterText}
                />
              </FormElement>
            )}
          />
        </FilterBox>
      </div>
      <div style={{ color: "#b0ada6" }}>
        {!isDown && (
          <div
            style={{
              width: "95%",
              margin: "1em 1em 0 1em",
              background: "#e4e3e0",
            }}
          >
            <MoreFilterBox>
              <Form
                render={(formRenderProps) => (
                  <FormElement style={{ display: "flex", flexWrap: "wrap" }}>
                    {(usuario && usuario.type !== 4 && usuario.type !== 2
                      ? true
                      : false) && (
                      <Field
                        dClassName="k-form-field-wrap finbe-form-combo"
                        id="account"
                        name="account"
                        data={accounts}
                        onChange={onChange}
                        placeholder="Selec. Cuenta"
                        value={accounts.find(
                          (x) => x.id == dataFilters.account
                        )}
                        wrapperStyle={{ width: "25%", margin: "0.8em" }}
                        component={InputComboBox}
                      />
                    )}

                    {(usuario && usuario.type !== 4 && usuario.type !== 2
                      ? true
                      : false) && (
                      <Field
                        dClassName="k-form-field-wrap finbe-form-combo"
                        id="company"
                        name="company"
                        data={companies}
                        onChange={onChange}
                        placeholder="Selec. Empresa"
                        value={companies.find(
                          (x) => x.id == dataFilters.company
                        )}
                        wrapperStyle={{ width: "25%", margin: "1.2em" }}
                        component={InputComboBox}
                      />
                    )}
                    {(usuario && usuario.type !== 4 && usuario.type !== 2
                      ? true
                      : false) && (
                      <Field
                        dClassName="k-form-field-wrap finbe-form-combo"
                        id="agency"
                        name="agency"
                        data={agencies}
                        onChange={onChange}
                        placeholder="Selec. Sucursal"
                        value={agencies.find((x) => x.id == dataFilters.agency)}
                        wrapperStyle={{ width: "25%", margin: "1.2em" }}
                        component={InputComboBox}
                      />
                    )}
                    <Field
                      dClassName="k-form-field-wrap finbe-form-combo"
                      id="role"
                      name="role"
                      data={roles}
                      onChange={onChange}
                      placeholder="Selec. Rol"
                      value={roles.find((x) => x.id == dataFilters.role)}
                      wrapperStyle={
                        usuario.type !== 4 && usuario.type !== 2
                          ? { width: "25%", margin: "1.2em" }
                          : { width: "33%", margin: "0.75em" }
                      }
                      component={InputComboBox}
                    />
                    <Field
                      dClassName="k-form-field-wrap finbe-form-date"
                      dateRangePickerId="datePickerFilter"
                      dateRangePickerName="datePickerFilter"
                      dateRangePickerOnChange={onChange}
                      fieldWrapperStyle={
                        usuario.type !== 4 && usuario.type !== 2
                          ? { width: "50%", paddingLeft: "1.2em" }
                          : { width: "56%", paddingLeft: "1.2em" }
                      }
                      defaultValue={{
                        start: dataFilters.datePickerFilter.start,
                        end: dataFilters.datePickerFilter.end,
                      }}
                      dateRangePickerValue={dataFilters.datePickerFilter}
                      component={InputRangeDatePicker}
                    />
                  </FormElement>
                )}
              />
            </MoreFilterBox>
          </div>
        )}
      </div>
      <div>
        <Grid
          className="finbe-table"
          data={process(users, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.data);
          }}
          sortable={{
            mode: "single",
          }}
          pageable={{
            info: false,
          }}
          skip={dataState.skip}
          take={dataState.take}
          resizable
        >
          <Column
            field="firstname"
            title="Usuario"
            cell={(props) => (
              <td>
                {props.dataItem.firstname + " " + props.dataItem.lastname}
              </td>
            )}
          />
          <Column field="email" title="Correo" />
          <Column
            field="role.name"
            title="Rol"
            cell={(props) => (
              <td>
                {props.dataItem.role != null ? props.dataItem.role.name : "N/A"}
              </td>
            )}
          />
          <Column
            field="createdAt"
            title="Fecha"
            filter="date"
            format="{0:dd/MM/yyyy}"
          />
          <Column
            title="Acciones"
            cell={(props) => (
              <td>
                <div style={{ display: "flex" }}>
                  {((usuario &&
                    usuario.type !== 1 &&
                    props.dataItem.id !== usuario.id) ||
                    (usuario.type === 1 && props.dataItem.type === 2)) && (
                    <>
                      <ButtonAction
                        title={"Editar"}
                        icon=" icon editar"
                        look="bare"
                        primary={false}
                        isRedirect={true}
                        url={"/editar-usuario/" + props.dataItem.id}
                      />
                      {/* <ButtonAction
                      title={"Archivar"}
                      icon=" icon archivar"
                      look="bare"
                      primary={false}
                      isRedirect={false}
                      data={props.dataItem.id}
                      onClick={handleDialogArchived}
                    /> */}
                    </>
                  )}
                  {usuario &&
                    (usuario.type == 1 ||
                      usuario.type == 2 ||
                      usuario.type == 3) && (
                      <ButtonAction
                        title={"Reenviar contraseña"}
                        icon=" icon enviar"
                        look="bare"
                        primary={false}
                        isRedirect={false}
                        data={props.dataItem.id}
                        onClick={handleDialogSendPassword}
                      />
                    )}
                </div>
              </td>
            )}
          />
          <Column
            field="isActive"
            title="Estatus"
            cell={(props) => (
              <td>
                {usuario && usuario.id !== props.dataItem.id && (
                  <InputSwitch
                    checked={props.dataItem.isActive}
                    onChange={handleDialogChangeStatus}
                    data={props.dataItem.id}
                  />
                )}
              </td>
            )}
          />
        </Grid>
      </div>
      <DialogBox
        buttonSuccessText="Aceptar"
        centerButtons={true}
        handelAccept={handleChangeStatus}
        handelClose={handleDialogChangeStatus}
        title={"Cambiar estado"}
        visible={visibleChangeStatus}
        visibleCancelButton={true}
      >
        ¿Seguro que desea cambiar el estatus del usuario?
      </DialogBox>
      <DialogBox
        buttonSuccessText="Aceptar"
        centerButtons={true}
        handelAccept={handleSendPassword}
        handelClose={handleDialogSendPassword}
        title={"Reenviar contraseña"}
        visible={visibleSendPassword}
        visibleCancelButton={true}
      >
        ¿Seguro que desea enviar de nuevo la contraseña temporal?
      </DialogBox>
      {/* <DialogBox
        buttonSuccessText="Aceptar"
        centerButtons={true}
        handelAccept={handleArchived}
        handelClose={handleDialogArchived}
        title={"Archivar usuario"}
        visible={visibleArchived}
        visibleCancelButton={true}
      >
        ¿Seguro que desea archivar este usuario?
      </DialogBox> */}
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={response.title}
        visible={visibleResponse}
      >
        {response.message}
      </DialogBox>
    </div>
  );
};
export default Users;
