import httpCommon from "../Utils/http-common";

const getAuth = (data) => {
  const client = httpCommon();
  return client.post("/auth/login", data);
};
const newInformation = (data) => {
  const client = httpCommon();
  return client.post("/auth/set-information", data);
};

export default {
  getAuth,
  newInformation,
};
