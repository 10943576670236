export const menuSU = [
    { text: 'Financiamiento', icon: 'icon financiamiento', id: 1,['data-expanded']: false, className: 'finbe-menu-item header' },
    { text: 'Disposiciones', id: 2, parentId: 1, route: '/disposiciones', className: 'finbe-menu-item' },
    { text: 'Créditos con fallos', id: 3, parentId: 1, route: '/creditos-fallos', className: 'finbe-menu-item' },

    { text: 'Usuarios', icon: 'icon usuarios-asignados', id: 4, ['data-expanded']: false, className: 'finbe-menu-item header' },
    { text: 'Usuarios', id: 5, parentId: 4, route: '/usuarios', className: 'finbe-menu-item' },
    { text: 'Roles', id: 6, parentId: 4, route: '/roles', className: 'finbe-menu-item' },
    { text: 'Correos mesa de servicio', id: 7, parentId: 4, route: '/emails-mesa-servicio', className: 'finbe-menu-item' },


    { text: 'Catálogos', icon: 'icon catalogos', id:8, ['data-expanded']: false, className: 'finbe-menu-item header' },
    { text: 'Cuentas', id: 9, parentId: 8, route: '/cuentas', className: 'finbe-menu-item' },

    { text: 'Configuraciones', icon:'icon configuraciones-', id: 10, route: '/configuraciones', className: 'finbe-menu-item' },
    { text: 'Días inhabiles', icon:'icon configuraciones-', id: 11, route: '/dias-inhabiles', className: 'finbe-menu-item' },


];

export const menuAF = [
    { text: 'Financiamiento', icon: 'icon financiamiento',['data-expanded']: false, id: 1, className: 'finbe-menu-item header' },
    { text: 'Disposiciones', id: 2, parentId: 1, route: '/disposiciones', className: 'finbe-menu-item' },
    { text: 'Unidades en revisión', id: 3, parentId: 1, route: '/disposiciones-revision', className: 'finbe-menu-item' },
    { text: 'Notificaciones', id: 4, parentId: 1, route: '/notificaciones', className: 'finbe-menu-item' },
    { text: 'Excepciones', id: 5, parentId: 1, route: '/excepciones', className: 'finbe-menu-item' },
    { text: 'Layout', id: 6, parentId: 1, route: '/layout', className: 'finbe-menu-item' },


    { text: 'Refinanciamientos', icon: 'icon usuarios-asignados', id: 7, ['data-expanded']: false, className: 'finbe-menu-item header' },
    { text: 'Refinanciamiento', id: 8, parentId: 7, route: '/refinanciamientos', className: 'finbe-menu-item' },
    
    { text: 'Auditorías', icon: 'icon auditorias',['data-expanded']: false, id: 9, className: 'finbe-menu-item header' },
    { text: 'Auditorías', id: 10, parentId: 9, route: '/auditorias', className: 'finbe-menu-item' },

    { text: 'Usuarios', icon: 'icon usuarios-asignados', ['data-expanded']: false, id: 11, className: 'finbe-menu-item header' },
    { text: 'Usuarios', id: 12, parentId: 11, route: '/usuarios', className: 'finbe-menu-item' },
    { text: 'Roles', id: 13, parentId: 11, route: '/roles', className: 'finbe-menu-item' },

    { text: 'Catálogos', icon: 'icon catalogos', id: 14, ['data-expanded']: false, className: 'finbe-menu-item header' },
    { text: 'Cuentas', id: 15, parentId: 14, route: '/cuentas', className: 'finbe-menu-item' },

    // { text: 'Reportes', icon: 'icon reportes', id: 13, ['data-expanded']: false, className: 'finbe-menu-item header' },
    // { text: 'Validaciones periódicas', id: 14, parentId: 13, route: '/validaciones-periodicas', className: 'finbe-menu-item' },

    { text: 'Configuraciones', icon:'icon configuraciones-', id: 16, route: '/configuraciones', className: 'finbe-menu-item' },

];

export const menuOF = [
    { text: 'Financiamiento', icon: 'icon financiamiento', id: 1, ['data-expanded']: false, className: 'finbe-menu-item header' },
    { text: 'Disposiciones', id: 2, parentId: 1, route: '/disposiciones', className: 'finbe-menu-item' },
    { text: 'Inventario', id: 3, parentId: 1, route: '/inventario', className: 'finbe-menu-item' },
    { text: 'Notificaciones', id: 4, parentId: 1, route: '/notificaciones', className: 'finbe-menu-item' },
    { text: 'Excepciones', id: 5, parentId: 1, route: '/excepciones', className: 'finbe-menu-item' },
    
    { text: 'Refinanciamientos', icon: 'icon financiamiento', id: 6, ['data-expanded']: false, className: 'finbe-menu-item header' },
    { text: 'Refinanciamiento', id: 7, parentId: 6, route: '/refinanciamientos', className: 'finbe-menu-item' },
    { text: 'Unidades para refinanciar', id: 8, parentId: 6, route: '/refinanciamientos/unidades', className: 'finbe-menu-item' },
    



    { text: 'Usuarios', icon: 'icon usuarios-asignados', id: 9, ['data-expanded']: false, className: 'finbe-menu-item header' },
    { text: 'Usuarios', id: 10, parentId: 9, route: '/usuarios', className: 'finbe-menu-item' },
    { text: 'Roles', id: 11, parentId: 9, route: '/roles', className: 'finbe-menu-item' },

    { text: 'Catálogos', icon: 'icon catalogos', id: 12, ['data-expanded']: false, className: 'finbe-menu-item header' },
    { text: 'Cuentas', id: 13, parentId: 12, route: '/cuentas', className: 'finbe-menu-item' },
    { text: 'Compañías', id: 14, parentId: 12, route: '/companias', className: 'finbe-menu-item' },
    { text: 'Sucursales', id: 15, parentId: 12, route: '/sucursales', className: 'finbe-menu-item' },

    { text: 'Reportes', icon: 'icon reportes', id: 16, ['data-expanded']: false, className: 'finbe-menu-item header' },
    // { text: 'Validaciones periódicas', id: 14, parentId: 13, route: '/validaciones-periodicas', className: 'finbe-menu-item' },
    { text: 'Estadísticas de disposiciones', id: 17, parentId: 16, route: '/estadisticas-disposiciones', className: 'finbe-menu-item' },


];

export const menuRL = [
    { text: 'Financiamiento', icon: 'icon financiamiento', id: 1, ['data-expanded']: false, className: 'finbe-menu-item header' },
    { text: 'Disposiciones', id: 2, parentId: 1, route: '/disposiciones', className: 'finbe-menu-item' },
    { text: 'Inventario', id: 3, parentId: 1, route: '/inventario', className: 'finbe-menu-item' },
    
    { text: 'Refinanciamientos', icon: 'icon financiamiento', id: 4, ['data-expanded']: false, className: 'finbe-menu-item header' },
    { text: 'Refinanciamiento', id: 5, parentId: 4, route: '/refinanciamientos', className: 'finbe-menu-item' },

    { text: 'Catálogos', icon: 'icon catalogos', id: 6, ['data-expanded']: false, className: 'finbe-menu-item header' },
    { text: 'Sucursales', id: 7, parentId: 6, route: '/sucursales', className: 'finbe-menu-item' },
    
    { text: 'Usuarios', icon: 'icon usuarios-asignados', id: 8, ['data-expanded']: false, className: 'finbe-menu-item header' },
    { text: 'Usuarios', id: 9, parentId: 8, route: '/usuarios', className: 'finbe-menu-item' },

]

export const menuOA = [
    { text: 'Financiamiento', icon: 'icon financiamiento', id: 1, ['data-expanded']: false, className: 'finbe-menu-item header' },
    { text: 'Disposiciones', id: 2, parentId: 1, route: '/disposiciones', className: 'finbe-menu-item' },
    { text: 'Unidades en revisión', id: 3, parentId: 1, route: '/unidades', className: 'finbe-menu-item' },
    { text: 'Inventario', id: 4, parentId: 1, route: '/inventario', className: 'finbe-menu-item' },
    { text: 'Notificaciones', id: 5, parentId: 1, route: '/notificaciones', className: 'finbe-menu-item' },
    { text: 'Excepciones', id: 6, parentId: 1, route: '/excepciones', className: 'finbe-menu-item' },

    { text: 'Refinanciamientos', icon: 'icon financiamiento', id: 7, ['data-expanded']: false, className: 'finbe-menu-item header' },
    { text: 'Refinanciamiento', id: 8, parentId: 7, route: '/refinanciamientos', className: 'finbe-menu-item' },
];
