import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Amplify from "aws-amplify";
import {cognitoConfig} from "./Context/cognito.config.js";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: cognitoConfig.cognito.REGION,
    userPoolId: cognitoConfig.cognito.USER_POOL_ID,
    userPoolWebClientId: cognitoConfig.cognito.APP_CLIENT_ID
  },
});


ReactDOM.render(
  <App />,
  document.getElementById('root')
);

