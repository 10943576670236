import httpCommon from "../Utils/http-common";

const getAll = (params) => {
  const client = httpCommon();
  return client.get("/audits", params);
};

const getById = (id) => {
  const client = httpCommon();
  return client.get(`/audits/${id}`);
};

const create = (data) => {
  const client = httpCommon();
  return client.post("/audits", data);
};

const update = (id, data) => {
  const client = httpCommon();
  return client.put(`/audits/${id}`, data);
};

const validateProcess = (data) => {
  const client = httpCommon();
  return client.get("/audits/validate-process", data);
};

export default {
  create,
  getAll,
  getById,
  update,
  validateProcess,
};
