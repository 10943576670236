/**
 * @author: Alexander Chi
 * @description: Component of disposiciones list, create, edit, information CSV,
 *               upload XML o CSV, units evidences, FinBe validation
 * @date: 22/sept/2020
 **/
import React, { Component } from "react";
import RequisitionCompleted from "./Sections/RequisitionCompleted";
import { ButtonDanger, ButtonSuccess } from "../Sections/Components/Buttons";
import { DialogBox } from "../Sections/Dialogs";
import { useUsuario } from "../../Context/UserContext";
import FinbeService from "../../Services/Finbe/FinbeService";
import ProvisionService from "../../Services/ProvisionService";
import AccountService from "../../Services/AccountService";
import CompanyService from "../../Services/CompanyService";
import UserService from "../../Services/UserServices";
import DocumentService from "../../Services/DocumentService";
import * as XLSX from "xlsx";
import qs from "qs";
import ErrorsUnits from './Sections/ErrorsUnits'
import useLoader from "../../hooks/useLoader";
import dotenv from "dotenv";
dotenv.config();
/** Global variables **/
let user = null;
let signOut = null;
let Loading = useLoader();

/** Get user cotext hook **/
function UserData({ children }) {
  const { usuario,signOutCognito } = useUsuario();
  user = usuario;
  signOut = signOutCognito;
  return children({ user: usuario });
}
const COLUMNS_CSV={
  AGENCY: "SUCURSAL",
  BRANDNAME: "MARCA",
  TYPE: "TIPO UNIDAD",
  MODEL:"MODELO/YEAR",
  DESCRIPTION: "DESCRIPCION",
  COLOR: "COLOR EXTERNO",
  INSIDE_COLOR: "COLOR INTERNO",
  VIN: "VIN",
  MOTOR:"MOTOR",
  AMOUNT:"VALOR TOTAL"

}

/** Create, Edit main **/
class EditProvisions extends Component {
  constructor(props) {
    super(props);
    this.attachedDocumentRef= React.createRef();
    this.attachedDocumentCsvRef= React.createRef();
    this.state = {
      agency: null,
      attachedDocument: [],
      attachedDocumentCsv: [],
      attachedDocumentEvidence: [],
      availableBalance: 0,
      companies: [],
      company: null,
      creditLine: null,
      deadline: null,
      deadlines: [],
      description: "",
      handleChange: "",
      fileName:"",
      value: "",
      legalRepresentative: null,
      includeAll: false,
      remainingBalance: 0,
      response: {title: "", message: "", success: false},
      status: 0,
      step: 0,
      stepMax: 0,
      totalAmount: 0,
      units: [],
      visibleResponse: false,
      visibleConfirm: false,
      showAddUnit:false,
      loading:false,
      isPreowned:false
    };

    /** Get information provision **/
    this._getProvision(this.props.match.params.id)
  }

  /** **/
  componentDidMount() { }

  /** Get Provision **/
  _getProvision = async (provisionId) => {

    try {
    Loading();
      const { data } = await ProvisionService.getById(provisionId);
      let units = data.units.map((unit, index) => {
        unit.evidences.map((evidence, index) => {
          evidence.index = index;
          return evidence;
        });
        return {
          index: index,
          id: unit.id,
          model: unit.model,
          motorNumber: unit.motorNumber,
          vinNumber: unit.vinNumber,
          color: unit.color,
          amount: Number(unit.amount),
          agency: unit.agency,
          type: unit.type,
          description: unit.description,
          brandName: unit.brandName,
          insideColor: unit.insideColor,
          evidences: unit.evidences,
          check: true,
        };
      });
      // if(data.evidences.length>0){
      //   this.attachedDocumentRef.current.disabled=true;
      // }
          /** Set information provision **/
    this.setState({
      agency: data.agency,
      company: data.company,
      creditLine: data.creditLine,
      description: data.description,
      document: data.evidences,
      folio: data.folio,
      fileName:data.evidences.length>0?data.evidences[0].fileName:null,
      legalRepresentative: data.legalPerson,
      status: data.status,
      totalAmount: Number(data.total),
      units,
      includeAll: data.includeAll,
      attachedDocument:data.evidences,
      // loading:false
      isPreowned:process.env.REACT_APP_TYPE_PREOWNED==data.typeLine
    });

    /** Get information selects **/
    this._getCompaniesByAccount(data.accountId);
    this._getAgenciesByCompany(data.company.id, data.agency);
    this._getLegalRepresentantive(data.company.id);
    this._getCreditLines(data.company.clientId);
    Loading();
    } catch (error) {
    Loading();
      if (error.response&&error.response.status == 401) signOut();
    }
  };

  /** Get all companies by account of user session  **/
  _getCompaniesByAccount = async (accountId) => {
    let companies = [];
    let company = {};
    try {
    Loading();
      const res = await AccountService.getCompanies(accountId);
      companies = res.data.map((company, index) => {
        return {
          accountId: company.accountId,
          id: company.id,
          legal: company.legal,
          text: company.name,
        };
      });
      company = companies.find((it) => it.id === this.state.company.id);
      this.setState({ companies, company });
    Loading();
    } catch (error) {
    Loading();
      if (error.response&&error.response.status == 401) signOut();


    }
  };

  /** Get all agencies by company of user session  **/
  _getAgenciesByCompany = async (companyId, agency = null) => {
    let agencies = [];
    try {
    Loading();
      const res = await CompanyService.getAgencies(companyId);
      const _agencies = res.data.filter(ag=>user.agency.find(i=>i.id==ag.id));
      agencies = _agencies.map((agency, index) => {
        return {
          id: agency.id,
          text: agency.name,
          value: agency.id,
        };
      });
      if(agency != null){ agency = agencies.find((it) => it.id === agency.id); }
      this.setState({ agencies, agency });
    Loading();
    } catch (error) {
    Loading();
      if (error.response&&error.response.status == 401) signOut();

    }
  };

  /** Get all legal representative by company of user session **/
  _getLegalRepresentantive = async (companyId) => {
    let legalRepresentatives = [];
    let legalRepresentative = {};
    try {
    Loading();
      const { data } = await UserService.getAll({
        params: { company: companyId, type: [4] },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      legalRepresentatives = data.map((legal, index) => {
        return {
          id: legal.id,
          text: legal.firstname + " " + legal.lastname,
          value: legal.id,
        };
      });
      legalRepresentative = legalRepresentatives.find(it=>it.id == this.state.legalRepresentative.id)
      this.setState({ legalRepresentatives, legalRepresentative });
    Loading();
    } catch (error) {
    Loading();
      if (error.response&&error.response.status == 401) signOut();

    }
  };

  /** Get all credit lines of customer **/
  _getCreditLines = async (customerId, type) => {
    let creditLines = [];
    try {
    Loading();
      const res = await FinbeService.getConfigurationCreditLine({
        params: { numeroCliente: customerId },
      });
      creditLines = res.data.map((line, index) => {
        let deadlines = line.plazos.split("|")
        deadlines.sort(function(a, b){return a-b})
        deadlines = deadlines.map((deadline, index) => {
          return {
            id: index,
            text: "Plazo - " + deadline,
            value: deadline
          };
        });
        return {
          id: line.lineaCredito,
          text: `${line.lineaCredito} ${line.tipoDispLinea== process.env.REACT_APP_TYPE_PREOWNED?"(Seminuevos)":"(Nuevos)"}`,
          value: line.lineaCredito,
          availableBalance: line.montoRestante,
          deadlines: deadlines,
          type: line.tipoDispLinea,
        };
      });
      const creditLine = creditLines.find((it) => it.id == this.state.creditLine);
      this.setState({
        creditLines,
        availableBalance: creditLine.availableBalance,
        deadline: creditLine.deadlines[creditLine.deadlines.length - 1],
        deadlines: creditLine.deadlines,
        creditLine: creditLine,
        remainingBalance: creditLine.availableBalance - this.state.totalAmount,
        // loading: false
      });
    Loading();
    } catch (error) {
    Loading();
    }
  };

  _getReservedAmount=async(params)=>{
    try {
    Loading();
      const res = await ProvisionService.getReservedAmount(params);
    Loading();
      return res.data.total||0;
    } catch (error) {
    Loading();
      if (error.response && error.response.status == 401) signOut();
    }
  }

  /** Delete document */
  _deleteDocument=async(file,nameState)=>{
    try {
    Loading();
      if(file.id){
        const res = await DocumentService.deleteEvidence(file.id,{path:file.url})
      }
      if(nameState=="attachedDocument"){
        // this.attachedDocumentRef.current.disabled=false;
        this.attachedDocumentRef.current.value=""
        if(this.state.includeAll){
          const {units}=this.state;
          units.map(item=>{
            item.check = false;
            item.evidences = [];
            return item;
          })
          this.setState({units,includeAll:false})
        }
      }else if(nameState == "attachedDocumentCsv"){
        this.attachedDocumentCsvRef.current.value=""
      }
      this.setState({[nameState]:[]})
    Loading();
    } catch (error) {
    Loading();
      if (error?.response?.status == 401) signOut();
      console.info(error);
    }
  }

  /** Save document **/
  _uploadDocument = async (file,nameState) => {
    try{
    Loading();
      const response = await DocumentService.uploadDocument(file);
      let doc = this.state[nameState];
      if(response.data.url){
        doc[0].extension = `.${response.data.filename.split('.')[1]}`;
        doc[0].url = response.data.url;
        this.setState({ [nameState]: doc});
        if(this.state.includeAll){
          let totalAmount = 0;
          const {units} = this.state;
          if(units.length > 0){
            units.map((unit, index) => {
              let evidences = [];
              evidences.push(this._setEvidenceUnit(0, doc[0], 7));
              unit.check = true;
              unit.evidences = evidences;
              totalAmount += Number(unit.amount);
              return unit;
            });
            this.setState({units, totalAmount });
          }
        }
        // else{
        //   this.setState({[nameState]:[]})
        // }
      }
    Loading();
      // this.setState({document: response.data})
    }catch(error){
    Loading();
      if (error?.response?.status == 401) signOut();

    }
  };

  /** Calculate amounts**/
  handleCalculate = (amount, check) => {
    let result = 0;
    if (check) {
      result = this.state.totalAmount + amount;
    } else {
      result = this.state.totalAmount - amount;
    }
    let saldoRestante = this.state.availableBalance - result;
    this.setState({
      totalAmount: result,
      remainingBalance: saldoRestante,
    });
  };

  /** Click cancel **/
  handleClickCancel = (e) => {
    this.props.history.push("/disposiciones");
  };

  /** Click next or save **/
  handleClickSave = async (e) => {
    const id = this.props.match.params.id;
    let units = this.state.units;
    let success = true;
    let message = "";
    this.setState({ visibleConfirm:false });
    Loading();
    const {remainingBalance} = this.state;
    if(remainingBalance < 0){
      this.setState({
        response: {title: 'Ocurrió un error', message: 'El monto total supera el saldo restante', success: false},
        visibleResponse: !this.state.visibleResponse,
        loading: false,
      })
    }else{
      try {
        if(this.state.agency !== null && this.state.company !== null && this.state.creditLine !== null && this.state.deadline !== null && this.state.legalRepresentative !=null){
          let data = {
            accountId: user.account.id,
            agencyId: this.state.agency.id,
            companyId: this.state.company.id,
            creditLine: this.state.creditLine.id,
            deadlines: this.state.deadline.value,
            description: this.state.description,
            evidences: [],
            legalId: this.state.legalRepresentative.id,
            registerBy: user.id,
            total: this.state.totalAmount,
            typeLine: this.state.creditLine.type,
            units: [],
            includeAll: this.state.includeAll,

          };
  
          /** Set evidences in header **/
          if(this.state.attachedDocument.length>0){
            if(!this.state.attachedDocument[0].id){
              [...this.state.attachedDocument].map((file, index) => {
                let doc = this.state.document[0]
                if(doc){
                  const res = DocumentService.deleteEvidence(doc.id,{path:doc.url});
                }
                data.evidences.push(this._setEvidence(0, file, 2));
              });
            }
          }
  
          /** Set units of true checkbox **/
          units.map((unit) => {
            const { index, check, ...rest } = unit;
            if (check) {
              /** Delete attribute file **/
              rest.evidences.map((evidence) => {
                delete evidence.file;
                delete evidence.index;
                delete evidence.createdAt;
                delete evidence.legalarioId;
                delete evidence.signedDocumentId;
                delete evidence.signedStatus;
                return evidence;
              });
              rest.model = String(rest.model);
              data.units.push(rest);
            }
          });
          /** UPDATE API => ProvisionService **/
          const res = await ProvisionService.update(id, data);
          message = "Disposición con folio " + res.data.folio + " actualizada exitosamente.";

        }else{
          throw ({response:{status:500,data:{message:'Verifique los campos obligatorios'}}})

        }
        Loading();
      } catch (error) {
        Loading();
        if (error?.response?.status == 401) signOut();
          success = false;
          message = error.response?error.response.data.message:error.message
      }
      /** Show response dialog **/
      this.setState({
        response: {
          title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
          message: message,
          success: success
        },
        visibleResponse: !this.state.visibleResponse,
        // loading: false

      });
    }
  };

  /** Handel close or show dialog response **/
  handleDialogResponse = (e) => {
    if(this.state.response.success){
      this.props.history.push("/disposiciones");
    }else{
      this.setState({ visibleResponse: !this.state.visibleResponse });
    }
  };

  /**open dialog Confirm */

  handleDialogConfirm=()=>{
    this.setState({visibleConfirm:!this.state.visibleConfirm});
  }

  handleShowAddUnit=()=>{
    this.setState({showAddUnit:!this.state.showAddUnit})
  }

  // /** Change for get value inputs **/
  // handleChange = (e) => {
  //   const { value, props } = e.target;
  //   this.setState({ [props.id]: value });
  // };
  /** Change for get value inputs **/
  handleChange = async (e) => {
    const { value, props } = e.target;
    if(props === undefined){
      /** when is RadioButton **/
      const { element } = e.target;
      const {units} = this.state;
      let totalAmount = 0;
      if(units.length > 0){
        const {attachedDocument} = this.state;
        if(attachedDocument.length > 0){
          units.map((unit, index) => {
            let evidences = [];
            if(e.value) { evidences.push(this._setEvidenceUnit(0, attachedDocument[0], 7)); }
            unit.check = e.value;
            unit.evidences = evidences;
            totalAmount += Number(unit.amount);
            return unit;
          });
        }
      }
      if(units.length > 0 && totalAmount > 0 && e.value === false){
        totalAmount = 0;
      }
      this.setState({
        [element.current.id]: e.value,
        units,
        totalAmount
      });
    }else{
        this.setState({ [props.id]: value });
        if (props.id === "company") {
          if (value !== null) {
            this._getAgenciesByCompany(value.id);
            this._getLegalRepresentantive(value.id);
            this._getCreditLinesByCustomer(value.clientId, true);
          } else {
            this.setState({ agencies: [] });
          }
        } else if (props.id === "creditLine") {
          if (value !== null) {
            let montoReservado = await this._getReservedAmount({params:{creditLine:value.value}});
            let _availableBalance = (+(value.availableBalance-(+montoReservado)).toFixed(2))
            const {totalAmount} = this.state;
            let remainingBalance = (_availableBalance>0?_availableBalance:0) - totalAmount;
            let deadline = value.deadlines[value.deadlines.length - 1];
            if(remainingBalance < 0){
              this.setState({
                isPreowned: process.env.REACT_APP_TYPE_PREOWNED==value.type,
                availableBalance: _availableBalance>0?_availableBalance:0,
                remainingBalance,
                deadline,
                deadlines: value.deadlines,
                response: {title: 'Ocurrió un error', message: 'El monto total supera el saldo restante', success: false},
                visibleResponse: !this.state.visibleResponse
              });
            }else{
              this.setState({
                isPreowned: process.env.REACT_APP_TYPE_PREOWNED==value.type,
                availableBalance: value.availableBalance,
                remainingBalance,
                deadline,
                deadlines: value.deadlines,
              });
            }
          } else {
            this.setState({ availableBalance: 0.0, deadlines: [], deadline: null });
          }
        }
    }
  };
  /** Set object evidence for list evidences of units **/
  _setEvidenceUnit(index, evidences, type){
    return {
      extension: String(evidences.extension),
      file: evidences,
      fileName: String(evidences.name||evidences.fileName),
      fileSize: String(evidences.size||evidences.fileSize),
      index: index,
      type: String(type),
      url: evidences.url,
    }
  };
  /** Set object evidence for list evidences **/
  _setEvidence(index, evidence, type){
    return {
      extension: String(evidence.extension),
      /*file: evidences,*/
      fileName: String(evidence.name),
      fileSize: String(evidence.size),
      /*index: String(index),*/
      type: String(type),
      url: evidence.url,
    }
  }

/**Validation unit CSV */
handleValidateUnit=(unit)=>{
  let validations = []
  //Valid Model
  if(unit[COLUMNS_CSV.MODEL].toString() === ""){
    validations.push(`La columna ${COLUMNS_CSV.MODEL} está vacía`)
  }else if(unit[COLUMNS_CSV.MODEL].toString().length > 4){
    validations.push(`La columna ${COLUMNS_CSV.MODEL} excede el número de caracteres permitidos {4}`)
  }
  //Valid Motor number
  if(unit[COLUMNS_CSV.MOTOR].toString() === ""){
    validations.push(`La columna ${COLUMNS_CSV.MOTOR} está vacía`)
  }else if(unit[COLUMNS_CSV.MOTOR].toString().length > 25){
    validations.push(`La columna ${COLUMNS_CSV.MOTOR} excede el número de caracteres permitidos {25}`)
  }
  //Valid VIN
  if(unit[COLUMNS_CSV.VIN].toString() === ""){
    validations.push(`La columna ${COLUMNS_CSV.VIN} está vacía`)
  }else if(unit[COLUMNS_CSV.VIN].toString().length < 17 || unit[COLUMNS_CSV.VIN].toString().length > 23){
    validations.push(`La columna ${COLUMNS_CSV.VIN} no se encuentra en el rango de caracteres permitidos {17-23}`)
  }
  //Sucursal asignada
  if(unit[COLUMNS_CSV.AGENCY].toString() === ""){
    validations.push(`La columna ${COLUMNS_CSV.AGENCY} está vacía`)
  }else if(unit[COLUMNS_CSV.AGENCY].toString().length > 20){
    validations.push(`La columna ${COLUMNS_CSV.AGENCY} excede el número de caracteres permitidos {20}`)
  }
  //Valor total
  if(typeof(unit[COLUMNS_CSV.AMOUNT])!=="number"){
    validations.push(`La columna ${COLUMNS_CSV.AMOUNT} no es el tipo de dato especificado`)
  }else if(unit[COLUMNS_CSV.AMOUNT] ===0){
    validations.push(`La columna ${COLUMNS_CSV.AMOUNT} no contiene el valor especifico`)
  }else if((unit[COLUMNS_CSV.AMOUNT]+'').replace('.','').length>10){
    validations.push(`La columna ${COLUMNS_CSV.AMOUNT} excede el número de dígitos permitidos {10}`)
  }
  //Marca
  if(unit[COLUMNS_CSV.BRANDNAME].toString() === ""){
    validations.push(`La columna ${COLUMNS_CSV.BRANDNAME} está vacía`)
  }else if(unit[COLUMNS_CSV.BRANDNAME].toString().length > 15){
    validations.push(`La columna ${COLUMNS_CSV.BRANDNAME} excede el número de caracteres permitidos {15}`)
  }
  //Color exterior
  if(unit[COLUMNS_CSV.COLOR].toString() === ""){
    validations.push(`La columna ${COLUMNS_CSV.COLOR} está vacía`)
  }else if(unit[COLUMNS_CSV.COLOR].toString().length > 35){
    validations.push(`La columna ${COLUMNS_CSV.COLOR} excede el número de caracteres permitidos {35}`)
  }
  //Descripcion
  if(unit[COLUMNS_CSV.DESCRIPTION].toString() === ""){
    validations.push(`La columna ${COLUMNS_CSV.DESCRIPTION} está vacía`)
  }else if(unit[COLUMNS_CSV.DESCRIPTION].toString().length > 50){
    validations.push(`La columna ${COLUMNS_CSV.DESCRIPTION} excede el número de caracteres permitidos {50}`)
  }
  //Color interior
  if(unit[COLUMNS_CSV.INSIDE_COLOR].toString() === ""){
    validations.push(`La columna ${COLUMNS_CSV.INSIDE_COLOR} está vacía`)
  }else if(unit[COLUMNS_CSV.INSIDE_COLOR].toString().length > 70){
    validations.push(`La columna ${COLUMNS_CSV.INSIDE_COLOR} excede el número de caracteres permitidos {70}`)
  }
  //Tipo de unidad
  if(unit[COLUMNS_CSV.TYPE].toString() === ""){
    validations.push(`La columna ${COLUMNS_CSV.TYPE} está vacía`)
  }else if(unit[COLUMNS_CSV.TYPE].toString().length > 8){
    validations.push(`La columna ${COLUMNS_CSV.TYPE} excede el número de caracteres permitidos {8}`)
  }  
  return validations;
}

  /** Change for get value inputs arrays file **/
  handleChangeFile = (e) => {
    try {
      const {name, props, files } = e.target;
    // this.setState({ [props.id]: files });
    if (name === "attachedDocumentCsv") {
      /** Read information document **/
      let types = ['csv']
      const file = files[0];
      if(!types.includes(file.name.split('.')[1])) throw new Error('La extensión del documento no es permitido')
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsText(file,'ISO-8859-1');
        fileReader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          resolve(data);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
      promise.then((unitsFormatCsv) => {
        /** information csv **/
        let units = []
        let validations = [];
        unitsFormatCsv.forEach((unit, index) => {
          let isValid = true;
          let unitExist = this.state.units.find(it => it.vinNumber === unit.VIN)
          if(unitExist !== undefined){
            units.push(unitExist);
          }else{

            //Posiblemente agregar la validacion includeAll
            // if(this.state.includeAll){
            //   const {attachedDocument} = this.state;
            //   evidences.push(this._setEvidenceUnit(0, attachedDocument[0], 7));
            //   totalAmount += Number(unit[COLUMNS_CSV.AMOUNT]);
            // }

            if(Object.keys(unit).length !== 10){
              validations.push({title:`La fila ${index+1}:`,items:[`La fila no cuenta el número de columnas especificado en el formato CSV`]})
              isValid=false;
            }else{
              let validUnit = this.handleValidateUnit(unit);
              if(validUnit.length>0){
                isValid=false;
                validations.push({title:`La fila ${index+1}:`,items:this.handleValidateUnit(unit)})
              }
            }
  
            if(validations.length===0)
            units.push({
              index: index,
              model: unit[COLUMNS_CSV.MODEL].toString(),
              motorNumber: unit[COLUMNS_CSV.MOTOR].toString(),
              vinNumber: unit[COLUMNS_CSV.VIN].toString().trim(),
              color: unit[COLUMNS_CSV.COLOR].toString(),
              amount: unit[COLUMNS_CSV.AMOUNT],
              agency: unit[COLUMNS_CSV.AGENCY].toString(),
              brandName: unit[COLUMNS_CSV.BRANDNAME].toString(),
              description: unit[COLUMNS_CSV.DESCRIPTION].toString(),
              insideColor: unit[COLUMNS_CSV.INSIDE_COLOR].toString(),
              type: unit[COLUMNS_CSV.TYPE].toString(),
              evidences:[],
              check: false,//this.state.includeAll
            });
          }
        });
        this.setState({ attachedDocumentCsv:validations.length>0?[]:files,visibleResponse:validations.length>0?true:false,units, response:validations.length>0?{title:"Ocurio un error",message:ErrorsUnits({validations}),success:false}:this.state.response });
      });
    }else{
      let types = ['pdf']
      const file = files[0];
      if(!types.includes(file.name.split('.')[1])) throw new Error('La extensión del documento no es permitido')
      let formData = new FormData();
      this.setState({ [name]: files, fileName:null });
      formData.append('file', file);
      this._uploadDocument(formData, name);
    }
    } catch (error) {
      this.setState({
        response: {
          title: "Ocurrió un error",
          message: error.message,
          success: false
        },
        visibleResponse: !this.state.visibleResponse,
      });
    }
  };

  render() {
    return (
      <UserData>
        {({ user }) => {
          return (
            <div className="finbe-app-container">
              <div>
                <RequisitionCompleted
                  agencies={this.state.agencies}
                  agency={this.state.agency}
                  attachedDocument={this.state.attachedDocument}
                  attachedDocumentCsv={this.state.attachedDocumentCsv}
                  availableBalance={this.state.availableBalance}
                  includeAll={this.state.includeAll}
                  fileName={this.state.fileName}
                  companies={this.state.companies}
                  company={this.state.company}
                  creditLine={this.state.creditLine}
                  creditLines={this.state.creditLines}
                  deadline = {this.state.deadline}
                  deadlines = {this.state.deadlines}
                  description={this.state.description}
                  // document = {this.state.document}
                  handleCalculate={this.handleCalculate}
                  handleChange={this.handleChange}
                  handleChangeFile={this.handleChangeFile}
                  legalRepresentative={this.state.legalRepresentative}
                  legalRepresentatives={this.state.legalRepresentatives}
                  remainingBalance={this.state.remainingBalance}
                  stepBoxTitle= {"N° de Disposición " + this.state.folio }
                  totalAmount={this.state.totalAmount}
                  units={this.state.units}
                  uploadDocument = {this._uploadDocument}
                  showAddUnit={this.state.showAddUnit}
                  handleShowAddUnit={this.handleShowAddUnit}
                  handleDeleteFile={this._deleteDocument}
                  refFile={this.attachedDocumentRef}
                  refFileCsv={this.attachedDocumentCsvRef}
                  isPreowned={this.state.isPreowned}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}></div>
                <div style={{ display: "flex", textAling: "left" }}>
                  <ButtonDanger
                    className=""
                    icon="close"
                    onClick={this.handleClickCancel}
                    pClassName="finbe-btn-margin"
                    primary={true}
                    text="Cancelar"
                  />
                  <ButtonSuccess
                    className=""
                    icon="check"
                    onClick={this.handleDialogConfirm}
                    pClassName="finbe-btn-margin"
                    primary={true}
                    text="Guardar"
                  />
                </div>
              </div>
              <DialogBox
                buttonSuccessText = {"Aceptar"}
                handelClose = {this.handleDialogConfirm}
                handelCancel={this.handleDialogConfirm}
                handelAccept = {this.handleClickSave}
                title = {"Creación de disposición"}
                visible = {this.state.visibleConfirm}
                visibleCancelButton={true}
              >
                {
                  `Tienes ${this.state.units.filter(item=>item.check).length} de ${this.state.units.length} unidades seleccionadas, ¿Estás seguro de crear esta disposición?`
                }
              </DialogBox>
              <DialogBox
                buttonSuccessText = {"Aceptar"}
                handelClose = {this.handleDialogResponse}
                handelAccept = {this.handleDialogResponse}
                title = {this.state.response.title}
                visible = {this.state.visibleResponse}
              >
                {this.state.response.message}
              </DialogBox>
            </div>
          );
        }}
      </UserData>
    );
  }
}

export default EditProvisions;
