import React, { useContext } from 'react';
import {useUsuario} from '../../../Context/UserContext'
import { Avatar } from '@progress/kendo-react-layout';


const defaultProps = {
    name: 'Nombre',
    position: 'Posición',
    src: '/icons/ICONOGRAFIA/Default_Foto de perfil circular.svg',
}

const _Avatar = (props) => {
    const {usuario} = useUsuario();
    const {
        name,
        position,
        src,
    } = { ...defaultProps, ...props};

    const getType = () => {
        switch (usuario.type) {
            case 1:
                return "Super usuario"
            case 2:
                return "Administrador Finbe"
            case 3:
                return "Operador Finbe"
            case 4:
                return "Representante Legal"
            case 5:
                return "Operador Agencia"
            default:
                return "-"
        }
    }

    return(
        
        <div className="finbe-avatar">
            <div className={"finbe-avatar-description"}>
                <div className={"finbe-avatar-name"}>{(usuario)?usuario.firstname + ' ' + usuario.lastname:''}</div>
                <div className={"finbe-avatar-rol"}>{(usuario)&&getType()}</div>
            </div>
            <Avatar shape="circle">
                <img src={src} />
            </Avatar>
        </div>
    );
}

export default _Avatar;