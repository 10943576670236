import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import AgencyService from "../../Services/AgencyService";
import AgencyButtons from "./AgencyButtons";
import AccountService from "../../Services/AccountService";
import { useUsuario } from "../../Context/UserContext";
import UserServices from "../../Services/UserServices";
import qs from "qs";
import { InputComboBox, InputText } from "../Sections/Components/Inputs";
import { DialogBox } from "../Sections/Dialogs";
import useLoader from "../../hooks/useLoader";

const CreateEditAgency = (props) => {
  let history = useHistory();
  let initialState = {
    id: "",
    name: "",
    address: "",
    account: {},
    company: {},
    description: "",
    client_id: "",
    assignedTo: "",
  };
  const { usuario, signOutCognito, setCargando } = useUsuario();

  const [agency, setAgency] = useState(initialState);
  const [editing, setEditing] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [gotAccounts, setGotAccounts] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [visible, setVisible] = useState(false);
  const [dataResponse, setDataResponse] = useState({
    title: "",
    message: "",
    success: "",
  });
  const Loading = useLoader();
  const onChange = (event) => {
    //Previene la limpieza de la combobox
    const { nativeEvent } = event;

    if (nativeEvent.type == "blur") return;

    const { name, value } = event.target;
    setAgency(prev=>({ ...prev, [name]: value }));

    if (name == "account") {
      if (value != null)
        if (value.id)
          if (value.id.length > 0)
            AccountService.getCompanies(value.id)
              .then((res) => {
                setCompanies(res.data);
                if (res.data.length === 1) {
                  setAgency(prev=>({ ...prev, company: {id:res.data[0].id,text:res.data[0].name} }));
                }
              })
              .catch((e) => {
                if (e?.response?.status == 401) signOutCognito();
              });

      if (value == null) {
        setCompanies([]);
        setAgency({ ...agency, account: null, company: null });
      }
    }
  };

  const onSave = async () => {
    setVisibleConfirm(false);
    let success = true;
    let message = "";
    const { name, address, account, company } = agency;

    const registerBy = usuario ? (usuario.id ? usuario.id : null) : null;
    let assignedTo = usuario ? (usuario.id ? usuario.id : null) : null;
    if (editing) {
      try {
        Loading();
        const { data } = await AgencyService.update(agency.id, {
          name,
          description: " ",
          address,
          companyId: company.id,
          accountId: account.id,
          registerBy,
          assignedTo,
        });
        message =
          "La informacion de la sucursal " + data.name + " se ha actualizado";
        Loading();
      } catch (error) {
        Loading();
        if (error?.response?.status == 401) signOutCognito();
        success = false;
        message = error.response ? error.response.data.message : error.message;

      }
      setDataResponse({
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: success,
      });
      setVisible(!visible);
    } else {
      try {
        Loading();
        const { data } = await AgencyService.create({
          name,
          description: " ",
          address,
          accountId: account.id,
          companyId: company.id,
          registerBy,
          assignedTo,
        });
        message = "La sucursal " + data.name + " se guardo correctamente";
        Loading();
      } catch (error) {
        Loading();
        if (error?.response?.status == 401) signOutCognito();
        success = false;
        message = error.response ? error.response.data.message : error.message;;
      }
      setDataResponse({
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: success,
      });
      setVisible(!visible);
    }
  };

  const getUpdate = async (id) => {
    try {
      Loading();
      const ag = await AgencyService.getById(id);
      const data = ag.data;

      const accountId = data.accountId;
      const companyId = data.companyId;
      const assignedTo_id = data.assignedTo;

      let account = {};
      let company = {};
      let assignedTo = {};

      const ac = await AccountService.getById(accountId);
      account = { id: ac.data.id, text: ac.data.name };

      const co = await AccountService.getCompanies(accountId);
      const companies = co.data.filter((t) => t.id == companyId);

      if (companies.length > 0) {
        company = { id: companyId, text: companies[0].name };
        setCompanies(companies);
      }

      const user = await UserServices.getById(assignedTo_id);
      assignedTo = { id: user.data.id, text: user.data.email };

      setAgency({ ...data, account, company, assignedTo });
      setEditing(!editing);
      Loading();
    } catch (e) {
      Loading();
      if (e?.response?.status == 401) signOutCognito();
    }
  };
  const handleDialogResponse = () => {
    if (dataResponse.success) {
      history.push("/sucursales");
    } else {
      setVisible(!visible);
    }
  };
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [dataConfirm, setDataConfirm] = useState({});
  const openDialog = () => {
    setVisibleConfirm(true);

    if (editing) {
      setDataConfirm({
        title: "Edición de sucursal",
        message: "¿Desea guardar los cambios?",
      });
    } else {
      setDataConfirm({
        title: "Creación de sucursal",
        message: "¿Estás seguro de crear esta sucursal?",
      });
    }
  };

  useEffect(() => {
    if (props.match.params.id != undefined) getUpdate(props.match.params.id);
  }, [props.match.params.id]);

  useEffect(() => {
    if (!gotAccounts)
      if (usuario) {
        Loading();
        AccountService.getAll({ params: { assigned: usuario.id } })
          .then((res) => {
            Loading();
            setAccounts(res.data);
            if (res.data.length === 1) {
              setAgency(prev=>({ ...prev, account: {id:res.data[0].id,text:res.data[0].name} }));
              AccountService.getCompanies(res.data[0].id)
                .then((res) => {
                  setCompanies(res.data);
                  if (res.data.length === 1) {
                    setAgency(prev=>({ ...prev, company: {id:res.data[0].id,text:res.data[0].name} }));
                  }
                })
                .catch((e) => {
                  if (e?.response?.status == 401) signOutCognito();
                });
            }
            setGotAccounts(true);
          })
          .catch((e) => {
            Loading();

            if (e?.response?.status == 401) signOutCognito();
            setGotAccounts(true);
          });
      }
  });

  return (
    <div className="finbe-app-container">
      <h3>Datos de la sucursal</h3>
      <Form
        render={(formRenderProps) => (
          <FormElement>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="name"
                  label="* Nombre"
                  nombre="name"
                  onChange={onChange}
                  placeholder="Ingresar nombre"
                  required={true}
                  validityStyles={true}
                  minLength={1}
                  maxLength={150}
                  value={agency.name}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputComboBox}
                  data={accounts.map((account) => ({
                    id: account.id,
                    text: account.name,
                  }))}
                  dClassName="k-form-field-wrap finbe-form-combo"
                  id="account"
                  label="* Cuentas"
                  name="account"
                  onChange={onChange}
                  placeholder="Seleccionar cuenta"
                  required={true}
                  validityStyles={true}
                  value={agency.account}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="address"
                  label="Dirección"
                  nombre="address"
                  onChange={onChange}
                  placeholder="Ingresar dirección"
                  value={agency.address}
                  validityStyles={true}
                  minLength={0}
                  maxLength={300}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputComboBox}
                  data={companies.map((company) => ({
                    id: company.id,
                    text: company.name,
                  }))}
                  dClassName="k-form-field-wrap finbe-form-combo"
                  id="company"
                  label="* Empresas"
                  name="company"
                  onChange={onChange}
                  placeholder="Seleccionar empresa"
                  required={true}
                  validityStyles={true}
                  value={agency.company}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
          </FormElement>
        )}
      />
      <AgencyButtons onClick={editing ? onSave : openDialog} />
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={dataResponse.title}
        visible={visible}
      >
        {dataResponse.message}
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => {
          setVisibleConfirm(false);
        }}
        handelCancel={() => setVisibleConfirm(false)}
        handelAccept={onSave}
        title={dataConfirm.title}
        visible={visibleConfirm}
        visibleCancelButton={true}
      >
        {dataConfirm.message}
      </DialogBox>
    </div>
  );
};

export default CreateEditAgency;
