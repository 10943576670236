import httpCommon from "../Utils/http-common";

const getUnitsLayout = (params) => {
  const client = httpCommon();
  return client.get("/layouts/generate", params);
};
const applyLayout = (data) => {
  const client = httpCommon();
  return client.post("/layouts/apply", data);
};

export default { getUnitsLayout, applyLayout };
