import React from 'react'
import { Dialog } from '@progress/kendo-react-dialogs';

export const ListCompanies = (props) => {
    return (
        <Dialog title=" " onClose={props.close} width={400} height={350}>
            {/* <button className="k-button" style={{float: "right"}} onClick={()=>props.setVisibleInfo(false)}>X</button> */}

            <p style={{ margin: "25px", textAlign: "left" }}>Datos de las empresas</p>

            {props.companies.map((company,index) => 
                <div key={index}><div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%', marginRight: '18px' }}>Nombre: {company.name}</div>
                    <div style={{ width: '50%', marginRight: '18px' }}>Repre. Legal: {(company.legal.firstname +' '+company.legal.lastname)}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%', marginRight: '18px' }}>Razon social: {company.legalName}</div>

                    <div style={{ width: '50%', marginRight: '18px' }}>Fecha de alta: {company.createdAt}</div>

                </div>
                <hr /></div>
            )}

        </Dialog>
    )
}
