import React from 'react'
import { StepBox } from '../../Sections/Steps'

export const Resumen = (props) => {
    return (

        <StepBox>
            
            {props.children}

        </StepBox>

    )
}
