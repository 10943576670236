import httpCommon from "../Utils/http-common";

const showDocument = (data) => {
  const client = httpCommon();
  return client.post("/document/token", data);
};
const deleteDocument = (data) => {
  const client = httpCommon();
  return client.post("/document/delete", data);
};
const uploadDocument = (data) => {
  const client = httpCommon();
  return client.post("/document/upload", data);
};

const sendDocumentSignature = (data) => {
  const client = httpCommon();
  return client.post("/document/requestSignature", data);
};

const deleteEvidence = (id, data) => {
  const client = httpCommon();
  return client.post(`/document/delete/${id}`, data);
};

const redoDocument = (data) => {
  const client = httpCommon();
  return client.post("/document/redoDocument", data);
};

const redoDocumentRef = (data) => {
  const client = httpCommon();
  return client.post("/document/redoDocument-ref", data);
};

export default {
  deleteDocument,
  deleteEvidence,
  sendDocumentSignature,
  showDocument,
  uploadDocument,
  redoDocument,
  redoDocumentRef,
};
