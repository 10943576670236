import React, { Component } from "react";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import "./index.css";
import "./finbe-codes.css";
import "./finbe-piso.css";
import Layout from "./Components/main/Layout";
import { UsuarioProvider } from "./Context/UserContext";
import { BrowserRouter } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <UsuarioProvider>
          <Layout />
        </UsuarioProvider>
      </BrowserRouter>
    );
  }
}

export default App;
