/**
 * @author: Alexander Chi
 * @description: Component grid units reviews of provisions
 * @date: 17/nov/2020
 **/
import React, { Component } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import { InputFilterText, InputFile } from "../Sections/Components/Inputs";
import { ButtonAction, ButtonSuccess } from "../Sections/Components/Buttons";
import { FilterBox } from "../Sections/Filters";
import { DialogBox } from "../Sections/Dialogs";
import { LabelWithTextBorder } from "../Sections/Components/Tags";
import moment from "moment";
import { useUsuario } from "../../Context/UserContext";
import UnitsService from "../../Services/UnitsService";
import DocumentService from "../../Services/DocumentService";
import qs from "qs";
import { process } from "@progress/kendo-data-query";
import useLoader from "../../hooks/useLoader";
/** Global variables **/
let user = null;
let signOut = null;
let Loading = useLoader();
/** Get user cotext hook **/
function UserData({ children }) {
  const { usuario, signOutCognito } = useUsuario();
  user = usuario;
  signOut = signOutCognito;
  return children({ user: usuario });
}

/** Format number **/
const numberFormat = (value) => new Intl.NumberFormat("en-US").format(value);

/** Main view **/
class Units extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attached: [],
      dataAction: { id: "", action: null },
      evidences: [],
      isDown: true,
      isMoreFilter: true,
      model: "",
      response: { title: "", message: "", success: false },
      skip: 0,
      take: 10,
      showRegister: { id: 1, text: 10 },
      units: [],
      vinNumber: "",
      visibleResponse: false,
      sort: [{ field: "vinNumber", dir: "desc" }],
    };
    localStorage.setItem("units", JSON.stringify([]));
  }

  /** Get information before render  **/
  componentWillMount() {
    //console.info(this.props.location.customNameData)
  }

  /** Get information after render  **/
  componentDidMount() {
    let params = { registerBy: user.id };
    this._getUnitsReview(params);
  }

  /** Event change props news **/
  /*shouldComponentUpdate (nextProps){
    return true;
  }*/

  /** Get all units review **/
  _getUnitsReview = async (params) => {
    try {
      Loading();
      // setCargandoC(true)
      let { data } = await UnitsService.reviews({ params });
      /** Valid check included **/
      data = data.map((unit, index) => {
        unit.check = false;
        return unit;
      });
      this.setState({ units: data });
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOut();
      console.info(error);
    }
  };

  /** Save document **/
  _uploadDocument = async (id, formData) => {
    try {
      Loading();
      const response = await UnitsService.uploadDocument(id, formData);
      this._getEvidencesByIdUnit(id);
      // this.setState(prev=>{
      //   return { ...prev,attached: null }
      // });
      this.setState((prev) => {
        return { ...prev, attached: [] };
      });
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOut();
      console.info(error);
    }
  };

  /** Get list evidences by ID unit **/
  _getEvidencesByIdUnit = async (id) => {
    try {
      Loading();
      const response = await UnitsService.getEvidenes(id);
      this.setState({ evidences: response.data });
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOut();
      console.info(error);
    }
  };

  /** Delete unit by ID **/
  _removeUnit = async (id) => {
    let { units } = this.state;
    let success = true;
    let message = "";
    try {
      Loading();
      const response = await UnitsService.remove(id);
      let unit = units.find((unit) => unit.id === id);
      if (response) {
        units = units.filter((unit) => unit.id !== id);
        message =
          "Unidad con VIN [" + unit.vinNumber + "]: Se removió correctamente";
      } else {
        message =
          "Unidad con VIN [" + unit.vinNumber + "]: " + response.message;
      }
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOut();
      success = false;
      message = error.message;
    }
    this.setState({
      response: {
        title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
        message: message,
        success: success,
      },
      visibleAction: !success,
      visibleResponse: !this.state.visibleResponse,
      units,
    });
  };

  /** Delete evidence document **/
  _removeEvidence = async (id, params) => {
    try {
      Loading();
      const response = await DocumentService.deleteEvidence(id, params);
      if (response) {
        let evidences = this.state.evidences.filter(
          (evidence) => evidence.id != id
        );
        this.setState({ evidences });
      }
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOut();
      console.info(error);
    }
  };

  /** Click Search **/
  handleClickSearch = (e) => {
    let params = {};
    params.registerBy = user.id;
    if (this.state.model !== "") {
      params.model = this.state.model;
    }
    if (this.state.vinNumber !== "") {
      params.vinNumber = this.state.vinNumber;
    }
    this._getUnitsReview(params);
    this.setState({ skip: 0 });
  };

  /** Change input credit line, operator, status **/
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === undefined) {
      const { props, files } = e.target;
      this.setState((prevState) => {
        return {
          ...prevState,
          [props.id]: files,
        };
      });
    } else {
      this.setState({
        [name]: value,
        ["skip"]: name == "showRegister" ? 0 : this.state.skip,
      });
    }
  };

  /** Handel click visualize, add evidences and delete units **/
  handleAction = (e) => {
    const target = e.currentTarget;
    if (target !== undefined) {
      let id = target.getAttribute("value");
      let action = target.getAttribute("data");
      if (action === "upload") {
        this._getEvidencesByIdUnit(id);
      }
      if (action != null && id != null) {
        this.setState({
          dataAction: { id, action },
          visibleAction: !this.state.visibleAction,
        });
      } else {
        this.setState((prev) => {
          return {
            ...prev,
            visibleAction: !this.state.visibleAction,
            attached: [],
          };
        });
        // this.setState(prev=>{return{...prev,attached:[]}})
      }
    } else {
      this.setState((prev) => {
        return {
          ...prev,
          visibleAction: !this.state.visibleAction,
          attached: [],
        };
      });
      // this.setState(prev=>{return{...prev,attached:[]}})
    }
  };
  // _renderEnableSuccess(){
  //   let {attached} = this.state;
  //   // if(attached !== null){
  //   //   if(!(attached.length > 0)){
  //   //     return false
  //   //   }
  //   // }
  //   if(attached.length>0)
  //   return true;
  //   else
  //   return false
  // }

  /** Hendel click button aceptar o guardar (upload or delete) **/
  handleAcceptAction = (e) => {
    let { dataAction } = this.state;
    switch (dataAction.action) {
      case "upload":
        let { attached } = this.state;
        if (attached.length > 0) {
          let formData = new FormData();
          formData.append("file", attached[0].getRawFile());
          formData.append("fileName", attached[0].name);
          formData.append("extension", attached[0].extension);
          formData.append("fileSize", attached[0].size);
          formData.append("type", 8);
          this._uploadDocument(dataAction.id, formData);
        }
        break;
      case "delete":
        this._removeUnit(dataAction.id);
        break;
      default:
        return null;
    }
  };

  /** Delete evidence view and azure **/
  handelRemoveFile = (e) => {
    const target = e.currentTarget;
    if (target !== undefined) {
      let id = target.getAttribute("data");
      let evidence = this.state.evidences.find(
        (evidence) => evidence.id === id
      );
      this._removeEvidence(id, { path: evidence.url });
    }
  };

  /** Click close dialog response **/
  handleResponse = (e) => {
    this.setState({ visibleResponse: !this.state.visibleResponse });
  };

  /** handel check row **/
  handelSelectionChange = (event) => {
    let unit = event.dataItem;
    const units = this.state.units.map((item) => {
      if (item.id === unit.id) {
        item.check = !unit.check;
      }
      return item;
    });
    localStorage.setItem(
      "units",
      JSON.stringify(units.filter((it) => it.check))
    );
    this.setState({ units });
  };

  /** Title dialog **/
  renderTitle() {
    let { dataAction } = this.state;
    switch (dataAction.action) {
      case "visualize":
        return "Visualizar comentario";
      case "upload":
        return "Cargar evidencias";
      case "delete":
        return "Eliminar unidad";
      default:
        return null;
    }
  }

  /** Content dialog **/
  renderContent() {
    let { dataAction, units } = this.state;
    if (dataAction.id !== "") {
      let unit = units.find((unit) => unit.id == dataAction.id);
      switch (dataAction.action) {
        case "visualize":
          return (
            <div>
              <LabelWithTextBorder
                bText={unit.comment}
                divStyle={{ width: "500px" }}
                labelText={"Comentario"}
              />
            </div>
          );
        case "upload":
          return (
            <div>
              <InputFile
                fieldWrapperStyle={{ width: "100%" }}
                uploadAllowedExtensions={null}
                uploadId="attached"
                uploadAutoUpload={false}
                uploadDefaultFiles={this.state.attached}
                uploadOnAdd={this.handleChange}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ButtonSuccess
                  className=""
                  icon="check"
                  onClick={this.handleAcceptAction}
                  pClassName="finbe-btn-margin"
                  primary={true}
                  text="Agregar"
                  disabled={!this.state.attached.length > 0}
                />
              </div>
              <div style={{ width: "637px", paddingTop: "1em" }}>
                <Grid data={this.state.evidences} pageable={true} sortable>
                  <Column field="fileName" title="Documento" />
                  <Column
                    title="Tamaño"
                    cell={(props) => (
                      <td>{this.renderFileSize(props.dataItem.fileSize)}</td>
                    )}
                  />
                  <Column
                    title="Acciones"
                    cell={(props) => (
                      <td>
                        <ButtonAction
                          title={"Eliminar"}
                          disabled={props.dataItem.type == 7}
                          icon={" icon eliminar"}
                          look="bare"
                          primary={false}
                          onClick={this.handelRemoveFile}
                          data={props.dataItem.id}
                        />
                      </td>
                    )}
                  />
                </Grid>
              </div>
            </div>
          );
        case "delete":
          return (
            <div>
              ¿Está seguro de eliminar la undiad con VIN{" "}
              <b>{unit !== undefined ? unit.vinNumber : null}</b> ?
            </div>
          );
        default:
          return null;
      }
    } else {
      return null;
    }
  }

  /** Convert bytes to MegaBytes or Kilobytes **/
  renderFileSize(size) {
    let sizeInt = Number(size);
    return sizeInt > 1000000
      ? sizeInt / 1000000 + " MB"
      : sizeInt / 1000 + " KB";
  }

  render() {
    return (
      <UserData>
        {({ user }) => {
          return (
            <div className="finbe-app-container">
              <div className="finbe-app-section-top">
                <FilterBox
                  buttonMoreFilterOnClick={this.handleClickMoreFilter}
                  buttonSearchOnClick={this.handleClickSearch}
                  inputDropDownValue={this.state.showRegister}
                  inputFilterOnChange={this.handleChange}
                  isMoreFilter={false}
                >
                  <div style={{ width: "100%" }}>
                    <Form
                      render={(formRenderProps) => (
                        <FormElement style={{ display: "flex" }}>
                          <Field
                            component={InputFilterText}
                            dClassName="k-form-field-wrap finbe-form-field-filter"
                            id="model"
                            nombre="model"
                            onChange={this.handleChange}
                            placeholder="Ingrese modelo"
                            value={this.state.model}
                            wrapperStyle={{ width: "33%", paddingTop: "1.8em" }}
                          />
                          <Field
                            component={InputFilterText}
                            dClassName="k-form-field-wrap finbe-form-field-filter"
                            id="vinNumber"
                            nombre="vinNumber"
                            onChange={this.handleChange}
                            placeholder="Ingrese N° de VIN"
                            value={this.state.vinNumber}
                            wrapperStyle={{ width: "33%", paddingTop: "1.8em" }}
                          />
                        </FormElement>
                      )}
                    />
                  </div>
                </FilterBox>
              </div>
              <div>
                <Grid
                  className="finbe-table"
                  data={
                    process(this.state.units, {
                      take: +this.state.showRegister.text,
                      skip: this.state.skip,
                      sort: this.state.sort,
                    })
                    // this.state.units.slice(
                    //   this.state.skip,
                    //   this.state.takeShow.text + this.state.skip
                    // )
                  }
                  onDataStateChange={(e) => {
                    this.setState({
                      sort: e.data.sort,
                      take: e.data.take,
                      skip: e.data.skip,
                    });
                  }}
                  onHeaderSelectionChange={this.headerSelectionChange}
                  onPageChange={this.handlePageChange}
                  onSelectionChange={this.handelSelectionChange}
                  pageable={{
                    info: false,
                  }}
                  selectedField="check"
                  skip={this.state.skip}
                  sortable
                  sort={this.state.sort}
                  take={this.state.showRegister.text}
                  total={this.state.units.length}
                  resizable
                  onSortChange={(e) => {
                    this.setState({
                      sort: e.sort,
                    });
                  }}
                >
                  <Column
                    field={"check"}
                    title="Incluir"
                    headerSelectionValue={
                      this.state.units.findIndex((it) => it.check === false) ===
                      -1
                    }
                  />
                  <Column field="model" title="Modelo" />
                  <Column field="motorNumber" title="N° Motor" />
                  <Column field="vinNumber" title="N° VIN" />
                  <Column field="color" title="Color" />
                  <Column
                    field="amount"
                    title="Monto"
                    cell={(props) => (
                      <td> {numberFormat(props.dataItem.amount)} </td>
                    )}
                  />
                  <Column
                    field="createdAt"
                    title="Fecha de alta"
                    filter="date"
                    format="{0:dd/MM/yyyy}"
                  />
                  <Column
                    title="Acciones"
                    cell={(props) => (
                      <td>
                        <div style={{ display: "flex" }}>
                          <ButtonAction
                            title={"Visualizar"}
                            data={"visualize"}
                            icon={" icon visualizar"}
                            look="bare"
                            onClick={this.handleAction}
                            primary={false}
                            value={props.dataItem.id}
                          />
                          <ButtonAction
                            title={"Subir documentos"}
                            data={"upload"}
                            icon={" icon cargar_docs_sin_doc"}
                            look="bare"
                            onClick={this.handleAction}
                            primary={false}
                            value={props.dataItem.id}
                          />
                          <ButtonAction
                            title={"Remover/Eliminar"}
                            data={"delete"}
                            icon={" icon remover-eliminar"}
                            look="bare"
                            onClick={this.handleAction}
                            primary={false}
                            value={props.dataItem.id}
                          />
                        </div>
                      </td>
                    )}
                  />
                </Grid>
              </div>
              <DialogBox
                buttonDangerText="Cerrar"
                buttonSuccessText={
                  this.state.dataAction.action === "delete"
                    ? "Aceptar"
                    : "Guardar"
                }
                handelAccept={this.handleAcceptAction}
                handelClose={this.handleAction}
                title={this.renderTitle()}
                visible={this.state.visibleAction}
                visibleButtons={this.state.dataAction.action !== "visualize"}
                visibleCancelButton={
                  this.state.dataAction.action !== "visualize"
                }
                visibleSuccessButton={
                  this.state.dataAction.action === "delete" ? true : false
                }
              >
                {this.renderContent()}
              </DialogBox>
              <DialogBox
                buttonSuccessText={"Aceptar"}
                handelClose={this.handleResponse}
                handelAccept={this.handleResponse}
                title={this.state.response.title}
                visible={this.state.visibleResponse}
              >
                {this.state.response.message}
              </DialogBox>
            </div>
          );
        }}
      </UserData>
    );
  }
}

export default Units;
