import httpCommon from "../Utils/http-common";

const getAll = async () => {
  const client = httpCommon();
  return client.get("/configuration");
};

const save = async (data) => {
  const client = httpCommon();
  return client.post("/configuration", data);
};

const getOperation = async () => {
  const client = httpCommon();
  return client.get("/operations");
};

export default {
  getAll,
  save,
  getOperation
};
