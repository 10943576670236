import httpCommon from "../Utils/http-common";

const getAgencies = (id) => {
  const client = httpCommon();
  return client.get(`/companies/${id}/agencies`);
};
const getUsers = (id) => {
  const client = httpCommon();
  return client.get(`/companies/${id}/users`);
};
const getUsersForAgencies = (id) => {
  const client = httpCommon();
  return client.get(`/companies/${id}/users-agencies`);
};

const lastException = (id, params) => {
  const client = httpCommon();
  return client.get(`/companies/${id}/last-exception`, params);
};
const addUser = (id, data) => {
  const client = httpCommon();
  return client.post(`/companies/${id}/users`, data);
};

const removeUser = (id, userId) => {
  const client = httpCommon();
  return client.patch(`/companies/${id}/users`, { userId: userId });
};

export default {
  getAgencies,
  getUsers,
  getUsersForAgencies,
  lastException,
  addUser,
  removeUser,
};
