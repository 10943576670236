import React, { useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ButtonItem } from "../../Inputs/Buttons";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { StepBox } from "../../Sections/Steps";
import { InputComboBox, InputText } from "../../Sections/Components/Inputs";
import {
  ButtonAction,
  ButtonPrimary,
  ButtonSecundary,
  ButtonTertiary,
} from "../../Sections/Components/Buttons";

export const Empresas = (props) => {
  const [dataState, setDataState] = useState({
    sort: [{ field: "name", dir: "asc" }],
    take: 10,
    skip: 0,
  });

  const {
    buttons,
    step,
    typeAccount,
    company,
    moralPersons,
    handleChange,
    addCompanie,
    saveForm,
    cancel,
  } = props;
  //Validar y mejorar
  const showForm =
    step == 1 ? true : step == 3 ? (typeAccount == 2 ? true : false) : false;
  const showButtons = step == 1 ? (typeAccount == 1 ? true : false) : false;
  const showTable =
    step == 1
      ? typeAccount == 1
        ? true
        : false
      : step == 3
      ? typeAccount == 1
        ? true
        : false
      : false;
  const showBTable = step == 1 && typeAccount == 1 ? true : false;

  let _moralPersons = moralPersons.map((x) => {
    return { id: x.numeroCliente, text: x.numeroCliente };
  });
  return (
    <StepBox title="Datos de la empresa">
      <Form
        render={(formRenderProps) => (
          <FormElement>
            {showForm && (
              <div style={{ display: "flex" }}>
                <div style={{ width: "33%" }}>
                  <Field
                    component={InputComboBox}
                    data={_moralPersons}
                    dClassName="k-form-field-wrap finbe-form-combo"
                    id="clientId"
                    label="Persona moral"
                    name="clientId"
                    onChange={handleChange}
                    placeholder="Seleccionar persona moral"
                    required={true}
                    validityStyles={true}
                    value={_moralPersons.find((x) => x.id === company.clientId)}
                    wrapperStyle={{ width: "90%" }}
                  />
                </div>
                <div style={{ width: "33%" }}>
                  <Field
                    component={InputText}
                    disabled={true}
                    dClassName="k-form-field-wrap finbe-form-field-filter"
                    id="name"
                    label="Nombre de la empresa"
                    nombre="name"
                    placeholder=""
                    value={company.name}
                    wrapperStyle={{ width: "90%" }}
                  />
                </div>
                <div style={{ width: "33%" }}>
                  <Field
                    component={InputText}
                    disabled={true}
                    dClassName="k-form-field-wrap finbe-form-field-filter"
                    id="legalName"
                    label="Razón social"
                    nombre="legalName"
                    placeholder=""
                    value={company.legalName}
                    wrapperStyle={{ width: "90%" }}
                  />
                </div>
              </div>
            )}
          </FormElement>
        )}
      />
      {showButtons && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "3.5em",
            marginTop: "1.1em",
          }}
        >
          <ButtonPrimary
            disabled={buttons.add}
            onClick={addCompanie}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Agregar"
          />
          <ButtonPrimary
            disabled={buttons.save}
            onClick={saveForm}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Guardar"
          />
          <ButtonTertiary
            disabled={buttons.cancel}
            onClick={cancel}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Cancelar"
          />
        </div>
      )}
      <div>
        {showTable && (
          <Grid
            className="finbe-table"
            pageable={{
              info: false,
            }}
            sortable
            data={process(props.companies, dataState)}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.data);
            }}
          >
            <Column field="clientId" title="Persona moral" />
            <Column field="name" title="Empresa" />
            <Column field="legalName" title="Razón social" />
            {showBTable && (
              <Column
                title="Acciones"
                cell={(data) => (
                  <td style={{ display: "flex" }}>
                    <ButtonAction
                      title = {'Editar'}
                      data={data.dataItem.rowId}
                      icon=" icon editar"
                      isRedirect={false}
                      look="bare"
                      onClick={props.editCompanie}
                      primary={false}
                    />
                    <ButtonAction
                      title = {'Eliminar'}
                      data={data.dataItem.rowId}
                      icon=" icon eliminar"
                      isRedirect={false}
                      look="bare"
                      onClick={props.removeCompanie}
                      primary={false}
                    />
                  </td>
                )}
              />
            )}
          </Grid>
        )}
      </div>
    </StepBox>
  );
};
