/**
 * @author: Alexander Chi
 * @description: Component create step CSV
 * @date: 24/sept/2020
 **/
import React, { Component } from "react";
import { StepBox } from "../../Sections/Steps";
import {
  InputComboBox,
  InputMoney,
  InputDropDown,
  InputFile,
  InputSwitch,
  InputText,
  InputFileH
} from "../../Sections/Components/Inputs";
import { ButtonAction, ButtonDanger, ButtonPrimary, ButtonSuccess } from "../../Sections/Components/Buttons";
import { DialogBox } from "../../Sections/Dialogs";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { RadioButton } from "@progress/kendo-react-inputs";
import DocumentService from "../../../Services/DocumentService";
import UnitsService from "../../../Services/UnitsService";
import { useUsuario } from "../../../Context/UserContext";
import XMLParser from 'react-xml-parser';
import { process } from "@progress/kendo-data-query";
import { useState } from "react";
import { LabelWithTextBorder } from "../../Sections/Components/Tags";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import useLoader from '../../../hooks/useLoader'
import moment from 'moment'
/** Information user **/
let user = null;
let signOut = null;
let Loading = useLoader();

/** Get user cotext hook **/
function UserData({ children }) {
  const { usuario,signOutCognito } = useUsuario();
  user = usuario;
  signOut = signOutCognito;
  return children({ user: usuario });
}

/** Format number **/
const numberFormat = (value) => new Intl.NumberFormat("en-US").format(value);

/** Information data show registers **/
const dataDropDown = [
  { id: 1, text: 10 },
  { id: 2, text: 25 },
  { id: 3, text: 50 },
  { id: 4, text: 100 },
];

/** Main component units **/
class Units extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      showAddUnit,
      handleShowAddUnit,
      availableBalance,
      creditLine,
      creditLines,
      deadline,
      deadlines,
      document,
      getDocument,
      handleCalculate,
      handleChange,
      remainingBalance,
      status,
      styleContainer,
      totalAmount,
      units,
      unitsTitle,
      attachedDocument,
      includeAll, reviewUnits,handleSendCRM,handleLiberate,handleDialogUpdateFolio,isPreowned
    } = this.props;
    return (
      <div className="finbe-app-container" style={styleContainer}>
        <StepBox title={unitsTitle ?? "Seleccionar unidades"}>
          <Form
            render={(formRenderProps) => (
              <FormElement>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%" }}>
                    <Field
                      component={InputComboBox}
                      data={creditLines}
                      dClassName="k-form-field-wrap finbe-form-combo"
                      id="creditLine"
                      label="* Línea de crédito"
                      name="creditLine"
                      onChange={handleChange}
                      placeholder="Seleccione línea de crédito"
                      required={true}
                      validityStyles={true}
                      value={creditLine}
                      wrapperStyle={{ width: "90%" }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <Field
                      component={InputComboBox}
                      data={deadlines}
                      dClassName="k-form-field-wrap finbe-form-combo"
                      id="deadline"
                      label="* Plazo"
                      name="deadline"
                      onChange={handleChange}
                      placeholder="Seleccione un plazo"
                      required={true}
                      validityStyles={true}
                      value={deadline}
                      wrapperStyle={{ width: "90%" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "1em",
                    justifyContent: "flex-end",
                  }}
                >
                  {!reviewUnits&&<ButtonPrimary
                    disabled={creditLine===null}
                    onClick={handleShowAddUnit}
                    pClassName="finbe-btn-margin"
                    primary={true}
                    icon={"add"}
                    text="Añadir unidad"
                  />}
                </div>
                <MoneyUnits
                  availableBalance={numberFormat(availableBalance)}
                  remainingBalance={numberFormat(remainingBalance)}
                  totalAmount={numberFormat(totalAmount)}
                />
              </FormElement>
            )}
          />
          {/* <FormUnit></FormUnit> */}
          <GridUnits
            creditLine={creditLine}
            dataDropDown={dataDropDown}
            document={document}
            getDocument={getDocument}
            handleCalculate={handleCalculate}
            remainingBalance={remainingBalance}
            availableBalance={availableBalance}
            status={status}
            totalAmount={totalAmount}
            units={units}
            attachedDocument={attachedDocument}
            includeAll={includeAll}
            showAddUnit={showAddUnit}
            handleShowAddUnit={handleShowAddUnit}
            handleSendCRM={handleSendCRM}
            handleLiberate={handleLiberate}
            handleDialogUpdateFolio={handleDialogUpdateFolio}
            isPreowned={isPreowned}
          />
        </StepBox>
      </div>
    );
  }
}

/** default props units **/
Units.defaultProps = {
  availableBalance: 0,
  creditLines: [],
  gridUnits: [],
  handleChange: null,
  handleCalulate:null,
  lineCredit: 0,
  remainingBalance: 0,
  totalAmount: 0,
};

/** Grid of units **/
export class GridUnits extends Component {
  _export;


  constructor(props) {
    super(props);
    this.state = {
      attachedDocumentEvidence: [],
      creditLine: this.props.creditLine,
      dataState: {
        sort: [{ field: "vinNumber", dir: "desc" }],
        take: 10,
        skip: 0,
      },
      document: null,
      eventCheck: false,
      eventId: 0,
      evidences: [],
      invoiceDate: "",
      response: { title: "", message: "", success: false },
      skip: 0,
      take: 10,
      takeShow: { id: 1, text: 10 },
      units: this.props.units,
      unit: {
        agency: "",
        model: "",
        motorNumber: "",
        vinNumber: "",
        color: "",
        amount: "",
        brandName: "",
        description: "",
        insideColor: "",
        type: "",
        check: false,
      },
      visibleComment:false,
      visibleAddEvidence: false,
      visibleResponse: false,
      visibleUpload: false,
      visibleAddAttachedDocument: false,
      youHaveOne: false,
      attachedDocument: this.props.attachedDocument,
      includeAll: this.props.includeAll,
      showAddUnit: this.props.showAddUnit,
      allChecked: false,
      // loading:this.props.loading,
      unitComment:"",
      isPreowned:this.props.isPreowned,
    };
  }

  /** Get new props **/
  componentWillReceiveProps (nextProps){
    const {units, document, creditLine, attachedDocument, includeAll, showAddUnit,isPreowned} = nextProps;
    this.setState({units, document, creditLine, attachedDocument, includeAll, showAddUnit,isPreowned});
  }

  /** Save document **/
  _uploadDocument = async (file, nameState) => {
    try{
      // this.setState({loading:true})
      Loading();
      const response = await DocumentService.uploadDocument(file);
      let docs = [...this.state[nameState]];

      docs.map(doc=>{
        if(response.data.filename.includes(doc.name)){
          if(!doc.extension){
            doc.extension = `.${response.data.filename.split('.')[1]}`
          }
          doc.url = response.data.url;
          return doc;
        }else{
          return doc;
        }
      })
      // let doc = docs.find(item=>response.data.filename.includes(item.name));
      this.setState({ [nameState]: docs })
      Loading();
    }catch(error){
      Loading();
      if (error?.response?.status == 401) signOut();
      console.info(error);
    }
  };

  /** Set review value true or false **/
  _setReview = async (id, review) => {
    try{
      Loading();
      const response = await UnitsService.setReview(id, {review});
      Loading();
    }catch(error){
      Loading();
      if (error?.response?.status == 401) signOut();
      console.info(error);
    }
  };

  handleChangeUnit=(e)=>{
    const { name, value } = e.target;
    this.setState({unit:{...this.state.unit, [name]: name==="amount"?isNaN(Number(value))?"":Number(value):value }});

  }
  handleCancelModal=()=>{
    this.setState({unit: {
      agency: "",
      model: "",
      motorNumber: "",
      vinNumber: "",
      color: "",
      amount: "",
      brandName: "",
      description: "",
      insideColor: "",
      type: "",
      check: false,
    },})
    this.props.handleShowAddUnit();
  }
  /** Set object evidence for list evidences of units **/
  _setEvidenceUnit(index, evidences, type){
    if(evidences.getRawFile){
      return {
        extension: String(evidences.extension),
        file: evidences,
        fileName: String(evidences.name),
        fileSize: String(evidences.size),
        index: index,
        type: String(type),
        url: evidences.url,
      }

    }else{
      let _evidence = evidences;
      _evidence.index = index;
      _evidence.type = type;
      return _evidence;
    }
  };

  handleAcceptModal=async()=>{
    let _unit= this.state.unit;
    let _units = this.state.units;
    if(_unit.index == undefined){
      _unit.index = this.state.units.length+1
      
      if(this.state.includeAll){
        if(this.state.attachedDocument.length > 0){
          let evidences = [];
            evidences.push(this._setEvidenceUnit(0, this.state.attachedDocument[0], 7));
          _unit.evidences = evidences;
          if((this.props.totalAmount + _unit.amount) < this.props.availableBalance){
            await this.props.handleCalculate(_unit.amount, true)
            _unit.check = this.state.includeAll;
          }else{
            _unit.check = !this.state.includeAll;
          }
        }
      }else{
        _unit.evidences = []
      }
      _units.push(_unit);
    }else{
      _units.map(async item=>{
        if(item.index == _unit.index){

          if(item.amount != _unit.amount){
            if(_unit.check){
              await this.props.handleCalculate(item.amount, false);
              await this.props.handleCalculate(_unit.amount,true);
            }
          }
          item.model = _unit.model
          item.motorNumber = _unit.motorNumber
          item.vinNumber = _unit.vinNumber
          item.amount = _unit.amount
          item.check = _unit.check
          item.color = _unit.color
          item.agency = _unit.agency
          item.brandName = _unit.brandName
          item.description = _unit.description
          item.insideColor = _unit.insideColor
          item.type = _unit.type
        }
        return item;        
      })
    }
    this.setState({ 
      units:_units,
      unit: {
        agency: "",
        model: "",
        motorNumber: "",
        vinNumber: "",
        color: "",
        amount: "",
        brandName: "",
        description: "",
        insideColor: "",
        type: "",
        check: false,
      },
    })
    this.props.handleShowAddUnit();
  }

  handleEditUnit=(e)=>{
    const target = e.currentTarget;
    if(target !== undefined){
      let index = target.getAttribute("data");
      let unit = this.state.units.find(it => it.index == index);
      this.setState({unit});
      this.props.handleShowAddUnit()
    }

  }

  handleRemoveUnit=(e)=>{
    const target = e.currentTarget;
    if(target !== undefined){
      let index = target.getAttribute("data");
      let _units = this.state.units.filter(it => it.index != index);

      this.setState({units:_units});
    }

  }

  /** Change input credit line, operator, status **/
  handleChange = async (e) => {
    const { name, value } = e.target;
    /** for file input **/
    
    if(name === undefined || name==="attachedDocumentEvidence"){
      const { props, files } = e.target;
      for(const file of files ){
        // const file = _file;
        const fileExtension = [".xml", ".XML", ".pdf", ".PDF",".PNG",".png",".JPG",".jpg",".JPEG",".jpeg"]
        if(fileExtension.includes(file.extension||`.${file.name.split('.')[1]}`)){
        // if(file.extension === ".xml" || file.extension === ".XML" || file.extension === ".pdf" || file.extension === ".PDF"){
          let invoiceDate = "";
          if(file.extension === ".xml" || file.extension === ".XML" ||`.${file.name.split('.')[1]}` === '.xml' ||`.${file.name.split('.')[1]}` === '.XML'){
            // const file = files[0];
            const promise = new Promise((resolve, reject) => {
              const fileReader = new FileReader();
              fileReader.readAsText(file.getRawFile?file.getRawFile():file);
              fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const xml = new XMLParser().parseFromString(bufferArray);
                resolve(xml);
              };
              fileReader.onerror = (error) => {
                reject(error);
              };
            });
            promise.then((xml) => {
              /** information xml **/
              let complemento = xml.getElementsByTagName('Cfdi:Complemento');
              let timbreFiscalDigital = complemento[0].getElementsByTagName("tfd:TimbreFiscalDigital");
              invoiceDate = timbreFiscalDigital[0].attributes.FechaTimbrado;
              this.setState({ invoiceDate });
            });
          }else{
            this.setState({ invoiceDate });
          }
          let formData = new FormData();
          formData.append('file', file.getRawFile?file.getRawFile():file);
          let nameState = props?.id||name;
          this.setState({ [nameState]: files });
          await this._uploadDocument(formData, nameState)
        }

      }
    }else{
      this.setState({ [name]: value });
    }
  };

  /** Change page of grid **/
  handlePageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  /**Añade el documento anexo como evidecia de la unidad */
  handleAddAttachedDocument=()=>{
    const {
      attachedDocument,
      units,
    } = this.state;
    const unitsNew = units.map((unit) => {
      if (unit.index === this.state.eventId) {
        if ((this.props.totalAmount + unit.amount) < this.props.availableBalance) {
          if (!unit.check) { this.props.handleCalculate(unit.amount, true); }
          
          unit.evidences.push(this._setEvidenceUnit(unit.evidences.length, attachedDocument[0], 7));
          unit.invoiceDate = this.state.invoiceDate;
          unit.check = true;
        }
      }
      return unit;
    });
    this.setState({ unitsNew, visibleAddAttachedDocument: !this.state.visibleAddAttachedDocument });


  }
  
  handleinputFilterOnChange = (e) => {
    this.setState({
      takeShow: e.value,
      skip:0
    })
  };

  /** Upload and add information in list units  **/
  handleUpload = (e) => {
    const {
      attachedDocumentEvidence,
      units,
      youHaveOne,
    } = this.state;
    if(youHaveOne){
      if(attachedDocumentEvidence.length > 0){
        if(attachedDocumentEvidence[0].url !== undefined){
          const unitsNew = units.map((unit) => {
            if (unit.index === this.state.eventId) {
              if (youHaveOne && (this.props.totalAmount + unit.amount) <= this.props.availableBalance) {
                if (!unit.check) { this.props.handleCalculate(unit.amount, true); }
                if(unit.evidences.length > 0){
                  if(attachedDocumentEvidence[0].uid !== unit.evidences[0].file.uid){
                    unit.evidences[0] = this._setEvidence(1, attachedDocumentEvidence[0], 7);
                  }
                }else{
                  unit.evidences.push(this._setEvidence(unit.evidences.length, attachedDocumentEvidence[0], 7));
                }
                unit.invoiceDate = this.state.invoiceDate;
                unit.check = true;
              } else {
                if (unit.check) { this.props.handleCalculate(unit.amount, false); }
                unit.evidences = [];
                unit.check = false;
              }
            }
            return unit;
          });
          this.setState({ unitsNew, visibleUpload: !this.state.visibleUpload });
        }
      }
    }else{
      const unitsNew = units.map((unit) => {
        if (unit.index === this.state.eventId) {
          if (unit.check) { this.props.handleCalculate(unit.amount, false); }
          unit.evidences = [];
          unit.check = false;
        }
        return unit;
      });
      this.setState({ unitsNew, visibleUpload: !this.state.visibleUpload })
    }
  };

  /** Handel click show or hide dialog upload **/
  handleShowOrHideUpload = (e) => {
    this.setState({
      attachedDocumentEvidence: [],
      eventCheck: false,
      eventId: 0,
      visibleUpload: !this.state.visibleUpload,
      youHaveOne: false,
    });
  };

  /** Handel add evicences array in unit **/
  handleAddEvidence = (e) => {
    const {attachedDocumentEvidence} = this.state;
    const units = [...this.state.units];
    if(attachedDocumentEvidence.length > 0){
      for(const file of attachedDocumentEvidence.filter(item=>item.url!==undefined)){
        units.map((unit) => {
          if (unit.index === this.state.eventId) {
            unit.evidences.push(this._setEvidence(unit.evidences.length, file, 8));
          }
          return unit;
        });

      }
      this.setState({
        units,
        attachedDocumentEvidence: []
      });
    }
  };
  /** Handel remove row file **/
  handelRemoveFile = (e) => {
    let evidences = [];
    const target = e.currentTarget;
    if(target !== undefined){
      let index = target.getAttribute("data");
      const units = this.state.units.map((unit) => {
        if (unit.index == this.state.eventId) {
          evidences = unit.evidences.filter(evidence => evidence.index != index);
          unit.evidences = evidences;
        }
        return unit;
      });
      this.setState({ units,  evidences: evidences });
    }
  };

  /**Handle clcik show comment */
  handleShowOrHideCommnet=(e)=>{
    const target = e.currentTarget;
    if(target !== undefined){
      let index = target.getAttribute("data");
      let unit = this.state.units.find(it => it.index == index);
      this.setState({unitComment:unit.comment,visibleComment:true})
    }else{
      this.setState({visibleComment:false})
    }
  }

  /** Handel click show or hide dialog add evidences **/
  handleShowOrHideAddEvidence = (e) => {
    const target = e.currentTarget;
    if(target !== undefined){
      let index = target.getAttribute("data");
      let unit = this.state.units.find(it => it.index == index);
      this.setState({
        visibleAddEvidence: !this.state.visibleAddEvidence,
        evidences: unit?.evidences,
        eventId: unit?.index,
        model:unit?.model,
        attachedDocumentEvidence: []
      });
    }else{
      this.setState({
        visibleAddEvidence: !this.state.visibleAddEvidence,
        attachedDocumentEvidence: []
      });
    }
  };

  /** Handel click show or hide dialog response **/
  handleShowOrHideResponse = (e) => {
    this.setState({ visibleResponse: !this.state.visibleResponse });
  };

  /** handel change all check grid  **/
  headerSelectionChange = (event) => { 
    let _units = [...this.state.units];
    let _total = 0;
    _units.map(item=>{
      let chekced = this._renderCheck(item);
      if(!this.state.allChecked){
        if (chekced && (_total + item.amount) < this.props.remainingBalance){
          item.check = true;
          _total += item.amount
        }
      }else{
          item.check = false;
      }
      return item;
    })
    this.props.handleCalculate(_total,true,true);
    this.setState({units:_units, allChecked: !this.state.allChecked})
  };

  /** handel check row **/
  handelSelectionChange = (event) => {
    this.setState({loading:true});
    let unit = event.dataItem;
    if(user.type == 3 || user.type == 1){
      let chekced = this._renderCheck(unit);
      const units = this.state.units.map((item) => {
        if (item.id === unit.id) {
          if(chekced){
            item.check = !item.check;
            this.props.handleCalculate(unit.amount, item.check);
            item.included = item.check;
          }
        }
        return item;
      });
      this.setState({units});
    }else{
      if(unit.check){
        const units = this.state.units.map((item) => {
          if (item.index === unit.index) {
            item.check = !item.check;
            item.evidences = [];
            this.props.handleCalculate(item.amount, item.check);
          }
          return item;
        });
        this.setState({units});
      }else{
        let attachedDocumentEvidence = [];
        let {creditLine} = this.state;
        if(creditLine !== undefined && creditLine != null){
          if(creditLine.availableBalance > 0){
            if(unit.evidences != null){
              if(unit.evidences.length > 0){
                if(unit.evidences[0].file !== undefined){
                  attachedDocumentEvidence.push(unit.evidences[0].file);
                }
              }
            }
            // this.setState({
            //   attachedDocumentEvidence,
            //   eventCheck: unit.check,
            //   eventId: unit.index,
            //   visibleUpload: !this.state.visibleUpload,
            //   youHaveOne: unit.check,
            // });
          }else{
            this.setState({
              response: {title: 'Ocurrió un error', message: 'La línea de crédito no puede tener un monto 0', success: false},
              visibleResponse: !this.state.visibleResponse
            });
          }
          let _state = {
            attachedDocumentEvidence,
            eventCheck: unit.check,
            eventId: unit.index,
            youHaveOne: unit.check,
          }
            
          this.state.isPreowned?_state.visibleUpload = !this.state.visibleUpload:this.state.includeAll
              ? (_state.visibleAddAttachedDocument = !this.state
                  .visibleAddAttachedDocument)
              : (_state.visibleUpload = !this.state.visibleUpload);
          this.setState(_state);
        }else{
          this.setState({
            response: {title: 'Ocurrió un error', message: 'Seleccione la línea de crédito', success: false},
            visibleResponse: !this.state.visibleResponse
          });
        }
      }
    }
    this.setState({loading:false});

  };

  /** Select radio button **/
  handelChangeRadioButton = (e) => {
    this.setState({ youHaveOne: e.value });
  };

  /** Handel change switch **/
  handelSwitch = (e) => {
    const {value, props} = e.target;
    const units = this.state.units.map((unit) => {
      if(unit.id === props.data) {
        unit.reviewAF = value;
        unit.included = value ? this._renderCheck(unit) : value;
        unit.check = unit.included;
        this._setReview(unit.id, unit.reviewAF);
      }
      return unit;
    });
    this.setState({ units });
  };

  /** Set object evidence for list evidences of units **/
  _setEvidence(index, evidences, type){
    return {
      extension: String(evidences.extension),
      file: evidences,
      fileName: String(evidences.name),
      fileSize: String(evidences.size),
      index: index,
      type: String(type),
      url: evidences.url,
    }
  };

  /** Set icon approve or not REPUVE **/
  _renderApproveRepuve(validation){
    if(validation !== undefined){
      return this._approvedReject(validation.validRepuve);
    }else{
      return 'desaprobado';
    }
  };

  /** Set icon approve or not **/
  _renderApproveTransunion(validation){
    if(validation !== undefined){
      return this._approvedReject(validation.validTransunion);
    }else{
      return 'desaprobado';
    }
  };

  /** Set icon approve or not **/
  _renderApproveAx(validation){
    if(validation !== undefined){
      return this._approvedReject(validation.validAx);
    }else{
      return 'desaprobado';
    }
  };

  /** Set icon check or close **/
  _approvedReject(validation){
      return validation ? 'aprobado' : 'desaprobado';
  }

  /** Set icon approve, rejection or question**/
  _renderApproveInvoice(unit){
    if(unit.validation !== undefined){
      if(unit.validation.validInvoice){
        return 'aprobado';
      }else{
        return unit.invoiceDate != null ? 'desaprobado' : 'interrogacion';
      }
    }
  };

  /** Set icon approve, rejection or not**/
  _renderApproveAdminFinBe(status, adminFinBe){
    if (!adminFinBe || status == 0 || status === undefined){
      return '';
    } else if (status == 1){
      return 'aprobado';
    } else {
      return 'desaprobado';
    }
  };

  /** Convert bytes to MegaBytes or Kilobytes **/
  _renderFileSize(size){
    let sizeInt = Number(size);
    return sizeInt > 1000000
              ? (sizeInt / 1000000) + " MB"
              : (sizeInt / 1000) + " KB" ;
  }

  /** Show or hide checkbox for include **/
  _renderCheck(unit){
    if(unit.validation !== undefined){
      return (unit.status == 1 && unit.reviewAF) ||
             (unit.validation.validInvoice && unit.validation.validRepuve &&
              unit.validation.validTransunion && unit.validation.validAx);
    }
    return false;
  };

  /** Enable or disable button success upload XML or CSV **/
  _renderEnableSuccess(){
    let {attachedDocumentEvidence} = this.state;
    if(attachedDocumentEvidence !== null){
      if(attachedDocumentEvidence.length > 0){
        return ([...attachedDocumentEvidence].filter(item=>item.url === undefined).length>0);
      }
    }
    return true;
  }

  handleShowRegisterChange = (e) => {
    const { value } = e.target;
    this.setState({takeShow:value,dataState:{...this.state.dataState,take:value.text}});
 };

  handleDownload = (e) => {
    this._export.save();
    this.setState({
      // visibleConfirm: !this.state.visibleConfirm,
      response: {
        title: "Descarga completa",
        message: "El archivo se descargo correctamente",
        success: false,
      },
      visibleResponse: !this.state.visibleResponse,
    });
  };
  render() {
    const {
      dataDropDown,
      getDocument,
      showActions,
      status,
      handleSendCRM,
      handleLiberate,
      handleDialogUpdateFolio
    } = this.props;
    return (
      <UserData>
        {({ user }) => {
          return (
            <div style={{ width: "100%", marginTop: "1em" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                {(user.type===3 || user.type===1)&&<div style={{display:"flex"}}>
                  <ButtonPrimary
                      className=""
                      icon=""
                      onClick={handleDialogUpdateFolio}
                      pClassName="finbe-btn-margin"
                      primary={true}
                      text="Actualizar folio"
                    />
                    <ButtonPrimary
                      className=""
                      icon=""
                      onClick={this.handleDownload}
                      pClassName="finbe-btn-margin"
                      primary={true}
                      text="Descargar XLSX"
                      disabled={!this.state.units.length>0}
                    />
                  </div>}
                    {((user.type===3 || user.type===1)&&status===6)&&<div style={{display:"flex"}}>
                      <ButtonSuccess
                        className=""
                        icon=""
                        onClick={handleLiberate}
                        pClassName="finbe-btn-margin"
                        primary={true}
                        text="Liberar"
                        disabled={!this.state.units.length>0}
                      />
                      <ButtonSuccess
                        className=""
                        icon=""
                        onClick={handleSendCRM}
                        pClassName="finbe-btn-margin"
                        primary={true}
                        text="Enviar a CRM"
                        disabled={!this.state.units.length>0}
                      />
                    </div>}
              </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ display: "flex", textAling: "left" }}>
                  <InputDropDown
                    id="takeShow"
                    name="takeShow"
                    data={dataDropDown}
                    onChange={this.handleinputFilterOnChange}
                    value={this.state.takeShow}
                    fieldWrapperClassName="finbe-drop-down-show-register"
                  />
                </div>
                <p className="finbe-p-register" style={{ marginTop: "1.5em" }}>
                  unidades
                </p>
              </div>
              <ExcelExport
                  fileName={
                    "Unidades (" +
                    moment().format("DD-MM-YYYY") +
                    ")"
                  }
                  data={this.state.units}
                  ref={(exporter) => (this._export = exporter)}
                >
              <Grid
                className="finbe-table"
                // data={this.state.units.slice(
                //   this.state.skip,
                //   this.state.takeShow.text + this.state.skip
                // )}

                data={process(this.state.units, {
                  take: +this.state.takeShow.text,
                  skip: this.state.skip, 
                  sort: this.state.sort}
                )}
                onDataStateChange={(e) => {
                  this.setState({
                    sort: e.data.sort,
                    take:e.data.take,
                    skip:e.data.skip
                  })
                }}

                onHeaderSelectionChange={this.headerSelectionChange}
                onPageChange={this.handlePageChange}
                onSelectionChange={this.handelSelectionChange}
                pageable={{
                  info: false,
                }}
                selectedField="check"
                
                skip={this.state.skip}
                sortable
                sort={this.state.sort}
                take={this.state.takeShow.text}
                total={this.state.units.length}
                resizable
                reorderable
                onSortChange={(e) => {
                  this.setState({
                    sort:e.sort
                  })
                }}
              >
                {
                  (showActions || ((user.type == 3 || user.type ===1) && status == 3)) &&
                  (
                    <Column
                      width="40px"
                      field= {"check"}
                      title="Incluir"
                      headerSelectionValue={
                        (user.type == 3 || user.type==1)
                        ? this.state.allChecked
                        : this.state.units.findIndex((it) => it.check === false) === -1
                      }
                    />
                  )
                }
                <Column width="220px" field="vinNumber" title="N° VIN" />
                <Column width="180px" field="agency" title="Sucursal de Asignación"/>
                <Column width="180px" field="brandName" title="Marca"/>
                <Column width="180px" field="type" title="Tipo de unidad"/>
                <Column width="180px" field="model" title="Año Modelo" />
                <Column width="180px" field="description" title="Descripción"/>
                <Column width="180px" field="color" title="Color Exterior" />
                <Column width="180px" field="insideColor" title="Color Interior"/>
                <Column width="180px" field="motorNumber" title="N° Motor" />
                {(user.type == 3 || user.type===1) &&
                  <Column width="180px" title="Factura"
                    cell={(props) => (
                      <td>
                        <span className = {
                          ' icon ' + this._renderApproveInvoice(props.dataItem)
                        }/>
                      </td>
                    )}
                  />
                }
                {(user.type == 3 || user.type===1) &&
                  <Column width="180px" title="REPUVE"
                    cell={(props) => (
                      <td>
                        <span className = {' icon ' + this._renderApproveRepuve(props.dataItem.validation)} />
                      </td>
                    )}
                  />
                }
                {(user.type == 3 || user.type===1) &&
                  <Column width="180px" title="TransUnion"
                    cell={(props) => (
                      <td>
                        <span className = {' icon ' + this._renderApproveTransunion(props.dataItem.validation)} />
                      </td>
                    )}
                  />
                }
                {(user.type == 3 || user.type===1) &&
                  <Column width="70px" title="AX"
                    cell={(props) => (
                      <td>
                        <span className = {' icon ' + this._renderApproveAx(props.dataItem.validation)} />
                      </td>
                    )}
                  />
                }
                {(user.type == 3 || user.type===1) &&
                  <Column width="180px" title="Admin. FinBe"
                    cell={(props) => (
                      <td>
                        <span className = {' icon ' + (
                          this._renderApproveAdminFinBe(props.dataItem.status, props.dataItem.reviewAF)
                        )} />
                      </td>
                    )}
                  />
                }
                <Column width="120px" field="amount" title="Monto"
                  cell={(props) => (
                    <td> {numberFormat(props.dataItem.amount)} </td>
                  )}
                />
                {showActions && (
                  <Column
                    width="180px"
                    title="Acciones"
                    cell={(props) => (
                      <td>
                        <div style={{display: 'flex'}}>
                          <ButtonAction
                            title = {'Cargar archivos'}
                            disabled = {!props.dataItem.check}
                            icon = {
                              " icon " +
                              (props.dataItem.check
                                ? "cargar_docs_activo"
                                : "cargar_docs_sin_doc")
                            }
                            look="bare"
                            primary = {false}
                            data = {props.dataItem.index}
                            onClick = {this.handleShowOrHideAddEvidence}
                          />
                          <ButtonAction
                            title = {'Editar unidad'}
                            // disabled = {!props.dataItem.check}
                            icon = {" icon editar"}
                            look="bare"
                            primary = {false}
                            data = {props.dataItem.index}
                            onClick = {this.handleEditUnit}
                          />
                          <ButtonAction
                            title = {'Remover unidad'}
                            // disabled = {!props.dataItem.check}
                            icon = {" icon remover-eliminar"}
                            look="bare"
                            primary = {false}
                            data = {props.dataItem.index}
                            onClick = {this.handleRemoveUnit}
                          />
                        </div>
                      </td>
                    )}
                  />
                )}
                {(user.type !== 3 && user.type !==1) && !showActions  && (
                  <Column width="180px" title="Acciones"
                    cell={(props) => (
                      <td >
                        <div style={{display: 'flex'}}>
                        <ButtonAction
                            title = {'Cargar archivos'}
                            disabled = {!props.dataItem.check}
                            icon = {
                              " icon " +
                              (props.dataItem.check
                                ? "cargar_docs_activo"
                                : "cargar_docs_sin_doc")
                            }
                            look="bare"
                            primary = {false}
                            data = {props.dataItem.index}
                            onClick = {this.handleShowOrHideAddEvidence}
                          />
                        {(props.dataItem.comment!==""&&props.dataItem.comment!==null)&&<ButtonAction
                          title ="Comentario"
                          icon = " icon visualizar"
                          look="bare"
                          primary = {false}
                          data = {props.dataItem.index}
                          onClick = {this.handleShowOrHideCommnet}
                        />}
                        </div>
                      </td>
                    )}
                  />
                )}
                {(user.type == 3 || user.type===1) && (
                  <Column width="180px" title="Acciones"
                    cell={(props) => (
                      <td >
                        <div style={{display: 'flex'}}>
                        <ButtonAction
                          title = {'Evidencias'}
                          icon = " icon visualizar_evidencia"
                          look="bare"
                          primary = {false}
                          data = {props.dataItem.index}
                          onClick = {this.handleShowOrHideAddEvidence}
                        />
                        {(props.dataItem.comment!==""&&props.dataItem.comment!==null)&&<ButtonAction
                          title ="Comentario"
                          icon = " icon visualizar"
                          look="bare"
                          primary = {false}
                          data = {props.dataItem.index}
                          onClick = {this.handleShowOrHideCommnet}
                        />}
                        </div>
                      </td>
                    )}
                  />
                )}
                {((user.type == 3 || user.type===1) && status == 3) && (
                  <Column width="180px" title="Admin. FinBe"
                    cell={(props) => (
                      <td>
                        <InputSwitch
                        checked = {props.dataItem.reviewAF}
                        onChange = {this.handelSwitch}
                        data = {props.dataItem.id}
                        />
                      </td>
                    )}
                  />
                )}
              </Grid>
              </ExcelExport>
              <DialogBox
                handelClose={()=>{this.setState({visibleComment:false,unitComment:""})}}
                title="Comentario"
                visible={this.state.visibleComment}
                visibleButtons = {false}
                visibleCancelButton = {false}
              >
                <div>
                  <LabelWithTextBorder
                    bText={this.state.unitComment}
                    divStyle={{ width: "500px" }}
                    labelText={"Comentario"}
                  />
                 </div>
              </DialogBox>
              <DialogBox title={"Cargar Evidencia"}
              handelClose={()=>{this.setState({visibleAddAttachedDocument:false})}}
              handelCancel={()=>{this.setState({visibleUpload:true,visibleAddAttachedDocument:false})}}
              handelAccept={this.handleAddAttachedDocument}
              visible={this.state.visibleAddAttachedDocument}
              buttonSuccessText='Si'
              buttonDangerText='No'
              visibleCancelButton={true}
              >
                ¿Desea agregar el documento anexo como evidencia de la unidad?
              </DialogBox>
              <DialogBox title={"Añadir Unidad"}
              handelClose={this.handleCancelModal}
              handelCancel={this.handleCancelModal}
              handelAccept={this.handleAcceptModal}
              visible={this.state.showAddUnit}
              buttonSuccessText='Aceptar'
              buttonDangerText='Cancelar'
              visibleCancelButton={true}
              >
                <FormUnit handleChangeUnit={this.handleChangeUnit} unit={this.state.unit}/>
              </DialogBox>
              <DialogBox
                handelClose={this.handleShowOrHideUpload}
                handelCancel={this.handleShowOrHideUpload}
                handelAccept={this.handleUpload}
                title={"Carga Evidencia"}
                visible={this.state.visibleUpload}
                enableSuccess = {this._renderEnableSuccess()}
                visibleCancelButton={true}
                buttonSuccessText='Aceptar'
              >
                <p>Agregar el XML o PDF de la planta</p>
                <div style={{ display: "flex" }}>
                  <div>¿Cuentas con alguno de ellos?</div>
                  <div style={{ paddingLeft: "1em" }}>
                    <RadioButton
                      name="group1"
                      value={true}
                      checked={this.state.youHaveOne === true}
                      label="Si"
                      onChange={this.handelChangeRadioButton}
                    />
                    <RadioButton
                      name="group1"
                      value={false}
                      checked={this.state.youHaveOne === false}
                      label="No"
                      onChange={this.handelChangeRadioButton}
                    />
                  </div>
                </div>
                <div style={{ width: "600px" }}></div>
                {this.state.youHaveOne ? (
                  <InputFileH
                  // refFile={refFile}
                  handleChange={this.handleChange}
                  name='attachedDocumentEvidence'
                  id='attachedDocumentEvidence'
                  files={[...this.state.attachedDocumentEvidence]}
                  accept={".pdf,.PDF,.xml,.XML"}
                  // uploadLabel={"Documento anexo"}
                  // handleDelete={handleDeleteFile}
                  />
                  // <InputFile
                  //   fieldWrapperStyle={{ width: "100%" }}
                  //   uploadId="attachedDocumentEvidence"
                  //   uploadAllowedExtensions = {[".xml", ".XML", ".pdf", ".PDF"]}
                  //   uploadAutoUpload = {false}
                  //   uploadDefaultFiles = {this.state.attachedDocumentEvidence}
                  //   uploadOnAdd = {this.handleChange}
                  // />
                ) : (
                  ""
                )}
              </DialogBox>

              <DialogBox
                buttonSuccessText={this.state.attachedDocumentEvidence === null ? 'Aceptar' : 'Cerrar'}
                // enableSuccess = {this._renderEnableSuccess()}
                // handelAccept={this.handleAddEvidence}
                handelAccept={this.state.attachedDocumentEvidence === null ?this.handleAddEvidence:this.handleShowOrHideAddEvidence}
                handelClose={this.handleShowOrHideAddEvidence}
                title={(user.type == 3 || user.type==1) ? "Evidencia" : "Cargar Evidencia"}
                visible={this.state.visibleAddEvidence}
                // visibleButtons = {user.type != 3}
                styleContent={{ width: "600px" }}
                width={650}
                height={550}
              >
                {(user.type != 3 && user.type!=2  && user.type !=1) &&
                  <p>
                    Agregar las evidencias que respalden esta unidad, puedes incluir la
                    factura y fotografías <br></br> en los siguientes formatos: png, jpg, jpeg, xml o pdf.
                  </p>
                }
                <p><strong>Modelo de la unidad: </strong>{this.state.model}</p>
                {(user.type != 3 && user.type!=2  && user.type !=1) &&
                  (<><InputFile
                    fieldWrapperStyle={{ width: "100%" }}
                    uploadAllowedExtensions = {[".xml", ".XML", ".pdf", ".PDF",".png",".jpg",".jpeg"]}
                    uploadId="attachedDocumentEvidence"
                    uploadAutoUpload = {false}
                    uploadDefaultFiles = {this.state.attachedDocumentEvidence}
                    uploadOnAdd = {this.handleChange}
                  />
                  <div style={{display:"flex",justifyContent:"flex-end"}}>
                  {/* <ButtonDanger
                        className=""
                        icon="close"
                        onClick={()=>{this.setState({attachedDocumentEvidence:[]})}}
                        pClassName="finbe-btn-margin"
                        primary={true}
                        text="Cancelar"
                        disabled={this._renderEnableSuccess()}
                      /> */}
                      <ButtonSuccess
                        className=""
                        icon="check"
                        onClick={this.handleAddEvidence}
                        pClassName="finbe-btn-margin"
                        primary={true}
                        text="Agregar"
                        disabled={this._renderEnableSuccess()}
                      />
                    </div></>)
                }
                <div style={{ width: "637px", paddingTop: "1em" }}>
                  <Grid data={this.state.evidences} pageable={true} sortable>
                    <Column field="fileName" title="Documento" />
                    <Column
                      title="Tamaño"
                      cell={(props) => (
                        <td>
                          {this._renderFileSize(props.dataItem.fileSize)}
                        </td>
                      )}
                    />
                    <Column
                      title="Acciones"
                      cell={(props) => (
                        <td>
                          <div  style={{ display: 'flex'}}>
                          {(user.type == 3 || user.type == 2 || user.type == 1)
                            ? <>
                              <ButtonAction
                                title = {'Visualizar'}
                                icon={" icon visualizar"}
                                look="bare"
                                primary = {false}
                                onClick = { () => { getDocument(props.dataItem.url) }}
                              />
                              <ButtonAction
                                title = {'Descargar'}
                                icon={" icon descargar"}
                                look="bare"
                                primary = {false}
                                onClick = { () => { getDocument(props.dataItem.url, true) }}
                              />
                            </>
                            : <ButtonAction
                                title = {'Eliminar'}
                                disabled = {props.dataItem.type == 7}
                                icon={" icon eliminar"}
                                look="bare"
                                primary={false}
                                onClick={this.handelRemoveFile}
                                data = {props.dataItem.index}
                              />
                          }
                          </div>
                        </td>
                      )}
                    />
                  </Grid>
                </div>
              </DialogBox>

              <DialogBox
                handelClose={this.handleShowOrHideResponse}
                title={this.state.response.title}
                visible={this.state.visibleResponse}
                visibleButtons={false}
              >
                {this.state.response.message}
              </DialogBox>
            </div>
          );
        }}
      </UserData>
    );
  }
}

/** default props grid **/
GridUnits.defaultProps = {
  dataDropDown: null,
  handleCalulate: null,
  remainingBalance: 0,
  showActions: true,
  totalAmount:0,
  units: [],
  status: 0,
  loading:false
};

export class MoneyUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      availableBalance,
      remainingBalance,
      totalAmount,
      showRemainingBalance
    } = this.props;
    return (
      <div
        style={{
          display: "flex",
          marginTop: "1em",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "30%" }}>
          <InputMoney
            dClassName="k-form-field-wrap finbe-form-field-wrap finbe-input-money"
            disabled={true}
            id="availableBalance"
            label="Saldo disponible"
            nombre="availableBalance"
            value={availableBalance}
          />
        </div>
        <div style={{ width: "30%" }}>
          <InputMoney
            dClassName="k-form-field-wrap finbe-form-field-wrap finbe-input-money"
            disabled={true}
            id="totalAmount"
            label="Monto total"
            nombre="totalAmount"
            value={totalAmount}
          />
        </div>
        {showRemainingBalance&&<div style={{ width: "30%" }}>
          <InputMoney
            dClassName="k-form-field-wrap finbe-form-field-wrap finbe-input-money"
            disabled={true}
            id="remainingBalance"
            label="Saldo restante"
            nombre="remainingBalance"
            value={remainingBalance}
          />
        </div>}
      </div>
    );
  }
}

/** default props money inputs **/
MoneyUnits.defaultProps = {
  availableBalance: 0,
  remainingBalance: 0,
  totalAmount: 0,
  showRemainingBalance:true
};


const FormUnit=(props)=>{
  const { agency, model, motorNumber, vinNumber, color, amount, brandName, description, insideColor,type } = props.unit;
  return(
    <div>
      <Form
        render={(formRenderProps) => (
          <FormElement>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                    component={InputText}
                    dClassName="k-form-field-wrap finbe-form-field-filter"
                    id="agency"
                    label="* Sucursal"
                    nombre="agency"
                    onChange={props.handleChangeUnit}
                    placeholder="Ingresar sucursal"
                    required={true}
                    validityStyles={true}
                    minLength={1}
                    maxLength={20}
                    value={agency}
                    wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                    component={InputText}
                    dClassName="k-form-field-wrap finbe-form-field-filter"
                    id="brandName"
                    label="* Marca"
                    nombre="brandName"
                    onChange={props.handleChangeUnit}
                    placeholder="Ingresar marca"
                    required={true}
                    validityStyles={true}
                    minLength={1}
                    maxLength={15}
                    value={brandName}
                    wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
            <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="model"
                  label="* Modelo"
                  nombre="model"
                  onChange={props.handleChangeUnit}
                  placeholder="Ingresar modelo"
                  required={true}
                  validityStyles={true}
                  minLength={1}
                  maxLength={4}
                  value={model}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="description"
                  label="* Descripción"
                  nombre="description"
                  onChange={props.handleChangeUnit}
                  placeholder="Ingresar descripción"
                  required={true}
                  validityStyles={true}
                  minLength={1}
                  maxLength={50}
                  value={description}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="motorNumber"
                  label="* N° Motor"
                  nombre="motorNumber"
                  onChange={props.handleChangeUnit}
                  placeholder="Ingresar N. Motor"
                  required={true}
                  validityStyles={true}
                  minLength={1}
                  maxLength={25}
                  value={motorNumber}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="vinNumber"
                  label="* N° VIN"
                  nombre="vinNumber"
                  onChange={props.handleChangeUnit}
                  placeholder="Ingresar N. VIN"
                  required={true}
                  validityStyles={true}
                  minLength={17}
                  maxLength={23}
                  value={vinNumber}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="color"
                  label="Color Exterior"
                  nombre="color"
                  onChange={props.handleChangeUnit}
                  placeholder="Ingresar color exterior"
                  validityStyles={true}
                  required={true}
                  minLength={1}
                  maxLength={35}
                  value={color}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="insideColor"
                  label="Color Interior"
                  nombre="insideColor"
                  onChange={props.handleChangeUnit}
                  placeholder="Ingresar color interior"
                  validityStyles={true}
                  required={true}
                  minLength={1}
                  maxLength={70}
                  value={insideColor}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
            <div style={{ width: "50%" }}>
                <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="type"
                  label="Tipo de unidad"
                  nombre="type"
                  onChange={props.handleChangeUnit}
                  placeholder="Ingresar tipo de unidad"
                  validityStyles={true}
                  required={true}
                  minLength={1}
                  maxLength={8}
                  value={type}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
              <div style={{ width: "50%" }}>
              <Field
                  component={InputText}
                  dClassName="k-form-field-wrap finbe-form-field-filter"
                  id="amount"
                  label="* Monto"
                  nombre="amount"
                  onChange={props.handleChangeUnit}
                  placeholder="Ingresar monto"
                  validityStyles={true}
                  required={true}
                  value={amount}
                  wrapperStyle={{ width: "90%" }}
                />
              </div>
            </div>
          </FormElement>
        )}
      />
    </div>
  )

}

export default Units;
