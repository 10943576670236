import React, { useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import {
  ButtonAction,
  ButtonPrimary,
  ButtonSuccess,
} from "../../Sections/Components/Buttons";
import { InputFile, InputText } from "../../Sections/Components/Inputs";
import { DialogBox } from "../../Sections/Dialogs";
import { useUsuario } from "../../../Context/UserContext";
import UnitsService from "../../../Services/UnitsService";
import useLoader from "../../../hooks/useLoader";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { RadioButton } from "@progress/kendo-react-inputs";
import DocumentService from "../../../Services/DocumentService";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import moment from "moment";
const numberFormat = (value) => new Intl.NumberFormat("en-US").format(value);

export default function Units({
  units = [],
  dataState,
  setDataState,
  handelSelectionChange = null,
  confirmUnit = null,
  status = 1,
  requireEvidence = true,
  isCreate = false,
  handleLiberate = null,
  handleSendCRM = null,
  handleDialogUpdateFolio=null,
}) {
  let _export;
  const { usuario, signOutCognito } = useUsuario();
  const Loading = useLoader();

  const [unit, setUnit] = useState({
    model: "",
    evidences: [],
    id: null,
    status: 0,
    comment: "",
  });
  const [attachedDocumentEvidence, setAttachedDocumentEvidence] = useState([]);
  const [visibleDialogEvidences, setVisibleDialogEvidences] = useState(false);

  const approveOrRejectUnit = (validation) => {
    return validation === null ? "" : validation ? "aprobado" : "desaprobado";
  };
  const renderApproveAdminFinBe = (status) => {
    if (status == 0 || status === undefined) {
      return "";
    } else if (status == 1) {
      return "aprobado";
    } else {
      return "desaprobado";
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== undefined) setUnit({ ...unit, [name]: value });
    else setUnit({ ...unit, ["status"]: e.value });
  };
  const handleChangeFile = (e) => {
    const { files } = e.target;
    setAttachedDocumentEvidence(files);
  };
  //Mostrar dialogo de evidencias
  const handleShowOrHideEvidence = async (e) => {
    try {
      Loading();
      const target = e.currentTarget;
      if (target) {
        let index = target.getAttribute("data");
        // if (index) {
        let unit = units.find((item) => item.id == index);
        const evidences = await getUnitEvidences(unit.id);
        setUnit({
          id: unit.id,
          model: unit.model,
          status: unit.status,
          comment: unit.comment,
          evidences: evidences,
        });
        //   setUnitEvidences(unit.evidences);
        setVisibleDialogEvidences(true);
        // } else {
        //   setVisibleDialogEvidences(false);
        //   setUnit({ model: "", evidences: [], id: null, status: 0, comment: "" });
        // }
        // Loading();
      } else {
        setVisibleDialogEvidences(false);
        setUnit({ model: "", evidences: [], id: null, status: 0, comment: "" });
      }
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOutCognito();
      console.info(error);
    }
  };
  const handleConfirmUnit = () => {
    confirmUnit(unit.id, unit.status, unit.comment);
    setVisibleDialogEvidences(false);
    setUnit({ model: "", evidences: [], id: null, status: 0, comment: "" });
  };

  //Recuperar evidencias de una unidad
  const getUnitEvidences = async (unitId) => {
    try {
      Loading();
      const response = await UnitsService.getEvidenes(unitId);
      Loading();
      return response.data.map((item, index) => {
        item.index = index;
        delete item.createdAt;
        delete item.legalarioId;
        delete item.signedDocumentId;
        delete item.signedStatus;
        return item;
      });
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOutCognito();
      console.info(error);
    }
  };
  //Añadir una nueva evidencia a la unidad
  const handleAddEvidenceUnit = async () => {
    const files = [...attachedDocumentEvidence];
    if (files.length > 0) {
      for (const file of files) {
        let formData = new FormData();
        formData.append("file", file.getRawFile());
        formData.append("fileName", file.name);
        formData.append("extension", file.extension);
        formData.append("fileSize", file.size);
        formData.append("type", 8);
        await uploadDocument(unit.id, formData);
      }
      //Recuperar las evidencias de la unidad.
      //Hacer un map para añadir las evidencias a la unidad
      setAttachedDocumentEvidence([]);
      const res = await getUnitEvidences(unit.id);
      setUnit({
        ...unit,
        evidences: res,
      });
    }
  };
  //Subir evidencia a la nube
  const uploadDocument = async (id, formData) => {
    try {
      Loading();
      const response = await UnitsService.uploadDocument(id, formData);
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOutCognito();
      console.info(error);
    }
  };
  const getDocument = async (url, download = false) => {
    try {
      Loading();
      const response = await DocumentService.showDocument({
        path: url,
        download: download,
      });
      window.open(response.data.url, "_blank");
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.status == 401) signOutCognito();
      console.info(error);
    }
  };
  const handleDownload = (e) => {
    _export.save();
    // ({
    //   // visibleConfirm: !this.state.visibleConfirm,
    //   response: {
    //     title: "Descarga completa",
    //     message: "El archivo se descargo correctamente",
    //     success: false,
    //   },
    //   visibleResponse: !this.state.visibleResponse,
    // });
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {(usuario.type === 3 || usuario.type === 1) && (
          <div style={{ display: "flex" }}>
            {status >= 4 &&<ButtonPrimary
              className=""
              icon=""
              onClick={handleDialogUpdateFolio}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Actualizar folio"
            />}
            <ButtonPrimary
              className=""
              icon=""
              onClick={handleDownload}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Descargar XLSX"
              disabled={!units.length > 0}
            />
          </div>
        )}
        {(usuario.type === 3 || usuario.type === 1) && status === 6 && (
          <div style={{ display: "flex" }}>
            <ButtonSuccess
              className=""
              icon=""
              onClick={handleLiberate}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Liberar"
              disabled={!units.length > 0}
            />
            <ButtonSuccess
              className=""
              icon=""
              onClick={handleSendCRM}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Enviar a CRM"
              disabled={!units.length > 0}
            />
          </div>
        )}
      </div>
      <ExcelExport
        fileName={"Unidades (" + moment().format("DD-MM-YYYY") + ")"}
        data={units}
        ref={(exporter) => (_export = exporter)}
      >
        <Grid
          className="finbe-table"
          data={process(units, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.data);
          }}
          // onHeaderSelectionChange={this.headerSelectionChange}

          onSelectionChange={handelSelectionChange}
          pageable={{
            info: false,
          }}
          selectedField="check"
          sortable
          resizable
        >
          {(isCreate || (usuario.type == 3 && status < 4)) && (
            <Column
              field={"check"}
              width="50px"
              // title="Incluir"
              headerSelectionValue={
                units.findIndex((it) => it.check === false) === -1
              }
            />
          )}
          <Column width="220px" field="vinNumber" title="N° VIN" />
          <Column width="180px" field="agency" title="Sucursal de Asignación" />
          <Column width="180px" field="brandName" title="Marca" />
          <Column width="180px" field="type" title="Tipo de unidad" />
          <Column width="180px" field="model" title="Año Modelo" />
          <Column width="180px" field="description" title="Descripción" />
          <Column width="180px" field="color" title="Color Exterior" />
          <Column width="180px" field="insideColor" title="Color Interior" />
          <Column width="180px" field="motorNumber" title="N° Motor" />
          <Column width="180px" field="deadlines" title="Plazo" />
          <Column
            width="180px"
            title="REPUVE"
            cell={(props) => (
              <td>
                <span
                  className={
                    " icon " + approveOrRejectUnit(props.dataItem.validRepuve)
                  }
                />
              </td>
            )}
          />
          <Column
            width="180px"
            title="TransUnion"
            cell={(props) => (
              <td>
                <span
                  className={
                    " icon " +
                    approveOrRejectUnit(props.dataItem.validTransunion)
                  }
                />
              </td>
            )}
          />
          <Column
            width="70px"
            title="AX"
            cell={(props) => (
              <td>
                <span
                  className={
                    " icon " + approveOrRejectUnit(props.dataItem.validAx)
                  }
                />
              </td>
            )}
          />
          <Column
            width="180px"
            title="Admin. FinBe"
            cell={(props) => (
              <td>
                <span
                  className={
                    " icon " + renderApproveAdminFinBe(props.dataItem.status)
                  }
                />
              </td>
            )}
          />

          <Column
            width="120px"
            field="amount"
            title="Monto"
            cell={(props) => <td> {numberFormat(props.dataItem.amount)} </td>}
          />
          <Column
            width="180px"
            title="Acciones"
            cell={(props) => (
              <td>
                <div style={{ display: "flex" }}>
                  <ButtonAction
                    title={"Cargar archivos"}
                    //   disabled={!props.dataItem.check}
                    icon={" icon cargar_docs_activo"}
                    look="bare"
                    primary={false}
                    data={props.dataItem.id}
                    onClick={handleShowOrHideEvidence}
                  />
                </div>
              </td>
            )}
          />
        </Grid>
      </ExcelExport>
      {/* Modal para visualizar o cargar evidencias */}
      <DialogBox
        handelClose={handleShowOrHideEvidence}
        title={
          (usuario.type !== 3 && usuario.type !== 5) || status > 1
            ? "Evidencia"
            : "Cargar Evidencia"
        }
        visible={visibleDialogEvidences}
        visibleButtons={usuario.type == 2 && status == 3}
        buttonSuccessText="Confirmar"
        handelAccept={handleConfirmUnit}
        styleContent={{ width: "600px" }}
        width={650}
        height={550}
      >
        {(usuario.type === 3 || (usuario.type === 5 && requireEvidence)) &&
          status < 2 && (
            <p>
              Agregar las evidencias que respalden esta unidad, puedes incluir
              fotografías <br></br> en los siguientes formatos: png, jpg, jpeg.
            </p>
          )}
        <p>
          <strong>Modelo de la unidad: </strong>
          {unit.model}
        </p>
        {(usuario.type === 3 || (usuario.type === 5 && requireEvidence)) &&
          status < 2 && (
            <>
              <InputFile
                fieldWrapperStyle={{ width: "100%" }}
                uploadAllowedExtensions={null}
                uploadId="attachedDocumentEvidence"
                uploadAutoUpload={false}
                uploadDefaultFiles={attachedDocumentEvidence}
                uploadOnAdd={handleChangeFile}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ButtonSuccess
                  className=""
                  icon="check"
                  onClick={handleAddEvidenceUnit}
                  pClassName="finbe-btn-margin"
                  primary={true}
                  text="Agregar"
                  disabled={!attachedDocumentEvidence.length > 0}
                />
              </div>
            </>
          )}
        <div style={{ width: "637px", paddingTop: "1em" }}>
          <Grid data={unit.evidences} pageable={true} sortable>
            <Column field="fileName" title="Documento" />
            <Column
              title="Tamaño"
              cell={(props) => (
                <td>{/*{this._renderFileSize(props.dataItem.fileSize)}*/}</td>
              )}
            />
            <Column
              title="Acciones"
              cell={(props) => (
                <td>
                  <div style={{ display: "flex" }}>
                    {/* {usuario.type !== 3 || usuario.type !== 5 ? ( */}
                    <>
                      <ButtonAction
                        title={"Visualizar"}
                        icon={" icon visualizar"}
                        look="bare"
                        primary={false}
                        onClick={() => {
                          getDocument(props.dataItem.url);
                        }}
                      />
                      <ButtonAction
                        title={"Descargar"}
                        icon={" icon descargar"}
                        look="bare"
                        primary={false}
                        onClick={() => {
                          getDocument(props.dataItem.url, true);
                        }}
                      />
                    </>
                    {/* ) : ( */}
                    {/* <ButtonAction
                      title={"Eliminar"}
                      disabled={props.dataItem.type == 7}
                      icon={" icon eliminar"}
                      look="bare"
                      primary={false}
                      // onClick={this.handelRemoveFile}
                      data={props.dataItem.index}
                    /> */}
                    {/* )} */}
                  </div>
                </td>
              )}
            />
          </Grid>
        </div>
        {usuario.type === 2 && status == 3 && (
          <div>
            <Form
              render={(formRenderProps) => (
                <FormElement>
                  <div style={{ display: "flex" }}>
                    <div>¿Las evidencias son correctas?</div>
                    <div style={{ paddingLeft: "1em" }}>
                      <RadioButton
                        name="status"
                        value={1}
                        checked={unit.status === 1}
                        label="Si"
                        onChange={handleChange}
                      />
                      <RadioButton
                        name="status"
                        value={2}
                        checked={unit.status === 2}
                        label="No"
                        onChange={handleChange}
                      />
                      {/* <RadioButton
                        name="status"
                        value={3}
                        checked={unit.status === 3}
                        label="Ajuste"
                        onChange={handleChange}
                      /> */}
                    </div>
                  </div>
                  {/* <div style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      <Label editorId={"type-user"}>
                        ¿Las evidencias son correctas?
                      </Label>
                      <Field
                        layout={"horizontal"}
                        data={status}
                        disabled={false}
                        id="status"
                        nombre="status"
                        textField=""
                        component={RadioGroupInput}
                        value={
                          unit.status > 0
                            ? status.find((x) => x.value == unit.status).value
                            : ""
                        }
                        onChange={handleChange}
                      />
                    </div>
                  </div> */}
                  <br></br>
                  {unit.status === 2 && (
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <Field
                          component={InputText}
                          dClassName="k-form-field-wrap finbe-form-field-filter"
                          id="comment"
                          label="Comentario"
                          nombre="comment"
                          onChange={handleChange}
                          placeholder="Ingresa comentario"
                          value={unit.comment != null ? unit.comment : ""}
                          wrapperStyle={{ width: "90%" }}
                        />
                      </div>
                    </div>
                  )}
                </FormElement>
              )}
            />
          </div>
        )}
      </DialogBox>

      {/* Modal para validar unidades del administrador */}
    </>
  );
}
