import { process } from "@progress/kendo-data-query";
import React, { useEffect, useState } from "react";
import { useUsuario } from "../../Context/UserContext";
import {
  InputComboBox,
  InputFilterText,
  InputRangeDatePicker,
} from "../Sections/Components/Inputs";
import { FilterBox, MoreFilterBox } from "../Sections/Filters";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ButtonAction } from "../Sections/Components/Buttons";
import AddUsers from "./AddUsers";
import AccountService from "../../Services/AccountService";
import moment from "moment";
import useLoader from "../../hooks/useLoader";
export default function Index(props) {
  const initialFilters = {
    name: "",
    account: null, //Operador finbe Asignado
    datePickerFilter: { start: null, end: null },
  };
  const { usuario, signOutCognito } = useUsuario();
  const [companyId, setCompanyId] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [dataFilters, setDataFilters] = useState(initialFilters);

  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [isDown, setIsDown] = useState(true);

  const [visibleUsers, setVisibleUsers] = useState(false);

  const [dataState, setDataState] = useState({
    sort: [{ field: "name", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });

  const Loading = useLoader();

  const getAccounts = async () => {
    try {
      Loading();
      let res = await AccountService.getAll({
        params: { assigned: usuario.id },
      });
      let list = res.data.map((x) => {
        return { id: x.id, text: x.name };
      });
      setAccounts(list);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };
  const getCompanies = async (assignedTo, params = null) => {
    try {
      Loading();
      let res = await AccountService.getCompaniesByAssingned(assignedTo, {
        params: params,
      });
      setCompanies(res.data);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    if (typeof value !== "object") {
      setDataFilters({ ...dataFilters, [name]: value });
    } else if (value != null) {
      if (value.id) {
        setDataFilters({ ...dataFilters, [name]: value.id });
      } else {
        setDataFilters({
          ...dataFilters,
          ["datePickerFilter"]: value,
        });
      }
    } else {
      setDataFilters({ ...dataFilters, [name]: "" });
    }
  };
  const handleClickMoreFilter = async () => {
    setIsDown(!isDown);
  };
  const handleClickSearch = () => {
    let data = {};
    if (dataFilters.name !== "") data.name = dataFilters.name;
    if (dataFilters.account !== null && dataFilters.account != "")
      data.account = dataFilters.account;
    if (dataFilters.datePickerFilter.start !== null)
      data.from = moment(dataFilters.datePickerFilter.start).format(
        "YYYY-MM-DD"
      );
    if (dataFilters.datePickerFilter.end !== null)
      data.to = moment(dataFilters.datePickerFilter.end).format("YYYY-MM-DD");

    setDataState({ ...dataState, skip: 0 });
    getCompanies(usuario.id, data);
  };
  const handleShowRegisterChange = () => {};

  const toggleUsers = (e) => {
    const id = e.currentTarget.getAttribute("data");
    setCompanyId(id);
    setVisibleUsers(!visibleUsers);
  };
  useEffect(() => {
    if (usuario) {
      getCompanies(usuario.id);
      getAccounts();
    }
  }, []);
  return (
    <>
      <div className="finbe-app-container">
        <div className="finbe-app-section-top">
          <FilterBox
            buttonMoreFilterOnClick={handleClickMoreFilter}
            buttonSearchOnClick={handleClickSearch}
            inputDropDownValue={showRegister}
            inputFilterOnChange={handleShowRegisterChange}
            isDown={isDown}
            inputFilterwWrapperStyle={{ width: "100%" }}
          >
            <Form
              render={(formRenderProps) => (
                <FormElement style={{ display: "flex" }}>
                  <Field
                    dClassName="k-form-field-wrap finbe-form-field-filter"
                    id="name"
                    nombre="name"
                    onChange={onChange}
                    placeholder="Ingresar nombre"
                    value={dataFilters.name}
                    wrapperStyle={{ width: "90%", margin: "1em" }}
                    component={InputFilterText}
                  />
                </FormElement>
              )}
            />
          </FilterBox>
        </div>
        <div style={{ color: "#b0ada6" }}>
          {!isDown && (
            <div
              style={{
                width: "95%",
                margin: "1em 1em 0 1em",
                background: "#e4e3e0",
              }}
            >
              <MoreFilterBox>
                <Form
                  render={(formRenderProps) => (
                    <FormElement style={{ display: "flex" }}>
                      <Field
                        dClassName="k-form-field-wrap finbe-form-combo"
                        id="account"
                        name="account"
                        data={accounts}
                        onChange={onChange}
                        placeholder="Selec. cuenta"
                        value={accounts.find(
                          (x) => x.id == dataFilters.account
                        )}
                        wrapperStyle={{ width: "25%", margin: "1em" }}
                        component={InputComboBox}
                      />
                      <Field
                        dClassName="k-form-field-wrap finbe-form-date"
                        dateRangePickerId="datePickerFilter"
                        dateRangePickerName="datePickerFilter"
                        dateRangePickerOnChange={onChange}
                        fieldWrapperStyle={{
                          width: "50%",
                          paddingLeft: "1.2em",
                        }}
                        defaultValue={{
                          start: dataFilters.datePickerFilter.start,
                          end: dataFilters.datePickerFilter.end,
                        }}
                        dateRangePickerValue={dataFilters.datePickerFilter}
                        component={InputRangeDatePicker}
                      />
                    </FormElement>
                  )}
                />
              </MoreFilterBox>
            </div>
          )}
        </div>
        <div>
          <Grid
            className="finbe-table"
            data={process(companies, dataState)}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.data);
            }}
            pageable={{
              info: false,
            }}
            sortable
            skip={dataState.skip}
            take={dataState.take}
            resizable
          >
            <Column field="name" title="Nombre" />
            <Column field="company.name" title="Empresa" />
            <Column
              field="createdAt"
              title="Fecha"
              filter="date"
              format="{0:dd/MM/yyyy}"
            />
            <Column
              title="Acciones"
              cell={(props) => (
                <td>
                  <div style={{ display: "flex" }}>
                    {usuario && usuario.type != 4 && (
                      <>
                        {/* <ButtonAction
                          title={"Editar"}
                          icon=" icon editar"
                          isRedirect={true}
                          look="bare"
                          primary={false}
                          url={"/editar-sucursal/" + props.dataItem.id}
                        /> */}
                        {/* <ButtonAction
                          title={"Archivar"}
                          icon=" icon archivar"
                          look="bare"
                          primary={false}
                          isRedirect={false}
                          data={props.dataItem.id}
                          onClick={handleDialogArchived}
                        /> */}
                      </>
                    )}
                    <ButtonAction
                      title={"Usuarios asignados"}
                      data={props.dataItem.id}
                      icon=" icon usuarios-asignados-black"
                      isRedirect={false}
                      look="bare"
                      primary={false}
                      onClick={toggleUsers}
                    />
                  </div>
                </td>
              )}
            />
            {/* {usuario && usuario.type != 4 && (
              <Column
                field="isActive"
                title="Estatus"
                cell={(props) => (
                  <td>
                    <InputSwitch
                      checked={props.dataItem.isActive}
                      onChange={handleDialogChangeStatus}
                      data={props.dataItem.id}
                    />
                  </td>
                )}
              />
            )} */}
          </Grid>
        </div>
      </div>
      <AddUsers
        // account={account}
        company={companyId}
        // agency={agency}
        // agencyName={agencyName}
        visible={visibleUsers}
        setVisible={setVisibleUsers}
      />
    </>
  );
}
