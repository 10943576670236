import axios from "axios";
import dotenv from "dotenv";

dotenv.config();
export default axios.create({
  baseURL: process.env.REACT_APP_CONNECTION_FINBE,
  headers: {
    
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Authorization": process.env.REACT_APP_TOKEN_FINBE
  }
});
