import httpCommon from "../Utils/http-common";

const getAll = (params) => {
  const client = httpCommon();
  return client.get("/agencies", params);
};

const getById = (id) => {
  const client = httpCommon();
  return client.get(`/agencies/${id}`);
};

const create = (data) => {
  const client = httpCommon();
  return client.post("/agencies", data);
};

const update = (id, data) => {
  const client = httpCommon();
  return client.put(`/agencies/${id}`, data);
};

const setActive = (id, data) => {
  const client = httpCommon();
  return client.patch(`/agencies/${id}/active`, data);
};

const setArchive = (id, data) => {
  const client = httpCommon();
  return client.patch(`/agencies/${id}/archive`, data);
};

const getArchived = (params) => {
  const client = httpCommon();
  return client.get("/agencies/archived",params);
};

const getUsers = (id) => {
  const client = httpCommon();
  return client.get(`/agencies/${id}/users`);
};

const addUser = (id, data) => {
  const client = httpCommon();
  return client.post(`/agencies/${id}/users`, data);
};

const removeUser = (id, userId) => {
  const client = httpCommon();
  return client.patch(`/agencies/${id}/users`, { userId: userId });
};

//Aun faltan los metodos de filtrado

export default {
  getAll,
  getById,
  create,
  update,
  setActive,
  setArchive,
  getArchived,
  getUsers,
  addUser,
  removeUser,
};
