import React from 'react';
import { ButtonDanger, ButtonSuccess } from "../Sections/Components/Buttons";
import { useHistory } from "react-router-dom";

const styleButton = {
    borderRadius: '15px',
}

const RoleButtons = (props)=>{
  let history = useHistory();
    return (
      <div style={{ display: "flex", justifyContent: "space-between", marginRight: '5em' }}>
        <div style={{ display: "flex" }}></div>
        <div style={{ display: "flex", textAling: "left" }}>
            <ButtonDanger
              className=""
              icon="close"
              onClick={() => {
                history.push("/roles");
              }}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Cancelar"
            />
            <ButtonSuccess
              className=""
              icon="close"
              onClick = {props.onClick}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Guardar"
            />
        </div>
      </div>
    )
}

export default RoleButtons;
