import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import FinbeService from "../../Services/Finbe/FinbeService";
// import { ButtonItem } from '../Inputs/Buttons';
import { useUsuario } from "../../Context/UserContext";
import { FilterBox } from "../Sections/Filters";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import {
  InputComboBox,
  InputFilterText,
  InputRangeDatePicker,
} from "../Sections/Components/Inputs";
import moment from "moment";
import AccountService from "../../Services/AccountService";
import useLoader from "../../hooks/useLoader";
export const List = () => {
  const initialFilters = {
    numeroDeCliente: "",
    datePickerFilter: { start: null, end: null },
  };
  const { usuario, signOutCognito } = useUsuario();
  const [dataFilters, setDataFilters] = useState(initialFilters);
  const [data, setData] = useState([]);
  const [clients, setClients] = useState([]);
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [dataState, setDataState] = useState({
    sort: [{ field: "noCredito", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });
  const Loading = useLoader();

  const getInvetory = async (params) => {
    try {
      Loading();
      const { data } = await FinbeService.getInventory(params);
      setData(data);
      Loading();
    } catch (error) {
      Loading();
    }
  };
  const getClients = async (id) => {
    try {
      Loading();
      const res = await AccountService.getCompaniesByAssingned(id);
      setClients(
        res.data.map((item) => {
          return {
            id: item.clientId,
            text: `${item.legalName} (${item.clientId})`,
          };
        })
      );
      Loading();
    } catch (error) {
      Loading();
      if (error.response && error.response.data.status === 401)
        signOutCognito();
    }
  };
  const handleShowRegisterChange = (e) => {
    const { value } = e.target;
    setShowRegister(value);
    setDataState({ ...dataState, ["take"]: value.text, skip: 0 });
  };
  const handleClickSearch = async () => {
    let data = {};
    if (usuario.type !== 3) {
      data.numeroDeCliente = usuario.company.clientId;
    }
    if (usuario.type === 3) {
      if (
        dataFilters.numeroDeCliente !== "" &&
        dataFilters.numeroDeCliente !== null
      ) {
        data.numeroDeCliente = dataFilters.numeroDeCliente.id;
      }
    }
    if (dataFilters.datePickerFilter.start !== null)
      data.fechaInicio = moment(dataFilters.datePickerFilter.start).format(
        "YYYY-MM-DD"
      );
    if (dataFilters.datePickerFilter.end !== null)
      data.fechaFinal = moment(dataFilters.datePickerFilter.end).format(
        "YYYY-MM-DD"
      );
    setDataState({ ...dataState, skip: 0 });
    getInvetory({ params: data });
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    if (value !== null && name == undefined) {
      setDataFilters({
        ...dataFilters,
        ["datePickerFilter"]: value,
      });
    } else if (typeof value === "object") {
      setDataFilters({ ...dataFilters, [name]: value });
    }
  };
  useEffect(() => {
    if (usuario && usuario.type !== 3) {
      getInvetory({
        params: { numeroDeCliente: usuario.company.clientId },
      });
    }
    if (usuario && usuario.type === 3) {
      getClients(usuario.id);
    }
  }, []);
  return (
    <>
      <div className="finbe-app-container">
        <div className="finbe-app-section-top">
          <FilterBox
            buttonSearchOnClick={handleClickSearch}
            inputDropDownValue={showRegister}
            inputFilterOnChange={handleShowRegisterChange}
            isMoreFilter={false}
          >
            <Form
              render={(formRenderProps) => (
                <FormElement style={{ display: "flex" }}>
                  {usuario && usuario.type === 3 && (
                    <Field
                      dClassName="k-form-field-wrap finbe-form-field-filter"
                      id="numeroDeCliente"
                      name="numeroDeCliente"
                      onChange={onChange}
                      placeholder="Seleccionar cliente"
                      value={dataFilters.numeroDeCliente}
                      wrapperStyle={{ width: "50%", margin: "1em" }}
                      component={InputComboBox}
                      data={clients}
                    />
                  )}
                  {/* <Field
                    dClassName="k-form-field-wrap finbe-form-combo"
                    id="operator"
                    name="operator"
                    // data={operators}
                    // onChange={onChange}
                    placeholder="Selec. tipo de linea"
                    // value={operators.find((x) => x.id == dataFilters.operator)}
                    wrapperStyle={{ width: "25%", margin: "1em" }}
                    component={InputComboBox}
                  /> */}
                  <Field
                    dClassName="k-form-field-wrap finbe-form-date"
                    dateRangePickerId="datePickerFilter"
                    dateRangePickerName="datePickerFilter"
                    dateRangePickerOnChange={onChange}
                    fieldWrapperStyle={{ width: "100%", paddingLeft: "1.2em" }}
                    defaultValue={{
                      start: dataFilters.datePickerFilter.start,
                      end: dataFilters.datePickerFilter.end,
                    }}
                    dateRangePickerValue={dataFilters.datePickerFilter}
                    component={InputRangeDatePicker}
                  />
                </FormElement>
              )}
            />
          </FilterBox>
        </div>
        <div>
          <Grid
            className="finbe-table"
            pageable={{
              info: false,
            }}
            sortable
            data={process(data, dataState)}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.data);
            }}
            resizable
          >
            <Column title="N° de crédito" field="noCredito" width="220px" />
            {/* <Column title="Tipo de línea" field="tipolinea" width="180px" /> */}
            {/* <Column title="N° Disposición" field="noCredito"/> */}
            <Column title="Modelo" field="modelo" width="200px" />
            {/* <Column title="Color exterior" field="color" width="180px"/> */}
            <Column title="N° VIN" field="vin" width="220px" />
            {/* <Column title="N° Motor" field="noMotor" width="180px"/> */}
            {/* <Column title="Capital original" field="capitalOriginal" width="180px"/> */}
            <Column title="Plazo" field="plazo" width="200px" />
            {/* <Column title="Próximo pago" field="proximoPago" width="180px" /> */}
            <Column
              title="Fecha fin de crédito"
              field="finCredito"
              width="200px"
            />
          </Grid>
        </div>
      </div>
    </>
  );
};

export default List;
