import { Loading } from "react-loading-ui";
export default function useLoader() {
    const L = ()=>{
        Loading({
            title: "Página cargando",
            text: "Cargando contenido, por favor espere...",
          });
    }
  return L
}
