import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Datos } from "./Sections/Datos";
import AccountService from "../../Services/AccountService";
import { useUsuario } from "../../Context/UserContext";
import { Resumen } from "./Sections/Resumen";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Link } from "react-router-dom";
import FinbeService from "../../Services/Finbe/FinbeService";
import { FormEdit } from "./Sections/FormEdit";
import qs from "qs";
import RoleService from "../../Services/RoleService";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import {
  ButtonAction,
  ButtonPrimary,
  ButtonTertiary,
} from "../Sections/Components/Buttons";
import useLoader from '../../hooks/useLoader'

const Edit = (props) => {
  let history = useHistory();
  const { usuario, signOutCognito,setCargando } = useUsuario();

  const initialAccount = {
    name: "",
    email: "",
    type: 0,
    assignedTo: null,
    registerBy: null,
  };
  const initialLegal = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    legalId: null,
    companyId: null,
    roleId: null,
    rowId: "",
  };
  const initialCompany = {
    id: null,
    name: "",
    legalName: "",
    clientId: null,
    rowId: "",
  };
  const initialButtons = { save: true, cancel: false, add: false };

  const [dataState, setDataState] = useState({
    sort: [{ field: "name", dir: "asc" }],
    take: 10,
    skip: 0,
  });

  const [account, setAccount] = useState(initialAccount);
  const [legalPerson, setLegalPerson] = useState(initialLegal);
  const [company, setCompany] = useState(initialCompany);
  const [moralPersons, setMoralPersons] = useState([]);
  const [legalPersonsFinbe, setLegalPersonsFinbe] = useState([]);
  const [roles, setRoles] = useState([]);
  const [dataGrid, setDataGrid] = useState([]);
  const [buttons, setButtons] = useState(initialButtons);

  const Loading = useLoader();
  // const [legalPersons, setLegalPersons] = useState([]);
  // const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (props.match.params.id != undefined) {
      getUpdate(props.match.params.id);
    }
    if (usuario) {
      getClients();
      getRoles();
    }
  }, [props.match.params.id]);

  const getUpdate = async (_id) => {
    try {
      // setCargando(true);
      Loading()
      const res = await AccountService.getById(_id);
      const {
        id,
        name,
        email,
        type,
        assignedTo,
        registerBy,
        legals,
        companies,
      } = res.data;
      setAccount({ id, name, email, type, assignedTo, registerBy });

      if (type === 1) {
        let _data = [];
        legals.map((user) => {
          const _company = companies.find((c) => c.id == user.companyId);
          let dataCompany = _company;
          let dataLP = user;

          dataCompany.legalPerson = dataLP;
          dataCompany.rowId = _company.id;
          _data.push(dataCompany);
        });
        setDataGrid(_data);
      } else if (type == 2) {
        setCompany(res.data.companies[0]);
        setLegalPerson(res.data.legals[0]);
        getLegalPersons(res.data.companies[0].clientId);
      }
      // setCargando(false);
      Loading()
    } catch (error) {
      Loading()
      if (error?.response?.status == 401) signOutCognito();

    }
  };

  const getClients = async () => {
    try {
      // setCargando(true);
      Loading();
      const res = await FinbeService.getClients();
      setMoralPersons(res.data);
      // setCargando(false);
      Loading()
    } catch (error) {
      Loading()
    }
  };
  const getLegalPersons = async (clientId) => {
    try {
      // setCargando(true);
      Loading()
      const res = await FinbeService.getLegalPersons({
        params: { numeroCliente: clientId },
      });
      setLegalPersonsFinbe(res.data);
      // setCargando(false);
      Loading()
    } catch (error) {
      Loading()
    }
  };
  const getRoles = async () => {
    try {
      // setCargando(true);
      Loading()
      const res = await RoleService.getAll({
        params: { type: [4] },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      let _roles = res.data.map((x) => {
        return { id: x.id, text: x.name };
      });
      setRoles(_roles);
      // setCargando(false);
      Loading()
    } catch (error) {
      // setCargando(false);
      Loading()
      if (error?.response?.status == 401) signOutCognito();
    }
  };

  const handleChangeCompany = (event) => {
    //Cmabiar datos de form compania
    const { name, value } = event.target;
    if (value != null) {
      let _moralPerson = moralPersons.find((x) => x.numeroCliente === value.id);
      getLegalPersons(value.id);
      setLegalPerson(initialLegal);
      setCompany({
        ...company,
        clientId: value.id,
        name: _moralPerson.nombreEmpresa,
        legalName: _moralPerson.razonSocial,
      });
    }
  };

  const handleChangeData = (event) => {
    //Cambiar datos form cuenta
    const { name, value } = event.target;
    if (name !== undefined) setAccount({ ...account, [name]: value });
    else setAccount({ ...account, ["type"]: event.value });
  };

  const handleChangeLegal = (event) => {
    //Cambiar datos form RL
    const { name, value } = event.target;
    if (typeof value !== "object")
      setLegalPerson({ ...legalPerson, [name]: value });
    else if (value != null)
      setLegalPerson({ ...legalPerson, [name]: value.id });
    // if (name === "companyId") {
    //   let _company = companies.find((x) => x.rowId === value.id);
    //   getLegalPersons(_company.clientId);
    // }
    if (name === "legalId") {
      let _legalPerson = legalPersonsFinbe.find(
        (x) => x.numeroCliente === value.id
      );
      setLegalPerson({
        ...legalPerson,
        firstname: _legalPerson.nombres,
        lastname: _legalPerson.apellidos,
        // email: _legalPerson.correo,
        phone: _legalPerson.telefono != null ? _legalPerson.telefono : "",
        legalId: _legalPerson.numeroCliente,
      });
    }
  };

  const handleSave = async () => {
    try {
      Loading();
      // setCargando(true);
      const { id, ...rest } = account;
      let data = rest;
      data.companies = [];
      let valid = true;
      if (account.type === 1) {
        dataGrid.map((value) => {
          if (
            (value.clientId == null && value.clientId == undefined) ||
            (value.legalPerson.legalId == null &&
              value.legalPerson.legalId == undefined) ||
            !RegExp("^[0-9]{8,13}$").test(value.legalPerson.phone)
          )
            valid = false;
          if (value.id !== undefined && value.id !== null) {
            data.companies.push({
              id: value.id,
              name: value.name,
              legalName: value.legalName,
              clientId: value.clientId,
              legalPerson: {
                id: value.legalPerson.id,
                firstname: value.legalPerson.firstname,
                lastname: value.legalPerson.lastname,
                email: value.legalPerson.email,
                phone: value.legalPerson.phone,
                legalId: value.legalPerson.legalId,
                roleId: value.legalPerson.roleId,
              },
            });
          } else {
            data.companies.push({
              name: value.name,
              legalName: value.legalName,
              clientId: value.clientId,
              legalPerson: {
                firstname: value.legalPerson.firstname,
                lastname: value.legalPerson.lastname,
                email: value.legalPerson.email,
                phone: value.legalPerson.phone,
                legalId: value.legalPerson.legalId,
                roleId: value.legalPerson.roleId,
              },
            });
          }
        });
      } else if (account.type === 2) {
        if (
          (company.clientId == null && company.clientId == undefined) ||
          (legalPerson.legalId == null && legalPerson.legalId == undefined) ||
          !RegExp("^[0-9]{8,13}$").test(legalPerson.phone)
        )
          valid = false;
        data.companies.push({
          id: company.id,
          name: company.name,
          legalName: company.legalName,
          clientId: company.clientId,
          legalPerson: {
            id: legalPerson.id,
            firstname: legalPerson.firstname,
            lastname: legalPerson.lastname,
            email: legalPerson.email,
            phone: legalPerson.phone,
            legalId: legalPerson.legalId,
            roleId: legalPerson.roleId,
          },
        });
      }

      const res = await AccountService.update(id, data);
      // setCargando(false);
      Loading();
      history.push("/cuentas");
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
      // setCargando(false);
    }
  };

  const handleEdit = (event) => {
    const id = event.currentTarget.getAttribute("data");
    const { legalPerson, ...rest } = dataGrid.find((x) => x.rowId == id);
    getLegalPersons(rest.clientId);
    setCompany(rest);
    setLegalPerson(legalPerson);
    setButtons({ save: false, cancel: false, add: true });
  };
  const handleRemove = (event) => {
    const id = event.currentTarget.getAttribute("data");
    const _dataGrid = dataGrid.filter((x) => x.id !== id);
    setDataGrid(_dataGrid);
  };

  const handleAddData = () => {
    const id = Math.random().toString(36).substr(2, 9);

    let _dataGrid = dataGrid;

    let objGrid = company;
    objGrid.rowId = id;
    objGrid.legalPerson = legalPerson;
    _dataGrid.push(objGrid);
    setCompany(initialCompany);
    setLegalPerson(initialLegal);
    setDataGrid(_dataGrid);
    setButtons(initialButtons);
  };
  const handleSaveData = () => {
    let _dataGrid = dataGrid.filter((x) => x.rowId !== company.rowId);
    let objGrid = company;
    objGrid.legalPerson = legalPerson;
    _dataGrid.push(objGrid);
    setCompany(initialCompany);
    setLegalPerson(initialLegal);
    setDataGrid(_dataGrid);
    setButtons(initialButtons);
  };

  const handleCleanForm = () => {
    setCompany(initialCompany);
    setLegalPerson(initialLegal);
    setButtons(initialButtons);
  };

  return (
    <div className="finbe-app-container">
      <Resumen>
        <Datos handleChange={handleChangeData} disabled={true} account={account} />
        <FormEdit
          roles={roles}
          handleChangeCompany={handleChangeCompany}
          handleChangeLegal={handleChangeLegal}
          company={company}
          moralPersons={moralPersons}
          legalPersonsFinbe={legalPersonsFinbe}
          legalPerson={legalPerson}
        />
        {account.type === 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "3.5em",
              marginTop: "1.1em",
            }}
          >
            <ButtonPrimary
              disabled={buttons.add}
              onClick={handleAddData}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Agregar"
            />
            <ButtonPrimary
              disabled={buttons.save}
              onClick={handleSaveData}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Guardar"
            />
            <ButtonTertiary
              disabled={buttons.cancel}
              onClick={handleCleanForm}
              pClassName="finbe-btn-margin"
              primary={true}
              text="Cancelar"
            />
          </div>
        )}
        <br></br>
        {account.type === 1 && (
          <>
            <Grid
              className="finbe-table"
              pageable={{
                info: false,
              }}
              sortable
              data={process(dataGrid, dataState)}
              {...dataState}
              onDataStateChange={(e) => {
                setDataState(e.data);
              }}
            >
              <Column title="Empresa" field="name" />
              <Column title="Razón social" field="legalName" />
              <Column
                title="Repr. Legal"
                cell={(data) => (
                  <td>
                    {data.dataItem.legalPerson.firstname +
                      " " +
                      data.dataItem.legalPerson.lastname}
                  </td>
                )}
              />
              <Column title="Correo" field="legalPerson.email" />
              <Column title="Telefono" field="legalPerson.phone" />
              <Column
                title="Acciones"
                cell={(props) => (
                  <td style={{ display: "flex" }}>
                    <ButtonAction
                      title={"Editar"}
                      data={props.dataItem.rowId}
                      icon=" icon editar"
                      isRedirect={false}
                      look="bare"
                      onClick={handleEdit}
                      primary={false}
                    />
                    <ButtonAction
                      title={"Eliminar"}
                      data={props.dataItem.rowId}
                      icon=" icon eliminar"
                      isRedirect={false}
                      look="bare"
                      onClick={handleRemove}
                      primary={false}
                    />
                  </td>
                )}
              />
            </Grid>
          </>
        )}
      </Resumen>

      {/* <ButtonGroup className="formButtons">
        <Link to="/cuentas">
          <Button>Cancelar</Button>
        </Link>
        <Button onClick={handleSave}>Guardar</Button>
      </ButtonGroup> */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}></div>
        <div style={{ display: "flex", textAling: "left" }}>
          <ButtonTertiary
            className=""
            icon="close"
            onClick={() => {
              history.push("/cuentas");
            }}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Cancelar"
          />
          <ButtonPrimary
            className=""
            icon="check"
            onClick={handleSave}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Guardar"
          />
        </div>
      </div>
    </div>
  );
};

export default Edit;
