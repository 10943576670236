import React, { useEffect, useState } from "react";
import {
  Grid,
  GridToolbar,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import LayoutService from "../../Services/LayoutService";
import moment from "moment";
import { FilterBox } from "../Sections/Filters";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { useUsuario } from "../../Context/UserContext";
import useLoader from "../../hooks/useLoader";
import { ButtonPrimary, ButtonSuccess } from "../Sections/Components/Buttons";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { DialogBox } from "../Sections/Dialogs";

export default function Layout() {
  let _export;
  const { usuario, signOutCognito } = useUsuario();
  const Loading = useLoader();

  const [unitsLayout, setUnitsLayout] = useState([]);
  const [unitsRes, setUnitsRes] = useState([]);
  const [showRegister, setShowRegister] = useState({ id: 1, text: 10 });
  const [editID, setEditID] = React.useState(null);
  const [changes, setChanges] = useState(false);
  const [canCancel, setCancel] = useState(false);
  const [dataState, setDataState] = useState({
    sort: [{ field: "noCredito", dir: "asc" }],
    take: showRegister.text,
    skip: 0,
  });
  const [months, setMonths] = useState(null);

  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataResponse, setDataResponse] = useState({
    title: "",
    message: "",
    success: "",
  });
  const getUnitsLayout = async (months) => {
    try {
      Loading();

      const { data } = await LayoutService.getUnitsLayout({
        params: { months: months },
      });
      data.map((d) => {
        d.fechaInicio = moment(d.fechaInicio, "DD/MM/YYYY").toDate();
        d.fechaVencimiento = moment(d.fechaVencimiento, "DD/MM/YYYY").toDate();
      });
      setUnitsLayout(data);
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
    }
  };

  const rowClick = async (event) => {
    if (editID === null && event.dataItem.tipo === "Nuevo") {
      setEditID(event.dataItem.unitId);
      setUnitsRes([
        ...unitsLayout.map((u) => {
          return { ...u };
        }),
      ]);
      setCancel(true);
    }
  };

  const itemChange = (event) => {
    const inEditID = event.dataItem.unitId;
    const field = event.field || "";
    const newData = unitsLayout.map((item) => {
      //Cambio en observaciones de auditoria
      if (item.unitId === inEditID && field === "finbeCob") {
        item.plazo = event.value ? "N/A" : item.plazo;
        item.puedeRefinanciar = !event.value;
      }
      //Cambio en la fecha de vencimiento
      if (item.unitId === inEditID && field === "fechaInicio") {
        const fechaInicial = moment(event.value, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        );
        const fechaFinal = moment(event.value, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        );
        const now = moment().format("YYYY-MM-DD");
        const diasTranscurridos = moment(fechaFinal).diff(fechaInicial, "days");
        const diasRescate = item.plazoFinanciamiento - diasTranscurridos;
        const plazoSugerido = Math.round(diasRescate / 30.4);

        item.plazoSugerido = plazoSugerido;
        item.diasTranscurridos = diasTranscurridos;
        item.plazo = item.puedeRefinanciar
          ? plazoSugerido > 0
            ? plazoSugerido
            : "N/A"
          : "N/A";
        item.diasRescate = diasRescate;
      }
      if (item.unitId === inEditID && field === "plazoFinanciamiento") {
        const diasRescate = event.value - item.diasTranscurridos;
        const plazoSugerido = Math.round(diasRescate / 30.4);

        item.plazoSugerido = plazoSugerido;
        item.plazo = item.puedeRefinanciar
          ? plazoSugerido > 0
            ? plazoSugerido
            : "N/A"
          : "N/A";
        item.diasRescate = diasRescate;
      }
      return item.unitId === inEditID
        ? { ...item, [field]: event.value }
        : item;
    });
    setUnitsLayout(newData);
    setChanges(true);
    setCancel(true);
  };
  const saveChanges = () => {
    setUnitsLayout(unitsLayout);
    setEditID(null);
    setChanges(false);
    setUnitsRes(unitsLayout);
    setCancel(false);
  };

  const cancelChanges = () => {
    const _units = [...unitsRes];
    setUnitsLayout(_units);
    setUnitsRes([]);
    setEditID(null);
    setChanges(false);
    setCancel(false);
  };

  const handleChangeMonths = (e) => {
    setMonths(e.value);
  };
  const handleDialogResponse = () => {
    setVisible(!visible);
  };
  const handleClickSearch = () => {
    if (months !== null && months > 0) {
      getUnitsLayout(months);
    } else {
      setDataResponse({
        title: "Ocurrió un error",
        message: "Los meses de anticipación son requeridos",
        success: false,
      });
      setVisible(!visible);
    }
  };
  const handleShowRegisterChange = (e) => {
    const { value } = e.target;
    setShowRegister(value);
    setDataState({ ...dataState, ["take"]: value.text, skip: 0 });
  };
  const handleDownloadExcel = () => {
    let success = true;
    let message = "";
    try {
      if (!unitsLayout.length > 0) throw Error("no hay unidades en el layout");
      _export.save();
      message = "El layout se descargo forma correcta";
    } catch (error) {
      success = false;
      message = error.response ? error.response.data.message : error.message;
    }
    setDataResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
    });
    setVisible(!visible);
  };
  const handleApplyLayout = async () => {
    let success = true;
    let message = "";
    setVisibleConfirm(false)
    try {
      Loading();
      if (!unitsLayout.length > 0) throw Error("no hay unidades en el layout");

      const _units = unitsLayout.map((u) => {
        return {
          unitId: u.unitId,
          deadlines: u.plazo === "N/A" ? 0 : u.plazo,
          canRefinancing: u.plazo==="N/A"?false:u.puedeRefinanciar,
        };
      });
      const { data } = await LayoutService.applyLayout({ units: _units });
      message = "Las unidades fueron actualizadas";
      _export.save();
      Loading();
    } catch (error) {
      Loading();
      if (error?.response?.status == 401) signOutCognito();
      success = false;
      message = error.response ? error.response.data.message : error.message;
    }
    setDataResponse({
      title: success ? "Acción realizada exitosamente" : "Ocurrió un error",
      message: message,
      success: success,
    });
    setVisible(!visible);
  };
  useEffect(() => {
    // getUnitsLayout();
  }, []);
  return (
    <div className="finbe-app-container">
      <div className="finbe-app-section-top">
        <FilterBox
          buttonSearchOnClick={handleClickSearch}
          inputDropDownValue={showRegister}
          inputFilterOnChange={handleShowRegisterChange}
          isMoreFilter={false}
        >
          <div style={{ marginTop: "1.6em" }}>
            <label> Calculo de meses<br/>
            <NumericTextBox
              width={200}
              placeholder="Anticipación"
              min={0}
              value={months}
              onChange={handleChangeMonths}
            />
            </label>
          </div>
        </FilterBox>
      </div>
      <div>
        <ExcelExport
          fileName={"Layout (" + moment().format("DD-MM-YYYY") + ")"}
          data={unitsLayout}
          ref={(exporter) => (_export = exporter)}
        >
          <Grid
            className="finbe-table"
            pageable={{
              info: false,
            }}
            sortable
            data={process(
              unitsLayout.map((item) => ({
                ...item,
                inEdit: item.unitId === editID,
              })),
              dataState
            )}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.data);
            }}
            resizable
            editField="inEdit"
            onRowClick={rowClick}
            onItemChange={itemChange}
          >
            <GridToolbar>
              <button
                title="Save Changes"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={saveChanges}
                disabled={!changes}
              >
                Guardar
              </button>
              <button
                title="Cancel Changes"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={cancelChanges}
                disabled={!canCancel}
              >
                Cancelar
              </button>
            </GridToolbar>
            <Column
              title="Asignación"
              field="asignacion"
              editable={false}
              width="110px"
            />
            <Column
              title="Agencia"
              field="agencia"
              editable={false}
              width="100px"
            />
            <Column
              title="Contrato"
              field="contrato"
              editable={false}
              width="100px"
            />
            <Column title="Plazo" field="plazo" width="80px" />
            <Column
              title="Modelo"
              field="modelo"
              editable={false}
              width="80px"
            />
            <Column title="VIN" field="vin" editable={false} width="100px" />
            <Column title="Tasa" field="tasa" editable={false} width="80px" />
            <Column
              title="Saldo capital"
              field="saldoCapital"
              editable={false}
              width="100px"
            />
            <Column
              title="Auditoria"
              field="finbeCob"
              editor="boolean"
              width="100px"
            />
            <Column
              title="Inicio"
              field="fechaInicio"
              editor="date"
              format="{0:dd/MM/yyyy}"
              width="100px"
            />
            <Column
              title="Vigencia"
              field="fechaVencimiento"
              editor="date"
              format="{0:dd/MM/yyyy}"
              editable={false}
              width="100px"
            />
            <Column
              title="Dias transcurridos"
              field="diasTranscurridos"
              editable={false}
              width="100px"
            />
            <Column
              title="Plazo financiamiento"
              field="plazoFinanciamiento"
              width="100px"
            />
            <Column
              title="Dias de rescate"
              field="diasRescate"
              editable={false}
              width="100px"
            />
            <Column
              title="Plazo en meses"
              field="plazoSugerido"
              editable={false}
              width="100px"
            />
          </Grid>
        </ExcelExport>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}></div>
        <div style={{ display: "flex", textAling: "left" }}>
          <ButtonSuccess
            className=""
            onClick={handleDownloadExcel}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Descargar"
          />
          <ButtonPrimary
            className=""
            icon="check"
            onClick={() => {
              setVisibleConfirm(true);
            }}
            pClassName="finbe-btn-margin"
            primary={true}
            text="Aplicar"
          />
        </div>
      </div>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={() => {
          setVisibleConfirm(false);
        }}
        handelCancel={() => {
          setVisibleConfirm(false);
        }}
        handelAccept={handleApplyLayout}
        title={"Aplicar plazos a unidades"}
        visible={visibleConfirm}
        visibleCancelButton={true}
      >
        ¿Seguro que desea aplicar los plazos a la unidades del layout?
      </DialogBox>
      <DialogBox
        buttonSuccessText={"Aceptar"}
        handelClose={handleDialogResponse}
        handelAccept={handleDialogResponse}
        title={dataResponse.title}
        visible={visible}
      >
        {dataResponse.message}
      </DialogBox>
    </div>
  );
}
