import httpCommon from "../Utils/http-common";

const getAll = (params) => {
  const client = httpCommon();
  return client.get("/roles", params);
};

const getById = (id) => {
  const client = httpCommon();
  return client.get(`/roles/${id}`);
};

const create = (data) => {
  const client = httpCommon();
  return client.post("/roles", data);
};

const update = (id, data) => {
  const client = httpCommon();
  return client.put(`/roles/${id}`, data);
};

const setActive = (id, data) => {
  const client = httpCommon();
  return client.patch(`/roles/${id}/active`, data);
};

const setArchive = (id, data) => {
  const client = httpCommon();
  return client.patch(`/roles/${id}/archive`, data);
};

const getArchived = () => {
  const client = httpCommon();
  return client.get("/roles/archived");
};

//Aun faltan los metodos de filtrado

export default {
  getAll,
  getById,
  create,
  update,
  setActive,
  setArchive,
  getArchived,
};
