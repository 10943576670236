//parentRoute: si no es null= mostra boton <-back, <Link to="ruta">
//botones: si tien valor mostrar componnete.
//nuevo, archivados->com
//nuevo, excepcion,archivados->com
import Pricipal from '../../Components/main/Principal'

import Users from '../../Components/users/Index';
import CreateEditUser from '../../Components/users/CreateEditUser';
import UsersArchived from '../../Components/users/Archivados'
import Roles from '../../Components/roles/Index';
import CreateEditRole from '../../Components/roles/CreateEditRole';
import RolesArchived from '../../Components/roles/archivados';

import Agencies from '../../Components/agencies/Index';
import SucursalesArchivadas from '../../Components/agencies/Archivados';
import CreateEditAgency from '../../Components/agencies/CreateEditAgency';

import Accounts from '../../Components/accounts/Index';
import AccountsArchived from '../../Components/accounts/Archivados';
import CreateAccount from '../../Components/accounts/CreateAccount'
import EditAccount from '../../Components/accounts/EditAccount'
import ConfigAccount from '../../Components/accounts/Configuration'
import Notifications from '../../Components/notifications/index';

/** Provisions **/
import Provisions from '../../Components/Provisions/Index';
import CreateProvisions from '../../Components/Provisions/Create';
import EditProvisions from '../../Components/Provisions/Edit';
import VisualizeProvisions from '../../Components/Provisions/Visualize';
import Show from '../../Components/Provisions/Show'
/** Exceptions **/
import Exceptions from '../../Components/Exceptions/Index';
/** Units **/
import Units from '../../Components/Units/Index';

import Inventory from '../../Components/inventory/Index'
import Principal from '../../Components/main/Principal';

import Audits from '../../Components/audits/index'
import CreateEditAudit from '../../Components/audits/createEditAudit'
import Calendar from '../../Components/audits/schedule'

import ProvisionReview from '../../Components/revision_units/ProvisionReview'
import UnitsReview from '../../Components/revision_units/UnitsReview'

/** Reports **/
import PeriodicValidation from '../../Components/reports/PeriodicValidation'
import ProvisionsStadistics from '../../Components/reports/ProvisionsStadistics'
import Validations from '../../Components/reports/validations'
import Config from '../../Components/configuration/config'
import BadCredits from '../../Components/BadCredits/index'
import CreateEditEmails from '../../Components/EmailServiceDesk/CreateEdit';
import ListEmails from '../../Components/EmailServiceDesk/Index'; 
import ListCompanies from '../../Components/companies/Index'

//Refinanciamientos
import UnitsRefinancing from '../../Components/refinancings/UnitsRefinancing'
import CreateRefinancing from '../../Components/refinancings/Create'
import ListRefinancings from '../../Components/refinancings/List'
import VisualizeRefinancing from '../../Components/refinancings/Visualize'

//Dias inhabiles
import ListNonWorking from '../../Components/NonWorking/Index';
import CreatedNonWorking from '../../Components/NonWorking/Created';
import Layout from '../../Components/Layout/Layout'
const routes = [
    //Rutas de usuarios
    {
        path: '/', exact: true, component: Principal,
    },
    {
        path: '/usuarios', exact: true, name: 'Usuarios', component: Users, parentRoute: null, titleComponent: [
            // { icon: "", primary: true, name: "Ver archivados", look: "", uri: '/usuarios-archivados' },
            { icon: "add", class: "secondary", primary: true, name: "Crear Nuevo", look: "", uri: '/crear-usuario' }
        ]
    },
    {
        path: '/crear-usuario', exact: true, name: 'Crear nuevo usuario', component: CreateEditUser, parentRoute: '/usuarios', titleComponent: null
    },
    {
        path: '/editar-usuario/:id', exact: true, name: 'Editar usuario', component: CreateEditUser, parentRoute: '/usuarios', titleComponent: null
    },
    // {
    //     path: '/usuarios-archivados', exact: true, name: 'Usuarios archivados', component: UsersArchived, parentRoute: '/usuarios', titleComponent: null
    // },
    //Rutas de roles
    {
        path: '/roles', exact: true, name: 'Roles', component: Roles, parentRoute: null, titleComponent: [
            // { icon: "", primary: true, name: "Ver archivados", look: "", uri: '/roles-archivados' },
            { icon: "add", class: "secondary", primary: true, name: "Crear Nuevo", look: "", uri: '/crear-rol' }
        ]
    },
    {
        path: '/crear-rol', exact: true, name: 'Crear nuevo rol', component: CreateEditRole, parentRoute: '/roles', titleComponent: null
    },
    {
        path: '/editar-rol/:id', exact: true, name: 'Editar rol', component: CreateEditRole, parentRoute: '/roles', titleComponent: null
    },
    // {
    //     path: '/roles-archivados', exact: true, name: 'Roles archivados', component: RolesArchived, parentRoute: '/roles', titleComponent: null
    // },
    //Rutas companias
    {
        path: '/companias', exact: true, name: 'Compañías', component: ListCompanies, parentRoute: null, titleComponent:null
    },
    //Rutas de sucursales
    {
        path: '/sucursales', exact: true, name: 'Sucursales', component: Agencies, parentRoute: null,visibleCompenent: [3], titleComponent: [
            // { icon: "", primary: true, name: "Ver archivadas", look: "", uri: '/sucursales-archivadas' },
            { icon: "add", class: "secondary", primary: true, name: "Crear Nuevo", look: "", uri: '/crear-sucursal' }
        ]
    },
    // {
    //     path: '/sucursales-archivadas', exact: true, name: 'Sucursales archivadas', component: SucursalesArchivadas, parentRoute: '/sucursales', titleComponent: null
    // },
    {
        path: '/crear-sucursal', exact: true, name: 'Crear Sucursal', component: CreateEditAgency, parentRoute: '/sucursales', titleComponent: null
    },
    {
        path: '/editar-sucursal/:id', exact: true, name: 'Editar sucursal', component: CreateEditAgency, parentRoute: '/sucursales', titleComponent: null
    },
    //Ruta de cuentas
    {
        path: '/cuentas', exact: true, name: 'Cuentas', component: Accounts, parentRoute: null,visibleCompenent: [3], titleComponent: [
            // { icon: "", primary: true, name: "Ver archivadas", look: "", uri: '/cuentas-archivadas' },
            { icon: "add", class: "secondary", primary: true, name: "Crear Nuevo", look: "", uri: '/crear-cuenta' }
        ]
    },
    {
        path: '/crear-cuenta', exact: true, name: 'Crear nueva cuenta', component: CreateAccount, parentRoute: '/cuentas', titleComponent: null
    },
    // {
    //     path: '/cuentas-archivadas', exact: true, name: 'Cuentas archivadas', component: AccountsArchived, parentRoute: '/cuentas', titleComponent: null
    // },
    {
        path: '/editar-cuenta/:id', exact: true, name: 'Editar cuenta', component: EditAccount, parentRoute: '/cuentas', titleComponent: null
    },
    {
        path: '/configurar-cuenta/:id', exact: true, name: 'Configurar cuenta', component:ConfigAccount , parentRoute: '/cuentas', titleComponent: null
    },

    {
        path: '/notificaciones', exact: true, name: 'Notificaciones', component: Notifications, parentRoute: null, titleComponent: null
    },
    /** Route of Provisions **/
    {
        path: '/disposiciones', exact: true, name: 'Disposiciones', component: Provisions, parentRoute: null, visibleCompenent: [5], titleComponent: [
            { icon: "", class: "tertiary", primary: true, name: "Solicitar Excepción", look: "", dialog: 'RequestException', localStorage: 'exceptionDisabled' } ,
            { icon: "add", class: "secondary", primary: true, name: "Crear Nuevo", look: "", uri: '/crear-disposicion', localStorage: 'createDisabled' }
        ]
    },
    {
        path: '/crear-disposicion', exact: true, name: 'Crear nueva disposición', component: CreateProvisions, parentRoute: '/disposiciones', titleComponent: null
    },
    {
        path: '/editar-disposicion/:id', exact: true, name: 'Editar disposición', component: EditProvisions, parentRoute: '/disposiciones', titleComponent: null
    },
    {
        path: '/vizualizar-disposicion/:id', exact: true, name: 'Vizualizar disposición', component: VisualizeProvisions, parentRoute: '/disposiciones', titleComponent: null
    },
    {
        path: '/firmar-disposicion/:id', exact: true, name: 'Firmar documentos', component: Show, parentRoute: null, titleComponent: null
    },
    {
        path: '/vizualizar-expediente/:id', exact: true, name: 'Vizualizar expediente', component: VisualizeProvisions, parentRoute: '/disposiciones', titleComponent: null
    },
    /** Route of Exceptions **/
    {
        path: '/excepciones', exact: true, name: 'Solicitudes de excepciones', component: Exceptions, parentRoute: null, titleComponent: null
    },
    {
        path: '/inventario', exact: true, name: 'Inventario', component: Inventory, parentRoute: null, titleComponent: null
    },
    /** Units **/
    {
        path: '/unidades', exact: true, name: 'Unidades en revisión', component: Units, parentRoute: null, titleComponent: [
            { icon: "add", class: "secondary", primary: true, name: "Crear Nuevo", look: "", dialog: 'CreateProvisions' }
        ]
    },
    /** Reports **/
    // {
    //     path: '/validaciones-periodicas', exact: true, name: 'Validaciones periódicas', component: PeriodicValidation, parentRoute: null, titleComponent: null
    // },
    {
        path: '/estadisticas-disposiciones', exact: true, name: 'Estadísticas de disposiciones', component: ProvisionsStadistics, parentRoute: null, titleComponent: null
    },
    {
        path: '/auditorias', exact: true, name: 'Auditorias', component: Audits, parentRoute: null, titleComponent: [
            // { icon: "", primary: true, name: "Ver archivadas", look: "", uri: '/cuentas-archivadas' },
            { icon: " icon vista-calendario", class: "secondary", primary: true, name: "Cambiar Vista", look: "", uri: '/cambiar-vista-calendario' },
            { icon: "add", class: "secondary", primary: true, name: "Crear Nuevo", look: "", uri: '/crear-auditoria' },

        ]
    },
    {
        path: '/crear-auditoria', exact: true, name: 'Crear auditoria', component: CreateEditAudit, parentRoute: '/auditorias', titleComponent: null
    },
    {
        path: '/editar-auditoria/:id', exact: true, name: 'Editar Auditoria', component: CreateEditAudit, parentRoute: '/auditorias', titleComponent: null
    },
    {
        path: '/cambiar-vista-calendario', exact: true, name: 'Calendario de auditorias', component: Calendar, parentRoute: null, titleComponent: [
            // { icon: "", primary: true, name: "Ver archivadas", look: "", uri: '/cuentas-archivadas' },
            { icon: " icon vista-calendario", class: "secondary", primary: true, name: "Cambiar Vista", look: "", uri: '/auditorias' },
            { icon: "add", class: "secondary", primary: true, name: "Crear Nuevo", look: "", uri: '/crear-auditoria' },

        ]
    },

    {
        path: '/disposiciones-revision', exact: true, name: 'Unidades en Revisión', component: ProvisionReview, parentRoute: null, titleComponent: []
    },
    {
        path: '/disposiciones-revision/:id', exact: true, name: 'Visualizar Unidades', component: UnitsReview, parentRoute: '/disposiciones-revision', titleComponent: []
    },
    // {
    //     path: '/validaciones', exact: true, name: 'Validaciones periódicas', component: Validations, parentRoute: null, titleComponent: []
    // },

    {
        path: '/configuraciones', exact: true, name: 'Configuraciones', component: Config, parentRoute: null, titleComponent: []
    },

    {
        path: '/creditos-fallos', exact: true, name: 'Créditos con fallos', component: BadCredits, parentRoute: null, titleComponent: null
    },
    {
        path: '/emails-mesa-servicio', exact: true, name: 'Correo mesa de servicio', component: ListEmails, parentRoute: null, titleComponent: [
           { icon: "add", class: "secondary", primary: true, name: "Crear Nuevo", look: "", uri: '/crear-emails-mesa-servicio' },
        ]
    },
    {
        path: '/crear-emails-mesa-servicio', exact: true, name: 'Crear correo mesa de servicio', component: CreateEditEmails, parentRoute: '/emails-mesa-servicio', titleComponent: null
    },
    {
        path: '/editar-emails-mesa-servicio/:id', exact: true, name: 'Editar mesa de servicio', component: CreateEditEmails, parentRoute: '/emails-mesa-servicio', titleComponent: null
    },

    //Refinanciamientos
    {
        path: '/refinanciamientos/unidades', exact: true, name: 'Unidades para refinanciamiento', component: UnitsRefinancing, parentRoute: null, titleComponent: [
            { icon: "add", class: "secondary", primary: true, name: "Crear Nuevo", look: "", dialog: 'CreateRefinancing' }
        ]
    },
    {
        path: '/refinanciamientos', exact: true, name: 'Refinanciamientos', component: ListRefinancings, parentRoute: null, titleComponent: null
    },
    {
        path: '/crear-refinanciamiento', exact: true, name: 'Crear nuevo refinanciamiento', component: CreateRefinancing, parentRoute: '/refinanciamientos', titleComponent: null
    },
    {
        path: '/refinanciamientos/:id', exact: true, name: 'Visualizar refinanciamiento', component: VisualizeRefinancing, parentRoute: '/refinanciamientos', titleComponent: null
    },

    //Dias inhabiles
    {
        path: '/dias-inhabiles', exact: true, name: 'Días inhabiles', component: ListNonWorking, parentRoute: null, titleComponent: [
            { icon: "add", class: "secondary", primary: true, name: "Crear Nuevo", look: "", uri: '/crear-dia-inhabil' },

        ]
    },
    {
        path: '/crear-dia-inhabil', exact: true, name: 'Crear nuevo día inhabil', component: CreatedNonWorking, parentRoute: '/dias-inhabiles', titleComponent: null
    },
    {
        path: '/layout', exact: true, name: 'Generación de layout', component: Layout, parentRoute: null, titleComponent: null
    },
]

export default routes;
